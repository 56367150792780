<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">
                COSTO IMPORTACIÓN
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row class="mt-10">
                            <v-col cols="8" md="2" class="form-space">
                                <!-- <v-text-field class='text-caption' @keypress="onlyNumber" v-model="carpeta" label="N° Carpeta" :disabled="soloCarpeta" :filled="soloCarpeta" outlined dense></v-text-field> -->
                                <v-text-field class='text-caption' v-model="carpeta" label="N° Carpeta" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <!-- <v-btn class="mt-1" @click="verIntegra()" color="teal" fab x-small dark><v-icon>search</v-icon></v-btn>                                     -->
                                <v-btn class="mt-1" @click="dialogo_importa=true" color="teal" fab x-small dark><v-icon>search</v-icon></v-btn>                                    
                            </v-col>
                            <v-col cols="12" md="4" class="form-space">
                                <v-text-field class='text-caption' v-model="proveedor" label="Proveedor" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="2" class="form-space">
                                <v-text-field class='text-caption' v-model="mercaderia" label="Total Mercadeia" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <v-text-field class='text-caption' v-model="moneda" label="Moneda" :disabled="resto" :filled="resto" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="2" class="form-space">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="paridad" label="Paridad" :disabled="resto" :filled="resto" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-row>
                                    <v-col cols="8" md="8" class="form-space">
                                        <v-combobox @change="verFacturador()" class='text-caption' v-model="facturador" :items="itemFacturador" item-text="razon" label="Facturador" dense outlined :disabled="resto" :filled="resto"></v-combobox>
                                    </v-col>
                                    <v-col cols="4" md="4" class="form-space">
                                        <v-text-field class='text-caption' v-model="rut" label="Rut" disabled filled outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="4" class="form-space">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="factura" label="Factura" :disabled="resto" :filled="resto" outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="4" class="form-space">
                                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="fecha" label="Fecha" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                                            </template>
                                            <v-date-picker v-model="date1" @input="dateFecha()"></v-date-picker>
                                        </v-menu>
                                        <!-- <v-text-field class='text-caption' v-model="fecha" label="Fecha" :disabled="resto" :filled="resto" outlined dense></v-text-field> -->
                                    </v-col>
                                    <v-col cols="6" md="4" class="form-space">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="neto" label="Neto" :disabled="resto" :filled="resto" outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="4" class="form-space">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="iva" label="Iva" :disabled="resto" :filled="resto" outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="4" class="form-space">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="total" label="Total" :disabled="resto" :filled="resto" outlined dense></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="4" class="form-space">
                                         <v-btn @click="addItems()" class="ml-2 mt-1" fab x-small dark color="indigo"><v-icon>add</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="7" class="form-space">
                                <v-data-table dense dark :headers="head" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                                <template v-slot:item.accion="{ item }">
                                    <v-icon @click="deleteItems(item.id)" small>delete</v-icon>
                                </template>
                                </v-data-table>
                                <p class="text-center">
                                    <v-pagination v-model="page" :length="pageCount"></v-pagination>              
                                </p>
                                <v-row>
                                    <v-col cols="6" md="2">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="gasto" label="% Gasto" outlined dense disabled filled></v-text-field>
                                    </v-col>
                                    <v-col cols="6" md="4">
                                        <v-btn class="mt-2" @click="porcentaje()" color="indigo" rounded x-small dark>Calcular</v-btn>
                                    </v-col>
                                    <v-col cols="12" md="6" class="text-right">
                                        <v-btn v-if="btn_save!=true" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                                        <v-btn v-else small @click="saveCosto()" color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                                        <v-btn class="ml-2" small @click="clsInput()" color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-35px">
            </v-card-actions>
        </v-card>

        <!-- dialog importaciones -->
        <v-dialog v-model="dialogo_importa" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialogo_importa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="fila_importa" dense dark :search="busca_importa" :headers="head_importa" :items="items_importa" :page.sync="page_importa" :items-per-page="PerPage_importa"  @page-count="pageCount_importa = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="page_importa" :length="pageCount_importa"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>

        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"/>
    </div>
</template>
<style scoped>
.form-space {
    margin-top:-40px;
}
.text-size >>> td {
    font-size: 8pt !important;
    height: 25px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import axios from 'axios';
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
export default {
    name:'Costo', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            carpeta:'',
            soloCarpeta:false,
            resto:false,
            proveedor:'',
            mercaderia:0,
            moneda:'EUR',
            paridad:'',
            gasto:0,
            existe:'no',
            head:[
                { text: 'Facturador', align: 'start', sortable: true, value: 'facturador', width: '35%' },
                { text: 'Giro', align: 'start', sortable: true, value: 'giro', width: '25%' },
                { text: 'Factura', align: 'center', sortable: true, value: 'factura', width: '8%' },
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha', width: '20%' },
                { text: 'Total', align: 'center', sortable: true, value: 'total', width: '8%' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion', width: '4%' },
            ],
            items:[],
            itemFacturador:[],
            facturador:'',
            rut:'',
            giro:'',
            factura:'',
            fecha:'',
            neto:'',
            iva:'',
            total:'',
            idItems:0,
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            cierre:'',
            btn_save:false,
            //dialogo importa
            dialogo_importa:false,
            busca_importa:'',
            page_importa: 1,
            pageCount_importa: 0,
            PerPage_importa: 10,
            head_importa: [
                { text: 'Carpeta', align: 'start', sortable: true, value: 'carpeta', width: '20%' },
                { text: 'Fecha', align: 'start', sortable: true, value: 'fecha', width: '20%' },
                { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor', width: '60%' },
            ],
            items_importa: [],
        }
    },
    async mounted(){
        await this.buscarImporta();
        await this.buscaProveedor();
    },
    methods:{
        fila_importa(fila){
            //console.log(fila);
            this.carpeta=fila.carpeta;
            this.dialogo_importa=false;
            this.verIntegra();
        },
        async buscarImporta(){
            this.items_importa=[];
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/carpeta/pendientes/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.items_importa.push({
                            'carpeta':element.ID,
                            'fecha':element.Fecha,
                            'proveedor':element.Proveedor
                            })        
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        dateFecha(){
            this.fecha=this.date1;
            this.menu1=false;
        },
        async buscaProveedor(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/proveedores/'+this.contrato);
            const datos=response.data;
            this.itemFacturador=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemFacturador.push({
                        'rut':element.Rut,
                        'razon':element.Razon,
                        'giro':element.Giro
                    })        
                });
            }
        },
        verFacturador(){
            this.rut=this.facturador.rut;
            this.giro=this.facturador.giro;
        },
        async verIntegra(){
            this.loading=true;
            try {
                this.cierre='no';
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/integra/all/'+this.contrato+'/'+this.carpeta);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    if (datos[0].Estado=='C'){
                        this.cierre='si';
                        this.Msg='Carpeta Cerrada'
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();
                    }
                };
                if(this.cierre!='si'){
                    //this.btn_save=false;
                    await this.verCarpeta();
                }
            } catch (error) {
                console.log(error);
            };
            this.loading=false;
        },
        async verCarpeta(){
             if (this.carpeta.trim()!=''){
                this.loading=true;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/carpeta/'+this.contrato+'/'+this.carpeta);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    await this.verMercaderia();
                } else {
                    this.Msg='Carpeta No Existe'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
                this.loading=false;
             } else {
                this.Msg='Ingrese Carpeta'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
             }
        },
        async verMercaderia(){
            const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/costo/add/'+this.contrato+'/'+this.carpeta);
            const datos1=response1.data;
            if (datos1[0].existe=='si'){
                this.proveedor=datos1[0].Proveedor;
                //this.mercaderia=datos1[0].Precio;
                this.mercaderia=datos1[0].Total;
                this.soloCarpeta=true;
                await this.verGastos();
            }
        },
        async verGastos(){
            this.items=[];
            this.IdItems=0;
            const response2 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/costo/search/'+this.contrato+'/'+this.carpeta);
            const datos2=response2.data;
            if (datos2[0].existe=='si'){
                this.proveedor=datos2[0].Proveedor;
                //this.mercaderia=datos2[0].Mercaderia;
                this.moneda=datos2[0].Moneda;
                this.paridad=datos2[0].Paridad;
                //this.gasto=datos2[0].Gasto;
                this.soloCarpeta=true;
                datos2.forEach(element => {
                    this.idItems++;
                    this.items.push({
                        'id':this.idItems,
                        'facturador':element.Facturador, 
                        'rut':element.Rut, 
                        'giro':element.Giro, 
                        'factura':element.Factura, 
                        'fecha':element.Fecha, 
                        'neto':element.Neto, 
                        'iva':element.Iva, 
                        'total':element.Total, 
                    })                           
                });
            }
        },
        clsInput(){
            this.carpeta='';
            this.soloCarpeta=false;
            this.proveedor='';
            this.mercaderia=0;
            this.moneda='EUR'
            this.paridad='';
            this.gasto=0;
            this.resto=false;
            this.existe='no';
            this.items=[];
            this.idItems=0;
            this.cierre='';
            this.btn_save=false;
            this.clsLista();
            this.btn_save=false;
        },
        clsLista(){
            this.facturador='';
            this.rut='';
            this.giro='';
            this.factura='';
            this.fecha='';
            this.neto='';
            this.iva='';
            this.total='';
        },
        addItems(){
            if (this.carpeta.trim()!='' && this.proveedor.trim()!='' && parseInt(this.mercaderia)!=0){
                if (this.rut.trim()!='' && this.factura.trim()!='' && this.fecha.trim()!='' 
                        && this.neto.trim()!='' && this.total.trim()!=''){
                    this.idItems++;
                    this.items.push({
                        'id':this.idItems,
                        'facturador':this.facturador.razon, 
                        'rut':this.rut, 
                        'giro':this.giro, 
                        'factura':this.factura, 
                        'fecha':this.fecha, 
                        'neto':this.neto, 
                        'iva':this.iva, 
                        'total':this.total
                    });
                    this.btn_save=false;
                    this.clsLista();
                } else {
                    this.Msg='Completar Datos'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
            } else {
                this.Msg='Verificar Carpeta'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        deleteItems(n){
            this.items = this.items.filter(function(dat) {
                return dat.id !== n; 
            });
        },
        porcentaje(){
            if (this.carpeta.trim()!='' && this.proveedor.trim()!='' && parseInt(this.mercaderia)!=0
                 && this.paridad.trim()!='' && this.items.length!=0){
                let totalGastos=0;
                let totalMercaderia=parseFloat(this.mercaderia)*parseFloat(this.paridad);
                this.items.forEach(element => {
                    totalGastos=totalGastos+parseFloat(element.neto);
                });
                console.log(totalGastos,totalMercaderia);                
                this.gasto=parseFloat((totalGastos*100)/totalMercaderia);
                this.gasto=parseFloat(this.gasto).toFixed(2);
                //this.gasto=(totalGastos*100)/totalMercaderia;
                this.btn_save=true;
            } else {
                this.Msg='Completar Datos';
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async saveCosto(){
            if (this.carpeta.trim()!='' && this.proveedor.trim()!='' && parseInt(this.mercaderia)!=0
                 && this.paridad.trim()!='' && this.items.length!=0 && parseFloat(this.gasto)!=0){
                this.loading=true;
                //eliminar costo
                const r = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/costo/delete/'+this.contrato+'/'+this.carpeta);
                const d=r.data;
                if (d!='error'){
                    let filas=0;
                    for (let index = 0; index < this.items.length; index++) {
                        const element = this.items[index];
                        const params ={
                            'contrato': this.contrato, 
                            'carpeta': this.carpeta, 
                            'proveedor':this.proveedor, 
                            'mercaderia':this.mercaderia, 
                            'moneda':this.moneda, 
                            'paridad':this.paridad, 
                            'facturador':element.facturador, 
                            'rut':element.rut, 
                            'giro':element.giro, 
                            'factura':element.factura, 
                            'fecha':element.fecha, 
                            'neto':element.neto, 
                            'iva':element.iva, 
                            'total':element.total, 
                            'gasto':this.gasto,
                            'usuario':this.usu
                        };
                        const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/costo/nuevo',params);
                        const datos=response.data;
                        if (datos=='si'){
                            filas++
                        }
                    }
                    if (filas==this.items.length){
                        this.Msg='Costos Grabados'
                        this.color='success';
                        this.msgbox=true;
                        this.showSnack();
                        this.btn_save=false;
                    } else {
                        this.Msg='Inconsistencia de Datos'
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();
                    }
                } else {
                    this.Msg='Inconsistencia en Carpeta'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
                this.loading=false;
            } else {
                this.Msg='Completar Datos'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        }
    }
}
</script>
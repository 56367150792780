<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Productos</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Productos</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">      
          <span>Crear</span>
          <v-icon>{{CreateIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Web</span>
          <v-icon>{{WebIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">

          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>

      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
import CrearProducto from '@/components/CrearProducto.vue';
import WebProducto from '@/components/WebProducto.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Productos',
  components: { CrearProducto, WebProducto ,dialogo},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      CreateIcon:'create',
      WebIcon:'public',
      CreateIconDefault:'create',
      WebIconDefault:'public',
      comp0:true,
      comp1:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
  methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_2_1!=0) {
          this.comp0=false;
        };
        if(datos[0].Permiso_2_2!=0) {
          this.comp1=false;
        };
      } 
      this.moduloActivo();
    },
    moduloActivo(){
      if (this.comp0!=true){
          this.componente='CrearProducto';
          this.CreateIcon=this.IconSelect;
          this.WebIcon=this.WebIconDefault;
          this.value=0;
      } else {
          if (this.comp1!=true){
            this.componente='WebProducto';
            this.CreateIcon=this.CreateIconDefault;
            this.WebIcon=this.IconSelect;
            this.value=1;
          } else {
              this.CreateIcon=this.CreateIconDefault;
              this.WebIcon=this.WebIconDefault;
            }
        }
        this.loading=false;
    },
    OpenComponent(valor){
      let vComponente;
      switch (valor) {
        case 0:
          vComponente='CrearProducto';
          this.CreateIcon=this.IconSelect;
          this.WebIcon=this.WebIconDefault;
          break;
        case 1:
          vComponente='WebProducto';
          this.CreateIcon=this.CreateIconDefault;
          this.WebIcon=this.IconSelect;
          break;
      }
      this.componente=vComponente;
    }
  }
}
</script>
<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">PAGO CLIENTE</v-card-title>
            <v-card-text>
                <v-row  class="mt-1">
                    <v-col cols="12" md="6">
                        <v-row class="mt-5">
                            <v-col cols="7" md="4" class="mt-n5">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" outlined dense filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-text-field class='text-caption' v-model="razon" label="Razon Social" outlined dense filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="2" md="1" class="mt-n5">
                                <v-btn @click="clientes=true" small color="indigo" dark fab><v-icon>list</v-icon></v-btn>
                            </v-col>
                            <v-col cols="2" md="1" class="mt-n5 text-center">
                                <v-btn v-if="list_comprobante==true" @click="verComprobantes(rut)" small color="teal" dark fab><v-icon>receipt</v-icon></v-btn>
                                <v-btn v-else small color="grey" dark fab><v-icon>receipt</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="12" class="mt-n8">
                                <v-data-table :loading="pagosprogress"  loading-text="Cargando ..."  height="150px" dark dense :headers="header" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                                    <template v-slot:item.accion="{ item }">
                                        <div v-if="item.tipodte!=2"> <!-- si no es NC -->
                                            <v-icon class="ml-2" v-if="item.estado!='V'" @click="ck_push(item)" color='green' :disabled="disabled">check_box_outline_blank</v-icon>
                                            <v-icon class="ml-2" v-else @click="ck_push(item)" color='green' :disabled="disabled">check_box</v-icon>
                                        </div>
                                        <div v-else> <!-- y si es NC -->
                                            <v-icon class="ml-2" v-if="item.estado!='V'" @click="ck_push(item)" color='red' :disabled="disabled">check_box_outline_blank</v-icon>
                                            <v-icon class="ml-2" v-else @click="ck_push(item)" color='red' :disabled="disabled">check_box</v-icon>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-pagination v-model="page" :length="pageCount" total-visible="5"></v-pagination> 
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-alert class='text-body-2' text border="left" color="success">
                                    <strong>Total a Pagar:
                                        <div class="float-right">
                                            ${{new Intl.NumberFormat("de-DE").format(parseFloat(totalPagar))}}
                                        </div>
                                    </strong>
                                </v-alert>                                
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row class="mt-5">
                            <v-col cols="12" md="4" class="mt-n5">
                                <v-select class='text-caption' @change="addRef(pago)" v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" dense outlined :disabled="disabled"></v-select>
                            </v-col>
                            <v-col cols="12" md="4" class="mt-n5">
                                <v-select v-if="pago=='Nota de Credito'" class='text-caption' @change="pagoNC(idNC)" v-model="idNC" :items="NC" item-text="texto" item-value="id" label="Referencia" dense outlined></v-select>
                                <v-text-field v-else class='text-caption' v-model="referencia" label="Referencia" dense outlined :disabled="disabled"></v-text-field>
                            </v-col>
                            <v-col cols="9" md="3" class="mt-n5">
                                <v-text-field class='text-caption' @keypress="onlyNegative" v-model="monto" label="Monto" dense outlined :disabled="disabled"></v-text-field>
                            </v-col>
                            <v-col cols="3" md="1" class="mt-n5">
                                <div v-if="disabled!=true">
                                    <v-btn @click="addPagos()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                                </div>
                                <div v-else>
                                    <v-btn small fab dark color="grey"><v-icon>playlist_add</v-icon></v-btn>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12" class="mt-n8">
                                <v-data-table height="150px" dense :headers="header_pago" :items="items_pago" :page.sync="pago_page" :items-per-page="pago_itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pago_pageCount = $event">
                                    <template v-slot:item.accion="{ item }">
                                        <v-icon small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                    </template>
                                </v-data-table>
                            </v-col>
                            <!-- <v-col cols="12" md="3" class="mt-n5">
                                <v-pagination v-model="pago_page" :length="pago_pageCount" total-visible="1"></v-pagination>
                            </v-col> -->
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-alert class='text-body-2' text border="left" color="warning">
                                    <strong>Saldo:
                                        <div class="float-right">
                                            ${{new Intl.NumberFormat("de-DE").format(parseFloat(totalSaldo))}}
                                        </div>
                                    </strong>
                                </v-alert>                                
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-alert class='text-body-2' text border="left" color="indigo">
                                    <strong>Total Recaudación:
                                        <div class="float-right">
                                            ${{new Intl.NumberFormat("de-DE").format(parseFloat(totalRecauda))}}
                                        </div>
                                    </strong>
                                </v-alert>                                
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions style="margin-top:-45px">
                <v-checkbox @click="ck_all()" v-model="ckall" color="success" label="Pagar Todo" :disabled="disabled"></v-checkbox>
                <div v-if="linkReport!=''">
                    <a target="_blank" :href="linkReport" style="text-decoration: none">
                        <v-btn class="ml-5" @click="estadoCuenta()" rounded color="teal" dark x-small>&nbsp;Estado de Cuenta&nbsp;</v-btn>
                    </a>
                </div>
                <div v-else>
                    <v-btn class="ml-5" rounded color="grey" dark x-small>&nbsp;Estado de Cuenta&nbsp;</v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-btn class="mr-2" @click="Nuevo()" rounded color="teal" dark>&nbsp;&nbsp;&nbsp;&nbsp;NUEVO&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
                <div v-if="btn==true">
                    <v-btn class="mr-2" @click="Pagar()" rounded color="teal" dark>&nbsp;&nbsp;&nbsp;&nbsp;PAGAR&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
                </div>
                <div v-else>
                    <v-btn class="mr-2" rounded color="grey" dark>&nbsp;&nbsp;&nbsp;&nbsp;PAGAR&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
                </div>
                <div v-if="linkPago!=''">
                    <a target="_blank" :href="linkPago" style="text-decoration: none">
                        &nbsp;&nbsp;<v-icon class="mt-2" color="teal">print</v-icon>&nbsp;&nbsp;
                    </a>
                </div>
                <div v-else>
                    &nbsp;&nbsp;<v-icon class="mt-2"  color="grey">print</v-icon>&nbsp;&nbsp;
                </div>
            </v-card-actions>
        </v-card>
        <!-- dialog clientes --> 
        <v-dialog v-model="clientes" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="clientes=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field prepend-inner-icon="search" class='ml-5 mr-2 text-caption' v-model="clientesearch" label="Buscar" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table  :loading="clienteprogress"  loading-text="Cargando ..."  @click:row="handleClientes" dense dark :search="clientesearch" :headers="clientetitulo" :items="clientelista" :page.sync="clientepage" :items-per-page="clienteitemsPerPage"  @page-count="clientepageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="clientepage"  total-visible="8" :length="clientepageCount"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
        <!-- dialog comprobantes -->
        <v-dialog v-model="dialog_comprobante" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
                <v-card-title>
                    <v-btn @click="dialog_comprobante=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                    <v-spacer>
                        <div class="float-right">
                        <v-text-field prepend-inner-icon="search" class='ml-5 mr-2 text-caption' v-model="search_comprobante" label="Buscar" dense required></v-text-field>
                        </div>
                    </v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-data-table 
                        hide-default-footer class="elevation-1 text-size col-color"
                        :search="search_comprobante" 
                        :headers="headers_comprobantes" 
                        :items="items_comprobantes" 
                        :page.sync="page_comprobante" 
                        :items-per-page="itemsPerPage_comprobante" 
                        @page-count="pageCount_comprobante = $event" dense>
                        <template v-slot:item.ruta="{ item }">
                            <a target="_blank" :href="item.ruta"><v-icon small >print</v-icon></a>                        
                        </template>
                        <template v-slot:item.accion="{ item }">
                            <v-btn v-if="item.accion=='Padre'" @click="delete_comprobante(item)" icon><v-icon color="red" small >delete</v-icon></v-btn>                        
                            <v-icon v-else color="grey" small >block</v-icon>
                        </template>
                    </v-data-table>
                    <v-pagination v-model="page_comprobante" :length="pageCount_comprobante"></v-pagination>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOGO CHEQUE -->
        <v-dialog v-model="dialogCheque" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>CHEQUES
                <v-spacer></v-spacer>
                <v-btn @click="dialogCheque=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="fechaCheque" label="Fecha" prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaCheque" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                   <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="rutCheque" label="Rut" placeholder="99999999-K" outlined dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="7">
                        <v-select class='text-caption' v-model="bancoCheque" :items="bancos" item-text="name" item-value="id" label="Seleciona Banco" outlined dense></v-select>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="cuentaCheque" label="N° Cuenta" outlined dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="numeroCheque" label="N° Cheque" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="montoCheque" label="Monto" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn @click="saveCheque()" class="mt-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>              
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 8pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import axios from 'axios';
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
export default {
    name:'PagoCliente',
    props: ['usu','contrato'],
    components:{dialogo, snack},
    data(){
        return{
            cuenta:'Cobrar',
            disabled:false,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',

            rut:'',
            razon:'',
            pago:'',
            referencia:'',
            monto:'',
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'2',forma:'T.Credito'},
                {id:'3',forma:'T.Debito'},
                {id:'4',forma:'Transferencia'},
                {id:'5',forma:'Cheque'},
                {id:'6',forma:'Ajuste'},
                {id:'7',forma:'Nota de Credito'}
            ],
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            header: [
                //{ text: 'Fecha', align: 'start', sortable: false, value: 'fecha' },
                { text: 'Vencimiento', align: 'center', sortable: false, value: 'vencimiento' },
                { text: 'Documento', align: 'start', sortable: false, value: 'documento' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Total', align: 'start', sortable: true, value: 'total' },
                { text: 'Pagar', align: 'start', sortable: false, value: 'accion' }
            ],
            items: [],
            pagosprogress:false,
            id_pago:1,
            header_pago: [
                { text: 'Forma Pago', align: 'start', sortable: true, value: 'pago' },
                { text: 'Referencia', align: 'start', sortable: false, value: 'referencia' },
                { text: 'Monto', align: 'center', sortable: false, value: 'monto' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            items_pago: [],
            pago_page: 1,
            pago_pageCount: 0,
            pago_itemsPerPage: 4,
            inTotal:0,
            totalPagar:0,
            totalRecauda:0,
            totalSaldo:0,
            btn:false,
            clientes:false,
            clientesearch:'',
            clientetitulo:[
                { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'nombre' },
            ],
            clientelista:[],
            clientepage: 1,
            clientepageCount: 0,
            clienteitemsPerPage: 10,
            clienteprogress:false,
            ckall:false,
            folio:0,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            linkReport:'',
            linkPago:'',
            NC:[],
            idNC:null,
            valorNota:0,

            dialog_comprobante:false,
            headers_comprobantes:[],
            items_comprobantes:[],
            search_comprobante:'',
            page_comprobante: 1,
            pageCount_comprobante: 0,
            itemsPerPage_comprobante: 8,
            list_comprobante:false,

            //datos Cheque
            dialogCheque:false,
            fechaCheque: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu2:false,
            rutCheque:'',
            bancos:[
                {id:1,name:'Banco Estado'},
                {id:2,name:'Banco de Chile / Edwards'}, 
                {id:3,name:'Banco de Crédito e Inversiones (BCI)'}, 
                {id:4,name:'Banco Bice'},
                {id:5,name:'Banco Santander'}, 
                {id:6,name:'Itaú / Corpbanca'}, 
                {id:7,name:'Banco Security'},
                {id:8,name:'Scotiabank'},
                {id:9,name:'Banco Falabella'}, 
                {id:10,name:'Banco Ripley'},
                {id:11,name:'Banco Consorcio'}, 
                {id:12,name:'Banco Internacional'}
            ],
            bancoCheque:'',
            cuentaCheque:'',
            numeroCheque:'',
            montoCheque:'',
            listaCheques:[],            

        }
    },
    mounted(){
        this.search_entidad();
    },
    methods:{
        addRef(t){
            this.referencia='';
            this.monto='';
            if(t=='Cheque'){
                this.limpiarCheque();
                this.dialogCheque=true;
            }
        },
        limpiarCheque(){
            this.fechaCheque= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.rutCheque='';
            this.bancoCheque='';
            this.cuentaCheque='';
            this.numeroCheque='';
            this.montoCheque='';
        },
        async saveCheque(){
            let ruts=this.validaRut(this.rutCheque);
            if (ruts){
                if(this.orden!='' && this.bancoCheque!='' && this.cuentaCheque!='' && this.montoCheque!='' && this.numeroCheque!=''){
                    //validar si el cheque existe en la lista
                    const enLista=this.validaLista();
                    if(enLista){
                        this.Msg='Cheque existe en la lista'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    } else {
                        //agregar en Lista
                        this.listaCheques.push({
                            'id':this.id_pago,
                            'contrato':this.contrato,
                            'fecha':this.fechaCheque,
                            'rut':this.rutCheque,
                            'banco':this.bancoCheque,
                            'cuenta':this.cuentaCheque,
                            'numero':this.numeroCheque,
                            'monto':this.montoCheque,
                            'movimiento':'MultiPago',
                            'documento':this.orden,
                            'tipo':this.docDTE
                        });
                        this.referencia="CH " + this.numeroCheque;
                        this.monto=this.montoCheque;
                        this.addPagos();
                        this.dialogCheque=false;
                    }
                } else {
                    this.Msg='Dato Obligatorio'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Rut no Valido'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        validaRut(rutCompleto){
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			    return false;
		    var tmp 	= rutCompleto.split('-');
		    var digv	= tmp[1]; 
		    var ruti 	= tmp[0];
		    if ( digv == 'K' ) digv = 'k' ;
		    return (this.dv(ruti) == digv );
        },
        dv(T){
            var M=0,S=1;
    		for(;T;T=Math.floor(T/10))
	    		S=(S+T%10*(9-M++%6))%11;
		    return S?S-1:'k';
        },
        validaLista(){
            let validar="no";
            if(this.listaCheques.length!=0){
                this.listaCheques.forEach(element => {
                    if (element.rut==this.rutCheque && 
                        element.banco==this.bancoCheque && 
                        element.cuenta==this.cuentaCheque &&
                        element.numero==this.numeroCheque)
                    validar="si";
                });
            };
            if (validar=="si"){
                return true
            } else {
                return false
            }
        },
        async grabarCheques(){
            try {
                if (this.listaCheques.length!=0){
                    for (let index = 0; index < this.listaCheques.length; index++) {
                        const element = this.listaCheques[index];
                        const params={
                            'contrato':element.contrato,
                            'fecha':element.fecha,
                            'rut':element.rut,
                            'banco':element.banco,
                            'cuenta':element.cuenta,
                            'numero':element.numero,
                            'monto':element.monto,
                            'movimiento':element.movimiento,
                            'documento':this.folio,
                            'tipo':99, // comprobantes
                        }
                        await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/add/cheque/prepago/',params);
                    };
                };
            } catch (error) {
                console.log(error)
            }
        },
        clsInput(){
            this.rut='';
            this.razon='';
            this.items=[];
            this.id_pago=1;
            this.items_pago=[];
            this.inTotal=0;
            this.totalPagar=0;
            this.totalRecauda=0;
            this.totalSaldo=0;
            this.ckall=false;
            this.folio=0;
            this.btn=false;
            this.disabled=false;
            this.pago='';
            this.referencia='';
            this.monto='';
            this.linkReport='';
            this.linkPago='';
            this.NC=[];
            this.idNC=null;
            this.list_comprobante=false;
            this.limpiarCheque();
            this.listaCheques=[];
        },
        Nuevo(){
            this.clsInput();
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        onlyNegative ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 45) { // && keyCode !== 46 =>,  y  keyCode !== 46 => -
                $event.preventDefault();
            }
        },
        async search_entidad(){
            this.clienteprogress=true;
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/lista/clientes/'+this.contrato);
                const datos=response.data;
                this.clientelista=[];
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        if(element.Rut!='1-9'){
                            this.clientelista.push({
                                entidad:element.ID,
                                nombre:element.Nombre,
                                rut:element.Rut,
                                email:element.Email,
                                direccion:element.Direccion,
                                comuna:element.Comuna,
                                ciudad:element.Ciudad,
                                giro:element.Observacion,
                            })
                        }
                    });
                }                
            } catch (error) {
                console.log(error)
            };
            this.clienteprogress=false;
        },
        async handleClientes(fila) {
            this.clsInput();
            this.rut=fila.rut;
            this.razon=fila.nombre;
            this.clientes=false;
            this.pagosprogress=true;
            await this.notas_creditos(fila.entidad);
            await this.search_creditos(fila.entidad);
            this.pagosprogress=false;
        },
        async notas_creditos(ente){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/search/notas/cliente/v2/'+this.contrato+'/'+ente);
                const datos=response.data;
                if (datos[0].existe=='si'){ 
                    this.NC=[];
                    var indice=0;
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        //buscamos el valor de los pagos
                        const resp = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sumas/notas/cliente/v2/'+this.contrato+'/'+element.Numero);
                        const dato=resp.data;
                        const sumaNotas=dato[0].Monto;
                        if(parseFloat(element.Total)-parseFloat(sumaNotas)!=0){
                            this.NC.push({
                                id:indice,
                                fecha:element.Fecha,
                                numero:element.Numero,
                                total:element.Total,
                                sumas:sumaNotas,
                                texto: 'NC '+element.Numero
                            });
                            indice++;                        
                        }
                    };
                }                
            } catch (error) {
                console.log(error)
            }
        },
        async pagoNC(i){
            this.referencia=this.NC[i].texto
            //this.monto=this.NC[i].total;
            this.monto=this.NC[i].total-this.NC[i].sumas;            
            this.valorNota=this.monto;
        },
        isNum(val){
            return !isNaN(val)
        },
        addPagos(){
            var sigue='no';
            if(this.pago=='Nota de Credito'){
                if(parseFloat(this.monto) <= parseFloat(this.valorNota)){
                    sigue='si'
                } else {
                    sigue='no';
                    this.Msg='Monto Supera Valor de NC';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } 
            } else {
                sigue='si'
            };
            if(sigue=="si"){
                if(this.pago!=''){
                    if (this.referencia.trim()!=''){
                        if (this.isNum(this.monto)){
                            if(parseFloat(this.monto)!=0){
                                this.inTotal=this.inTotal+parseFloat(this.monto);
                                if (parseFloat(this.inTotal)<=parseFloat(this.totalPagar)){
                                    this.totalRecauda=this.inTotal;                        
                                    this.items_pago.push({
                                        'id':this.id_pago,
                                        'pago': this.pago,
                                        'referencia': this.referencia,
                                        'monto':new Intl.NumberFormat("de-DE").format(parseFloat(this.monto)),
                                        'valor':this.monto
                                    });
                                    this.id_pago++;
                                    this.totalSaldo=parseFloat(this.totalPagar)-parseFloat(this.totalRecauda);
                                    this.btn=true;
                                    // if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar)){ this.btn=true } 
                                    // else { this.btn=false }
                                } else {
                                    this.inTotal=this.inTotal-parseFloat(this.monto);
                                    this.Msg='Excede Total a Pagar'
                                    this.msgbox=true;
                                    this.color='red';
                                    this.showSnack();
                                };
                                this.pago='';
                                this.referencia='';
                                this.monto='';
                            }
                        } else {
                            this.Msg='Ingrese Monto Valido'
                            this.msgbox=true;
                            this.color='warning';
                            this.showSnack();
                        }
                    } 
                } 
            };
            console.log(this.items_pago);
            console.log(this.listaCheques);
        },
        async deleteItem(fila){
            this.items_pago = this.items_pago.filter(function(dat) {
                return dat.id !== fila.id; 
            });
            this.listaCheques = this.listaCheques.filter(function(dat) {
                return dat.id !== fila.id; 
            });
            this.inTotal=this.inTotal-parseFloat(fila.valor);
            this.totalRecauda=this.totalRecauda-parseFloat(fila.valor);
            if(parseFloat(this.totalRecauda)!=0){
                this.totalSaldo=parseFloat(this.totalPagar)-parseFloat(this.totalRecauda);
            } else {
                this.totalSaldo=0
                this.btn=false;
            }            
        },
        format_fecha(date){
            var d = new Date(date);
            date = [
                ('0' + d.getDate()).slice(-2),
                ('0' + (d.getMonth() + 1)).slice(-2),
                d.getFullYear()
            ].join('-');
            return date;
        },
        async search_creditos(ente){
            try {
                if(ente.trim()!=''){
                    const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/one/'+this.contrato +'/'+ente);
                    const datos=response.data;
                    this.items=[];
                    if (datos[0].existe=='si'){
                        var vence='';
                        datos.forEach(element => {
                            if(parseFloat(element.TipoDte)==2 || parseFloat(element.TipoDte)==0){ 
                                vence='--'
                            }
                            else {
                                const dias=parseInt(element.TipoPago);
                                const fec=new Date(element.Fecha);
                                fec.setDate(fec.getDate() + dias);
                                vence=this.format_fecha(fec);
                            };
                            this.items.push({
                                'entidad':ente,
                                'fecha':element.Fecha,
                                'vencimiento':vence,
                                'tipodte':element.TipoDte,
                                'documento':element.Documento,
                                'numero':element.Numero,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'monto':element.Total,
                                'estado':'N'
                            })        
                        });
                        //generamos el estado de Cuenta
                        await this.estadoCuenta();
                    }
                };
                this.list_comprobante=true;
            } catch (error) {
                console.log(error)
            };
        },
        ck_push(fila){
            if(fila.estado=='N'){
                fila.estado='V';
                if(parseFloat(fila.tipodte)!=2){
                    this.totalPagar=parseFloat(this.totalPagar)+parseFloat(fila.monto);
                } else {
                    this.totalPagar=parseFloat(this.totalPagar)-parseFloat(fila.monto);
                };
                if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar) 
                    && parseFloat(this.totalRecauda)!=0 
                    && parseFloat(this.totalPagar)!=0){ this.btn=true } 
                else { this.btn=false }
            } else { 
                fila.estado='N';
                this.ckall=false;
                if(parseFloat(fila.tipodte)!=2){
                    this.totalPagar=parseFloat(this.totalPagar)-parseFloat(fila.monto);
                } else {
                    this.totalPagar=parseFloat(this.totalPagar)+parseFloat(fila.monto);
                };
                if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar) 
                    && parseFloat(this.totalRecauda)!=0 
                    && parseFloat(this.totalPagar)!=0){ this.btn=true } 
                else { this.btn=false }
            }
        },
        ck_all(){
            if(this.items.length!=0){
                this.totalPagar=0;
                if(this.ckall==true){
                    this.items.forEach(element => {
                        element.estado='V';
                        if(parseFloat(element.tipodte)!=2){
                            this.totalPagar=parseFloat(this.totalPagar)+parseFloat(element.monto);
                        } else {
                            this.totalPagar=parseFloat(this.totalPagar)-parseFloat(element.monto);
                        };
                        if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar) 
                            && parseFloat(this.totalRecauda)!=0 
                            && parseFloat(this.totalPagar)!=0){ this.btn=true } 
                        else { this.btn=false }
                    });
                } else {
                    this.items.forEach(element => {
                        element.estado='N';
                        if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar) 
                            && parseFloat(this.totalRecauda)!=0 
                            && parseFloat(this.totalPagar)!=0){ this.btn=true } 
                        else { this.btn=false }
                    });
                }
            }
        },
        async Pagar(){
            this.loading=true;
            try {
                this.pago='';
                this.referenciav
                this.monto='';
                this.btn=false;
                this.folio = Date.now() // timestamp
                if(parseFloat(this.folio)!=0){
                    var reg = 0;
                    var sigue='no';
                    //grabar pago Cliente
                    for (let index = 0; index < this.items.length; index++) {
                        const element = this.items[index];
                        if(element.estado=='V'){
                            const params={
                                'contrato':this.contrato,
                                'fecha':this.date1,
                                'folio':this.folio,
                                'rut': this.rut,
                                'razon':this.razon,
                                'entidad':element.entidad,
                                'tipodte': element.tipodte,
                                'documento':element.documento,
                                'numero':element.numero,
                                'vencimiento':element.vencimiento,
                                'monto':element.monto,
                                'usuario':this.usu,
                                'cuenta':this.cuenta,
                            }
                            const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/pago/nuevo/',params);
                            const datos=response.data;
                            if (datos=='si'){ 
                                reg++;
                            }                
                        }
                    };
                    // comprobar la grabacion de todos los dte
                    const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/search/folio/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                    const datos=response.data;
                    if (datos[0].existe=='si'){
                        if(datos.length==reg){
                            sigue='si';
                        } else {
                            //no grabo todo lo seleccionado por ende se borran registros
                            const res=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                            const dat=res.data;
                        }
                    } else {
                            //no encontro registros con el folio creado o bien no lee la base de datos, se borran regisros de todas formas 
                            const res=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                            const dat=res.data;
                    }
                    // grabar formas de pago siempre y cuando la grabacion de documentos este OK
                    if(sigue=='si'){
                        //grabar pagos
                        reg=0;
                        sigue='no';
                        for (let index = 0; index < this.items_pago.length; index++) {
                            const element = this.items_pago[index];
                            const params={
                                'contrato':this.contrato,
                                'fecha':this.date1,
                                'folio':this.folio,
                                'formapago': element.pago,
                                'referencia':element.referencia,
                                'monto':element.valor,
                                'usuario':this.usu,
                                'cuenta':this.cuenta,
                            }
                            const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/formapago/nuevo/',params);
                            const datos=response.data;
                            if (datos=='si'){ 
                                reg++;
                            }                
                        };
                        // comprobar la grabacion de todas las formas de pagos
                        const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/search/pagos/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                        const datos=response.data;
                        if (datos[0].existe=='si'){
                            if(datos.length==reg){
                                sigue='si';
                            } else {
                                //no grabo todo lo ingresado por ende se borran registros de ambas tablas
                                const res=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                                const dat=res.data;
                                const res1=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/pagos/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                                const dat1=res1.data;
                            }
                        } else { 
                            //no encontro registros con el folio creado o bien no lee la base de datos, se borran regisros de todas formas 
                            const res=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                            const dat=res.data;
                            const res1=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/pagos/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                            const dat1=res1.data;
                        }
                    }
                    if(sigue=='si'){
                        await this.grabarCheques();
                        await this.comprobante();
                        this.Msg='Pago Exitoso'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                        this.disabled=true;
                    } else {
                        this.Msg='Algo salio mal, intentelo nuevamente'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Folio no creado, intentelo nuevamente'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                };
            } catch (error) {
                console.log(error)
            };
            this.loading=false;
        },
        async estadoCuenta(){
            if(this.items.length!=0){
                const date_1 = this.date1.split("-");
                const documentDate = date_1[2]+'-'+date_1[1]+'-'+date_1[0];
                var salida='';
                const encabezado=`
                    <table width='100%'>
                    <tr>
                        <td style='width:5%;'></td>
                        <td style='width:90%;'>
                            <table width='100%'>
                                <tr>
                                    <td style='width:10%;'><strong>Fecha:</strong></td>
                                    <td style='width:90%;'><strong>`+documentDate+`</strong>
                                </tr>
                                <tr>
                                    <td style='width:10%;'><strong>Cliente:</strong></td>
                                    <td style='width:90%;'><strong>`+this.razon+`</strong></td>
                                </tr>
                                <tr>
                                    <td style='width:10%;'><strong>Rut:</strong></td>
                                    <td style='width:90%;'><strong>`+this.rut+`</strong></td>
                                </tr>
                            </table>
                        </td>
                        <td style='width:5%;'></td>
                    </tr>
                    </table>
                `;
                const titulo=`
                    <table width='100%' style='margin-top:2px'>
                    <tr>
                    <td align='center' style='width:100%;'><h3>ESTADO DE CUENTA</h3></td>
                    </tr>
                    </table>
                    <table width='100%' style='margin-top:2px'>  
                        <tr>
                        <td style='width:5%'></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Fecha Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Tipo Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;N° Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Monto&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Fecha Pago&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Estado&nbsp;&nbsp;</b></td>
                        <td style='width:5%'></td>
                        </tr>
                `;
                var detalle=''
                var montoDocumentos=0;
                this.items.forEach(element => {
                    const date_fecha = element.fecha.split("-");
                    const fillDate = date_fecha[2]+'-'+date_fecha[1]+'-'+date_fecha[0];
                    var status=''
                    if(element.vencimiento!='--'){
                        const registro = element.vencimiento.split("-");
                        const fec = registro[2]+'-'+registro[1]+'-'+registro[0];
                        if(new Date(fec)>new Date(this.date1)){
                            status='Pendiente';
                        } else {
                            status='Vencido';
                        };
                    } else {
                        status='Pendiente';
                    }
                    detalle=detalle+`
                        <tr>
                        <td></td>
                        <td align='center' style='border:1px solid #000'>`+fillDate+`</td>
                        <td align='center' style='border:1px solid #000'>`+element.documento+`</td>
                        <td align='center' style='border:1px solid #000'>`+element.numero+`</td>
                        <td align='right' style='border:1px solid #000'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(element.monto))+`</td>
                        <td align='center' style='border:1px solid #000'>`+element.vencimiento+`</td>
                    `;
                    if(status=='Vencido'){
                        detalle=detalle+                    `
                            <td align='center' style='border:1px solid #000;color:red'>`+status+`</td>
                            <td></td>
                            </tr>                    
                        `;
                    } else{
                        detalle=detalle+                    `
                            <td align='center' style='border:1px solid #000'>`+status+`</td>
                            <td></td>
                            </tr>                    
                        `;
                    };
                    montoDocumentos=montoDocumentos+parseFloat(element.monto);
                });
                detalle=detalle+'</table>';
                //total monto documentos
                var detalle2=`
                    <table width='100%' style='margin-top:20px'>
                    <tr>
                    <td style='width:5%'></td>
                    <td style='width:90%'><b>Total:&nbsp;&nbsp;$`+new Intl.NumberFormat("de-DE").format(parseFloat(montoDocumentos))+`</b></td>
                    <td style='width:5%'></td>
                    </tr>                    
                    </table>
                `;
                //notas de creditos
                const titulo3=`
                    <table width='100%' style='margin-top:2px'>
                    <tr>
                    <td align='center' style='width:100%;'><h3>NOTAS DE CREDITO</h3></td>
                    </tr>
                    </table>
                    <table width='100%' style='margin-top:2px'>  
                        <tr>
                        <td style='width:20%'></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Fecha Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Tipo Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;N° Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Saldo&nbsp;&nbsp;</b></td>
                        <td style='width:20%'></td>
                        </tr>
                `;
                var detalle3=''
                var monto3=0;
                this.NC.forEach(element => {
                    const date_fecha3 = element.fecha.split("-");
                    const date3 = date_fecha3[2]+'-'+date_fecha3[1]+'-'+date_fecha3[0];
                    monto3=parseFloat(element.total)-parseFloat(element.sumas);
                    detalle3=detalle3+`
                        <tr>
                        <td></td>
                        <td align='center' style='border:1px solid #000'>`+date3+`</td>
                        <td align='center' style='border:1px solid #000'>Nota de Credito</td>
                        <td align='center' style='border:1px solid #000'>`+element.numero+`</td>
                        <td align='right' style='border:1px solid #000'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(monto3))+`</td>
                        <td></td>
                        </tr>
                    `;
                });
                detalle3=detalle3+`</table>`;
                salida=encabezado+titulo+detalle+detalle2+titulo3+detalle3;
                await this.saveReport(salida);
            } else {
                this.Msg='Lista sin Datos'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async saveReport(HTML){
            try {
                //borramos el estado de cuenta actual
                await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/estadodecuenta/delete/'+this.contrato+'/'+this.date1+'/'+this.rut+'/Cliente');
                //ingresamos nuevo estado de cuenta
                const params={
                    'contrato':this.contrato,
                    'rut':this.rut,
                    'fecha': this.date1,
                    'html':HTML,
                    'tipo': 'Cliente'
                }
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/estadodecuenta/new/',params);
                const datos=response.data;
                if (datos=='si'){ 
                    console.log('ok');
                    //verificamos la carga del regitro
                    const res1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/html/estadodecuenta/'+this.contrato+'/'+this.date1+'/'+this.rut+'/Cliente');
                    const dat=res1.data;
                    if (dat!='no' && dat!='error'){
                        this.linkReport='https://www.transervi.cl/Api_TranServi/public/api/inv/html/estadodecuenta/'+this.contrato+'/'+this.date1+'/'+this.rut+'/Cliente';
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async comprobante(){
            if(this.items.length!=0){
                const date_1 = this.date1.split("-");
                const documentDate = date_1[2]+'-'+date_1[1]+'-'+date_1[0];
                var salida='';
                const encabezado=`
                    <table width='100%'>
                    <tr>
                        <td style='width:20%;'></td>
                        <td style='width:60%;'>
                            <table width='100%'>
                                <tr>
                                    <td style='width:10%;'><strong>Fecha:</strong></td>
                                    <td style='width:90%;'><strong>`+documentDate+`</strong>
                                </tr>
                                <tr>
                                    <td style='width:10%;'><strong>Cliente:</strong></td>
                                    <td style='width:90%;'><strong>`+this.razon+`</strong></td>
                                </tr>
                                <tr>
                                    <td style='width:10%;'><strong>Rut:</strong></td>
                                    <td style='width:90%;'><strong>`+this.rut+`</strong></td>
                                </tr>
                                <tr>
                                    <td style='width:10%;'><strong>Comprobante:</strong></td>
                                    <td style='width:90%;'><strong>`+this.folio+`</strong>
                                </tr>
                            </table>
                        </td>
                        <td style='width:20%;'></td>
                    </tr>
                    </table>
                `;
                const titulo=`
                    <table width='100%' style='margin-top:2px'>
                        <tr>
                            <td align='center' style='width:100%;'><h3>Documentos Pagados</h3></td>
                        </tr>
                    </table>
                    <table width='100%' style='margin-top:2px'>  
                        <tr>
                        <td style='width:20%'></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Fecha Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Tipo Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;N° Documento&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Monto&nbsp;&nbsp;</b></td>
                        <td style='width:20%'></td>
                        </tr>
                `;
                var detalle=''
                var montoDocumentos=0;
                this.items.forEach(element => {
                    const date_fecha = element.fecha.split("-");
                    const fillDate = date_fecha[2]+'-'+date_fecha[1]+'-'+date_fecha[0];
                    if(element.estado=='V'){
                        detalle=detalle+`
                            <tr>
                            <td></td>
                            <td align='center' style='border:1px solid #000'>`+fillDate+`</td>
                            <td align='center' style='border:1px solid #000'>`+element.documento+`</td>
                            <td align='center' style='border:1px solid #000'>`+element.numero+`</td>
                            <td align='right' style='border:1px solid #000'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(element.monto))+`</td>
                            <td></td>
                            </tr>                    
                        `;
                        montoDocumentos=montoDocumentos+parseFloat(element.monto);
                    }
                });
                //total monto documentos
                detalle=detalle+`
                    <tr>
                    <td></td>
                    <td align='right' colspan='3'><b>Total</b>&nbsp;&nbsp;</td>
                    <td align='right' style='border:1px solid #000'><b>$`+new Intl.NumberFormat("de-DE").format(parseFloat(montoDocumentos))+`</b></td>
                    <td></td>
                    </tr>                    
                `; detalle=detalle+'</table>';

                //pagos del comprobante
                const titulo2=`
                    <table width='100%' style='margin-top:2px'>
                        <tr>
                            <td align='center' style='width:100%;'><h3>Pagos</h3</td>
                        </tr>
                    </table>
                    <table width='100%' style='margin-top:2px'>  
                        <tr>
                        <td style='width:20%'></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Forma de Pago&nbsp;&nbsp;</b></td>
                        <td style='width:30%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Referencia&nbsp;&nbsp;</b></td>
                        <td style='width:15%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Monto&nbsp;&nbsp;</b></td>
                        <td style='width:20%'></td>
                        </tr>
                    `;
                var detalle2='';
                var montoPagos=0;
                this.items_pago.forEach(element => {
                    detalle2=detalle2+`
                        <tr>
                        <td></td>
                        <td align='center' style='border:1px solid #000'>`+element.pago+`</td>
                        <td align='left' style='border:1px solid #000'>`+element.referencia+`</td>
                        <td align='right' style='border:1px solid #000'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(element.valor))+`</td>
                        <td></td>
                        </tr>                    
                    `;
                    montoPagos=montoPagos+parseFloat(element.valor);
                });
                //total monto pagos
                detalle2=detalle2+`
                    <tr>
                    <td></td>
                    <td align='right' colspan='2'><b>Total</b>&nbsp;&nbsp;</td>
                    <td align='right' style='border:1px solid #000'><b>$`+new Intl.NumberFormat("de-DE").format(parseFloat(montoPagos))+`</b></td>
                    <td></td>
                    </tr>                    
                `; detalle2=detalle2+'</table>';
                //saldo
                var montoSaldo=parseFloat(montoDocumentos)-parseFloat(montoPagos);
                var detalle3=`
                    <table width='100%' style='margin-top:20px'>
                    <tr>
                    <td style='width:20%'></td>
                    <td style='width:60%'><b>Saldo:&nbsp;&nbsp;$`+new Intl.NumberFormat("de-DE").format(parseFloat(montoSaldo))+`</b></td>
                    <td style='width:20%'></td>
                    </tr>                    
                    </table>
                `
                salida=encabezado+titulo+detalle+titulo2+detalle2+detalle3;
                await this.saveComprobante(salida,montoDocumentos,montoPagos,montoSaldo);
            } else {
                this.Msg='Lista sin Datos'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async saveComprobante(HTML,montoDocumentos,montoPagos,montoSaldo){
            try {
                //ingresamos nuevo estado de cuenta
                const params={
                    'contrato':this.contrato,
                    'fecha': this.date1,
                    'numero':this.folio,
                    'rut':this.rut,
                    'nombre':this.razon,
                    'montodocumento':parseFloat(montoDocumentos),
                    'montopago':parseFloat(montoPagos),
                    'montosaldo':parseFloat(montoSaldo),
                    'html':HTML,
                    'tipo': 'Cliente',
                    'usuario':this.usu
                }
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/comprobante/new/',params);
                const datos=response.data;
                if (datos=='si'){ 
                    console.log('ok');
                    this.linkPago='https://www.transervi.cl/Api_TranServi/public/api/inv/html/comprobante/'+this.contrato+'/'+this.folio+'/Cliente';
                }
            } catch (error) {
                console.log(error)
            }
        },
        async verComprobantes(rut){
            this.loading=true;
            if(rut!=''){
                try {
                    this.headers_comprobantes=[];
                    this.items_comprobantes= [];
                    this.headers_comprobantes=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        //{ text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        //{ text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        //{ text: 'Tipo Documento', align: 'start', sortable: true, value: 'documento' },
                        { text: 'N° Documento', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Total Pago', align: 'center', sortable: false, value: 'total' },
                        { text: 'Saldo', align: 'center', sortable: false, value: 'saldo' },
                        { text: 'Ver', align: 'center', sortable: false, value: 'ruta' },
                        { text: '', align: 'center', sortable: false, value: 'accion' }
                    ]
                    const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/father/comprobante/'+this.contrato+'/'+rut+'/Cliente/Cobrar');
                    const datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items_comprobantes= [];
                        datos.forEach(element => {
                            let html_comprobante='https://www.transervi.cl/Api_TranServi/public/api/inv/html/comprobante/'+this.contrato+'/'+element.Numero+'/Cliente';
                            this.items_comprobantes.push({
                                'fecha':element.Fecha,
                                'rut':element.Rut,
                                'cliente':element.Nombre,
                                'documento':'Comprobante',
                                'numero':element.Numero,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.MontoPago)),
                                'saldo':new Intl.NumberFormat("de-DE").format(parseFloat(element.MontoSaldo)),
                                'ruta':html_comprobante,
                                'accion':element.Familia
                            })
                        });
                        this.dialog_comprobante=true;
                    }                    
                } catch (error) {
                    console.log(error)
                }
            };
            this.loading=false;
        },
        async delete_comprobante(item){
            try {
                this.loading=true;

                //borrar Comprobante
                const res_00=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/comprobante/'+this.contrato+'/'+item.numero+'/Cliente');
                const dat_00=res_00.data;
                //borrar Credito Entidad
                const res_01=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+item.numero+'/'+this.cuenta);
                const dat_01=res_01.data;
                //borrar Pagos Credito
                const res_02=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/pagos/'+this.contrato+'/'+item.numero+'/'+this.cuenta);
                const dat_02=res_02.data;
                //borrar Cheques Credito
                const res_03=await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/delete/cheques/prepagos/'+this.contrato+'/'+item.numero);
                const dat_03=res_03.data;

                await this.verComprobantes(item.rut)

                this.loading=false;                
            } catch (error) {
                console.log(error)
            }
        },

    }
}
</script>
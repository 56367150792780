<template>
    <div>
         <v-card class="mt-5">
            <v-card-title class="blue-grey white--text">NC S/Compra</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="9" md="2">
                            <v-text-field class='text-caption' v-model="rut" label="Rut Proveedor" :disabled="disabledRut" :filled="filledRut" outlined dense required></v-text-field>
                        </v-col>
                        <v-col cols="3" md="1">
                            <v-btn @click="verNCS(rut)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field class='text-caption' v-model="razon" label="Razon Social" disabled filled outlined dense></v-text-field>                        
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-select @change="SelectNota(nota)" class='text-caption' v-model="nota" :items='notas' label="Devolucion" item-text="name" item-value="id" dense outlined :disabled="disabledDev" :filled="filledDev"></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n5">
                        <v-col cols="8" md="2">
                            <v-text-field class='text-caption' v-model="numero" label="Nota Credito" outlined dense :disabled="disabledDatos" :filled="filledDatos"></v-text-field>                        
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-menu v-model="menu1" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="text-caption" v-model="emision" label="Fec. Emision" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </template>
                                <v-date-picker v-model="emision" @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="text-caption" v-model="contable" label="Fec. Contable" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </template>
                                <v-date-picker v-model="contable" @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select @change="activaCheque(pago)" class='text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field class='text-caption' v-model="ref_pago" label="Referencia" :disabled="disabledDatos" :filled="filledDatos" outlined dense required></v-text-field>
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-menu v-model="menu3" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="text-caption" v-model="vence" label="Fec. Vcto." readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </template>
                                <v-date-picker v-model="vence" @input="menu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n5">
                        <v-col cols="12" md="2"></v-col>
                        <v-col cols="12" md="10">
                            <v-card>
                                <v-card-text>
                                <v-row class="mt-3">
                                    <v-col cols="12" md="3" class="text-end">
                                        <h3 class="mt-2">Total Documento</h3>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field @keypress="onlyNumber" @keyup="calcula()" class='text-caption' v-model="neto" label="Neto" outlined dense required :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{new Intl.NumberFormat("de-DE").format(parseFloat(iva))}}</v-alert>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-alert class='text-caption' dense text border="left" color="red">Total ($): {{new Intl.NumberFormat("de-DE").format(parseFloat(total))}}</v-alert>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-btn v-if="btnOK!=true" class="mt-2" color="grey" dark x-small rounded>OK</v-btn>
                                        <v-btn v-else @click="ok()" class="mt-2" color="teal" dark x-small rounded>OK</v-btn>
                                    </v-col>
                                </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12" class="text-end">
                            <v-btn @click="limpiar()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                            <v-btn v-if="btnGrabar===true" @click="saveNC()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                            <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                            <v-btn v-if="btnAnula===true" @click="anulaDev()" class="ml-2" small color="red" dark rounded><v-icon small>delete</v-icon><span class='text-btn'>Anular</span></v-btn>
                            <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>delete</v-icon><span class='text-btn'>Anular</span></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
         </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
        <!-- DIALOGO CHEQUE -->
        <v-dialog v-model="dialogCheque" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>CHEQUES
                <v-spacer></v-spacer>
                <v-btn @click="dialogCheque=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-menu v-model="menucheque" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="fechaCheque" label="Fecha Vcto." prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaCheque" @input="menucheque = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                   <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="rutCheque" label="Rut" placeholder="99999999-K" outlined dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="7">
                        <!-- <v-text-field class='text-caption' v-model="bancoCheque" label="Banco" outlined dense required></v-text-field> -->
                        <v-select class='text-caption' v-model="bancoCheque" :items="bancos" label="Banco" item-text="name" item-value="id" dense outlined></v-select>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="cuentaCheque" label="N° Cuenta" outlined dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="numeroCheque" label="N° Cheque" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="montoCheque" label="Monto" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn @click="cierraCheque()" class="mt-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-n8 text-center">
                        <span class="text-caption red--text">{{msjCheque}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>              
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'NCSCompra',
    components:{dialogo, snack},  
    props: ['usu','contrato'],
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            rut:'',
            entidad:[],
            disabledRut:false,
            filledRut:false,
            razon:'',
            nota:0,
            notas:[{name:'Nueva', number:0, type:0, id:0}],
            disabledDev:true,
            filledDev:true,
            disabledDatos:true,
            filledDatos:true,
            fullNotas:[],
            menu1:false,
            emision:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu2:false,
            contable:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            pago:'',
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'8',forma:'Transferencia'},
                {id:'10',forma:'Cheque'}, // opciones nuevas para devoluciones
                {id:'20',forma:'Credito'} // opciones nuevas para devoluciones
            ],
            ref_pago:'',
            vence:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu3:false,
            neto:'',
            impuesto:19,
            iva:0,
            total:0,
            btnGrabar:false,
            btnAnula:false,          
            numero:'',
            tipo_cv:2,
            itemDTE:[
                {id:'2',doc:'Nota Credito'},
                {id:'17',doc:'Nota Debito'},
            ],
            hoy:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dialogCheque:false,
            fechaCheque: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            rutCheque:'',
            bancoCheque:0,
            cuentaCheque:'',
            numeroCheque:'',
            montoCheque:'',
            msjCheque:'',
            menucheque:false,
            bancos:[
                {id:1,name:'Banco Estado'},
                {id:2,name:'Banco de Chile / Edwards'}, 
                {id:3,name:'Banco de Crédito e Inversiones (BCI)'}, 
                {id:4,name:'Banco Bice'},
                {id:5,name:'Banco Santander'}, 
                {id:6,name:'Itaú / Corpbanca'}, 
                {id:7,name:'Banco Security'},
                {id:8,name:'Scotiabank'},
                {id:9,name:'Banco Falabella'}, 
                {id:10,name:'Banco Ripley'},
                {id:11,name:'Banco Consorcio'}, 
                {id:12,name:'Banco Internacional'}
            ],
            listaCheques:[],
            valorNeto:0,
            btnOK:false,
        }
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        limpiar(){
            this.rut='';
            this.clsInput()
        },
        clsInput(){
            this.razon='';
            this.entidad=[];
            this.disabledRut=false;
            this.filledRut=false;
            this.disabledDatos=true;
            this.filledDatos=true;
            this.disabledDev=true;
            this.filledDev=true;
            this.nota=0;
            this.notas=[{name:'Nueva', number:0, type:0, id:0}];
            this.fullNotas=[];
            this.numero='';
            this.emision=this.hoy;
            this.contable=this.hoy;
            this.pago='';
            this.ref_pago='';
            this.vence=this.hoy;
            this.neto='';
            this.iva=0;
            this.total=0;
            this.btnGrabar=false;
            this.btnAnula=false;
            this.fechaCheque=this.hoy;
            this.rutCheque='';
            this.bancoCheque=0;
            this.cuentaCheque='';
            this.numeroCheque='';
            this.montoCheque='';
            this.msjCheque='';
            this.btnOK=false;
            this.valorNeto=0;
        },
        async verNCS(rut){
            this.loading=true;
            const ente= await this.numeroEntidad(rut);
            if (ente!=0){
                this.razon=this.entidad[0].razon;
                this.disabledRut=true;
                this.filledRut=true;
                this.disabledDev=false;
                this.filledDev=false;
                this.disabledDatos=false;
                this.filledDatos=false;
                this.btnOK=true;
                this.buscarNCS(ente);
            };
            this.loading=false;
        },
        async numeroEntidad(rutPro){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/proveedor/'+this.contrato+'/'+ rutPro);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.entidad.push({
                        id:parseInt(datos[0].ID),
                        rut:datos[0].Rut,
                        razon:datos[0].Razon
                    });
                    return parseInt(datos[0].ID)
                } else {
                    return 0
                }                
            } catch (error) {
                console.log(error)
                return 0
            }
        },
        async buscarNCS(ente){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/dev/proveedor/ncsc/all/'+this.contrato+'/'+ ente);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.fullNotas=datos;
                    datos.forEach(element => {
                        if (element.Tipo_Dev!=2){
                            this.notas.push({name:'ND '+element.Numero, number:element.Numero, type:element.Tipo_Dev, id:element.ID});
                        } else {
                            this.notas.push({name:'NC '+element.Numero,number:element.Numero,type:element.Tipo_Dev, id:element.ID});
                        }                        
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },
        SelectNota(select){
            if(this.razon.trim()!=''){
                this.numero='';
                this.emision=this.hoy;
                this.contable=this.hoy;
                this.pago='';
                this.ref_pago='';
                this.vence=this.hoy;
                this.neto='';
                this.iva=0;
                this.total=0;
                if(select!=0){
                    //buscar el numero seleccionado 
                    const num = this.notas.find(e => e.id==select);
                    if(num){
                        //buscamos el detalle de la devolucion
                        const filtro = this.fullNotas.filter(e => parseFloat(e.Numero)==parseFloat(num.number) && parseInt(e.Tipo_Dev)==parseInt(num.type));
                        if(filtro.length!=0){
                            //mostrar datos principales
                            this.numero=filtro[0].Numero;
                            this.emision=filtro[0].Fec_Emision;
                            this.contable=filtro[0].Fec_Contable;
                            this.pago=filtro[0].Forma_Pago;
                            this.ref_pago=filtro[0].Referencia;
                            this.vence=filtro[0].Fec_Vcto;
                            //mostramos los totales
                            this.neto=Math.round(filtro[0].Afecto);
                            this.iva=Math.round(filtro[0].Iva);
                            this.total=Math.round(filtro[0].Total);
                        };
                    };
                    this.disabledDatos=true;
                    this.filledDatos=true;
                    this.btnGrabar=false;
                    this.btnAnula=true;
                    this.btnOK=false
                } else {
                    this.numero='';
                    this.emision=this.hoy;
                    this.contable=this.hoy;
                    this.pago='';
                    this.ref_pago='';
                    this.vence=this.hoy;
                    this.neto='';
                    this.iva=0;
                    this.total=0;
                    this.disabledDatos=false;
                    this.filledDatos=false;
                    this.btnGrabar=false;
                    this.btnAnula=false;
                    this.btnOK=true
                }
            }
        },
        async calcula(){
            this.valorNeto=0;
            if(this.neto.trim()!=''){
                this.valorNeto=this.neto;
                this.iva = Math.round(parseFloat(this.neto)*(parseFloat(this.impuesto)/100));
                this.total = parseFloat(this.neto)+parseFloat(this.iva);
            } else {
                this.iva = 0
                this.total = 0
            };
        },
        ok(){
            if(this.numero.trim()!='' && this.neto.trim()!='' && parseInt(this.pago)!=0){
                this.disabledDev=true;
                this.filledDev=true;
                this.disabledDatos=true;
                this.filledDatos=true;
                this.btnGrabar=true;
                this.btnAnula=false;
                this.btnOK=false;
                this.neto=new Intl.NumberFormat("de-DE").format(parseFloat(this.valorNeto))
            } else {
                this.Msg='Datos Obligatorios';
                this.msgbox=true;
                this.color='error';
                this.showSnack();
            }
        },
        async saveNC(){
            this.loading=true;
            this.btnGrabar=false;
            this.btnAnula=false;
            this.btnOK=false;
            this.disabledDatos=true;
            this.filledDatos=true;
            this.disabledDev=true;
            this.filledDev=true;
            if(parseInt(this.pago)!=10){
                this.fechaCheque=this.hoy;
                this.rutCheque='';
                this.bancoCheque=0;
                this.cuentaCheque='';
                this.numeroCheque='';
                this.montoCheque='';
                this.msjCheque='';
            }
            var params={
                'compra': 0,
                'tipo_nc': 'Bruto',
                'contrato': this.contrato,
                'fecha': this.hoy,                        
                'entidad': this.entidad[0].id,
                'numero': this.numero,
                'tipo_dev': this.tipo_cv,
                'fec_emision':this.emision,
                'fec_contable':this.contable,
                'fec_vcto':this.vence,
                'forma_pago': this.pago,
                'referencia': this.ref_pago,
                'afecto':this.valorNeto,
                'iva':this.iva,
                'total':this.total,
                'sku':0,
                'producto':'Producto sin Compra',
                'precio':0,
                'cantidad':0,
                'descuento':0,
                'bodega': 0,
                'signo':'0-',
                'usuario':this.usu,
                'usuario_mod':this.usu,
                'fecha_mod':this.hoy,
                'estado':'Egreso',
                'rut_ch':this.rutCheque,
                'vence_ch':this.fechaCheque,
                'numero_ch':this.numeroCheque,
                'banco_ch':this.bancoCheque,
                'cuenta_ch':this.cuentaCheque,
                'monto_ch':this.montoCheque,
            };
            try {
                const res = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/dev/proveedor/ncsc/nueva',params);
                const dato=res.data;
                if (dato=='si'){
                    this.Msg='Documento ingresado';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                } else {
                    this.Msg='Documento NO ingresado';
                    this.msgbox=true;
                    this.color='error';
                    this.showSnack();
                }
            } catch (error) {
                console.log(error)
            }
            this.loading=false;
        },
        async anulaDev(){
            this.loading=true;
            try {
                this.btnGrabar=false;
                this.btnAnula=false;
                this.btnOK=false;
                this.disabledDatos=true;
                this.filledDatos=true;
                this.disabledDev=true;
                this.filledDev=true;
                const params={
                    'numero':parseFloat(this.numero),
                    'entidad':this.entidad[0].id,
                    'usuario_mod':this.usu,
                    'fecha_mod':this.hoy,
                    'estado':'Nulo'
                };
                const res = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/dev/proveedor/ncsc/anula/'+this.contrato,params);
                const dato=res.data;
                if (dato=='si'){
                    this.Msg='Documento anulado';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                } else {
                    this.Msg='Documento NO anulado';
                    this.msgbox=true;
                    this.color='error';
                    this.showSnack();
                }
            } catch (error) {
                console.log(error)
            };
            this.loading=false;
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ( (keyCode < 48 || keyCode > 57) ) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        activaCheque(pago){
            if(parseInt(pago)==10 && this.btnAnula!=true){
                this.dialogCheque=true;
            }
        },
        cierraCheque(){
            if(this.numeroCheque.trim()!='' &&
                this.rutCheque.trim()!='' &&
                this.bancoCheque!=0 &&
                this.cuentaCheque.trim()!='' &&
                this.montoCheque.trim()!=''
            ){
                this.msjCheque='';
                this.ref_pago='CH '+this.numeroCheque;
                this.dialogCheque=false;
            } else {
                this.msjCheque='Todos los datos son Obligatorios'
            }
        },
    }
}
</script>
 <template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="12" md="12">INGRESO</v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="3">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="guia" label="N° Guia" :disabled="disabledGuia" :filled="filledGuia" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verGUIA()" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        <v-divider width="0"></v-divider>
                        <v-row>
                            <v-col cols="12" md="10">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event"></v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' dense text border="left" color="grey">Afecto ($):{{neto.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva.toLocaleString()}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="red">Total ($): {{total.toLocaleString()}}</v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
            <v-card-actions class="mt-n5">
                <v-container>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-select class='text-caption' v-model="sucursal" label="Sucursales" :items="sucursales" item-text="sucursal" item-value='id' dense outlined></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="text-right">
                        <!-- <v-text-field class='text-caption' v-model="laGuia" label="Observacion" disabled filled outlined dense></v-text-field> -->
                        <h5 class="mt-4">{{laGuia}}</h5>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="mt-3 text-right">
                            <v-btn @click="newGUIA()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                            <v-btn v-if="btnSave===false" class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                            <v-btn v-else @click="saveGUIA()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                    </v-col>
                </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Ingreso', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            guia:'',
            neto:0,
            impuesto:'',
            iva:0,
            total:0,
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Precio', align: 'start', sortable: true, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Observacion', align: 'center', sortable: true, value: 'observa' },
                { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            items: [],
            sucursales:[],
            sucursal:'',
            saveOK:'SI',
            btnSave:false,
            disabledGuia:false,
            filledGuia:false,
            laGuia:'',
        }
    },
    mounted(){
        this.searchSucursal();
    },
    methods:{
        async searchSucursal(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
            const datos=response.data;
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.sucursales.push({
                        id:element.ID,
                        sucursal:element.Nombre
                    });
                });
            }
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.loading=false;
            this.msgbox=false;
            this.color='';
            this.Msg='';
            this.guia='';
            this.neto=0;
            this.impuesto='';
            this.iva=0;
            this.total=0;
            this.items=[];
            this.sucursal='';
            this.saveOK='SI';
            this.disabledGuia=false;
            this.filledGuia=false;
            this.btnSave=false;
            this.laGuia='';
        },
        async verGUIA(){
            if(this.guia.trim()!=''){
                if(this.guia!='0'){
                    const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/search/guia/'+this.contrato+'/'+this.guia);
                    const datos=response.data;
                    if (datos[0].existe=='si'){
                        this.disabledGuia=true;
                        this.filledGuia=true;
                        this.btnSave=true;
                        //mustra datos
                        let varNeto=0;
                        this.impuesto=19;
                        let varIva=0;
                        let varTotal=0;
                        this.items=[];
                        datos.forEach(element => {
                            this.items.push({
                                sku:element.Sku,
                                producto:element.Producto,
                                precio:element.Precio,
                                cantidad:element.Cantidad,
                                observa:element.Observa,
                                bodega:element.Bodega,
                            });
                            varNeto=varNeto+(element.Precio*element.Cantidad);
                        });
                        varIva=Math.round(varNeto*(this.impuesto/100),0);
                        varTotal=varNeto+varIva;
                        this.neto=varNeto;
                        this.iva=varIva;
                        this.total=varTotal;
                    } else {
                        this.Msg='Guia no existe o procesada'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                }
            }
        },
        async saveGUIA(){
            if (this.items.length!=0 && this.sucursal.trim()!=''){
                for (let index = 0; index < this.items.length; index++) {
                    const element = this.items[index];
                    var parametros={
                        'contrato': this.contrato,
                        'sku': element.sku,
                        'cantidad': element.cantidad,
                        'signo': '0+',
                        'bodega': this.sucursal,
                        'usuario':this.usu,
                        'observa':'Guia '+this.guia+' recibida',
                        'origen':'Ingreso'
                    };
                    //grabamos stock
                    const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros);
                    const datos=response.data;
                    if (datos!='si'){ this.saveOK='NO' };                    
                };
                 if (this.saveOK!='SI'){
                    this.Msg='Algunos productos no se actualizaron, favor revisar'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
                } else {
                    // actualizamos el estado de la guia
                    const response = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/guia/actualiza/'+this.contrato+'/'+this.guia);
                    const datos=response.data;
                    if (datos=='si'){ 
                        this.Msg='Productos con stock y estado actualizados.'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                        this.btnSave=false;
                        this.laGuia='Guia '+this.guia+' recibida';
                    } else {
                        this.Msg='Productos con stock actualizados y sin estado'
                        this.msgbox=true;
                        this.color='info';
                        this.showSnack();
                        this.btnSave=false;
                    }                    
                }
            } else {
                this.Msg='Sin Datos en la Lista o Sucursal Destino'
                this.msgbox=true;
                this.color='info';
                this.showSnack();
            }
        },
        newGUIA(){
            this.clsInput();
        },
    }
}
</script>
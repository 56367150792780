<template>
  <div>
    <v-card>
        <v-card-text>
          <!-- style="max-height: 300px;overflow: auto"  // para scroll --> 
            <v-data-table :loading="progress" loading-text="Cargando....." dense
                dark :search="likesearch" :headers="headers" :items="desserts" :page.sync="page" :items-per-page="itemsPerPage"  @page-count="pageCount = $event" hide-default-footer class="elevation-1 text-size">
                <template v-slot:item.tStock1="{ item }">
                    <v-chip small :color="CriticalSearch(item.tSku,1)" dark>
                        {{ item.tStock1 }}
                    </v-chip>                  
                </template>
                <template v-slot:item.tStock2="{ item }">
                    <v-chip small :color="CriticalSearch(item.tSku,2)" dark>
                        {{ item.tStock2 }}
                    </v-chip>                  
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount"  :total-visible="5"></v-pagination>
        </v-card-text>
        <v-card-actions>
                <v-row>
                    <v-col cols="12" md="2">
                        <v-text-field class='text-caption' @keyup="onlySku" v-model="onSku" label="SKU" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' @keyup="onlyProducto" v-model="onProducto" label="Producto" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field class='text-caption' @keyup="onlyModelo" v-model="onModelo" label="Referencia" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1" class="text-right">
                        <a v-if="descargaCSV===null" href="javascript:void(0)" style="text-decoration: none">
                            <v-btn class="mt-1 ml-2" small color="white" rounded><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></v-btn>
                        </a>
                        <a v-else-if="descargaCSV===false" href="javascript:void(0)" @click="exportCSV()" style="text-decoration: none">
                            <v-btn class="mt-1 ml-2" small color="white" rounded><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></v-btn>
                        </a>
                        <a v-else :href="descarga" style="text-decoration: none">
                            <v-btn class="mt-1 ml-2" small color="white" rounded><v-icon class="blue-grey--text">cloud_download</v-icon></v-btn>
                        </a>
                    </v-col>
                </v-row>
        </v-card-actions>
    </v-card>
    <dialogo :loading="loading"/>
  </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import axios from 'axios';
import dialogo from '@/components/dialogo.vue';
  export default {
    name:'Stock',
    components:{dialogo},
    props: ['usu','contrato'],
    data () {
      return {
        //contrato:'123',
        loading:false,
        progress:false,
        likesearch:'',
        page: 1,
        pageCount: 0,
        itemsPerPage: 6,        
        headers: [
          { text: 'Sku', align: 'start', sortable: true, value: 'tSku' },
          { text: 'Referencia', align: 'start', sortable: true, value: 'tCodigos' },
          { text: 'Producto', align: 'start', sortable: true, value: 'tNombre' },
          { text: 'Modelo', align: 'start', sortable: true, value: 'tModelo' },
          //{ text: '($) Neto', align: 'center', sortable: true, value: 'tDetalle' },
          //{ text: '($) Venta', align: 'center', sortable: true, value: 'tVenta' },
          { text: 'Stock1', align: 'center', sortable: true, value: 'tStock1' },
          { text: 'Stock2', align: 'center', sortable: true, value: 'tStock2' },
        ],
        desserts: [], 
        SkuCritical:[],
        onSku:'',
        onProducto:'',
        onModelo:'',
        ondesserts:[],
        itemUniversal:[],
        descarga:'javascript:void(0)',
        descargaCSV:null,
      }
    },
    async mounted(){
      this.progress=true;
      await this.generaTabla();
      this.progress=false;
      await this.allCritical();
      this.descargaCSV=false;
    },
    methods: {
      async exportCSV(){
        try {
            this.loading=true;
            this.descarga='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            const nameFile = 'inventario_'+formatDate(date);
            let csv;
            csv=[];
            let params;
            let response;
            //titulo
            csv.push({
                'tSku':'Sku',
                'tCodigos':'Referencia',
                'tNombre':'Producto',
                'tModelo':'Modelo',
                'tStock1':'Stock SB',
                'tStock2':'Stock RT',
            });
            //datos
            this.desserts.forEach(element => {
                csv.push({
                    'tSku':element.tSku,
                    'tCodigos':element.tCodigos,
                    'tNombre':element.tNombre,
                    'tModelo':element.tModelo,
                    'tStock1':element.tStock1,
                    'tStock2':element.tStock2
                })
            });
            params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
            response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
            if (response.data=='si'){
                this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                this.descargaCSV=true;
            };
            this.loading=false;    
        } catch (error) {
          console.log(error);
        }
      },
      async generaTabla(){
        const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/datatable/'+this.contrato);
        const datos=response.data;
        //console.log(datos);
        this.desserts=[];
        if (datos[0].existe=='si'){
          datos.forEach(element => {
            this.desserts.push({
              tSku: element.Sku,
              tCodigos: element.Codigos,
              tNombre: element.Nombre,
              tModelo: element.Descripcion,
              //tDetalle: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioLocal)),
              //tVenta:new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioVenta)),
              tStock1: new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock1)),
              tStock2: new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock2)),
            })            
          });
          this.ondesserts = this.desserts; // clonar array
        }
      },
      onlySku(){
        this.page=1;
        this.onProducto='';
        this.onModelo='';
        const letras=this.onSku.length;
        this.onSku=this.onSku.toUpperCase();
        this.desserts=[];
        this.desserts=this.ondesserts;
        this.desserts.forEach(element => {
            const parteSku=element.tSku.substring(0,letras);
            if (parteSku!=this.onSku){
                this.desserts = this.desserts.filter(function(dat) {
                    return dat.tSku !== element.tSku; 
                });
            }             
        });
      },
      onlyProducto(){
        this.page=1;
        this.onSku='';
        this.onModelo='';
        const letras=this.onProducto.length;
        this.onProducto=this.onProducto.toUpperCase();
        this.desserts=[];
        this.desserts=this.ondesserts;
        this.desserts.forEach(element => {
            const parteNombre=element.tNombre.substring(0,letras);
            if (parteNombre!=this.onProducto){
                this.desserts = this.desserts.filter(function(dat) {
                    return dat.tNombre !== element.tNombre; 
                });
            }             
        });
      },
      onlyModelo(){
        this.page=1;
        this.onSku='';
        this.onProducto='';
        const letras=this.onModelo.length;
        this.onModelo=this.onModelo.toUpperCase();
        this.desserts=[];
        this.desserts=this.ondesserts;
        this.desserts.forEach(element => {
            const parteModelo=element.tCodigos.substring(0,letras);
            if (parteModelo!=this.onModelo){
                this.desserts = this.desserts.filter(function(dat) {
                    return dat.tCodigos !== element.tCodigos; 
                });
            }             
        });
      },
      async allCritical(){
        this.SkuCritical=[];
        const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/calculostock/'+this.contrato)
        const datos=response.data;
        if (datos[0].existe=='si'){
            datos.forEach(element => {
              this.SkuCritical.push({
                cSku: element.Sku,
                cBodega: element.numeroBodega,
                cStock: element.Stock,
                cP3M: element.P3M,
                cCritico:element.Critico
              })
            });
        }
      },
      CriticalSearch(tSku,tBodega){
        //console.log(tSku,tBodega);
        const resultado = this.SkuCritical.find( arreglo => arreglo.cSku === tSku && arreglo.cBodega == tBodega);
        if (resultado){
          if (resultado.cCritico!=0){ return 'red' }
          else { 
            if ((resultado.cStock/2) < resultado.cP3M) { return 'orange' }
            else { return 'green' }
          }
        }
      },
    },
  }
</script>
<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="12" md="12">FOLIOS</v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="5">
                                <v-file-input v-model="archivo" label="Archivo XML" accept=".xml" outlined dense></v-file-input>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn class="mt-1" @click="subirXML(archivo)" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        <v-divider width="0"></v-divider>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event"></v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
            <v-card-actions class="mt-n5">
                <v-container>
                    <strong class="teal--text">Folios Grabados: {{cuenta}}</strong>
                        <div class="mt-3 text-right">
                            <v-btn @click="clsInput()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                            <v-btn v-if="btnConfirm==true" @click="saveFolios()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Confirmar</span></v-btn>
                            <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Confirmar</span></v-btn>
                        </div>
                </v-container>
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Folios', 
    components:{dialogo, snack},
    props: ['usu','contrato'],
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Rut', align: 'center', sortable: true, value: 'rut' },
                { text: 'Razon Social', align: 'start', sortable: true, value: 'nombre' },
                { text: 'Fecha Autorizacón', align: 'center', sortable: true, value: 'fecha' },
                { text: 'Tipo Dte', align: 'center', sortable: true, value: 'tipodte' },
                { text: 'Folio Inicio', align: 'center', sortable: true, value: 'formatdesde' },
                { text: 'Folio Termino', align: 'center', sortable: true, value: 'formathasta' },
                { text: 'Total Folios', align: 'center', sortable: true, value: 'formattotal' },
            ],
            items: [],
            archivo:null,
            btnConfirm:false,
            tipo:0,
            inicio:0,
            final:0,
            total:0,
            cuenta:0,
        }
    },
    mounted(){
    },
    methods:{
        clsInput(){
            this.items=[];
            this.archivo=null;
            this.btnConfirm=false;
            this.tipo=0;
            this.inicio=0;
            this.final=0;
            this.total=0;
            this.cuenta=0;
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        async subirXML(file) {
            if(file!=null){
                this.loading=true;
                await this.upFile();
                this.loading=false;
            } else {
                this.Msg='Seleccione Archivo';
                this.color='red';
                this.msgbox=true;
                this.showSnack();                    
            }           
        },
        async XMLtoJson(){
            try {
                let InstFormData = new FormData();
                InstFormData.append('archivo' , this.archivo);
                InstFormData.append('contrato' , this.contrato);
                const response= await axios.post('https://www.transervi.cl/Api_TranServi/public/api/file/XMLtoJson/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                const datos=response.data;
                if (datos!='error'){
                    var Rut = datos.CAF.DA.RE;
                    var Nombre = datos.CAF.DA.RS;
                    var Fecha = datos.CAF.DA.FA;
                    var Tipo = datos.CAF.DA.TD;
                    var Desde = datos.CAF.DA.RNG.D;
                    var Hasta = datos.CAF.DA.RNG.H;
                    //asignar a variables globales
                    this.tipo=parseInt(Tipo);
                    this.inicio=parseInt(Desde);
                    this.final=parseInt(Hasta);
                    this.total=1+(parseInt(Hasta)-parseInt(Desde));
                    // Buscar Tipo Dte
                    var TipoDte='';
                    const res = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/DTE/tipo/documents/'+this.contrato);
                    const dat=res.data;
                    if (dat[0].existe=='si'){
                        dat.forEach(element => {
                            if (parseInt(element.CodigoSii)==parseInt(Tipo)){
                                TipoDte=element.Descripcion
                            }
                        });
                    };
                    this.items.push(
                        {
                            'rut':Rut,
                            'nombre':Nombre,
                            'fecha':Fecha,
                            'tipo':Tipo,
                            'tipodte':TipoDte,
                            'desde':Desde,
                            'formatdesde':new Intl.NumberFormat("de-DE").format(parseFloat(Desde)),
                            'hasta':Hasta,
                            'formathasta':new Intl.NumberFormat("de-DE").format(parseFloat(Hasta)),
                            'formattotal':new Intl.NumberFormat("de-DE").format(parseFloat(this.total)),
                        }
                    );
                    this.btnConfirm=true;
                } else {
                    this.Msg='Inconsistencia en XML';
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }                
            } catch (error) {
                console.log(error);
                this.Msg='Archivo XML Inconsistente';
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async upFile(){
            let InstFormData = new FormData();
            InstFormData.append('archivo' , this.archivo);
            InstFormData.append('contrato' , this.contrato);
            const response= await axios.post('https://www.transervi.cl/Api_TranServi/public/api/server/file/FileUp/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
            if (response.data=='ok'){
                await this.XMLtoJson();
            }
            if (response.data=='no'){
                this.Msg='Archivo no permitido';
                this.color='red';
                this.msgbox=true;
                this.showSnack();                    
            }
            if (response.data=='error'){
                this.Msg='No se guardo Imagen';
                this.color='red';
                this.msgbox=true;
                this.showSnack();                    
            }
        },
        async saveFolios(){
            this.loading=true;
            try {
                if(this.tipo!=0 && this.inicio!=0 && this.final!=0 && this.total!=0){
                    //valida la existencia de folios
                    const salida = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/folio/verify/'+this.contrato+'/'+this.tipo+'/'+this.inicio+'/'+this.final);
                    const res=salida.data;
                    if (res[0].existe=='si'){
                        this.Msg='Folios ya existen en Base';
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();                                            
                    } else {
                        //grabamos folios
                        this.cuenta=0;
                        for (let index = parseInt(this.inicio); index <= parseInt(this.final); index++) {
                            const params={
                                'contrato': this.contrato,
                                'tipo':this.tipo,
                                'folio': index,
                                'estado':'V'
                            };
                            const salida1 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/folio/nuevo/',params);
                            const res1=salida1.data;
                            if (res1=='si'){ 
                                this.cuenta++;
                            };
                        };
                        this.Msg=this.cuenta+' Folios Grabados';
                        this.color='green';
                        this.msgbox=true;
                        this.showSnack();                                            
                    }
                } else {
                    this.Msg='No existen Datos';
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();                    
                }
            } catch (error) {
                console.log(error)
            };
            this.loading=false
        }
    }
}
</script>
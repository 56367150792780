<template>
    <div>
    <div v-if="vista===true" class="mt-5">
        <v-card class="mt-5">
            <v-card-title class="blue-grey white--text">COMPRA</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="orden" label="N° Compra" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="9" md="2">
                                <v-text-field class='text-caption' v-model="rutProveedor" label="Rut Proveedor" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="3" md="1">
                                <v-btn @click="verOrden(orden,rutProveedor)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="9" md="4">
                                <v-select class='text-caption' v-model="proveedor" :items="itemProveedor" label="Razon Social" disabled filled dense outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Orden Existe !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nueva Orden !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    Selecciona Datos !!!
                                </v-alert>
                            </v-col >
                        </v-row>
                        <!-- <v-row class="mt-n10">
                            <v-col cols="12" md="2" class="mt-3">
                                <v-select class='text-caption' v-model="exenta" :items="fexenta" label="Exenta" dense outlined :disabled="disabledExenta" :filled="filledExenta"></v-select>
                            </v-col>
                            <v-col cols="12" md="2" class="mt-4">
                                <v-btn v-if="disabledExenta===true" class="mr-5" small color="grey" dark rounded><v-icon small>check</v-icon><span class='text-btn'>Confirmar</span></v-btn>
                                <v-btn v-else @click="confirmaExenta()" class="mr-5" small color="teal" dark rounded><v-icon small>check</v-icon><span class='text-btn'>Confirmar</span></v-btn>
                            </v-col>
                        </v-row> -->
                        <v-divider class="mt-n5"></v-divider>
                        <v-row class="mt-1">
                            <v-col cols="8" md="2">
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="text-caption" v-model="emision" label="Fec. Emision" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="emision" @input="menu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="text-caption" v-model="contable" label="Fec. Contable" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="contable" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='text-caption' v-model="forma_pago" :items="array_pago" label="Forma Pago" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="ref_pago" label="Referencia" :disabled="disabledDatos" :filled="filledDatos" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="text-caption" v-model="vence_pago" label="Fec. Vencimiento" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="vence_pago" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="4" md="2">
                                <v-btn v-if="existe=='si'" @click="saveFechas()" class="mt-1" small color="indigo" dark rounded><v-icon small>event_available</v-icon></v-btn>
                                <v-btn v-else class="mt-1" small color="grey" dark rounded><v-icon small>event_available</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-n5"></v-divider>
                        <v-row class="mt-1">
                            <v-col cols="12" md="10">
                                <v-row>
                                    <v-col cols="12" md="3" class="mt-n2">
                                        <v-radio-group v-model="radios" row>
                                            <v-radio label="Gasto" color="success" value="Bruto"></v-radio>
                                            <v-radio label="Neto" color="success" value="Neto"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="10" md="4">
                                        <v-text-field v-if="radios!='Neto'" class='text-caption' v-model="nombre" label="Nombre Producto" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                        <v-combobox v-else class='text-caption' :hint="SkuItem" persistent-hint v-model="listanombre" :items="itemProductos" label="Selecciona Producto" dense outlined disabled filled></v-combobox>
                                    </v-col>
                                    <v-col cols="2" md="1">
                                        <v-btn v-if="radios!='Neto'" class="mt-1" color="grey" fab dark x-small disabled><v-icon>search</v-icon></v-btn>
                                        <v-btn v-else-if="disabledDatos===false" class="mt-1" @click="inventa=true" color="cyan" fab dark x-small><v-icon>search</v-icon></v-btn>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field class='text-caption' v-model="oem" label="Cod. Proveedor" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-select class='text-caption' v-on:change="OpenDialog(bodega)" v-model="bodega" :items="itemBodegas" label="Bodega" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>                                
                                    </v-col>
                                </v-row>
                                <v-row class="mt-n5">
                                    <v-col cols="4" md="2">
                                        <div class="mt-2 text-right">
                                            <v-icon v-if="select_menos!==true" @click="selections('menos')">remove_circle_outline</v-icon>
                                            <v-icon v-else color="success">remove_circle</v-icon>
                                            <v-icon v-if="select_mas!==true" @click="selections('mas')">add_circle_outline</v-icon>
                                            <v-icon v-else color="success">add_circle</v-icon>
                                        </div>
                                    </v-col>
                                    <v-col cols="8" md="2">
                                        <v-text-field class='text-caption' @keypress="onlyDesc" v-model="precio" label="Precio" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field class='text-caption'  @keypress="onlyDesc" v-model="pordescuento" label="Desc. %" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="8" md="2">
                                        <v-select class='text-caption' v-model="exenta" :items="fexenta" item-text='text' item-value='id' label="Tipo Mov." dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col>
                                    <v-col cols="4" md="2">
                                        <v-btn @click="saveCompra()" class="mt-1" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-n5">
                                    <v-col cols="12" md="12">
                                        <v-btn @click="dialogCompras()" color="indigo" fab dark>{{nCompras}}</v-btn>
                                        <span class="ml-2 text-caption">Total Items</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-alert class='text-caption' dense text border="left" color="grey">
                                            <div class="float-left">
                                                Neto:
                                            </div>
                                            <div class="float-right">
                                                ${{subtotal.toLocaleString()}} 
                                            </div>
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-alert class='mt-n9 text-caption' dense text border="left" color="grey">
                                            <div class="float-left">
                                                Exento:
                                            </div>
                                            <div class="float-right">
                                                ${{exento.toLocaleString()}}
                                            </div>                                             
                                        </v-alert> 
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-alert class='mt-n9 text-caption' dense text border="left" color="grey">
                                            <div class="float-left">
                                                Impuesto:
                                            </div>
                                            <div class="float-right">
                                                ${{im.toLocaleString()}}
                                            </div>                                             
                                        </v-alert> 
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-alert class='mt-n9 text-caption' dense text border="left" color="grey">
                                            <div class="float-left">
                                                Iva ({{impuesto}}%):
                                            </div>
                                            <div class="float-right">
                                                ${{iva.toLocaleString()}}
                                            </div>                                             
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-alert class='mt-n9 text-caption' dense text border="left" color="red">
                                            <div class="float-left">
                                                Total:
                                            </div>
                                            <div class="float-right">
                                                ${{total.toLocaleString()}}
                                            </div>                                             
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="12" md="12" class="mt-n7 text-right">
                                        <v-btn @click="newCompra()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    </v-col>
                                </v-row>                                
                            </v-col>
                        </v-row>
                        <!-- <v-row class="mt-n6">
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="observa" label="Observacion" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-alert class='text-caption' dense text border="left" color="success">&nbsp;</v-alert> 
                            </v-col>
                        </v-row> -->
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="mt-n7">
            </v-card-actions>
        </v-card>
    </div>
        <div v-else-if="vista===false" class="mt-5">
      <v-card>
        <v-card-subtitle><v-icon class="red--text">cancel</v-icon><span class="ml-2">Solicitud Denegada</span></v-card-subtitle>
      </v-card>
    </div>
        <!-- Dialogo Bodega -->
        <v-dialog v-model="dialogBodega" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Nueva Bodega</v-toolbar>
            <v-card-text class="mt-5">
                <v-text-field class='text-caption' v-model="newbodega" label="Nombre Bodega" :disabled="disabledBodega" :filled="filledBodega" outlined dense required></v-text-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <div v-if="alertabodega!='si'">
                            <v-btn @click="newBodega()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                        <div v-else>
                            <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-alert class='text-caption' v-if="alertabodega=='si'" dense text type="success">
                            Bodega Creada con Exito !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertabodega=='no'" dense text type="error">
                            No ha Ingresado Bodega !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseDialog()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialogo tabla -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer></v-spacer>
                <a target="_blank" :href="html" style="text-decoration: none"><v-icon>print</v-icon></a>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <v-icon small @click="deleteCompra(item)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" class='mt-3'>
                        <a class="mt-2 ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img src="@/assets/csv.png" width="16px" height="20px"/></a>
                        <a class="ml-2" v-else :href="rutaCSV" style="text-decoration: none"><v-icon color='teal'>cloud_download</v-icon><span class="ml-2 text-caption">Descarga</span></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- dialog inventario --> 
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="90%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClick" dense dark :search="likesearch" :headers="head" :items="desserts" :page.sync="page" :items-per-page="itemsPerPage"  @page-count="pageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount"  :total-visible="5"></v-pagination>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' @keyup="onlySku" v-model="onSku" label="SKU" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' @keyup="onlyProducto" v-model="onProducto" label="Producto" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' @keyup="onlyModelo" v-model="onModelo" label="Modelo" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Compra', 
    components:{dialogo, snack},  
    props: ['usu','contrato'], 
    data(){
        return{
            //contrato:'123',
            observa:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',
            disabledOrden:false,
            filledOrden:false,
            disabledDatos:true,
            filledDatos:true,
            disabledExenta:true,
            filledExenta:true,
            btnGrabar:true,
            orden:'',
            itemProveedor:[],
            arrayProveedor:[],
            proveedor:'',
            listanombre:'',
            itemProductos:[],
            arrayProductos:[],
            bodega:'',
            itemBodegas:[],
            arrayBodegas:[],
            nombre:'',
            precio:'',
            cantidad:'',
            radios:'Neto',
            total:'0',            
            nProveedor:0,
            nProducto:0,
            nBodega:0,
            cv:'C',
            nCompras:0,
            // dialog Compras
            dialog:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'OEM', align: 'start', sortable: true, value: 'oem' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Desc.(%)', align: 'center', sortable: true, value: 'descuento' },
                { text: 'Exento', align: 'center', sortable: true, value: 'exento' },
                { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            items: [],
            html:'',
            descargaCSV:false,
            rutaCSV:'',
            alertabodega:'',
            disabledBodega:false,
            filledBodega:false,
            newbodega:'',
            dialogBodega:false,
            head: [
                { text: 'Sku', align: 'start', sortable: true, value: 'tSku' },
                { text: 'Referencia', align: 'start', sortable: true, value: 'tCodigos' },
                { text: 'Producto', align: 'start', sortable: true, value: 'tNombre' },
                { text: 'Modelo', align: 'start', sortable: true, value: 'tModelo' },
                //{ text: '($) Neto', align: 'center', sortable: true, value: 'tDetalle' },
                { text: '($) Venta', align: 'center', sortable: true, value: 'tVenta' },
                { text: 'Stock1', align: 'center', sortable: true, value: 'tStock1' },
                { text: 'Stock2', align: 'center', sortable: true, value: 'tStock2' },
            ],
            desserts: [],
            inventa:false,
            onSku:'',
            onProducto:'',
            onModelo:'',
            ondesserts:[],
            SkuItem:'',
            menu1:false,
            emision:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            emisionDefault:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu2:false,
            contable:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            fexenta:[
                {id:'NO',text:'NO Exenta'},
                {id:'SI',text:'Exenta'},
                {id:'IM',text:'Impuesto'}
            ],
            exenta:'NO',
            //section contable
            pordescuento:0,
            impuesto:0,
            iva:0,
            subtotal:0,
            exento:0,
            im:0,
            total:0,
            oem:'',
            rutProveedor:'',
            select_menos:false,
            select_mas:true,
            array_pago:['Efectivo','Tarjeta','Transferencia','Cheque','Credito'],
            forma_pago:'',
            ref_pago:'',
            vence_pago:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu3:false,
            vista:true
        }
    },
    async mounted(){
        await this.restoApp();
        //await this.entrada();
    },
    methods:{
        async entrada(){
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if(datos[0].Permiso_3_1!=0) {
                    this.vista=true;
                    await this.restoApp();
                } else { this.vista=false; }
            } else { this.vista=false; }
            this.loading=false;
        },
        async restoApp(){
            this.loading=true;
            await this.buscarImpuesto();
            await this.generaTabla();
            await this.buscaProveedor();
            await this.buscarProducto();
            await this.buscarBodegas();       
            this.loading=false;
        },
        fun_exenta(e){
            console.log(e);
        },
        selections(string){
            switch (string) {
                case 'menos':
                    this.select_menos=true;
                    this.select_mas=false;
                    break;            
                case 'mas':
                    this.select_mas=true;
                    this.select_menos=false
                    break;            
            };
            if(this.precio!=''){
                this.precio=parseInt(this.precio)*-1;
            }
        },
        async buscarImpuesto(){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/impuesto/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.impuesto=datos[0].Iva;
                }
            } catch (error) {
                console.log(error)
            }
        },
        async confirmaExenta(){
            this.loading=true;
            if(this.emision!='0000-00-00'){
                if(this.exenta!=''){
                    if(this.nCompras!=0){
                        await this.saveExenta();
                    };
                    this.disabledExenta=true;
                    this.filledExenta=true;
                    this.restoDatos(false);
                } else { 
                    this.exenta='NO'
                }
            } else {
                this.emision=this.emisionDefault;
            }
            this.loading=false;
        },
        async saveExenta(){
            try {
                const params={
                    'emision':this.emision,
                    'exenta':this.exenta
                }
                const response = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/compra/exenta/'+this.contrato+'/'+this.orden+'/'+this.nProveedor,params);
                const datos=response.data;
                //console.log(datos)
            } catch (error) {
                console.log(error)
            }
        },


        // search_SkuItem(itemName){ // variable Global
        //     //console.log(itemName);
        //     this.SkuItem='';
        //     this.numeroSku(itemName);
        //     this.SkuItem="Sku: "+this.nProducto;
        // },
        handleClick(txtOpcion) {
            //console.log(txtOpcion);
            this.listanombre=txtOpcion.tNombre;
            this.SkuItem='';
            this.SkuItem="Sku: "+txtOpcion.tSku;
            this.nProducto=txtOpcion.tSku;
            this.precio='';
            this.cantidad='';
            this.inventa=false;
            this.btnGrabar=false;
            this.ultimoCodigo();
        },
        async ultimoCodigo(){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/ultimo/codigo/'+this.contrato+'/'+this.nProveedor+'/'+this.nProducto);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.oem=datos[0].Observa;
                } else {
                    this.oem='';
                }
            } catch (error) {
                console.log(error);
            }
        },
        async generaTabla(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/datatable/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.desserts=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.desserts.push({
                    tSku: element.Sku,
                    tCodigos: element.Codigos,
                    tNombre: element.Nombre,
                    tModelo: element.Descripcion,
                    //tDetalle: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioLocal)),
                    tVenta:new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioVenta)),
                    tStock1: new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock1)),
                    tStock2: new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock2)),
                    precioDetalle:element.PrecioLocal,
                    precioVenta:element.PrecioVenta
                    })
                });
                this.ondesserts = this.desserts; // clonar array
            }
        },
        onlySku(){
            this.page=1;
            this.onProducto='';
            this.onModelo='';
            const letras=this.onSku.length;
            this.onSku=this.onSku.toUpperCase();
            this.desserts=[];
            this.desserts=this.ondesserts;
            this.desserts.forEach(element => {
                const parteSku=element.tSku.substring(0,letras);
                if (parteSku!=this.onSku){
                    this.desserts = this.desserts.filter(function(dat) {
                        return dat.tSku !== element.tSku; 
                    });
                }             
            });
        },
        onlyProducto(){
            this.page=1;
            this.onSku='';
            this.onModelo='';
            const letras=this.onProducto.length;
            this.onProducto=this.onProducto.toUpperCase();
            this.desserts=[];
            this.desserts=this.ondesserts;
            this.desserts.forEach(element => {
                const parteNombre=element.tNombre.substring(0,letras);
                if (parteNombre!=this.onProducto){
                    this.desserts = this.desserts.filter(function(dat) {
                        return dat.tNombre !== element.tNombre; 
                    });
                }             
            });
        },
        onlyModelo(){
            this.page=1;
            this.onSku='';
            this.onProducto='';
            const letras=this.onModelo.length;
            this.onModelo=this.onModelo.toUpperCase();
            this.desserts=[];
            this.desserts=this.ondesserts;
            this.desserts.forEach(element => {
                const parteModelo=element.tModelo.substring(0,letras);
                if (parteModelo!=this.onModelo){
                    this.desserts = this.desserts.filter(function(dat) {
                        return dat.tModelo !== element.tModelo; 
                    });
                }             
            });
        },
        async fileCSV(){
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/fileCsv/compra/'+this.contrato+'/'+this.orden+'/'+this.nProveedor);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){
                this.descargaCSV=true;
                this.rutaCSV='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+this.orden+'.csv';
            }
            this.loading=false;
        },
        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ( (keyCode < 48 || keyCode > 57) ) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        onlyDesc ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ( (keyCode < 48 || keyCode > 57) && keyCode !== 46 ) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        dialogCompras(){
            if (this.nCompras!=0){
                this.dialog=true;
            }
        },
        async datosDialog(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/'+this.cv+'/'+this.contrato+'/'+this.orden+'/'+this.nProveedor);
            const datos=response.data;
            if (datos[0].existe=='si'){
                this.items=[];
                this.subtotal=0;
                this.iva=0;
                this.total=0;
                this.exento=0;
                this.im=0;
                this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/compra/'+this.contrato+'/'+this.orden+'/'+this.nProveedor;
                let varValorExento=0;
                let varValorNoExento=0;
                let varValorImpuesto=0;
                datos.forEach(element => {
                    //calculo de totales

                    const valorCalculo=parseFloat(element.Precio)*parseFloat(element.Cantidad);
                    const varDescuento=Math.round((valorCalculo*parseFloat(element.Descuento))/100);
                    const varValorItem=valorCalculo-varDescuento;

                    // const varDescuento=(element.Precio*parseFloat(element.Descuento)/100);
                    // const varPrecioCalculo=element.Precio-varDescuento;
                    // const varValorItem=varPrecioCalculo*parseFloat(element.Cantidad);

                    if (element.Exenta=='SI'){ varValorExento=varValorExento+varValorItem }; 
                    if (element.Exenta=='NO'){ varValorNoExento=varValorNoExento+varValorItem };
                    if (element.Exenta=='IM'){ varValorImpuesto=varValorImpuesto+varValorItem };
                    //llenar array
                    this.items.push({
                        'id':element.ID,
                        'sku':element.Sku,
                        'oem':element.Observa,
                        'producto':element.Producto,
                        'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                        'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                        'descuento':element.Descuento,
                        'exento':element.Exenta,
                        'bodega':element.Bodega,
                        'numbodega':element.NumBodega
                    })        
                });
                this.reCalcular(varValorExento,varValorNoExento,varValorImpuesto);
            } else { this.dialog=false }
        },
        async deleteCompra(linea){
            this.loading=true;
            const n=linea.id;
            const response = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/delete/'+this.contrato+'/'+n);
            const datos=response.data;
            if (response.data=='ok'){
                await this.RebajaStock(linea);
                this.descargaCSV=false;
                this.rutaCSV='';
                await this.datosDialog()
                this.nCompras=this.nCompras-1
            }           
            this.loading=false;
        },
        async RebajaStock(linea){
            try {
                var param={
                    'contrato': this.contrato,
                    'sku': linea.sku,
                    'cantidad': linea.cantidad,
                    'signo': '0-',
                    'bodega': linea.numbodega,
                    'usuario': this.usu,
                    'observa': 'Elimina item Compra: '+this.orden,
                    'origen':'Ajuste'
                };
                const res = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',param);
                const dat=res.data;
                if (dat=='si'){ 
                    console.log('Rebaja de Stock Exitosa');
                }                
            } catch (error) {
                console.log(error);                
            }
        },
        clsInput(){
            this.observa='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.disabledExenta=true;
            this.filledExenta=true;
            this.btnGrabar=true;
            this.radios='Neto';
            this.total='0';
            this.listanombre='';
            this.nombre='';
            this.precio='';
            this.cantidad='';
            this.nProveedor=0;
            this.nProducto=0;
            this.bodega='';
            this.nBodega=0;
            this.existe='';
            this.nCompras=0
            this.dialog=false;
            this.items=[];
            this.html='';
            this.descargaCSV=false;
            this.rutaCSV='';
            this.alertabodega='';
            this.newbodega='';
            this.dialogBodega=false;
            this.onSku='';
            this.onProducto='';
            this.onModelo='';
            this.SkuItem='';
            this.emision=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.contable=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.exenta='NO';
            this.pordescuento=0;
            this.oem='';
            this.page=1;
            this.pageCount=0;
            this.forma_pago='';
            this.ref_pago='';
            this.vence_pago=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        },
        newCompra(){
            this.orden='';
            this.rutProveedor='';
            this.proveedor='';
            //this.impuesto=0;
            this.iva=0;
            this.subtotal=0;
            this.total=0;
            this.exento=0;
            this.im=0;
            this.clsInput()
            this.restoDatos(true); 
        },
        async buscaProveedor(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/proveedores/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemProveedor=[];
            this.arrayProveedor=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemProveedor.push(element.Razon);
                    this.arrayProveedor.push({id:element.ID,razon:element.Razon})        
                });
            }
        },
        async buscarProducto(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/productos/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemProductos=[];
            this.arrayProductos=[];
            if (datos[0].existe=='si'){
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    this.itemProductos.push(element.Nombre);
                    this.arrayProductos.push({sku:element.Sku,nameSku:element.Nombre})                    
                }
                // datos.forEach(element => {
                //     this.itemProductos.push(element.Nombre);
                //     this.arrayProductos.push({sku:element.Sku,nameSku:element.Nombre})        
                // });
            }
        },
        async buscarBodegas(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemBodegas=[];
            this.itemBodegas.push('Nueva');
            this.arrayBodegas=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemBodegas.push(element.Nombre);
                    this.arrayBodegas.push({numBodega:element.ID,nameBodega:element.Nombre})        
                });
            }
        },
        numeroSku(txtProducto){
            this.arrayProductos.forEach(element => {
                if (txtProducto==element.nameSku){
                    this.nProducto=element.sku
                }
            });
        },
        numeroProveedor(txtProveedor){
            this.arrayProveedor.forEach(element => {
                if (txtProveedor==element.razon){
                    this.nProveedor=element.id
                }
            });
        },

        async numeroBodega(txtbodega){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/bodegas/'+ this.contrato);
            const datos=response.data;
            datos.forEach(element => {
                if(element.Nombre==txtbodega){
                    this.nBodega=element.ID
                }
            });
        },
        // numeroBodega(txtBodega){
        //     this.arrayBodegas.forEach(element => {
        //         if (txtBodega==element.nameBodega){
        //             this.nBodega=element.numBodega
        //         }
        //     });
        // },
        async buscar_RutProveedor(rp){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/proveedor/'+this.contrato+'/'+ rp);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    return datos[0].Razon;
                } else {
                    return 'x';
                }
            } catch (error) {
                console.log(error)
                return 'x'
            }
        },
        async verOrden(nOrden,qProveedor){
            this.clsInput();
            this.loading=true;
            if (nOrden!='' && qProveedor!=''){
                const tProveedor = await this.buscar_RutProveedor(qProveedor)
                //console.log(qProveedor,tProveedor);
                if (tProveedor.trim()!='x'){
                    this.proveedor=tProveedor;
                    this.numeroProveedor(tProveedor);
                    const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/'+this.cv+'/'+this.contrato+'/'+nOrden+'/'+this.nProveedor);
                    const datos=response.data;
                    //console.log(datos);  
                    if (datos[0].existe=='si'){
                        this.nCompras=datos.length;
                        this.disabledOrden=true;
                        this.filledOrden=true;
                        if(datos[0].Emision!='0000-00-00'){ this.emision=datos[0].Emision };
                        if(datos[0].Contable!='0000-00-00'){ this.contable=datos[0].Contable };
                        if(datos[0].Forma_Pago!=null){ this.forma_pago=datos[0].Forma_Pago };
                        if(datos[0].Referencia_Pago!=null){ this.ref_pago=datos[0].Referencia_Pago };
                        if(datos[0].Fecha_Pago!=null){ this.vence_pago=datos[0].Fecha_Pago };
                        // if(datos[0].Exenta!=''){ this.exenta=datos[0].Exenta };
                        this.disabledExenta=false;
                        this.filledExenta=false;
                        this.restoDatos(false);
                        this.existe='si';
                        this.descargaCSV=false;
                        this.rutaCSV='';
                        await this.datosDialog()
                    }
                    if (datos[0].existe=='no'){
                        this.disabledOrden=true;
                        this.filledOrden=true;
                        this.disabledExenta=false;
                        this.filledExenta=false;
                        this.restoDatos(false);
                        this.existe='no'                        
                    }
                    if (datos[0].existe=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newCompra()
                        this.existe=''                        
                    }
                } else {
                    this.Msg='Proveedor no Existe';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newCompra()
                    this.existe=''                        
                }
            } else {
                this.Msg='Ingrese Numero Compra y Rut Proveedor';
                this.msgbox=true;
                this.color='info';
                this.showSnack();
                this.newCompra()
                this.existe=''                        
            }
            this.loading=false;
        },
        async saveCompra(){
            this.loading=true;
            this.numeroBodega(this.bodega);
            if (this.forma_pago!='' && this.contrato!='' && this.orden!='' && this.proveedor!='' && this.bodega!='' &&
                this.cantidad!='' && !isNaN(this.precio) && this.nProveedor!=0 && this.nBodega!=0 && 
                this.emision!='0000-00-00' && !isNaN(this.pordescuento)){
                if (this.radios=='Bruto'){
                    if(this.nombre!=''){
                        this.listanombre='';
                        this.nProducto=0;
                        await this.save()
                    } else { this.mensajeObligatorio() }
                }
                if (this.radios=='Neto'){
                    if(this.listanombre!=''){                        
                        //this.numeroSku(this.listanombre);
                        if (this.nProducto!=0){
                            this.nombre='';
                            this.save()
                        } else {
                            this.Msg='Inconsistencia, Intentelo nuevamente'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else { this.mensajeObligatorio() }
                } 
            } else {
                this.mensajeObligatorio()
            }
            this.loading=false;
        },
        mensajeObligatorio(){
            this.Msg='inconsistencia en un dato'
            this.msgbox=true;
            this.color='info';
            this.showSnack();
        },
        async save(){
            let nompro
            if(this.radios!='Bruto'){
                nompro=this.listanombre
            } else {
                nompro=this.nombre
            };
            var parametros={
                'contrato': this.contrato,
                'orden': this.orden,
                'entidad': this.nProveedor,
                'tipo': this.radios,
                'cv': this.cv,
                'sku':this.nProducto,
                'producto':nompro,
                'precio':this.precio,
                'cantidad':this.cantidad,
                'descuento':this.pordescuento,
                'bodega': this.nBodega,
                'observa':this.oem,
                'usuario':this.usu,
                'referencia':'0',
                'emision':this.emision,
                'contable':this.contable,
                'exenta':this.exenta,
                'for_pago':this.forma_pago,
                'ref_pago':this.ref_pago,
                'fec_pago':this.vence_pago};
            const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/compraventa/nueva',parametros);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){ 
                this.existe='si';               
                //actualizar stock de productos netos
                if(this.radio!='Bruto'){
                    var parametros1={
                        'contrato': this.contrato,
                        'sku': this.nProducto,
                        'cantidad': this.cantidad,
                        'signo': '0+',
                        'bodega': this.nBodega,
                        'usuario': this.usu,
                        'observa': this.observa,
                        'origen':'Compra'};
                    const response1 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ 
                        this.Msg='Compra-Neto Existosa !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                        this.btnGrabar=false;
                        this.nCompras++
                    } else {
                        this.Msg='Compra Exitosa, ingrese stock de forma manual'
                        this.msgbox=true;
                        this.color='warning';
                        this.showSnack();
                        this.btnGrabar=false;
                        this.nCompras++
                    }
                } else {
                    this.Msg='Compra-Bruto Existosa !!!'
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                    this.btnGrabar=false;
                    this.nCompras++
                }
                //calculo de totales
                this.descargaCSV=false;
                this.rutaCSV='';
                this.newItem();
                await this.datosDialog();
            } 
            if (datos=='error'){
                this.Msg='Conexion Inestable, intentelo mas tarde'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
                this.newCompra();
                this.existe=''  
            }         
        },
        reCalcular(varExento,varNoExento,varImpuesto){
            this.subtotal=varNoExento;
            this.exento=varExento;
            this.im=varImpuesto
            const nIva=Math.round(((varNoExento)*parseInt(this.impuesto))/100); 
            this.iva=nIva;
            this.total=this.subtotal+this.exento+this.im+this.iva;
        },
        OpenDialog(a) {
            if(a=='Nueva'){
                this.dialogBodega=true;
                this.alertabodega='';
                this.newbodega='';
            } else {
                this.alertabodega='';
                this.newbodega='';
                this.dialogBodega=false;
                this.numeroBodega(a);
            }
        },
        CloseDialog() {
           if(this.newbodega!=''){
                this.bodega=this.newbodega;
                this.numeroBodega(this.bodega);
            } else {
                this.bodega='';
            }
            this.newbodega='';
            this.alertabodega='';
            this.disabledBodega=false;
            this.filledBodega=false;
            this.dialogBodega=false;
        },
        async newBodega(){
            if(this.newbodega!=''){
                this.disabledBodega=true;
                this.filledBodega=true;
                var parametros={
                    'contrato': this.contrato,
                    'nombre': this.newbodega};
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/bodegas/nueva',parametros);
                const datos=response.data;
                this.itemBodegas.push(this.newbodega);
                this.bodega=this.newbodega;
                this.alertabodega='si';                
            } else {
                this.alertabodega='no';
            }
        },
        newItem(){
            this.nombre='';
            this.listanombre='';
            this.oem='';
            this.precio='';
            this.cantidad='';
            this.SkuItem='';
            this.select_menos=false;
            this.select_mas=true;    
        },
        async saveFechas(){
            if(this.orden.trim()!='' && this.nProveedor!=0){
                var parametros={
                    'orden':this.orden,
                    'contrato':this.contrato,
                    'emision': this.emision,
                    'contable': this.contable,
                    'for_pago':this.forma_pago,
                    'ref_pago':this.ref_pago,
                    'fec_pago':this.vence_pago};
                const response = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/fechas/compra/'+this.nProveedor,parametros);
                const datos=response.data;
                if(datos=='si'){
                    this.Msg='Fechas actualizadas'
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                } else {
                    this.Msg='Fechas NO actualizadas'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            }
        }


    }
}
</script>
<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">PAGOS</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-2 mr-2" justify="end">
                            <span class="text-caption">Suc.:<strong class="text-body-2 ml-2">{{txtSucursal}}</strong></span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="3" class="mt-n3">
                                <v-radio-group v-model="docDTE" row>
                                    <v-radio @click="newVenta()" label="Boleta" color="success" value="22"></v-radio>
                                    <v-radio @click="newVenta()" label="Factura" color="success" value="5"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="orden" label="N° Venta" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verDTE(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="razon" label="Nombre" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" md="1">
                                <v-text-field class='text-caption' v-model="ndev" label="Orden" dense outlined filled disabled></v-text-field>
                            </v-col> -->
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-row class="form-space">
                                    <v-col cols="12" md="4">
                                        <v-select class='text-caption' @change="addRef(pago)" v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" dense outlined></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select v-if="pago=='Nota de Credito'" class='text-caption' @change="pagoNC(idNC)" v-model="idNC" :items="NC" item-text="texto" item-value="id" label="Referencia" dense outlined></v-select>
                                        <v-text-field v-else-if="pago=='Cheque' || pago=='Efectivo'" class='text-caption' v-model="referencia" label="Referencia" dense outlined filled disabled></v-text-field>
                                        <v-text-field v-else class='text-caption' v-model="referencia" label="Referencia" dense outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="9" md="2">
                                        <v-text-field v-if="pago=='Cheque'" class='text-caption' v-model="monto" label="Monto" dense outlined filled disabled></v-text-field>
                                        <v-text-field v-else class='text-caption' @keypress="onlyNumber" v-model="monto" label="Monto" dense outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="3" md="2">
                                        <v-btn @click="addPagos()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                                    </v-col>
                                    <v-col cols="12" md="12" class="form-space">
                                        <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                            <template v-slot:item.accion="{ item }">
                                                <!-- <v-icon v-if="confirma=='V'" small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                                <v-icon v-else small color="grey">do_not_disturb</v-icon> -->
                                                <v-icon small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                            </template>
                                        </v-data-table>
                                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="3" class="text-space">
                                <v-alert v-if="parcial==total && parcial!=0" class='text-caption' dense text border="left" color="green">Total ($): {{parcial.toLocaleString()}}</v-alert>
                                <v-alert v-else class='text-caption' dense text border="left" color="red">Total ($): {{parcial.toLocaleString()}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="grey">Afecto ($):{{neto.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva.toLocaleString()}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="green">Total ($): {{total.toLocaleString()}}</v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" class="text-space">
                                <div class="text-right">
                                    <v-btn @click="newVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    <v-btn v-if="parcial==total && parcial!=0 && confirma=='V' && btn_pagar!=false" @click="Pagar()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Confirmar</span></v-btn>
                                    <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Confirmar</span></v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
        </v-card>
        <!-- DIALOGO CHEQUE -->
        <v-dialog v-model="dialogCheque" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>CHEQUES
                <v-spacer></v-spacer>
                <v-btn @click="dialogCheque=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="fechaCheque" label="Fecha" prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaCheque" @input="menu1 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                   <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="rutCheque" label="Rut" placeholder="99999999-K" outlined dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="7">
                        <v-select class='text-caption' v-model="bancoCheque" :items="bancos" item-text="name" item-value="id" label="Seleciona Banco" outlined dense></v-select>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="cuentaCheque" label="N° Cuenta" outlined dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="numeroCheque" label="N° Cheque" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="montoCheque" label="Monto" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn @click="saveCheque()" class="mt-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>              
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
.form-space {
    margin-top:-35px;
}
.text-space {
    margin-top:-25px;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Pagos', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            nSucursal:0,
            txtSucursal:'',

            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            
            disabledOrden:false,
            filledOrden:false,

            orden:'',
            ndev:'0',
            entidad:'',
            rut:'',
            razon:'',

            neto:0,
            impuesto:'',
            iva:0,
            total:0,
            parcial:0,

            pago:'',
            referencia:'',
            monto:'',

            page: 1,
            pageCount: 0,
            itemsPerPage: 3,
            headers: [
                { text: 'Fecha Pago', align: 'start', sortable: true, value: 'fecha' },
                { text: 'Forma Pago', align: 'start', sortable: true, value: 'pago' },
                { text: 'Referencia', align: 'start', sortable: false, value: 'referencia' },
                { text: 'Monto', align: 'center', sortable: false, value: 'monto' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            items: [],
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'2',forma:'T.Credito'},
                {id:'3',forma:'T.Debito'},
                {id:'4',forma:'Transferencia'},
                {id:'5',forma:'Cheque'},
                //{id:'6',forma:'Credito Cta.Cte.'},
                {id:'7',forma:'Nota de Credito'}
            ],
            
            pordescuento:0,
            subtotal:0,
            fecharef:'',
            ids:1,
            confirma:'',
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            docDTE:'5',
            NC:[],
            idNC:null,
            //datos Cheque
            dialogCheque:false,
            fechaCheque: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu1:false,
            rutCheque:'',
            bancos:[
                {id:1,name:'Banco Estado'},
                {id:2,name:'Banco de Chile / Edwards'}, 
                {id:3,name:'Banco de Crédito e Inversiones (BCI)'}, 
                {id:4,name:'Banco Bice'},
                {id:5,name:'Banco Santander'}, 
                {id:6,name:'Itaú / Corpbanca'}, 
                {id:7,name:'Banco Security'},
                {id:8,name:'Scotiabank'},
                {id:9,name:'Banco Falabella'}, 
                {id:10,name:'Banco Ripley'},
                {id:11,name:'Banco Consorcio'}, 
                {id:12,name:'Banco Internacional'}
            ],
            bancoCheque:'',
            cuentaCheque:'',
            numeroCheque:'',
            montoCheque:'',
            listaCheques:[],
            sumaNC:0,
            btn_pagar:false,
        }
    },
    mounted(){
        this.newVenta();
        this.buscarSucursal()
    },
    methods:{
        async buscarSucursal(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if (datos[0].Sucursal!=0){
                    this.nSucursal=datos[0].Sucursal;
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        datos1.forEach(element => {
                            if(element.ID==this.nSucursal){
                                this.txtSucursal=element.Nombre                            
                            }
                        });
                    } else {  
                        this.txtSucursal='Sin Sucursal';
                        this.nSucursal=0;
                    }
                } else {
                    this.txtSucursal='Sin Sucursal';
                    this.nSucursal=0;
                }
            } else { 
                this.txtSucursal='Sin Sucursal';
                this.nSucursal=0;
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        clsInput(){
            this.ndev='0';
            this.rut='';
            this.razon='';
            this.entidad='';
            this.pago='';
            this.referencia='',
            this.monto='',
            this.neto=0;
            this.iva=0;
            this.total=0;
            this.parcial=0;
            this.impuesto='';
            this.items=[];
            this.pordescuento=0;
            this.subtotal=0;
            this.ids=1;
            this.confirma='';
            //this.docDTE='5';
            this.NC=[];
            this.limpiarCheque();
            this.listaCheques=[];
            this.sumaNC=0;
        },
        newVenta(){
            this.orden='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.clsInput()
        },
        limpiarCheque(){
            this.fechaCheque= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.rutCheque='';
            this.bancoCheque='';
            this.cuentaCheque='';
            this.numeroCheque='';
            this.montoCheque='';
        },
        // async verifyPago(nOrden){
        //     try {
        //         const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
        //         const datos=response.data;
        //         if (datos[0].existe!='error'){
        //             if(datos[0].existe=='si'){
        //                 if(datos[0].Estado!='V'){
        //                     this.Msg='Flujo Diario Cerrado'
        //                     this.msgbox=true;
        //                     this.color='error';
        //                     this.showSnack()
        //                 } else { await this.verDTE(nOrden) }  // pagos ingresados aun no cerrado
        //             } else { // no existen pagos de hoy
        //                 // verificar que no exista fecha anterior abierta
        //                 const res = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/verify/pago/date/'+this.contrato+'/'+this.date1);
        //                 const dat=res.data;
        //                 if(dat[0].existe=='no'){
        //                     await this.verDTE(nOrden) 
        //                 } else { 
        //                     this.Msg='Fecha Anterior NO Cerrada'
        //                     this.msgbox=true;
        //                     this.color='error';
        //                     this.showSnack()
        //                 }
        //             } 
        //         } else { 
        //             this.Msg='Inconsistencia Base'
        //             this.msgbox=true;
        //             this.color='error';
        //             this.showSnack()
        //         }
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        async verDTE(nOrden){
            if (this.orden!=''){
                this.clsInput();
                this.loading=true;
                try {
                    const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/pago/DTE/'+this.contrato+'/'+nOrden+'/'+this.docDTE);
                    const datos=response.data;
                    if (datos[0].existe=='si'){
                        this.disabledOrden=true;
                        this.filledOrden=true;
                        this.ndev = datos[0].Orden;
                        this.entidad=datos[0].Entidad;
                        this.rut=datos[0].Rut;
                        this.razon=datos[0].Nombre;
                        this.neto=parseInt(datos[0].Neto);
                        this.iva=parseInt(datos[0].Iva);
                        this.total=parseInt(datos[0].Total);
                        //buscamos los pagos realizados
                        this.items=[];
                        const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/pago/buscar/'+this.contrato+'/'+nOrden+'/'+this.docDTE);
                        const datos1=response1.data;
                        if (datos1[0].existe=='si'){
                            datos1.forEach(element => {
                                this.items.push({
                                    'id':this.ids,
                                    'fecha':element.Fecha,
                                    'pago': element.FormaPago,
                                    'referencia': element.Referencia,
                                    'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                                    'valor':element.Monto
                                });
                                this.ids++;
                                this.parcial=this.parcial+parseInt(element.Monto)
                            });
                            this.confirma=datos1[0].Estado;
                        }
                    } else {
                        this.Msg='DTE no existe'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }                    
                } catch (error) {
                    console.log(error);
                }
                this.loading=false;
            }
        },
        // async verDTE_ant(nOrden){
        //     if (this.orden!=''){
        //         this.clsInput();
        //         this.loading=true;
        //         const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/DTE/dev/'+this.contrato+'/'+nOrden);
        //         const datos=response.data;
        //         console.log(datos);
        //         if (datos[0].existe=='si'){
        //             this.disabledOrden=true;
        //             this.filledOrden=true;
        //             this.ndev = datos[0].Orden;
        //             this.entidad=datos[0].Entidad;
        //             this.rut=datos[0].Rut;
        //             this.razon=datos[0].Nombre;
        //             this.impuesto=datos[0].Iva;
        //             this.pordescuento=datos[0].Descuento;
        //             this.items=[];
        //             let nTotal=0;
        //             datos.forEach(element => {
        //                 nTotal=nTotal+parseInt(element.Total)       
        //             });
        //             this.subtotal=nTotal;
        //             let descuento=Math.round((nTotal*parseInt(this.pordescuento))/100);            
        //             let afecto=nTotal-descuento;
        //             let nIva=Math.round((afecto*parseInt(datos[0].Iva))/100);
        //             this.neto=afecto;
        //             this.iva=nIva;
        //             this.total=afecto+nIva;
        //             const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/pago/buscar/'+this.contrato+'/'+nOrden);
        //             const datos1=response1.data;
        //             if (datos1[0].existe=='si'){
        //                 this.confirma=datos1[0].Estado;
        //                 datos1.forEach(element => {
        //                     this.items.push({
        //                         'id':this.ids,
        //                         'fecha':element.Fecha,
        //                         'pago': element.FormaPago,
        //                         'referencia': element.Referencia,
        //                         'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
        //                         'valor':element.Monto
        //                     });
        //                     this.ids++;
        //                     this.parcial=this.parcial+parseInt(element.Monto)
        //                 });
        //             }
        //         } else {
        //             this.Msg='DTE no existe'
        //             this.msgbox=true;
        //             this.color='red';
        //             this.showSnack();
        //         }
        //         this.loading=false;
        //     }
        // },
        isNum(val){
            return !isNaN(val)
        },
        addPagos(){
            if(this.pago!=''){
                if (this.referencia.trim()!=''){
                    if (this.isNum(this.monto)){
                        if(this.pago!='Nota de Credito'){
                            const totalRojo=this.parcial+parseInt(this.monto);
                            if (totalRojo<=parseInt(this.total)){    			            
                                this.parcial=totalRojo;
                                this.items.push({
                                    'id':this.ids,
                                    'fecha':this.date1,
                                    'pago': this.pago,
                                    'referencia': this.referencia,
                                    'monto':new Intl.NumberFormat("de-DE").format(parseFloat(this.monto)),
                                    'valor':this.monto
                                });
                                this.ids++;
                                this.pago='';
                                this.referencia='';
                                this.monto=''
                                if (this.parcial==parseInt(this.total)){ this.confirma='V'; this.btn_pagar=true }
                            } else {
                                this.Msg='No debe superar el Total'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } else {
                            if(this.monto<=this.sumaNC){
                                const totalRojo=this.parcial+parseInt(this.monto);
                                if (totalRojo<=parseInt(this.total)){    			            
                                    this.parcial=totalRojo;
                                    this.items.push({
                                        'id':this.ids,
                                        'fecha':this.date1,
                                        'pago': this.pago,
                                        'referencia': this.referencia,
                                        'monto':new Intl.NumberFormat("de-DE").format(parseFloat(this.monto)),
                                        'valor':this.monto
                                    });
                                    this.ids++;
                                    this.pago='';
                                    this.referencia='';
                                    this.monto=''
                                    if (this.parcial==parseInt(this.total)){ this.confirma='V'; this.btn_pagar=true }
                                } else {
                                    this.Msg='No debe superar el Total'
                                    this.msgbox=true;
                                    this.color='red';
                                    this.showSnack();
                                }
                            } else {
                                this.Msg='No debe superar Saldo'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        }
                    } else {
                        this.Msg='Ingrese Monto'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Ingrese Referencia'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Ingrese Forma Pago'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        deleteItem(array){
            this.items = this.items.filter(function(dat) {
                return dat.id !== array.id; 
            });
            this.listaCheques = this.listaCheques.filter(function(dat) {
                return dat.id !== array.id; 
            });
            this.parcial=this.parcial-parseInt(array.valor)
        },
        async Pagar(){
            this.loading=true;
            this.btn_pagar=false;
            try {
                //elimina los cheques del pago
                await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/pago/cheque/delete/'+this.contrato+'/'+this.orden+'/'+this.docDTE);
                //eliminar registros de pago
                await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/pago/delete/'+this.contrato+'/'+this.orden+'/'+this.docDTE);
                //validamos existencia de cheques
                let continua_pago="si";
                if(this.listaCheques.length!=0){
                    for (let index = 0; index < this.listaCheques.length; index++) {
                        const element = this.listaCheques[index];
                        this.rutCheque=element.rut;
                        this.bancoCheque=element.banco;
                        this.cuentaCheque=element.cuenta;
                        this.numeroCheque=element.numero;
                        const existencia = await this.validarCheques();
                        if (existencia){
                            continua_pago="no";
                        }
                    }
                };
                if (continua_pago=="no"){
                    this.Msg='Cheque Existente'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                } else {
                    //grabamos los cheques de la lista si existen
                    await this.chequeBBDD();
                    //grabar pagos
                    let params;
                    let reg=0;
                    for (let index = 0; index < this.items.length; index++) {
                        const element =this.items[index];
                        params={
                            'fecha':element.fecha,
                            'contrato':this.contrato,
                            'numero': this.orden,
                            'tipo':this.docDTE,
                            'entidad': this.entidad,
                            'forma':element.pago,
                            'referencia':element.referencia,
                            'monto':element.valor,
                            'sucursal':this.nSucursal,
                            'usuario':this.usu,
                            'estado':'V'
                        }
                        const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/pago/nuevo',params);
                        const datos=response.data;
                        console.log(datos);
                        if (datos=='si'){ 
                            reg++;
                        }                
                    };
                    if (reg==this.items.length){
                        this.Msg='Confirmacion Exitosa'
                        this.msgbox=true;
                        this.color='green';
                        this.showSnack();
                    } else {
                        this.Msg='Confirmacion Fallida'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();              
                    }
                }
            } catch (error) {
                console.log(error)
            }
            this.loading=false    
        },
        async addRef(t){
            this.referencia='';
            this.monto='';
            this.sumaNC=0;
            if(t!='Efectivo'){
                if (t=='Nota de Credito'){
                    //console.log(this.entidad);
                    try {
                        if(this.entidad!=''){
                            this.loading=true;
                            //buscar notas de Creditos del Cliente
                            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/search/notas/cliente/v2/'+this.contrato+'/'+this.entidad);
                            const datos=response.data;
                            if (datos[0].existe=='si'){ 
                                this.NC=[];
                                var indice=0;
                                for (let index = 0; index < datos.length; index++) {
                                    const element = datos[index];
                                    //buscamos el valor de los pagos
                                    const resp = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sumas/notas/cliente/v2/'+this.contrato+'/'+element.Numero);
                                    const dato=resp.data;
                                    const sumaNotas=dato[0].Monto;
                                    if(parseFloat(element.Total)-parseFloat(sumaNotas)!=0){
                                        this.NC.push({
                                            id:indice,
                                            numero:element.Numero,
                                            total:element.Total,
                                            sumas:sumaNotas,
                                            texto: 'NC '+element.Numero
                                        });
                                        indice++;                        
                                    }
                                };
                            }
                            this.loading=false;
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
                if(t=='Cheque'){
                    this.limpiarCheque();
                    this.dialogCheque=true;
                }
            } else {
                this.referencia=t;
            }
        },
        async pagoNC(i){
            this.referencia=this.NC[i].texto
            //this.monto=this.NC[i].total;
            this.monto=this.NC[i].total-this.NC[i].sumas;
            this.sumaNC=this.monto;
        },
        async saveCheque(){
            let ruts=this.validaRut(this.rutCheque);
            if (ruts){
                if(this.orden!='' && this.bancoCheque!='' && this.cuentaCheque!='' && this.montoCheque!='' && this.numeroCheque!=''){
                    //validar si el cheque existe en la lista
                    const enLista=this.validaLista();
                    if(enLista){
                        this.Msg='Cheque existe'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    } else {
                        //agregar en Lista
                        this.listaCheques.push({
                            'id':this.ids,
                            'contrato':this.contrato,
                            'fecha':this.fechaCheque,
                            'rut':this.rutCheque,
                            'banco':this.bancoCheque,
                            'cuenta':this.cuentaCheque,
                            'numero':this.numeroCheque,
                            'monto':this.montoCheque,
                            'movimiento':'Pago',
                            'documento':this.orden,
                            'tipo':this.docDTE
                        });
                        this.referencia="CH " + this.numeroCheque;
                        this.monto=this.montoCheque;
                        this.addPagos();
                        this.dialogCheque=false;
                    }
                } else {
                    this.Msg='Dato Obligatorio'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Rut no Valido'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async chequeBBDD(){
            try {
                if (this.listaCheques.length!=0){
                    for (let index = 0; index < this.listaCheques.length; index++) {
                        const element = this.listaCheques[index];
                        const params={
                            'contrato':element.contrato,
                            'fecha':element.fecha,
                            'rut':element.rut,
                            'banco':element.banco,
                            'cuenta':element.cuenta,
                            'numero':element.numero,
                            'monto':element.monto,
                            'movimiento':element.movimiento,
                            'documento':element.documento,
                            'tipo':element.tipo,
                        }
                        await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/add/cheque',params);
                    };
                };
            } catch (error) {
                console.log(error)
            }
        },
        validaRut(rutCompleto){
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			    return false;
		    var tmp 	= rutCompleto.split('-');
		    var digv	= tmp[1]; 
		    var ruti 	= tmp[0];
		    if ( digv == 'K' ) digv = 'k' ;
		    return (this.dv(ruti) == digv );
        },
        dv(T){
            var M=0,S=1;
    		for(;T;T=Math.floor(T/10))
	    		S=(S+T%10*(9-M++%6))%11;
		    return S?S-1:'k';
        },
        async validarCheques(){
            try {
                const datosCheque=this.contrato+'/'+this.rutCheque+'/'+this.bancoCheque+'/'+this.cuentaCheque+'/'+this.numeroCheque;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/search/cheque/'+datosCheque);
                const datos=response.data;
                if (datos[0].existe!='si'){ 
                    return false
                } else {
                    return true
                }
            } catch (error) {
                console.log(error);
                return true              
            }
        },
        validaLista(){
            let validar="no";
            if(this.listaCheques.length!=0){
                this.listaCheques.forEach(element => {
                    if (element.rut==this.rutCheque && 
                        element.banco==this.bancoCheque && 
                        element.cuenta==this.cuentaCheque &&
                        element.numero==this.numeroCheque)
                    validar="si";
                });
            };
            if (validar=="si"){
                return true
            } else {
                return false
            }
        }

    }
}
</script>
<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">VENTA</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-2 mr-2" justify="end">
                            <span class="text-caption">Suc.:<strong class="text-body-2 ml-2">{{txtSucursal}}</strong></span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="3">
                                <v-text-field class='text-caption' v-model="orden" :counter="15" label="Numero Cotiza" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verOrden(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" dense outlined filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field class='text-caption' v-model="razon" label="Nombre" dense outlined filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="6" md="1">
                                <v-text-field class='text-caption' v-model="descuento" label="Desc.%" dense outlined disabled filled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="8">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.stock="{ item }">
                                        <v-icon v-if="item.stock=='si'" small class="text green--text">check</v-icon>
                                        <v-icon v-if="item.stock=='no'" small class="text red--text">clear</v-icon>
                                    </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' dense text border="left" color="grey">Neto: $ {{subtotal.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Afecto: $ {{afecto.toLocaleString()}}</v-alert>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' dense text border="left" color="grey">Desc.: $ {{pesosdescuento.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): $ {{iva}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="red">Total: $ {{total.toLocaleString()}}</v-alert>
                            </v-col>
                             <v-col cols="12" md="2" class="mt-3">
                                <v-text-field class='text-caption' v-model="oc" label="Orden Compra" dense outlined></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='text-caption' @change="alertaFolios(dte)" v-model="dte" :items="itemDTE" label="Tipo Documento" item-text="doc" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='ml-2 text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id"></v-select>
                            </v-col>
                             <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="envio" label="Envio Digital" dense filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <div class="mt-5 text-right">
                                    <v-btn @click="newVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    <v-btn v-if="btnGrabar===true" @click="inDTE(dte,pago)" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <a  v-if="btnPrt===true" target="_blank" :href="html" class="ml-2"><v-icon color="teal">print</v-icon></a>
                                    <a  v-else href="javascript:void(0)" class="ml-3"><v-icon class="grey--text">print</v-icon></a>
                                    <!-- <a  class="ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></a>
                                    <a  class="ml-2" v-else :href="rutaCSV"><v-icon color="teal">cloud_download</v-icon></a> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
        </v-card>
        <!-- dialog alerta folios --> 
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <div class="d-flex justify-center">
                <h3>Quedan <strong class="text-h5 red--text">( {{countfolios}} )</strong> Folios Disponibles</h3>
                </div>
            </v-card-text>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledOrden:false,
            filledOrden:false,
            btnGrabar:false,
            btnPrt:false,
            html:'',
            orden:'',
            entidad:'',
            rut:'',
            razon:'',
            direccion:'',
            comuna:'',
            ciudad:'',
            giro:'',
            fecha:'',
            hoy:'',
            impuesto:'',
            iva:0,
            dte:'',
            pago:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Codigo', align: 'start', sortable: true, value: 'codigo' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: false, value: 'precio' },
                { text: 'cantidad', align: 'center', sortable: false, value: 'cantidad' },
                { text: 'Bodega', align: 'center', sortable: false, value: 'bodega' },
                { text: 'Stock', align: 'center', sortable: false, value: 'stock' },
            ],
            items: [],
            itemDTE:[
                {id:'22',doc:'Boleta'},
                {id:'5',doc:'Factura'},
            ],
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'6',forma:'T. Debito'},
                {id:'2',forma:'T. Credito'},
                {id:'30',forma:'Credito (30)'},
                {id:'60',forma:'Credito (60)'},
                {id:'90',forma:'Credito (90)'},
                {id:'8',forma:'Transferencia'},
                {id:'10',forma:'Cheque'}
            ],
            OutID:0,
            descargaCSV:false,
            rutaCSV:'',   
            envio:'',
            nStock:0,
            txtStock:'si',
            nSucursal:0,
            txtSucursal:'',
            descuento:0,
            subtotal:0,
            pesosdescuento:0,
            afecto:0,
            total:0,
            correoBodega:'',
            //control de Folios
            dialog:false,
            countfolios:0,
            oc:'',
        }
    },
    mounted(){
        this.newVenta();
        this.buscarSucursal()
    },
    methods:{
        async alertaFolios(tpdoc){
            //console.log(tpdoc);
            var codSII=0;
            switch (tpdoc) {
                case '5':
                        codSII=33
                break;
                case '22':
                        codSII=39
                break;
            }
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/alerta/folios/'+this.contrato+'/'+codSII);
            const datos=response.data;
            //console.log(datos);
            if (datos[0].existe=='si'){
                if (datos[0].Cuenta<=10){
                    //activa alerta
                    this.countfolios=datos[0].Cuenta;
                    this.dialog=true;
                }
            };
        },
        async buscarSucursal(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if (datos[0].Sucursal!=0){
                    this.nSucursal=datos[0].Sucursal;
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        datos1.forEach(element => {
                            if(element.ID==this.nSucursal){
                                this.txtSucursal=element.Nombre                            
                            }
                        });
                    } else {  
                        this.txtSucursal='Sin Sucursal';
                        this.nSucursal=0;
                    }
                } else {
                    this.txtSucursal='Sin Sucursal';
                    this.nSucursal=0;
                }
            } else { 
                this.txtSucursal='Sin Sucursal';
                this.nSucursal=0;
            }
        },
        async fileCSV(){
            if (this.btnPrt===true){
                this.loading=true;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/fileCsv/venta/'+this.contrato+'/'+this.OutID+'/'+this.entidad);
                const datos=response.data;
                //console.log(datos);
                if (datos=='si'){
                    this.descargaCSV=true;
                    this.rutaCSV='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+this.OutID+'.csv';
                }
                this.loading=false;
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.rut='';
            this.razon='';
            this.direccion='';
            this.comuna='';
            this.ciudad='';
            this.giro='';
            this.fecha='';
            this.hoy='';
            this.iva=0;
            this.impuesto='';
            this.dte='';
            this.pago='';
            this.items=[];
            this.btnPrt=false;
            this.html='';
            this.OutID=0;
            this.entidad='';
            this.descargaCSV=false;
            this.rutaCSV='';
            this.envio='';   
            this.nStock=0;
            this.txtStock='si';
            this.descuento=0;
            this.subtotal=0;
            this.pesosdescuento=0;
            this.afecto=0;
            this.total=0;
            this.oc='';
        },
        newVenta(){
            this.orden='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.btnGrabar=false;
            this.clsInput()
        },
        async verOrden(nOrden){
            this.clsInput();
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/'+this.contrato+'/'+nOrden);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.disabledOrden=true;
                this.filledOrden=true;
                this.btnGrabar=true;
                this.entidad=datos[0].Entidad;
                this.rut=datos[0].Rut;
                this.razon=datos[0].Nombre;
                this.direccion=datos[0].Direccion;
                this.comuna=datos[0].Comuna;
                this.ciudad=datos[0].Ciudad;
                this.giro=datos[0].Giro;
                this.fecha=datos[0].Fecha;
                this.hoy=datos[0].Hoy;
                this.impuesto=datos[0].Iva;
                this.descuento=datos[0].Descuento;
                this.items=[];
                let nTotal=0;
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    this.nStock=await this.totalStock(element.Bodega,element.Sku);
                    if (parseInt(this.nStock)>=parseInt(element.Cantidad)){
                        this.items.push({
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'unitario':element.Precio,
                            'cantidad':element.Cantidad,
                            'bodega':element.Bodega,
                            'stock':'si'
                        });
                    } else {
                        this.txtStock="no";
                        this.items.push({
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'unitario':element.Precio,
                            'cantidad':element.Cantidad,
                            'bodega':element.Bodega,
                            'stock':'no'
                        });
                    }
                    nTotal=nTotal+parseInt(element.Total)                    
                };
                this.subtotal=nTotal;                
                this.pesosdescuento=Math.round((parseInt(nTotal)*parseFloat(this.descuento))/100);
                this.afecto=nTotal-this.pesosdescuento;
                let nIva=Math.round((this.afecto*parseFloat(datos[0].Iva))/100);
                this.iva=nIva;
                this.total= this.afecto+nIva;
            } else {
                this.Msg='Orden no Existe o con DTE'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        async totalStock(b,s){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ b + '/' + s);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                 return datos[0].Total;
            } else { return 0 }
        },
        Vencimiento(fechaHoy,diasVence){
            var fechaVencimiento = new Date();
            fechaVencimiento.setDate(fechaVencimiento.getDate() + parseInt(diasVence));
            var diaVenc = fechaVencimiento.getDate();
            var mesVenc = fechaVencimiento.getMonth() + 1;
            var anioVenc = fechaVencimiento.getFullYear();
            //formateamos la fecha yyyy-mm-dd
            var formatFecha=anioVenc + "-" + ('0'+mesVenc).slice(-2) + "-" + ('0'+diaVenc).slice(-2);
            return formatFecha;
        },
        async inDTE(selectDTE,selectPago){
            if(this.nSucursal!=0){
                if(this.txtStock!="si"){
                    this.Msg='Productos sin Stock';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                } else {
                    this.loading=true;
                    if (selectDTE!=0 && selectPago!=0){                
                        let varApiKey='';
                        let varU='';
                        let varP='';
                        let varIdEmpresa='';                        
                        let numDocumento=0; // 0 folio automatico -- folio :: facturas: 43-501 boletas: 61-560 NC: 51-501
                        // tipo de Venta
                        let TpDocumento='';
                        if(selectDTE!=22){ TpDocumento='33' } // Factura (5)
                        else { TpDocumento='39' }; // Boleta (22)
                        // obtener folio
                        const resFolio = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/folio/nuevo/'+this.contrato+'/'+TpDocumento);
                        const datoFolio = resFolio.data;
                        //console.log(datoFolio);
                        if (datoFolio[0].existe=='si'){
                            numDocumento=datoFolio[0].Folio;
                            // reservo folio estado P
                            const parFolio={ 'numero':numDocumento,'estado':'P' };
                            const updateFolio = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio);
                            const dataFolio = updateFolio.data;
                            if (dataFolio=='si'){
                                //token
                                const resToken = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/apikey/access/'+this.contrato);
                                const respuesta = resToken.data;
                                //console.log(respuesta);
                                if (respuesta[0].existe=='si'){ 
                                    varApiKey=respuesta[0].Token;
                                    varU=respuesta[0].U;
                                    varP=respuesta[0].P;
                                    varIdEmpresa=respuesta[0].IdEmpresa;

                                    // completar formData
                                    let InstFormData = new FormData();
                                    InstFormData.append('fchemis' , this.hoy); 
                                    if(selectPago==30 || selectPago==60 || selectPago==90){ 
                                        var fechavence = this.Vencimiento(this.hoy,selectPago);
                                        InstFormData.append('fchvenc' , fechavence);
                                    };
                                    //InstFormData.append('nrocaja' , '2');
                                    //InstFormData.append('mntexe' , '0');
                                    //InstFormData.append('mntbruto' , '0');
                                    InstFormData.append('rutrecep' , this.rut);
                                    InstFormData.append('rznsocrecep' , this.razon);
                                    //InstFormData.append('descuentomonto1' , '0');
                                    //InstFormData.append('tasaimp' , '0.00');
                                    //InstFormData.append('nrotienda' , '1');
                                    InstFormData.append('dirrecep' , this.direccion);
                                    InstFormData.append('cmnarecep' , this.comuna); 
                                    InstFormData.append('ciudadrecep' , this.ciudad);
                                    InstFormData.append('idempresa' , varIdEmpresa);
                                    InstFormData.append('girorecep' , this.giro);
                                    //InstFormData.append('mntimp' , '0');

                                    // recorrer items
                                    let reg=1;
                                    this.items.forEach(element => {
                                        InstFormData.append('indexe'+reg , 0);
                                        InstFormData.append('vlrcodigo'+reg , element.codigo);
                                        InstFormData.append('tpocodigo'+reg , 'Sku');
                                        InstFormData.append('nmbitem'+reg , element.producto);
                                        //InstFormData.append('dscitem1' , 'Descripción');
                                        InstFormData.append('unmditem'+reg , 'UN');
                                        InstFormData.append('qtyitem'+reg , element.cantidad);
                                        InstFormData.append('prcitem'+reg , element.unitario);                                
                                        //InstFormData.append('descuentopct1' , '0.0');
                                        InstFormData.append('montoitem'+reg , parseInt(element.cantidad)*parseInt(element.unitario)); 
                                        reg ++;
                                    });
                                    //InstFormData.append('preview' , '1'); //prueba
                                    InstFormData.append('preview' , '0');
                                    InstFormData.append('rutemisor' , varP);
                                    InstFormData.append('decodeutf8' , 1);
                                    InstFormData.append('apikey' , varApiKey);
                                    InstFormData.append('u' , varU);
                                    InstFormData.append('p' , varP);
                                    InstFormData.append('adt_nombre1' , 'Descuento');
                                    InstFormData.append('adt_valor1' , this.pesosdescuento);
                                    InstFormData.append('adt_nombre2' , 'SubTotal');
                                    InstFormData.append('adt_valor2' , this.subtotal);
                                    // x 2 copias
                                    InstFormData.append('adt_nombre3' , 'Plantilla');
                                    InstFormData.append('adt_valor3' , 'x2_dte_tpl');

                                    if(selectPago==1 || selectPago==6 || selectPago==8){ 
                                        InstFormData.append('formapago' , 'CONTADO');
                                    } else {                             
                                        InstFormData.append('formapago' , 'CREDITO');
                                    }
                                    const fp = this.itemPAGO.find( resp => resp.id === selectPago );
                                    if (fp) {
                                        InstFormData.append('adt_nombre4' , 'FormaPago');
                                        InstFormData.append('adt_valor4' , fp.forma);
                                        InstFormData.append('adt_nombre5' , 'NmbVendedor');
                                        InstFormData.append('adt_valor5' , this.usu);
                                    } else {
                                        InstFormData.append('adt_nombre4' , 'NmbVendedor');
                                        InstFormData.append('adt_valor4' , this.usu);
                                    };
                                    //Orden de Compra por Referencia
                                    if(this.oc.trim()!=''){
                                        InstFormData.append('fchref1' , this.hoy);
                                        InstFormData.append('folioref1' , this.oc);
                                        InstFormData.append('tpodocref1' , '801');
                                    };
                                    InstFormData.append('mntneto' , this.afecto);
                                    InstFormData.append('iva' , this.iva);                            
                                    InstFormData.append('tasaiva' , this.impuesto);
                                    InstFormData.append('mnttotal' , this.total);
                                    
                                    //InstFormData.append('folio' , '1'); //prueba
                                    InstFormData.append('folio' , numDocumento);  
                                    InstFormData.append('tipodte' , TpDocumento);
                                    let resDTE =[]; 
                                    try {
                                        //const response= await axios.post('http://190.121.8.122:8080/ws_aces/generarDTE/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                                        //const response= await axios.post('http://190.121.8.122/ws_aces/generarDTE/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                                        const response= await axios.post('http://dte.transervi.cl/ws_aces/generarDTE/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                                        resDTE=response.data;
                                        console.log(resDTE)                                
                                    } catch (error) {
                                        console.log(error)
                                    }; 
                                    if (resDTE.mensaje!='OK'){
                                        this.Msg='DTE no enviado'
                                        this.msgbox=true;
                                        this.color='red';
                                        this.showSnack();
                                        //devolvemos el folio estado V
                                        const parFolio1={ 'numero':numDocumento,'estado':'V' };
                                        const updateFolio1 = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio1);
                                        const dataFolio1 = updateFolio1.data;
                                        if (dataFolio1=='si'){ console.log(numDocumento + ' folio devuelto') }
                                    } else {
                                        //this.envioDTE(this.envio,resDTE.urlPdf,resDTE.urlXml);
                                        numDocumento=resDTE.folio;
                                        this.html=resDTE.url;
                                        this.btnPrt=true;
                                        this.btnGrabar=false;
                                        this.OutID=0;
                                        this.OutID=numDocumento;                    
                                        const paramsDTE={
                                            'contrato':this.contrato,
                                            'numero':numDocumento,
                                            'tipoDte':selectDTE,
                                            'tipoPago':selectPago,
                                            'neto':this.afecto,
                                            'iva':this.iva,
                                            'total':this.total,
                                            'fecha':this.hoy,
                                            'orden':this.orden,
                                            'oc':this.oc,
                                            'rutaPDF':resDTE.url,
                                            'rutaXML':resDTE.url,
                                            'usuario':this.usu,
                                            'sucursal':this.nSucursal,
                                            'referencia':''
                                        }; 
                                        //console.log(paramsDTE) ;                  
                                        await this.saveDTE(paramsDTE);
                                        //cambiamos el estado del folio exitoso
                                        const parFolio2={ 'numero':numDocumento,'estado':'N' };
                                        const updateFolio2 = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio2);
                                        const dataFolio2 = updateFolio2.data;
                                        if (dataFolio2=='si'){ console.log(numDocumento+' folio exitoso') }
                                    }
                                } else { 
                                    this.Msg='Token Invalido'
                                    this.msgbox=true;
                                    this.color='red';
                                    this.showSnack();
                                }
                            } else {
                                this.Msg='Folio no reservado'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } else {
                            this.Msg='Sin folio para DTE'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                        this.Msg='Seleccione DTE y Pago'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                    this.loading=false;
                }
            } else {
                this.Msg='Venta Sin Sucursal'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async saveDTE(parametrosDTE){
            const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/DTE/nueva',parametrosDTE);
            const datos=response.data;
            //console.log(parametrosDTE);
            if (datos=='si'){ 
                await this.updateReferencia();
            } else {
                this.Msg='DTE enviado sin registro Local'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        async updateReferencia(){
            var parametros0={'numero': this.OutID};
            const response0 = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/referencia/actualiza/'+this.contrato+'/'+this.orden+'/'+this.entidad,parametros0);
            const datos0=response0.data;
            //console.log(datos0);
             if (datos0=='si'){
                await this.UpdateStock();
             } else {
                this.Msg='DTE enviado sin Referencia';
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
             }
        },
        async UpdateStock(){
            let t=0;
            if (this.items.length!=0) {
                for (var i = 0; i < this.items.length; i++) {
                    var parametros1={
                            'contrato': this.contrato,
                            'sku': this.items[i].codigo,
                            'cantidad': this.items[i].cantidad,
                            'signo': '0-',
                            'bodega': this.items[i].bodega,
                            'usuario': this.usu,
                            'observa': '',
                            'origen':'Venta'};
                    const response1 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin actualizar Stock';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    await this.preEmail();
                    this.btnPrt=true;
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            }
        },
        async envioDTE(datoEnvio,datoPDF,datoXML){
            if (datoEnvio.trim()!=''){
                let InstFormData = new FormData();
                InstFormData.append('email' , datoEnvio);
                InstFormData.append('pdf' , datoPDF);
                InstFormData.append('xml' , datoXML);
                const response= await axios.post('https://www.transervi.cl/Api_TranServi/public/api/server/sender', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
            }
        },
        async preEmail(){
            if(this.items.length!=0){

                if(this.nSucursal!=0){
                    if(this.nSucursal==1){ this.correoBodega='bodega@transervi.cl'; };
                    if(this.nSucursal==2){ this.correoBodega='bodegaruiztagle@transervi.cl'; };
                };

                let titulo_pedido='';
                titulo_pedido=titulo_pedido+"<table width='100%'>";
                titulo_pedido=titulo_pedido+"<tr>";
                titulo_pedido=titulo_pedido+"<td style='width:10%'>SKU</td>";
                titulo_pedido=titulo_pedido+"<td style='width:30%'>PRODUCTO</td>";
                titulo_pedido=titulo_pedido+"<td style='width:10%'>CANTIDAD</td>";
                titulo_pedido=titulo_pedido+"<td style='width:50%'></td>";
                titulo_pedido=titulo_pedido+"</tr>";
                
                let detalle_pedido='';
                this.items.forEach(element => {
                    detalle_pedido=detalle_pedido+'<tr>';
                    detalle_pedido=detalle_pedido+'<td>'+element.codigo+'</td>';
                    detalle_pedido=detalle_pedido+'<td>'+element.producto+'</td>';
                    detalle_pedido=detalle_pedido+'<td>'+element.cantidad+'</td>';
                    detalle_pedido=detalle_pedido+'<td></td>';             
                    detalle_pedido=detalle_pedido+'</tr>'
                });
                detalle_pedido=detalle_pedido+'</table>';

                let cuerpo_pedido='<h3>PEDIDO N°&nbsp;&nbsp;'+this.OutID+'</h3><h4>'+this.rut+'&nbsp;&nbsp;'+this.razon+'</h4><h3>DETALLE</h3>'+titulo_pedido+detalle_pedido;
                //console.log(cuerpo_pedido);
                await this.envioEmail(this.correoBodega,cuerpo_pedido)
            }
        },
        async envioEmail(destino,cuerpo){
            if (destino.trim()!=''){
                let InstFormData = new FormData();
                InstFormData.append('email' , destino);
                InstFormData.append('cuerpo' , cuerpo);
                const response= await axios.post('https://www.transervi.cl/Api_TranServi/public/api/server/sender/email', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
            }
        },
    }
}
</script>
<template>
    <div>
        <v-simple-table fixed-header height="200px" dense>
            <thead>
                <tr>
                <th style="background-color:lightgrey;width:10%">Fecha</th>
                <th style="background-color:lightgrey;width:10%">Referencia</th>
                <th style="background-color:lightgrey;width:30%">Razon Social</th>
                <th style="background-color:lightgrey;width:2%">Tipo</th>
                <th style="background-color:lightgrey;width:5%">Numero</th>
                <th style="background-color:lightgrey;width:2%">Moneda</th>
                <th style="background-color:lightgrey;width:5%">Paridad</th>
                <th style="background-color:lightgrey;width:5%">Precio</th>
                <th style="background-color:lightgrey;width:5%">Descuento</th>
                <th style="background-color:lightgrey;width:5%">Cantidad</th>
                <th style="background-color:lightgrey;width:7%">CLP Unitario</th>
                <th style="background-color:lightgrey;width:7%">CLP Compra</th>
                <th style="background-color:lightgrey;width:7%">Estado</th>
                </tr>
            </thead>
            <tbody v-if="existe===true" style="background-color:#B2EBF2">
                <tr v-for="item in desserts" :key="item.id">
                    <td style="font-size:9pt">{{ item.fecha }}</td>
                    <td style="font-size:9pt">{{ item.cod_proveedor }}</td>
                    <td style="font-size:9pt">{{ item.proveedor }}</td>
                    <td style="font-size:9pt">{{ item.tipo }}</td>
                    <td style="font-size:9pt">{{ item.numero }}</td>
                    <td style="font-size:9pt">{{ item.moneda }}</td>
                    <td style="font-size:9pt">{{ item.paridad }}</td>
                    <td style="font-size:9pt">{{ item.precio }}</td>
                    <td style="font-size:9pt">{{ item.descuento }}</td>
                    <td style="font-size:9pt">{{ item.cantidad }}</td>
                    <td style="font-size:9pt">{{ item.unitario }}</td>
                    <td style="font-size:9pt">{{ item.total }}</td>
                    <td style="font-size:9pt">{{ item.estado }}</td>
                </tr>                
            </tbody>
            <tbody v-else-if="existe===false" style="background-color:#B2EBF2">
                <tr>
                    <td colspan="13" style="height:165px;text-align: center">
                        <span class="text-caption grey--text">No existe informacion asociada.</span>
                    </td>
                </tr>
            </tbody>
            <tbody v-else style="background-color:#B2EBF2">
                <tr>
                    <td colspan="13" style="height:165px;text-align: center">
                        <v-progress-circular :width="2" :size="20" indeterminate color="indigo"></v-progress-circular>
                        <span class="ml-2 text-caption grey--text">Buscando números de referencia ......</span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-divider></v-divider>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name:'GerenciaPedidos',
    props: ['usu','contrato','sku'], 
    data(){
        return{
            desserts: [],
            existe:null,
        }
    },
    mounted(){
        if(this.sku.trim()!=''){
            this.searchCompras()
        } else {
            this.existe=false
        }
    },
    methods:{
        async searchCompras(){
            try {
                const dia_incial='2022-01-01';
                const dia_final= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/compras/sku/detalle/'+this.contrato+'/'+this.sku+'/'+dia_incial+'/'+dia_final);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    var ids=0;
                    datos.forEach(element => {
                        if(element.Estado=='Pendiente'){
                            ids++;
                            let varDesc=0
                            let varUnidad=0;
                            let varTotal=0;
                            if(element.Descuento!=0){ varDesc=element.Descuento };
                            // generar calculos
                            varUnidad=(parseFloat(element.Paridad)*parseFloat(element.Precio))-(((parseFloat(element.Paridad)*parseFloat(element.Precio))*varDesc)/100);
                            varTotal=varUnidad*parseFloat(element.Cantidad);
                            this.desserts.push({
                                'id':ids,
                                'fecha':element.Fecha,
                                'tipo':element.Tipo,
                                'numero':element.Orden,
                                'proveedor':element.Proveedor,
                                'cod_proveedor':element.Cod_Proveedor,
                                'moneda':element.Moneda,
                                'paridad':element.Paridad,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'descuento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Descuento)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'unitario':new Intl.NumberFormat("de-DE").format(parseFloat(varUnidad)),
                                'total':new Intl.NumberFormat("de-DE").format(varTotal),
                                'estado':element.Estado,
                            })                            
                        }
                    });
                    if(ids!=0){
                        this.existe=true;
                    } else {
                        this.existe=false;
                    }
                } else {
                    this.existe=false;
                }               
            } catch (error) {
                this.existe=false;
                console.log(error)
            }
        }
    }
}
</script>
<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">FLUJO</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-2 mr-2" justify="end">
                            <span class="text-caption">Suc.:<strong class="text-body-2 ml-2">{{txtSucursal}}</strong></span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip @click="OpenCalendar()" v-bind="attrs" v-on="on" outlined color="teal">
                                        <v-icon class="ml-2">events</v-icon>
                                            {{date1}}
                                    </v-chip>
                                </template>
                                <v-date-picker v-model="date1" @input="menu1 = false" no-title scrollable></v-date-picker>
                            </v-menu>                                        
                            <v-btn class="ml-2" @click="ReporteCajaDiaria()" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                        </v-col>
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="red"><strong>Arqueo :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(arqueo))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="2" md="1">
                            <v-btn @click="openDialog()" x-small fab dark color="indigo"><v-icon>add</v-icon></v-btn>
                        </v-col>
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="red"><strong>Gasto :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(gasto))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="2" md="1">
                            <v-btn @click="openDialog1()" x-small fab dark color="indigo"><v-icon>add</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="mt-2">
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Efectivo :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(efectivo))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>T.Debito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(debito))}}</div></strong></v-alert> 
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>T.Credito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(credito))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Transferencia :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(transferencia))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Cheques :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(cheque))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Credito Cta.Cte :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(ctacte))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Notas de Credito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(nota))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense dark border="left" color="green"><strong>Total :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(total))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event"></v-data-table>
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
                <v-card-actions style="margin-top:-25px">
                    <v-spacer></v-spacer>
                    <div v-if="linkReport!=''">
                        <a target="_blank" :href="linkReport" style="text-decoration: none">
                            <v-btn small color="teal" dark rounded><v-icon small>report</v-icon><span class='text-btn'>Reporte Diario</span></v-btn>
                        </a>
                    </div>
                    <div v-else>
                        <v-btn small color="teal" dark rounded><v-icon small>report</v-icon><span class='text-btn'>Reporte Diario</span></v-btn>
                    </div>
            </v-card-actions>
        </v-card>
        <!-- DIALOGO ARQUEOS -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>ARQUEOS
                <v-spacer></v-spacer>
                <v-btn @click="dialog=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="9" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="monto" label="Monto" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="3" md="8">
                        <v-btn @click="addArqueo()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-data-table dark dense :headers="head_arqueo" :items="items_arqueo" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1" @page-count="pageCount = $event">
                            <template v-slot:item.accion="{ item }">
                                <v-icon v-if="item.estado=='V'" small @click="deleteArqueo(item)">delete</v-icon>
                                <v-icon v-else small color="grey">do_not_disturb</v-icon>
                                <a v-if="item.estado=='V'" class="ml-2" target="_blank" :href="item.html" style="text-decoration: none"><v-icon small>print</v-icon></a>
                                <v-icon v-else class="ml-2" small color="grey">do_not_disturb</v-icon>
                            </template>
                        </v-data-table>
                    </v-col>   
                </v-row>
            </v-card-text>
            <v-card-actions>              
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DIALOGO GASTOS -->
        <v-dialog v-model="dialog1" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>GASTOS
                <v-spacer></v-spacer>
                <v-btn @click="dialog1=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="9" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="monto1" label="Monto" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="9" md="6">
                        <v-text-field class='text-caption' v-model="motivo" label="Motivo" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="3" md="2">
                        <v-btn @click="addGastos()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-data-table dark dense :headers="head_gasto" :items="items_gasto" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1" @page-count="pageCount = $event">
                            <template v-slot:item.accion="{ item }">
                                <v-icon v-if="item.estado=='V'" small @click="deleteGastos(item)">delete</v-icon>
                                <v-icon v-else small color="grey">do_not_disturb</v-icon>
                                <a v-if="item.estado=='V'" class="ml-2" target="_blank" :href="item.html" style="text-decoration: none"><v-icon small>print</v-icon></a>
                                <v-icon v-else class="ml-2" small color="grey">do_not_disturb</v-icon>
                            </template>
                        </v-data-table>
                    </v-col>   
                </v-row>
            </v-card-text>
            <v-card-actions>              
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Flujo', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            nSucursal:0,
            txtSucursal:'',
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Tipo DTE', align: 'start', sortable: true, value: 'tipo' },
                { text: 'Numero', align: 'start', sortable: false, value: 'numero' },
                { text: 'Forma Pago', align: 'center', sortable: false, value: 'pago' },
                { text: 'Referencia', align: 'center', sortable: false, value: 'referencia' },
                { text: 'Monto', align: 'center', sortable: false, value: 'monto' }
            ],
            items: [],
            efectivo:0,
            debito:0,
            credito:0,
            transferencia:0,
            cheque:0,
            ctacte:0,
            nota:0,
            total:0,
            dialog:false,
            dialog1:false,
            
            arqueo:0,
            head_arqueo: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                { text: 'Monto', align: 'start', sortable: true, value: 'monto' },
                { text: 'Hora', align: 'start', sortable: false, value: 'hora' },
                { text: 'Accion', align: 'start', sortable: false, value: 'accion' }
            ],
            items_arqueo: [],
            monto:'',


            gasto:0,
            head_gasto: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                { text: 'Monto', align: 'start', sortable: true, value: 'monto' },
                { text: 'Hora', align: 'start', sortable: false, value: 'hora' },
                { text: 'Motivo', align: 'start', sortable: false, value: 'motivo' },
                { text: 'Accion', align: 'start', sortable: false, value: 'accion' }
            ],
            items_gasto: [],
            monto1:'',
            motivo:'',

            
            //ids:0,
            //varArqueo:0,
            varEfectivo:0,
            varTotal:0,
            html:'',
            reportHTML:'',
            linkReport:''
        }
    },
    mounted(){
        this.buscarSucursal()
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        openDialog(){
            this.monto='';
            this.dialog=true;
        },
        openDialog1(){
            this.monto1='';
            this.motivo='';
            this.dialog1=true;
        },
        clsInput(){
            this.items=[];
            this.efectivo=0;
            this.debito=0;
            this.credito=0;
            this.transferencia=0;
            this.cheque=0;
            this.ctacte=0;
            this.nota=0;
            this.total=0;

            this.arqueo=0;
            this.items_arqueo=[];
            this.monto='';
            
            this.gasto=0;
            this.items_gasto=[];
            this.monto1='';
            this.motivo='';

            //this.ids=0;
            //this.varArqueo=0;
            this.varEfectivo=0;
            this.varTotal=0;
            this.html='';
            this.reportHTML='';
            this.linkReport=''
        },
        OpenCalendar(){
            this.menu1 = true;
            this.clsInput();
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        async buscarSucursal(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if (datos[0].Sucursal!=0){
                    this.nSucursal=datos[0].Sucursal;
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        datos1.forEach(element => {
                            if(element.ID==this.nSucursal){
                                this.txtSucursal=element.Nombre                            
                            }
                        });
                    } else {  
                        this.txtSucursal='Sin Sucursal';
                        this.nSucursal=0;
                    }
                } else {
                    this.txtSucursal='Sin Sucursal';
                    this.nSucursal=0;
                }
            } else { 
                this.txtSucursal='Sin Sucursal';
                this.nSucursal=0;
            }
        },

        // async verifyPago(){
        //     try {
        //         const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
        //         const datos=response.data;
        //         if (datos[0].existe!='error'){
        //             if(datos[0].existe=='si'){
        //                 if(datos[0].Estado!='V'){
        //                     this.Msg='Flujo Diario Cerrado'
        //                     this.msgbox=true;
        //                     this.color='error';
        //                     this.showSnack()
        //                 } else { await this.buscarCaja() }  // pagos ingresados aun no cerrado
        //             } else { // no existen pagos de hoy
        //                 // verificar que no exista fecha anterior abierta
        //                 const res = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/verify/pago/date/'+this.contrato+'/'+this.date1);
        //                 const dat=res.data;
        //                 if(dat[0].existe=='no'){
        //                     await this.buscarCaja() 
        //                 } else { 
        //                     this.Msg='Fecha Anterior NO Cerrada'
        //                     this.msgbox=true;
        //                     this.color='error';
        //                     this.showSnack()
        //                 }
        //             } 
        //         } else { 
        //             this.Msg='Inconsistencia Base'
        //             this.msgbox=true;
        //             this.color='error';
        //             this.showSnack()
        //         }
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },


        async ReporteCajaDiaria(){
            
            this.loading=true;
            this.clsInput();
            
            let ventas=0;
            let credito=0;
            let numerito=0;
            let credito_efectivo=0;
            let credito_transferencia=0;
            let credito_cheque=0;

            this.reportHTML='<h3>Detalle de Ventas del Día '+ this.date1 +' Sucursal '+this.txtSucursal+ '</h3>'
            try {
                // detalle ventas
                let response;
                let datos;
                response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/report/caja/ventas/hoy/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
                datos=response.data;
                if (datos[0].existe=='si'){
                    //titulos ventas
                    // this.reportHTML=this.reportHTML+"<table width=100%><tr>"
                    // this.reportHTML=this.reportHTML+"<td style='width:10%'>Documento</td>"
                    // this.reportHTML=this.reportHTML+"<td style='width:10%'>Numero</td>"
                    // this.reportHTML=this.reportHTML+"<td style='width:10%'>Total DTE</td>"
                    // this.reportHTML=this.reportHTML+"<td style='width:10%'>Tipo de Pago</td>"
                    // this.reportHTML=this.reportHTML+"<td style='width:10%'>Forma de Pago</td>"
                    // this.reportHTML=this.reportHTML+"<td style='width:20%'>Referencia</td>"
                    // this.reportHTML=this.reportHTML+"<td style='width:10%'>Monto Pago</td>"
                    // this.reportHTML=this.reportHTML+"<td style='width:20%'></td>"
                    // this.reportHTML=this.reportHTML+"</tr>"
                    this.reportHTML=this.reportHTML+"<table width=100%>"
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        if(parseFloat(element.Monto)!=0){
                            this.items.push({
                                'tipo':element.Documento,
                                'numero':element.Numero,
                                'pago':element.FormaPago,
                                'referencia':element.Referencia,
                                'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))
                            });
                        };
                        //detalle html
                        this.reportHTML=this.reportHTML+'<tr>'
                        this.reportHTML=this.reportHTML+'<td style="width:10%">'+element.Documento+'</td>'
                        this.reportHTML=this.reportHTML+'<td style="width:10%">'+element.Numero+'</td>'
                        if(element.Documento!='Nota Credito'){
                            this.reportHTML=this.reportHTML+'<td style="width:10%">'+new Intl.NumberFormat("de-DE").format(parseFloat(element.Total))+'</td>'
                        } else {
                            this.reportHTML=this.reportHTML+'<td style="width:10%">-'+new Intl.NumberFormat("de-DE").format(parseFloat(element.Total))+'</td>'
                        };
                        //this.reportHTML=this.reportHTML+'<td>'+element.TipoPago+'</td>'
                        if(element.TipoPago=='Credito (30)' || element.TipoPago=='Credito (60)' || element.TipoPago=='Credito (90)'){
                            this.reportHTML=this.reportHTML+'<td style="width:10%">Credito Cta.Cte</td>'
                            credito=credito+parseFloat(element.Monto);
                        } else {
                            if(element.FormaPago.trim()==''){
                                if(element.Documento!='Nota Credito'){
                                    this.reportHTML=this.reportHTML+'<td style="width:10%">'+element.FormaPago+'</td>'
                                } else {
                                    this.reportHTML=this.reportHTML+'<td style="width:10%">'+element.TipoPago+'</td>';
                                    if(element.TipoPago=='Efectivo'){
                                        credito_efectivo=credito_efectivo+parseFloat(element.Total);
                                    };
                                    if(element.TipoPago=='Transferencia'){
                                        credito_transferencia=credito_transferencia+parseFloat(element.Total);
                                    };
                                }
                            } else {
                                this.reportHTML=this.reportHTML+'<td style="width:10%">'+element.FormaPago+'</td>'
                            }
                        };
                        this.reportHTML=this.reportHTML+'<td style="width:10%">'+element.Referencia+'</td>'
                        if(element.FormaPago!='Nota de Credito'){
                            if(parseFloat(element.Monto)!=0){
                                this.reportHTML=this.reportHTML+'<td style="width:10%">'+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+'</td>'
                            } else {
                                this.reportHTML=this.reportHTML+'<td style="width:20%"></td>'
                            }
                        } else {
                            if(parseFloat(element.Monto)!=0){
                                this.reportHTML=this.reportHTML+'<td style="width:10%">-'+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+'</td>'
                            } else {
                                this.reportHTML=this.reportHTML+'<td style="width:10%"></td>'
                            }
                        };
                        this.reportHTML=this.reportHTML+'<td style="width:30%"></td>'
                        this.reportHTML=this.reportHTML+'</tr>'
                        //suma ventas
                        if(numerito!=parseInt(element.Numero)){
                            if(element.Documento!='Nota Credito'){
                                ventas=ventas+parseFloat(element.Total);
                            } else {
                                ventas=ventas-parseFloat(element.Total);
                            }
                        };
                        numerito=parseInt(element.Numero)
                    };
                    //total ventas
                    this.reportHTML=this.reportHTML+"<tr><td colspan='7'>&nbsp;</td></tr>"
                    this.reportHTML=this.reportHTML+"<tr>"
                    this.reportHTML=this.reportHTML+"<td colspan='2'><h3>Total Ventas del Día</h3</td>"
                    this.reportHTML=this.reportHTML+"<td><h3>"+new Intl.NumberFormat("de-DE").format(ventas)+"</h3></td>"
                    this.reportHTML=this.reportHTML+"</tr>"
                    this.reportHTML=this.reportHTML+"</table>"
                };
                // detalle cobranza
                response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/report/caja/cobranza/hoy/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
                datos=response.data;
                if (datos[0].existe=='si'){
                    this.reportHTML=this.reportHTML+"<h3>Detalle Cobranza del Día</h3>"
                    this.reportHTML=this.reportHTML+"<table width=100%>"
                    datos.forEach(element => {
                        this.reportHTML=this.reportHTML+"<tr>"
                        this.reportHTML=this.reportHTML+"<td><b>"+element.Rut+"</b></td>"
                        this.reportHTML=this.reportHTML+"<td colspan='7'><b>"+element.Nombre+"</b></td>"
                        this.reportHTML=this.reportHTML+"</tr>"
                        this.reportHTML=this.reportHTML+"<tr>"
                        this.reportHTML=this.reportHTML+"<td style='width:10%'>Documento</td>"
                        this.reportHTML=this.reportHTML+"<td style='width:10%'>Fecha</td>"
                        this.reportHTML=this.reportHTML+"<td style='width:10%'>Numero</td>"
                        this.reportHTML=this.reportHTML+"<td style='width:10%'>Total DTE</td>"
                        this.reportHTML=this.reportHTML+"<td style='width:10%'>Tipo de Pago</td>"
                        this.reportHTML=this.reportHTML+"<td style='width:10%'>Forma de Pago</td>"
                        this.reportHTML=this.reportHTML+"<td style='width:10%'>Referencia</td>"
                        this.reportHTML=this.reportHTML+"<td style='width:10%'>Monto Pago</td>"
                        this.reportHTML=this.reportHTML+"<td style='width:20%'></td>"
                        this.reportHTML=this.reportHTML+"</tr>"
                        this.reportHTML=this.reportHTML+"<tr>"
                        this.reportHTML=this.reportHTML+"<td>"+element.Documento+"</td>"
                        this.reportHTML=this.reportHTML+"<td>"+element.Fecha+"</td>"
                        this.reportHTML=this.reportHTML+"<td>"+element.Numero+"</td>"
                        this.reportHTML=this.reportHTML+"<td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Total))+"</td>"
                        this.reportHTML=this.reportHTML+"<td>"+element.TipoPago+"</td>"
                        this.reportHTML=this.reportHTML+"<td>"+element.FormaPago+"</td>"
                        this.reportHTML=this.reportHTML+"<td>"+element.Referencia+"</td>"
                        this.reportHTML=this.reportHTML+"<td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td>"
                        this.reportHTML=this.reportHTML+"<td></td>"
                        this.reportHTML=this.reportHTML+"</tr>"
                    });
                    this.reportHTML=this.reportHTML+"</table>"
                };
                // resumen pagos
                response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/report/caja/pagos/hoy/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
                datos=response.data;
                if (datos[0].existe=='si'){
                    this.reportHTML=this.reportHTML+"<h3>Resumen de Pagos del Día</h3>"
                    this.reportHTML=this.reportHTML+"<table width=100%>"
                    datos.forEach(element => {
                        switch (element.FormaPago) {
                            case 'Efectivo':
                                //this.efectivo=(this.efectivo+parseInt(element.Monto))-parseFloat(credito_efectivo);
                                //this.total=(this.total+parseInt(element.Monto))-parseFloat(credito_efectivo);
                                this.efectivo=(this.efectivo+parseInt(element.Monto));
                                this.total=(this.total+parseInt(element.Monto));
                                this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Efectivo</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.efectivo))+"</td></tr>"
                                break;
                            case 'T.Debito':
                                this.debito=this.debito+parseInt(element.Monto);
                                this.total=this.total+parseInt(element.Monto);
                                this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>T.Debito</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td></tr>"
                                break;
                            case 'T.Credito':
                                this.credito=this.credito+parseInt(element.Monto);
                                this.total=this.total+parseInt(element.Monto);
                                this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>T.Credito</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td></tr>"
                                break;
                            case 'Transferencia':
                                this.transferencia=(this.transferencia+parseInt(element.Monto))-parseFloat(credito_transferencia);
                                this.total=(this.total+parseInt(element.Monto))-parseFloat(credito_transferencia);
                                this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Transferencia</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.transferencia))+"</td></tr>"
                                break;
                            case 'Cheque':
                                this.cheque=this.cheque+parseInt(element.Monto);
                                this.total=this.total+parseInt(element.Monto);
                                this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Cheque</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td></tr>"
                                break;
                            // case 'Credito Cta.Cte.':
                            //     this.ctacte=this.ctacte+parseInt(element.Monto);
                            //     this.total=this.total+parseInt(element.Monto);
                            //     this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Crédito Cta.Cte.</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td></tr>"
                            //     break;
                            case 'Nota de Credito':
                                this.nota=this.nota+parseInt(element.Monto);
                                this.total=this.total-parseInt(element.Monto);
                                this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Nota de Crédito</td><td>-"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td></tr>"
                                break;
                        };                                               
                    });
                    // credito Cta.Cte.
                    if(credito!=0){
                        this.ctacte=credito;
                        this.total=this.total+(credito);
                        this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Crédito Cta.Cte.</td><td>"+new Intl.NumberFormat("de-DE").format(credito)+"</td></tr>"
                    }
                    this.reportHTML=this.reportHTML+"<tr><td style='width:20%'><h4>Total Pagos del Dia</h4></td><td><h4>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.total))+"</h4></td></tr>"
                    this.reportHTML=this.reportHTML+"</table>"
                };
                // resumen arqueos
                response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/report/caja/arqueo/hoy/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
                datos=response.data;
                if (datos[0].existe=='si' && parseFloat(datos[0].Monto)!=0){
                    await this.arrayArqueoCaja();
                    this.reportHTML=this.reportHTML+"<h3>Arqueos del Día</h3>"
                    this.reportHTML=this.reportHTML+"<table width=100%>"
                    this.reportHTML=this.reportHTML+'<tr>'
                    this.reportHTML=this.reportHTML+'<td style="width:20%">Total</td>'
                    this.reportHTML=this.reportHTML+'<td>'+new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].Monto))+'</td>'
                    this.reportHTML=this.reportHTML+'</tr>'
                    this.reportHTML=this.reportHTML+"</table>"
                };
                // detalle gastos
                response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/report/caja/gastos/hoy/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
                datos=response.data;
                if (datos[0].existe=='si'){
                    await this.arrayGastoCaja();
                    this.reportHTML=this.reportHTML+"<h3>Gastos del Día</h3>"
                    this.reportHTML=this.reportHTML+"<table width=100%>"
                    datos.forEach(element => {
                        this.reportHTML=this.reportHTML+'<tr>'
                        this.reportHTML=this.reportHTML+'<td style="width:20%">'+element.Motivo+'</td>'
                        this.reportHTML=this.reportHTML+'<td>'+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+'</td>'
                        this.reportHTML=this.reportHTML+'</tr>'
                    });
                    this.reportHTML=this.reportHTML+"</table>"
                };
                // Efectivo del dia
                var total_dia=parseFloat(this.efectivo)-parseFloat(credito_efectivo);
                this.reportHTML=this.reportHTML+"<h3>Efectivo del Día</h3>"
                this.reportHTML=this.reportHTML+"<table width=100%>"
                this.reportHTML=this.reportHTML+'<tr>'
                this.reportHTML=this.reportHTML+'<td style="width:20%">Pagos</td>'
                this.reportHTML=this.reportHTML+'<td>'+new Intl.NumberFormat("de-DE").format(parseFloat(this.efectivo))+'</td>'
                this.reportHTML=this.reportHTML+'</tr>'
                this.reportHTML=this.reportHTML+'<tr>'
                this.reportHTML=this.reportHTML+'<td style="width:20%">Devoluciones</td>'
                if(credito_efectivo!=0){
                    this.reportHTML=this.reportHTML+'<td>-'+new Intl.NumberFormat("de-DE").format(parseFloat(credito_efectivo))+'</td>'
                } else {
                    this.reportHTML=this.reportHTML+'<td>'+new Intl.NumberFormat("de-DE").format(parseFloat(credito_efectivo))+'</td>'
                };
                this.reportHTML=this.reportHTML+'</tr>'
                this.reportHTML=this.reportHTML+'<tr>'
                this.reportHTML=this.reportHTML+'<td style="width:20%"><h4>Total</h4></td>'
                this.reportHTML=this.reportHTML+'<td><h4>'+new Intl.NumberFormat("de-DE").format(parseFloat(total_dia))+'</h4></td>'
                this.reportHTML=this.reportHTML+'</tr>'
                this.reportHTML=this.reportHTML+"</table>"
                await this.saveReport();
            } catch (error) {
                console.log(error)
            };
            this.loading=false;
        },

        // async buscarCaja(){
        //     this.loading=true;
        //     this.clsInput();
        //     const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
        //     const datos=response.data;
        //     if (datos[0].existe=='si'){
        //         console.log(datos);
        //         if(datos[0].Estado!='V'){
        //             this.Msg='Flujo Diario Cerrado'
        //             this.msgbox=true;
        //             this.color='error';
        //             this.showSnack();
        //         } else {
        //             this.total=0;
        //             this.reportHTML='<h3>Detalle de Ventas del Día</h3>'
        //             this.reportHTML=this.reportHTML+"<table width=100%><tr>"
        //             this.reportHTML=this.reportHTML+"<td style='width:10%'>Documento</td>"
        //             this.reportHTML=this.reportHTML+"<td style='width:10%'>Numero</td>"
        //             this.reportHTML=this.reportHTML+"<td style='width:10%'>Monto</td>"
        //             this.reportHTML=this.reportHTML+"<td style='width:10%'>Forma de Pago</td>"
        //             this.reportHTML=this.reportHTML+"<td style='width:20%'>Referencia</td>"
        //             this.reportHTML=this.reportHTML+"<td style='width:40%'></td>"
        //             this.reportHTML=this.reportHTML+"</tr>"
        //             datos.forEach(element => {
        //                 this.items.push({
        //                     'tipo':element.Documento,
        //                     'numero':element.NumeroDte,
        //                     'pago':element.FormaPago,
        //                     'referencia':element.Referencia,
        //                     'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))
        //                 })
        //                 this.reportHTML=this.reportHTML+'<tr>'
        //                 this.reportHTML=this.reportHTML+'<td>'+element.Documento+'</td>'
        //                 this.reportHTML=this.reportHTML+'<td>'+element.NumeroDte+'</td>'
        //                 this.reportHTML=this.reportHTML+'<td>'+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+'</td>'
        //                 this.reportHTML=this.reportHTML+'<td>'+element.FormaPago+'</td>'
        //                 this.reportHTML=this.reportHTML+'<td>'+element.Referencia+'</td>'
        //                 this.reportHTML=this.reportHTML+'<td></td>'
        //                 this.reportHTML=this.reportHTML+'</tr>'
        //                 // Agrupar totales
        //                 switch (element.FormaPago) {
        //                     case 'Efectivo':
        //                         this.efectivo=this.efectivo+parseInt(element.Monto);
        //                         this.total=this.total+parseInt(element.Monto);
        //                         break;
        //                     case 'T.Debito':
        //                         this.debito=this.debito+parseInt(element.Monto);
        //                         this.total=this.total+parseInt(element.Monto);
        //                         break;
        //                     case 'T.Credito':
        //                         this.credito=this.credito+parseInt(element.Monto);
        //                         this.total=this.total+parseInt(element.Monto);
        //                         break;
        //                     case 'Transferencia':
        //                         this.transferencia=this.transferencia+parseInt(element.Monto);
        //                         this.total=this.total+parseInt(element.Monto);
        //                         break;
        //                     case 'Cheque':
        //                         this.cheque=this.cheque+parseInt(element.Monto);
        //                         this.total=this.total+parseInt(element.Monto);
        //                         break;
        //                     case 'Credito Cta.Cte.':
        //                         this.ctacte=this.ctacte+parseInt(element.Monto);
        //                         this.total=this.total+parseInt(element.Monto);
        //                         break;
        //                     case 'Nota de Credito':
        //                         this.nota=this.nota+parseInt(element.Monto);
        //                         this.total=this.total-parseInt(element.Monto);
        //                         break;
        //                 }
        //                 //asignar totales para arqueo
        //                 this.varEfectivo=this.efectivo;
        //                 this.varTotal=this.total;
        //             });
        //             this.reportHTML=this.reportHTML+"<tr><td colspan='6'>&nbsp;</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr>"
        //             this.reportHTML=this.reportHTML+"<td colspan='2'><h3>Total Ventas del Día</h3</td>"
        //             this.reportHTML=this.reportHTML+"<td><h3>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.total))+"</h3></td>"
        //             this.reportHTML=this.reportHTML+"</tr>"
        //             this.reportHTML=this.reportHTML+"</table>"
        //             this.reportHTML=this.reportHTML+"<h3>Resumen de Pagos del Día</h3>"
        //             this.reportHTML=this.reportHTML+"<table width=100%>"
        //             this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Efectivo</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.efectivo))+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>T.Debito</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.debito))+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>T.Credito</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.credito))+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Transferencia</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.transferencia))+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Cheque</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.cheque))+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Crédito Cta.Cte.</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.ctacte))+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Nota de Crédito</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.nota))+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"</table>"
        //             await this.arrayArqueo();
        //             await this.arrayGasto();
        //             this.reportHTML=this.reportHTML+"<br/><table width=100%>"
        //             this.reportHTML=this.reportHTML+"<tr><td style='width:20%'><h3>Efectivo Segun Sistema</h3</td><td><h3>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.efectivo))+"</h3></td></tr>"
        //             this.reportHTML=this.reportHTML+"</table>"
        //             await this.saveReport();
        //         }
        //     }
        //     this.loading=false;
        // },
        async saveReport(){
            try {
                //borramos el reporte actual
                await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/caja/diaria/delete/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
                //ingresamos nuevo reporte
                const params={
                    'fecha': this.date1,
                    'contrato':this.contrato,
                    'sucursal': this.nSucursal,
                    'html':this.reportHTML
                }
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/rpt/caja/diaria',params);
                const datos=response.data;
                if (datos=='si'){ 
                    console.log('ok');
                    this.linkReport='https://www.transervi.cl/Api_TranServi/public/api/inv/rpt/caja/diaria/vista/'+this.contrato+'/'+this.date1+'/'+this.nSucursal;
                }
            } catch (error) {
                console.log(error)
            }
        },
        showTime(){
            let myDate = new Date();
            let hours = myDate.getHours();
            let minutes = myDate.getMinutes();
            let seconds = myDate.getSeconds();
            if (hours < 10) hours = 0 + hours;
            if (minutes < 10) minutes = "0" + minutes;
            if (seconds < 10) seconds = "0" + seconds;
            let hora=hours+ ":" +minutes+ ":" +seconds;
            return hora;
        },
        async arrayArqueoCaja(){
            const response0 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/arqueo/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
            const datos0=response0.data;
            this.items_arqueo=[];
            this.arqueo=0;
            if (datos0[0].existe=='si'){
                datos0.forEach(element => {
                    this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/arqueo/'+this.contrato+'/'+element.ID+'/'+this.usu;
                    this.items_arqueo.push({
                        'id':element.ID,
                        'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                        'hora':element.Hora,
                        'valor':element.Monto,
                        'estado':element.Estado,
                        'html':this.html               
                    })
                    this.arqueo=this.arqueo+parseInt(element.Monto);
                });
            }
            //ajuste efectivo y total
            this.efectivo=this.efectivo-this.arqueo;
            this.total=this.total-this.arqueo;
        },
        // async arrayArqueo(){
        //     const response0 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/arqueo/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
        //     const datos0=response0.data;
        //     this.items_arqueo=[];
        //     this.arqueo=0;
        //     if (datos0[0].existe=='si'){
        //         this.reportHTML=this.reportHTML+"<h3>Arqueos del Día</h3>"
        //         this.reportHTML=this.reportHTML+"<table width=100%>"
        //         this.reportHTML=this.reportHTML+"<td style='width:20%'>Hora</td>"
        //         this.reportHTML=this.reportHTML+"<td style='width:20%'>Monto</td>"
        //         this.reportHTML=this.reportHTML+"<td style='width:60%'></td>"
        //         datos0.forEach(element => {
        //             this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/arqueo/'+this.contrato+'/'+element.ID+'/'+this.usu;
        //             this.items_arqueo.push({
        //                 'id':element.ID,
        //                 'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
        //                 'hora':element.Hora,
        //                 'valor':element.Monto,
        //                 'estado':element.Estado,
        //                 'html':this.html               
        //             })
        //             this.reportHTML=this.reportHTML+"<tr><td>"+element.Hora+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td></tr>"
        //             this.arqueo=this.arqueo+parseInt(element.Monto);
        //         });
        //         this.reportHTML=this.reportHTML+"<tr><td><h3>Total Arqueos</h3></td></tr>"
        //         this.reportHTML=this.reportHTML+"<tr><td><h3>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.arqueo))+"</h3></td></tr>"
        //         this.reportHTML=this.reportHTML+"</table>"
        //     }
        //     await this.ajusteTotal();
        // },
        async addArqueo(){
            this.loading=true;
            if(this.monto.trim()!=''){
                const ahora=this.showTime();
                const params={
                    'contrato':this.contrato,
                    'monto': this.monto,
                    'hora': ahora,
                    'fecha':this.date1,
                    'sucursal':this.nSucursal,
                    'estado':'V'
                }
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/arqueo/nuevo',params);
                const datos=response.data;
                if (datos=='si'){ 
                    await this.arrayArqueoCaja();
                }
                this.monto='';            
            }
            this.loading=false;
        },
        async deleteArqueo(array){
            const response = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/arqueo/delete/'+this.contrato+'/'+array.id);
            const datos=response.data;
            if (datos!='error'){
                await this.arrayArqueoCaja();
            }   
        },
        async arrayGastoCaja(){
            const response0 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/gasto/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
            const datos0=response0.data;
            this.items_gasto=[];
            this.gasto=0;
            if (datos0[0].existe=='si'){
                datos0.forEach(element => {
                    this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/gasto/'+this.contrato+'/'+element.ID+'/'+this.usu;
                    this.items_gasto.push({
                        'id':element.ID,
                        'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                        'hora':element.Hora,
                        'valor':element.Monto,
                        'motivo':element.Motivo,
                        'estado':element.Estado,
                        'html':this.html               
                    })
                    this.gasto=this.gasto+parseInt(element.Monto);
                });
            }
            // ajuste efecivo y arqueo
            this.efectivo=this.efectivo-this.gasto;
            this.total=this.total-this.gasto;
        },
        // async arrayGasto(){
        //     const response0 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/gasto/buscar/'+this.contrato+'/'+this.date1+'/'+this.nSucursal);
        //     const datos0=response0.data;
        //     this.items_gasto=[];
        //     this.gasto=0;
        //     if (datos0[0].existe=='si'){
        //         this.reportHTML=this.reportHTML+"<h3>Gastos del Día</h3>"
        //         this.reportHTML=this.reportHTML+"<table width=100%>"
        //         this.reportHTML=this.reportHTML+"<td style='width:20%'>Hora</td>"
        //         this.reportHTML=this.reportHTML+"<td style='width:20%'>Monto</td>"
        //         this.reportHTML=this.reportHTML+"<td style='width:30%'>Motivo</td>"
        //         this.reportHTML=this.reportHTML+"<td style='width:30%'></td>"
        //         datos0.forEach(element => {
        //             this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/gasto/'+this.contrato+'/'+element.ID+'/'+this.usu;
        //             this.items_gasto.push({
        //                 'id':element.ID,
        //                 'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
        //                 'hora':element.Hora,
        //                 'valor':element.Monto,
        //                 'motivo':element.Motivo,
        //                 'estado':element.Estado,
        //                 'html':this.html               
        //             })
        //             this.reportHTML=this.reportHTML+"<tr><td>"+element.Hora+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td></tr>"
        //             this.reportHTML=this.reportHTML+"<tr><td>"+element.Motivo+"</td></tr>"
        //             this.gasto=this.gasto+parseInt(element.Monto);
        //         });
        //         this.reportHTML=this.reportHTML+"<tr><td><h3>Total Gastos</h3></td></tr>"
        //         this.reportHTML=this.reportHTML+"<tr><td><h3>"+new Intl.NumberFormat("de-DE").format(parseFloat(this.gasto))+"</h3></td></tr>"
        //         this.reportHTML=this.reportHTML+"</table>"
        //     }
        //     await this.ajusteTotal();
        // },
        async addGastos(){
            this.loading=true;
            if(this.monto1.trim()!=''){
                const ahora=this.showTime();
                const params={
                    'contrato':this.contrato,
                    'monto': this.monto1,
                    'fecha':this.date1,
                    'hora': ahora,
                    'sucursal':this.nSucursal,
                    'motivo':this.motivo,
                    'estado':'V'
                }
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/gasto/nuevo',params);
                const datos=response.data;
                if (datos=='si'){ 
                    await this.arrayGastoCaja();
                }
                this.monto='';            
            }
            this.loading=false;
        },
        async deleteGastos(array){
            const response = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/cierre/gasto/delete/'+this.contrato+'/'+array.id);
            const datos=response.data;
            if (datos!='error'){
                await this.arrayGastoCaja();
            }   
        },
        // async ajusteTotal(){
        //     this.efectivo=this.efectivo-(this.arqueo+this.gasto);
        //     this.total=this.total-(this.arqueo+this.gasto);
        // },
        async ReporteDiario(){
            
            console.log('generar reporte')
        },
    }
}
</script>
<template>
    <div>
         <v-card class="mt-5">
            <v-card-title class="blue-grey white--text">NC Proveedores</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="8" md="2" class="mt-n2">
                            <v-radio-group v-model="radios" row disabled>
                                <v-radio label="Gasto" color="success" value="Bruto"></v-radio>
                                <v-radio label="Neto" color="success" value="Neto"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-text-field class='text-caption' @keypress="onlyNumber" v-model="orden" label="N° Compra" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                        </v-col>
                        <v-col cols="9" md="2">
                            <v-text-field class='text-caption' v-model="rut" label="Rut Proveedor" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                        </v-col>
                        <v-col cols="3" md="1">
                            <v-btn @click="verCompra(orden,rut)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field class='text-caption' v-model="razon" label="Razon Social" disabled filled outlined dense></v-text-field>                        
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-select @change="SelectNota(nota)" class='text-caption' v-model="nota" :items='notas' label="Devolucion" item-text="name" item-value="id" dense outlined :disabled="disabledDev" :filled="filledDev"></v-select>
                        </v-col>
                        <!-- <v-col cols="3" md="1">
                            <v-btn @click="SelectNota(nota)" class="mt-1" small color="teal" dark rounded>ir</v-btn>
                        </v-col> -->
                    </v-row>
                    <v-row class="mt-n8">
                        <v-col cols="8" md="2">
                            <v-text-field class='text-caption' v-model="numero" label="Nota Credito" outlined dense :disabled="disabledDatos" :filled="filledDatos"></v-text-field>                        
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-menu v-model="menu1" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="text-caption" v-model="emision" label="Fec. Emision" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </template>
                                <v-date-picker v-model="emision" @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="text-caption" v-model="contable" label="Fec. Contable" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </template>
                                <v-date-picker v-model="contable" @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select @change="activaCheque(pago)" class='text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field class='text-caption' v-model="ref_pago" label="Referencia" :disabled="disabledDatos" :filled="filledDatos" outlined dense required></v-text-field>
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-menu v-model="menu3" :close-on-content-click="false" :nudge-left="20" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="text-caption" v-model="vence" label="Fec. Vcto." readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </template>
                                <v-date-picker v-model="vence" @input="menu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <!-- <v-col cols="12" md="2">
                            <v-select class='text-caption' v-model="dte" :items="itemDTE" label="Selecciona DTE" item-text="doc" item-value="id" outlined dense></v-select>
                        </v-col> -->
                    </v-row>
                    <v-row class="mt-n8">
                        <v-col cols="12" md="9">
                            <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                <template v-slot:item.cantidad="{ item }">
                                    <span small>{{ item.cantidad }}</span>
                                    <v-icon v-if="btnGrabar==true" class="ml-1 red--text" @click="menos(item)">arrow_drop_down</v-icon>
                                    <v-icon v-if="btnAnula==true" class="ml-1 grey--text" >arrow_drop_down</v-icon>
                                </template>
                                <template v-slot:item.accion="{ item }">
                                    <v-icon v-if="btnGrabar==true" small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                    <v-icon v-if="btnAnula==true" small class="grey--text">delete</v-icon>
                                </template>
                            </v-data-table>
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-alert class='text-caption' dense text border="left" color="grey">Afecto ($):{{neto.toLocaleString()}}</v-alert> 
                            <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva.toLocaleString()}}</v-alert>
                            <v-alert class='text-caption' dense text border="left" color="red">Total ($): {{total.toLocaleString()}}</v-alert>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-btn @click="add_items()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo Item</span></v-btn>                            
                        </v-col>
                        <v-col cols="12" md="8" class="text-end">
                            <v-btn @click="limpiar()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                            <v-btn v-if="btnGrabar===true" @click="saveNC()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                            <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                            <v-btn v-if="btnAnula===true" @click="anulaDev()" class="ml-2" small color="red" dark rounded><v-icon small>delete</v-icon><span class='text-btn'>Anular</span></v-btn>
                            <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>delete</v-icon><span class='text-btn'>Anular</span></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
         </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
        <!-- DIALOGO CHEQUE -->
        <v-dialog v-model="dialogCheque" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>CHEQUES
                <v-spacer></v-spacer>
                <v-btn @click="dialogCheque=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-menu v-model="menucheque" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="fechaCheque" label="Fecha Vcto." prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaCheque" @input="menucheque = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                   <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="rutCheque" label="Rut" placeholder="99999999-K" outlined dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="7">
                        <!-- <v-text-field class='text-caption' v-model="bancoCheque" label="Banco" outlined dense required></v-text-field> -->
                        <v-select class='text-caption' v-model="bancoCheque" :items="bancos" label="Banco" item-text="name" item-value="id" dense outlined></v-select>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="cuentaCheque" label="N° Cuenta" outlined dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="numeroCheque" label="N° Cheque" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="montoCheque" label="Monto" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn @click="cierraCheque()" class="mt-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-n8 text-center">
                        <span class="text-caption red--text">{{msjCheque}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>              
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DIALOGO NEW ITEM -->
        <v-dialog v-model="dialogItem" transition="dialog-bottom-transition" max-width="500" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Nuevo Item
                <v-spacer></v-spacer>
                <v-btn @click="dialogItem=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="6" md="3">
                        <v-text-field class='text-caption' v-model="codigo_item" label="Codigo" outlined dense filled readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="9">
                        <v-text-field class='text-caption' v-model="producto_item" label="Descripcion" outlined dense></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="4" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="precio_item" label="Precio" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad_item" label="Cantidad" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="descuento_item" label="Descuento (%)" outlined dense filled readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6" md="4">
                        <v-select class='text-caption' v-model="exento_item" :items="item_exento" label="Exento" dense outlined></v-select>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-select @change="datos_bodega(bodega_item)" class='text-caption' v-model="bodega_item" :items="item_bodega" item-value="numBodega" item-text="nameBodega" label="Bodega" dense outlined></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn @click="save_items()" small color="teal" dark block rounded><v-icon small>save</v-icon><span class='text-btn'>Agregar Item</span></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Notas',
    components:{dialogo, snack},  
    props: ['usu','contrato'],
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledOrden:false,
            filledOrden:false,
            disabledDatos:false,
            filledDatos:false,
            disabledDev:false,
            filledDev:false,
            entidad:[],
            orden:'',
            rut:'',
            razon:'',
            nota:0,
            notas:[{name:'Nueva', number:0, type:0, id:0}],
            fullNotas:[],
            fullSaldos:[],
            menu1:false,
            emision:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu2:false,
            contable:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            pago:'',
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'8',forma:'Transferencia'},
                {id:'10',forma:'Cheque'}, // opciones nuevas para devoluciones
                {id:'20',forma:'Credito'} // opciones nuevas para devoluciones
            ],
            ref_pago:'',
            vence:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu3:false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Codigo', align: 'start', sortable: true, value: 'codigo' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: false, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: false, value: 'cantidad' },
                { text: 'Descuento', align: 'center', sortable: false, value: 'descuento' },
                { text: 'Exenta', align: 'center', sortable: false, value: 'exenta' },
                { text: 'Bodega', align: 'center', sortable: false, value: 'bodega' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            items: [],
            neto:0,
            impuesto:19,
            iva:0,
            total:0,
            btnGrabar:false,
            btnAnula:false,
            numero:'',
            tipo_cv:2,
            itemDTE:[
                {id:'2',doc:'Nota Credito'},
                {id:'17',doc:'Nota Debito'},
            ],
            hoy:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dialogCheque:false,
            fechaCheque: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            rutCheque:'',
            bancoCheque:0,
            cuentaCheque:'',
            numeroCheque:'',
            montoCheque:'',
            msjCheque:'',
            menucheque:false,
            bancos:[
                {id:1,name:'Banco Estado'},
                {id:2,name:'Banco de Chile / Edwards'}, 
                {id:3,name:'Banco de Crédito e Inversiones (BCI)'}, 
                {id:4,name:'Banco Bice'},
                {id:5,name:'Banco Santander'}, 
                {id:6,name:'Itaú / Corpbanca'}, 
                {id:7,name:'Banco Security'},
                {id:8,name:'Scotiabank'},
                {id:9,name:'Banco Falabella'}, 
                {id:10,name:'Banco Ripley'},
                {id:11,name:'Banco Consorcio'}, 
                {id:12,name:'Banco Internacional'}
            ],
            listaCheques:[],
            radios:'Neto',
            // new item
            dialogItem:false,
            id_item:0,
            codigo_item:'NCV',
            producto_item:'',
            precio_item:'',
            cantidad_item:'',
            descuento_item:'0.0',
            exento_item:'',
            item_exento:['SI','NO'],
            bodega_item:0,
            item_bodega:[],
            item_bodega_select:[],
        }
    },
    methods:{
        async add_items(){
            if(this.razon!=''){
                await this.buscarBodegas();
                this.dialogItem=true;
            } else {
                this.Msg='No existe Factura asociada';
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        async buscarBodegas(){
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.item_bodega=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.item_bodega.push(
                        {'numBodega':element.ID,'nameBodega':element.Nombre}
                    )        
                });
            };
            this.loading=false;
        },
        datos_bodega(id){
            this.item_bodega_select = this.item_bodega.filter(function(bod) {
                return bod.numBodega == id; 
            });
        },
        save_items(){
            this.items.push({
                'id':this.id_item,
                'codigo':this.codigo_item,
                'producto':this.producto_item,
                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(this.precio_item)),
                'valor':this.precio_item,
                'cantidad':this.cantidad_item,
                'descuento':this.descuento_item,
                'bodega':this.item_bodega_select[0].nameBodega,
                'numbodega':this.item_bodega_select[0].numBodega,
                'precioitem':this.precio_item,
                'exenta':this.exento_item
            });
            let nTotal=0;
            let nExento=0;
            const pDesc=(parseFloat(this.precio_item)*parseFloat(this.cantidad_item))*(parseFloat(this.descuento_item)/100);
            const subtotal=(parseFloat(this.precio_item)*parseFloat(this.cantidad_item))-pDesc;
            nTotal=nTotal+subtotal;
            if(this.exento_item=='SI'){ 
                nExento=nExento+subtotal 
            };
            this.neto=this.neto+Math.round(nTotal);
            this.iva=Math.round((this.neto-nExento)*(this.impuesto/100));
            this.total=this.neto+this.iva;
            this.id_item++;
            this.dialogItem=false
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        limpiar(){
            this.orden='';
            this.rut='';
            this.clsInput()
        },
        clsInput(){
            this.razon='';
            this.entidad=[];
            this.disabledOrden=false;
            this.filledOrden=false;
            this.disabledDatos=false;
            this.filledDatos=false;
            this.disabledDev=false;
            this.filledDev=false;
            this.items=[];
            this.nota=0;
            this.notas=[{name:'Nueva', number:0, type:0, id:0}];
            this.fullNotas=[];
            this.fullSaldos=[];
            this.numero='';
            this.emision=this.hoy;
            this.contable=this.hoy;
            this.pago='';
            this.ref_pago='';
            this.vence=this.hoy;
            this.neto=0;
            this.iva=0;
            this.total=0;
            this.btnGrabar=false;
            this.btnAnula=false;
            this.fechaCheque=this.hoy;
            this.rutCheque='';
            this.bancoCheque=0;
            this.cuentaCheque='';
            this.numeroCheque='';
            this.montoCheque='';
            this.msjCheque='';
            this.radios='Neto';
        },
        async verCompra(dtePro,rutPro){
            this.loading=true;
            this.clsInput();
            try {
                if (dtePro!='' && rutPro!=''){
                    const ente= await this.numeroEntidad(rutPro);
                    if (ente!=0){
                        //buscar compra
                        const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/C/'+this.contrato+'/'+dtePro+'/'+ente);
                        const datos=response.data;
                        if (datos[0].existe=='si'){
                            this.razon=this.entidad[0].razon;
                            this.disabledOrden=true;
                            this.filledOrden=true;
                            this.radios=datos[0].Tipo;
                            //devoluciones de la compra
                            await this.verDevoluciones(dtePro,ente,datos)
                        }
                    } else {
                        console.log('Proveedor no Encontrado')
                    }
                } else {
                    console.log('Seleccione N° Compra y Rut Proveedor')
                }
            } catch (error) {
                console.log(error)
            };
            this.loading=false;
        },
        async verDevoluciones(compra,ente,arrayCompra){
            try {
                //buscar dev
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/dev/proveedor/distinct/'+this.contrato+'/'+ente+'/'+compra);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.fullNotas=datos;
                    //calculamos saldos
                    let nTotal=0;
                    if (datos[0].Tipo_Dev!=2){
                        this.notas.push({name:'ND '+datos[0].Numero, number:datos[0].Numero, type:datos[0].Tipo_Dev, id:datos[0].ID});
                    } else {
                        this.notas.push({name:'NC '+datos[0].Numero,number:datos[0].Numero,type:datos[0].Tipo_Dev, id:datos[0].ID});
                    }
                    for (let index = 0; index < arrayCompra.length; index++) {
                        const element = arrayCompra[index];
                        var saldo = element.Cantidad;
                        //recorremos por sku
                        for (let index = 0; index < datos.length; index++) {
                            const element1 = datos[index];
                            //buscamos devoluciones
                            const found = this.notas.find(e => e.Numero==element1.Numero && e.Tipo_Dev==element1.Tipo_Dev);
                            if(!found){
                                if (element1.Tipo_Dev!=2){
                                    this.notas.push({name:'ND '+element1.Numero,number:element1.Numero,type:element1.Tipo_Dev, id:element1.ID});
                                } else {
                                    this.notas.push({name:'NC '+element1.Numero,number:element1.Numero,type:element1.Tipo_Dev, id:element1.ID});
                                }
                            }
                            if(element.Sku==element1.Sku){
                                saldo=saldo-element1.Cantidad
                                if(saldo<=0){
                                    saldo=0
                                }
                            }                            
                        };
                        // agregamos items
                        this.items.push({
                            'id':element.ID,
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'valor':element.Precio,
                            'cantidad':saldo,
                            'descuento':element.Descuento,
                            'bodega':element.Bodega,
                            'numbodega':element.NumBodega,
                            'precioitem':element.Precio,
                            'exenta':element.Exenta
                        });
                        const pDesc=(parseFloat(element.Precio)*parseFloat(saldo))*(parseFloat(element.Descuento)/100);
                        const subtotal=(parseFloat(element.Precio)*parseFloat(saldo))-pDesc;
                        nTotal=nTotal+subtotal
                    };
                    this.neto=Math.round(nTotal);
                    this.iva=Math.round(this.neto*(this.impuesto/100));
                    this.total=this.neto+this.iva;
                    this.fullSaldos=this.items
                } else { // nueva NC
                    let nTotal=0;
                    let nExento=0;
                    arrayCompra.forEach(element => {
                        this.items.push({
                            'id':element.ID,
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'valor':element.Precio,
                            'cantidad':element.Cantidad,
                            'descuento':element.Descuento,
                            'bodega':element.Bodega,
                            'numbodega':element.NumBodega,
                            'precioitem':element.Precio,
                            'exenta':element.Exenta
                        });
                        const pDesc=(parseFloat(element.Precio)*parseFloat(element.Cantidad))*(parseFloat(element.Descuento)/100);
                        const subtotal=(parseFloat(element.Precio)*parseFloat(element.Cantidad))-pDesc;
                        nTotal=nTotal+subtotal;
                        if(element.Exenta=='SI'){ nExento=nExento+subtotal }
                    });
                    this.neto=Math.round(nTotal);
                    this.iva=Math.round((this.neto-nExento)*(this.impuesto/100));
                    this.total=this.neto+this.iva;
                    this.fullSaldos=this.items;
                };
                this.btnGrabar=true;
                this.btnAnula=false;
            } catch (error) {
                console.log(error)
            }
            this.loading=false;
        },
        SelectNota(select){
            if(this.razon.trim()!=''){
                this.items=[];
                this.numero='';
                this.emision=this.hoy;
                this.contable=this.hoy;
                this.pago='';
                this.ref_pago='';
                this.vence=this.hoy;
                this.neto=0;
                this.iva=0;
                this.total=0;
                if(select!=0){
                    //buscar el numero seleccionado 
                    const num = this.notas.find(e => e.id==select);
                    if(num){
                        //buscamos el detalle de la devolucion
                        const filtro = this.fullNotas.filter(e => parseFloat(e.Numero)==parseFloat(num.number) && parseInt(e.Tipo_Dev)==parseInt(num.type));
                        if(filtro.length!=0){
                            this.disabledDatos=true;
                            this.filledDatos=true;
                            //mostrar datos principales
                            this.numero=filtro[0].Numero;
                            this.emision=filtro[0].Fec_Emision;
                            this.contable=filtro[0].Fec_Contable;
                            this.pago=filtro[0].Forma_Pago;
                            this.ref_pago=filtro[0].Referencia;
                            this.vence=filtro[0].Fec_Vcto;
                            for (let index = 0; index < filtro.length; index++) {
                                const element = filtro[index];
                                this.items.push({
                                    'codigo':element.Sku,
                                    'producto':element.Producto,
                                    'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                    'valor':element.Precio,
                                    'cantidad':element.Cantidad,
                                    'descuento':element.Descuento,
                                    'bodega':element.nameBodega,
                                    'numbodega':element.Bodega,
                                    'precioitem':element.Precio,
                                    'exenta':element.Exenta
                                });
                            };
                            //mostramos los totales
                            this.neto=Math.round(filtro[0].Afecto);
                            this.iva=Math.round(filtro[0].Iva);
                            this.total=Math.round(filtro[0].Total);
                        };
                    };
                    this.btnGrabar=false;
                    this.btnAnula=true;
                } else {
                    //mostrar nuevamente los saldos
                    let nTotal=0;
                    this.fullSaldos.forEach(element => {
                        this.items.push({
                            'codigo':element.codigo,
                            'producto':element.producto,
                            'precio':element.precio,
                            'valor':element.valor,
                            'cantidad':element.cantidad,
                            'descuento':element.descuento,
                            'bodega':element.bodega,
                            'numbodega':element.numbodega,
                            'precioitem':element.precioitem,
                            'exenta':element.Exenta
                        });
                        const pDesc=(parseFloat(element.precioitem)*parseFloat(element.cantidad))*(parseFloat(element.descuento)/100);
                        const subtotal=(parseFloat(element.precioitem)*parseFloat(element.cantidad))-pDesc;
                        nTotal=nTotal+subtotal
                    });
                    this.neto=Math.round(nTotal);
                    this.iva=Math.round(this.neto*(this.impuesto/100));
                    this.total=this.neto+this.iva;
                    this.disabledDatos=false;
                    this.filledDatos=false;
                    this.btnGrabar=true;
                    this.btnAnula=false;
                }
            }
        },
        async saveNC(){
            this.loading=true;
            let t=0;
            this.btnGrabar=false;
            this.btnAnula=false;
            this.disabledDatos=true;
            this.filledDatos=true;
            this.disabledDev=true;
            this.filledDev=true;
            if (this.items.length!=0) {
                if(this.orden.trim()!='' && this.pago!=''){
                    let signo='';
                    let glosa='';
                    if(parseInt(this.tipo_cv)!=2){
                        signo='0+';
                        glosa='Ingreso'
                    } else {
                        signo='0-'
                        glosa='Egreso'
                    };
                    if(parseInt(this.pago)!=10){
                        this.fechaCheque=this.hoy;
                        this.rutCheque='';
                        this.bancoCheque=0;
                        this.cuentaCheque='';
                        this.numeroCheque='';
                        this.montoCheque='';
                        this.msjCheque='';
                    }
                    for (var i = 0; i < this.items.length; i++) {
                        var parametros2={
                            'compra': this.orden,
                            'tipo_nc': this.radios,
                            'contrato': this.contrato,
                            'fecha': this.hoy,                        
                            'entidad': this.entidad[0].id,
                            'numero': this.numero,
                            'tipo_dev': this.tipo_cv,
                            'fec_emision':this.emision,
                            'fec_contable':this.contable,
                            'fec_vcto':this.vence,
                            'forma_pago': this.pago,
                            'referencia': this.ref_pago,
                            'afecto':this.neto,
                            'iva':this.iva,
                            'total':this.total,
                            'sku':this.items[i].codigo,
                            'producto':this.items[i].producto,
                            'precio':parseFloat(this.items[i].precioitem),
                            'cantidad':parseFloat(this.items[i].cantidad),
                            'descuento':parseFloat(this.items[i].descuento),
                            'bodega': this.items[i].numbodega,
                            'signo':signo,
                            'usuario':this.usu,
                            'usuario_mod':this.usu,
                            'fecha_mod':this.hoy,
                            'estado':glosa,
                            'rut_ch':this.rutCheque,
                            'vence_ch':this.fechaCheque,
                            'numero_ch':this.numeroCheque,
                            'banco_ch':this.bancoCheque,
                            'cuenta_ch':this.cuentaCheque,
                            'monto_ch':this.montoCheque,
                        };
                        try {
                            const response2 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/dev/proveedor/nueva',parametros2);
                            const datos2=response2.data;
                            if (datos2=='si'){ t++ }
                        } catch (error) {
                            console.log(error)
                        }
                    };
                    if (t!=this.items.length) {
                        this.Msg='Algunos items no fueron grabados, favor revisar';
                        this.msgbox=true;
                        this.color='warning';
                        this.showSnack();
                    } else {
                        if(this.radios=='Neto'){
                            await this.UpdateStock(signo,glosa);
                        } else {
                            this.btnPrt=true;
                            this.Msg='Proceso exitoso';
                            this.msgbox=true;
                            this.color='success';
                            this.showSnack();
                        }
                    }
                } else {
                    this.Msg='Todos los datos son obligatorios';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                }
            } else {
                this.Msg='Lista de items vacia';
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            };
            this.loading=false;
        },
        async anulaDev(){
            this.loading=true;
            try {
                this.btnGrabar=false;
                this.btnAnula=false;
                this.disabledDatos=true;
                this.filledDatos=true;
                this.disabledDev=true;
                this.filledDev=true;
                const parametros={
                    'compra':parseFloat(this.orden),
                    'numero':parseFloat(this.numero),
                    'entidad':this.entidad[0].id,
                    'tipo_dev':parseFloat(this.tipo_cv),
                    'usuario_mod':this.usu,
                    'fecha_mod':this.hoy,
                    'estado':'Nulo'
                };
                const res = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/dev/proveedor/anula/'+this.contrato,parametros);
                const dato=res.data;
                if (dato=='si'){
                    let signo='0+';
                    let glosa='Ingreso';
                    await this.UpdateStock(signo,glosa);                    
                } else {
                    this.Msg='Documento NO anulado';
                    this.msgbox=true;
                    this.color='error';
                    this.showSnack();
                }
            } catch (error) {
                console.log(error)
            };
            this.loading=false;
        },
        async UpdateStock(signo,glosa){
            let t=0;
            if (this.items.length!=0) {
                let signos=signo;
                for (var i = 0; i < this.items.length; i++) {
                    var parametros1={
                        'contrato': this.contrato,
                        'sku': this.items[i].codigo,
                        'cantidad': this.items[i].cantidad,
                        'signo': signos,
                        'bodega': this.items[i].numbodega,
                        'usuario': this.usu,
                        'observa': 'NC Proveedor',
                        'origen':glosa
                    };
                    const response1 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='algunos items no actualizaron stock, favor revisar';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    this.btnPrt=true;
                    this.Msg='Proceso exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            }
        },
        async numeroEntidad(rutPro){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/proveedor/'+this.contrato+'/'+ rutPro);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.entidad.push({
                        id:parseInt(datos[0].ID),
                        rut:datos[0].Rut,
                        razon:datos[0].Razon
                    });
                    return parseInt(datos[0].ID)
                } else {
                    return 0
                }                
            } catch (error) {
                console.log(error)
                return 0
            }
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ( (keyCode < 48 || keyCode > 57) ) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        deleteItem(array){
            console.log(array);
            const pDesc=(parseFloat(array.precioitem)*parseFloat(array.cantidad))*(parseFloat(array.descuento)/100);
            const valorItem=(parseFloat(array.precioitem)*parseFloat(array.cantidad))-pDesc;
            this.neto=this.neto-valorItem;
            if(array.exenta!='SI'){
                const iva_item=Math.round(valorItem*(this.impuesto/100));
                this.iva=this.iva-iva_item;
            } else {
              this.iva=this.iva  
            }
            //this.iva=Math.round(this.neto*(this.impuesto/100));
            this.total=this.neto+this.iva;
            array.cantidad='0';
            this.items = this.items.filter(function(dat) {
            return dat.id !== array.id; 
            });
        },
        menos(array){
            if (array.cantidad>1){
                const pDesc=(parseFloat(array.precioitem)*1)*(parseFloat(array.descuento)/100);
                const valorItem=(parseFloat(array.precioitem)*1)-pDesc;
                this.neto=this.neto-valorItem;
                if(array.exenta!='SI'){
                    const iva_item=Math.round(valorItem*(this.impuesto/100));
                    this.iva=this.iva-iva_item;
                } else {
                this.iva=this.iva  
                }                
                //this.iva=Math.round(this.neto*(this.impuesto/100));
                this.total=this.neto+this.iva;
                array.cantidad=parseInt(array.cantidad)-1
            }
        },
        activaCheque(pago){
            if(parseInt(pago)==10 && this.btnAnula!=true){
                this.dialogCheque=true;
            }
        },
        cierraCheque(){
            if(this.numeroCheque.trim()!='' &&
                this.rutCheque.trim()!='' &&
                this.bancoCheque!=0 &&
                this.cuentaCheque.trim()!='' &&
                this.montoCheque.trim()!=''
            ){
                this.msjCheque='';
                this.ref_pago='CH '+this.numeroCheque;
                this.dialogCheque=false;
            } else {
                this.msjCheque='Todos los datos son Obligatorios'
            }
        },
    }
}
</script>
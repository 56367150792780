<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">Orden</v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="orden" :counter="15" label="Numero Orden" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn v-if="btn===true" @click="verOrden(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                                <v-btn v-else class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" dense outlined :disabled="disabledOrden" :filled="filledOrden"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="fecha" label="Fecha" dense outlined filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="razon" label="Nombre" dense outlined filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-body-2' v-model="ntotal" label="Total ($)" dense outlined filled disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.sku="{ item }">
                                        <span :class="colorSku(item.valsku)">{{item.sku}}</span>
                                    </template>
                                    <template v-slot:item.bodega>
                                        <v-select style="width:175px" class="text-caption" v-on:change="stockSku(bodega,items[0].sku)" v-model="bodega" :items="bodegas" item-value="numBodega" item-text="nameBodega" label="Bodegas"></v-select>
                                    </template>
                                    <template v-slot:item.stock>
                                        <span class="text-caption grey--text">{{tStock}}</span>
                                    </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="12">
                                <div class="mt-5 text-right">
                                    <v-btn @click="newOrden()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    <v-btn v-if="btn===true" class="ml-2" @click="integrarOrden()" small color="teal" dark rounded><v-icon small>send</v-icon><span class='text-btn'>Integrar</span></v-btn>
                                    <v-btn v-else class="ml-2" small color="teal" dark rounded><v-icon small>send</v-icon><span class='text-btn'>Integrar</span></v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledOrden:false,
            filledOrden:false,
            orden:'',
            rut:'',
            razon:'',
            fecha:'',
            ntotal:0,
            tStock:0,
            page: 1,
            pageCount: 0,
            itemsPerPage: 3,
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto' },
                { text: 'Neto ($)', align: 'center', sortable: false, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: false, value: 'cantidad' },
                { text: 'Bodega', align: 'center', sortable: false, value: 'bodega' },
                { text: 'Stock', align: 'center', sortable: false, value: 'stock' },
            ],
            items: [],
            bodegas:[],
            bodega:'',
            ItemEcommerce:[],
            reg:0,
            existeSku:'',
            btn:true,
            neto:0,
        }
    },
    mounted(){
        this.newOrden();
        this.buscarBodegas();
    },
    methods:{
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.rut='';
            this.razon='';
            this.tStock='';
            this.items=[];
            this.bodega='';
            this.fecha='';
            this.ntotal=0;
            this.ItemEcommerce=[];
            this.reg=0;
            this.existeSku='';
            this.neto=0;
            this.btn=true;
        },
        newOrden(){
            this.orden='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.btn=true;
            this.clsInput()
        },
        async verOrden(nOrden){
            this.clsInput();
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/ApiEcomerce/public/api/ML/products/orders/'+nOrden+'/'+this.contrato);
            const datos=response.data;
            this.items=[];
            if (datos[0].existe=='si'){
                this.fecha=datos[0].Fecha;
                this.razon=datos[0].Nombre;
                this.ntotal=new Intl.NumberFormat("de-DE").format(parseFloat(datos[0].Total));
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    // valido la exisencia del sku
                    this.existeSku='';
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/producto/'+this.contrato+'/'+element.Sku);
                    const datos1=response1.data;
                    if (datos1[0].existe!='si'){ this.existeSku='no' } else { this.existeSku='si' }
                    //calculamos el neto del producto
                    this.neto=0;
                    const response2 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/impuesto/'+this.contrato);
                    const datos2=response2.data;
                    if (datos2[0].existe!='si'){ 
                        this.neto=Math.round(((parseInt(element.Precio)/119)*100)+0.1);
                    } else {
                        this.neto=Math.round(((parseInt(element.Precio)/(parseInt(datos2[0].Iva)+100))*100)+0.1);
                    }
                    this.items.push({
                        'sku':element.Sku,
                        'valsku': this.existeSku,
                        'producto':element.Descripcion,
                        'precio':new Intl.NumberFormat("de-DE").format(parseFloat(this.neto)),
                        'valor':this.neto,
                        'cantidad':element.Cantidad,
                        'bodega':'0',
                        'stock':'0',
                    });                    
                }              
            } else {
                this.Msg='Orden no Existe o Integrada'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        colorSku(eSku){
            if (eSku!='no'){
                return 'text-caption';
            } else {
                return 'text-caption red--text';
            }
        },
        async integrarOrden(){
            this.loading=true;
            //validar sku de los items
            const resultado = this.items.find( valor => valor.valsku === 'no' );
            if (resultado){
                this.Msg='Sku no Existe !!!'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            } else {
                //validamos disponibilidad de stock
                const resultado1 = this.items.find( valor => parseInt(valor.cantidad) >= parseInt(valor.stock) );
                if (resultado1){
                    this.Msg='Producto sin Stock !!!'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                } else {
                    if(this.rut.trim()!=''){
                        // verificar existencia del rut
                        const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/clientes/'+this.contrato+'/'+ this.rut);
                        const datos=response.data;
                        if (datos[0].existe=='si'){  
                            this.Msg='Rut ya Existe !!!'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        } else {
                            // crear nuevo Cliente
                            var parametros={
                            'contrato': this.contrato,
                            'rut': this.rut,
                            'nombre': this.razon,
                            'direccion': '',
                            'ciudad': '',
                            'comuna': '',
                            'telefono': '',
                            'email': '',
                            'observacion': '',
                            'estado': 'V'};
                            const response1 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/clientes/nuevo',parametros);
                            const datos1=response1.data;
                            if (datos1=='si'){ 
                                // buscar la entidad del cliente creado
                                const response2 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/clientes/'+this.contrato+'/'+ this.rut);
                                const datos2=response2.data;
                                if (datos2[0].existe=='si'){ 
                                    const nCliente=datos2[0].ID;
                                    //recorremos el items
                                    for (let i = 0; i < this.items.length; i++) {
                                        const elementos = this.items[i];
                                        var parametros1={
                                        'contrato': this.contrato,
                                        'orden': this.orden,
                                        'entidad': nCliente,
                                        'tipo': 'Neto',
                                        'cv': 'V',
                                        'sku':elementos.sku,
                                        'producto':elementos.producto,
                                        'precio':elementos.valor,
                                        'cantidad':elementos.cantidad,
                                        'bodega': elementos.bodega,
                                        'observa':'',
                                        'usuario':this.usu,
                                        'referencia':'0'};
                                        const response3 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/compraventa/nueva',parametros1);
                                        const datos3=response3.data;                                    
                                        if (datos3=='si'){ 
                                            this.reg++
                                        } 
                                    }
                                    if (this.reg==this.items.length){
                                        // actualiza estado ecommerce
                                        const response4 = await axios.put('https://www.transervi.cl/ApiEcomerce/public/api/ML/products/orders/status/N/'+this.orden+'/'+this.contrato);
                                        const datos4=response4.data;
                                        if (datos4=='si'){
                                            this.Msg='Orden Integrada !!!'
                                            this.msgbox=true;
                                            this.color='success';
                                            this.showSnack();
                                            this.disabledOrden=true;
                                            this.filledOrden=true; 
                                            this.btn=false;  
                                        } else {
                                            this.Msg='Orden sin Actualizar !!!'
                                            this.msgbox=true;
                                            this.color='orange';
                                            this.showSnack();  
                                        }
                                    } else {
                                        this.Msg='Orden Incompleta !!!'
                                        this.msgbox=true;
                                        this.color='orange';
                                        this.showSnack();                                
                                    }
                                } else {
                                    this.Msg='Rut Sin Conexion ...'
                                    this.msgbox=true;
                                    this.color='red';
                                    this.showSnack();
                                }
                            } else {
                                this.Msg='Rut Sin Conexion !!!'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        }
                    } else {
                        this.Msg='Cliente sin Rut !!!'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                }
            }
            this.loading=false;
        },
        async buscarBodegas(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.bodegas=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.bodegas.push({numBodega:element.ID,nameBodega:element.Nombre})        
                });
            }
        },
        async stockSku(B,S){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ B + '/' + S);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.tStock=datos[0].Total;
                //agregar bodega al array
                let indice = this.items.findIndex(busca => busca.sku === S);
                this.items[indice].bodega = B
                this.items[indice].stock = this.tStock
                //console.log(this.items);
            } 
            if (datos[0].existe=='no'){ 
                this.tStock='Sin Stock';
            } 
            if (datos[0].existe=='error'){
                this.tStock='Error de Stock';
            } 
        },
    }
}
</script>
<template>
    <div>
        <v-container>
            <div v-if="img.length!=0">
                <v-row >
                    <v-col v-for="item in img" :key="item.id" cols="12" md="3">
                        <v-img :src="item.image" height="230" width="230"></v-img>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-img src="" height="230" width="230"></v-img>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name:'GerenciaImagen',
    props: ['usu','contrato','sku'], 
    data(){
        return{
            img:[],
        }
    },
    mounted(){
        if(this.sku.trim()!=''){
            this.searchImages();
        } else {
            this.existe=false
        }
    },
    methods:{
        async searchImages(){
            try {
                this.img=[];
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/producto/imagen/'+this.contrato+'/'+this.sku);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    let ids=0;
                    datos.forEach(element => {
                        ids++;
                        this.img.push({
                            'id':ids,
                            'image':element.Imagen   
                        });
                    });
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

}
</script>
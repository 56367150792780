<template>
    <div>
        <v-simple-table fixed-header height="200px" dense>
            <thead>
                <tr>
                <th style="background-color:lightgrey;width:10%">Fecha</th>
                <th style="background-color:lightgrey;width:2%">Tipo</th>
                <th style="background-color:lightgrey;width:5%">Numero</th>
                <th style="background-color:lightgrey;width:10%">Rut</th>
                <th style="background-color:lightgrey;width:30%">Cliente</th>
                <th style="background-color:lightgrey;width:10%">Sucursal</th>
                <th style="background-color:lightgrey;width:10%">Vendedor</th>
                <th style="background-color:lightgrey;width:5%">Cantidad</th>
                <th style="background-color:lightgrey;width:7%">Precio Unitario</th>
                <th style="background-color:lightgrey;width:5%">Descuento</th>
                <th style="background-color:lightgrey;width:7%">Precio Venta</th>
                <th style="background-color:lightgrey;width:7%">Detalle</th>
                </tr>
            </thead>
            <tbody v-if="existe===true" style="background-color:#B2EBF2">
                <tr v-for="item in desserts" :key="item.id">
                    <td style="font-size:9pt">{{ item.fecha }}</td>
                    <td style="font-size:9pt">{{ item.tipo }}</td>
                    <td style="font-size:9pt">{{ item.numero }}</td>
                    <td style="font-size:9pt">{{ item.rut }}</td>
                    <td style="font-size:9pt">{{ item.cliente }}</td>
                    <td style="font-size:9pt">{{ item.sucursal }}</td>
                    <td style="font-size:9pt">{{ item.vendedor }}</td>
                    <td style="font-size:9pt">{{ item.cantidad }}</td>
                    <td style="font-size:9pt">{{ item.precio }}</td>
                    <td style="font-size:9pt">{{ item.descuento }}</td>
                    <td style="font-size:9pt">{{ item.total }}</td>
                    <td><v-icon @click="detalleCompra(item)" class="text-indigo" small >description</v-icon></td>
                    <!-- <td><a target="_blank" :href="item.ruta"><v-icon class="text-indigo" small >print</v-icon></a></td> -->
                </tr>                
            </tbody>
            <tbody v-else-if="existe===false" style="background-color:#B2EBF2">
                <tr>
                    <td colspan="12" style="height:165px;text-align: center">
                        <span class="text-caption grey--text">No existe informacion asociada.</span>
                    </td>
                </tr>
            </tbody>
            <tbody v-else style="background-color:#B2EBF2">
                <tr>
                    <td colspan="12" style="height:165px;text-align: center">
                        <v-progress-circular :width="2" :size="20" indeterminate color="indigo"></v-progress-circular>
                        <span class="ml-2 text-caption grey--text">Buscando números de referencia ......</span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-divider></v-divider>
        <v-row class="mt-1">
            <v-col cols="12" md="3">
                <span>Cantidad Facturas + Boletas: <strong>{{totalFAC}}</strong></span><br/>
                <span>Cantidad Notas de Credito: <strong>{{totalNC}}</strong></span><br/>
            </v-col>
            <v-col cols="12" md="3">
                <span>Cantidad Notas de Debito: <strong>{{totalND}}</strong></span><br/>
                <span>Total Ventas: <strong>{{totalVentas}}</strong></span>
            </v-col>
            <v-col cols="12" md="3">
                <span>Ventas últimos 6 meses: <strong>{{venta06Meses}}</strong></span><br/>
                <span>Venta promedio últimos 6 meses: <strong>{{ventaPromedio06Meses}}</strong></span><br/>
            </v-col>
            <v-col cols="12" md="3">
                <span>Ventas últimos 30 días: <strong>{{venta30Dias}}</strong></span><br/>
                <span>Ventas últimos 12 meses: <strong>{{venta90Dias}}</strong></span><br/>
            </v-col>
        </v-row>
        <!-- dialog detalle -->
        <v-dialog v-model="detalle" transition="dialog-bottom-transition" max-width="90%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="detalle=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :loading="progress" loading-text="Cargando....." dense dark :search="likesearch" :headers="headersDetalle" :items="itemsDetalle" :page.sync="page" :items-per-page="itemsPerPage"  @page-count="pageCount = $event" hide-default-footer class="elevation-1">
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount"  :total-visible="5"></v-pagination>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                </div>
            </v-card-actions>
        </v-card>
        </v-dialog>       
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import axios from 'axios';
export default {
    name:'GerenciaVentas',
    props: ['usu','contrato','sku'], 
    data(){
        return{
            desserts: [],
            existe:null,
            totalFAC:0,
            totalNC:0,
            totalND:0,
            totalVentas:0,
            venta06Meses:0,
            ventaPromedio06Meses:0.00,
            venta30Dias:0,
            venta90Dias:0,
            headersDetalle:[
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
            ],
            itemsDetalle: [],
            detalle:false,
            progress:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
        }
    },
    mounted(){
        if(this.sku.trim()!=''){
            this.searchVentas();
        } else {
            this.existe=false
        }
    },
    methods:{
        async searchVentas(){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/ventas/sku/'+this.contrato+'/'+this.sku);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    let BOE = 0;
                    let FAE = 0;
                    let NCE = 0;
                    let NDE = 0;
                    var ids=0;
                    datos.forEach(element => {
                        switch (parseInt(element.TipoDte)) {
                            case 2: // nota de Credito
                                NCE++
                                break;
                            case 5: // Factura
                                FAE++
                                break;
                            case 17: // nota de Debito
                                NDE++
                                break;
                            case 22: // Boleta
                                BOE++
                                break;
                        };
                        ids++;
                        let varDesc=0
                        let varUnidad=0;
                        let varTotal=0;
                        if(element.Descuento!=0){ varDesc=element.Descuento };
                        // generar calculos
                        varUnidad=parseFloat(element.Precio)-((parseFloat(element.Precio)*varDesc)/100);
                        varTotal=Math.round(varUnidad*parseFloat(element.Cantidad));
                        this.desserts.push({
                            'id':ids,
                            'fecha':element.Fecha,
                            'tipodte':element.TipoDte,
                            'tipo':element.Tipo,
                            'numero':element.Numero,
                            'orden':element.Orden,
                            'entidad':element.Entidad,
                            'rut':element.Rut,
                            'cliente':element.Cliente,
                            'idsucursal':element.idSucursal,
                            'sucursal':element.Sucursal,
                            'vendedor':element.Usuario,
                            'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'descuento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Descuento)),
                            'total':new Intl.NumberFormat("de-DE").format(varTotal),
                            'ruta':element.RutaPdf
                        })        
                    });
                    this.existe=true;
                    this.totalFAC=BOE+FAE;
                    this.totalNC=NCE;
                    this.totalND=NDE;
                    this.totalVentas=(BOE+FAE+NDE)-NCE;
                    // buscar valores critico del sku
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/critico/'+this.contrato+'/'+this.sku);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        let ult6mes=0;
                        let prom6mes=0.00;
                        let vent30dias=0;
                        let vent90dias=0;
                        datos1.forEach(element => {
                            ult6mes=ult6mes+parseFloat(element.U90D);
                            prom6mes=prom6mes+parseFloat(element.P3M);
                            vent30dias=vent30dias+parseFloat(element.U30D);
                            vent90dias=vent90dias+parseFloat(element.U12M);
                        });
                        this.venta06Meses=ult6mes;
                        this.ventaPromedio06Meses=parseFloat(prom6mes/2).toFixed(2);
                        this.venta30Dias=vent30dias;
                        this.venta90Dias=vent90dias;
                    };
                } else {
                    this.existe=false;
                }               
            } catch (error) {
                this.existe=false;
                console.log(error)
            }
        },
        async detalleCompra(e){
            try {
                this.detalle=true;
                this.progress=true;
                this.itemsDetalle= [];
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/V/'+this.contrato+'/'+e.orden+'/'+e.entidad);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.itemsDetalle.push({
                            'sku':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                            'bodega':element.Bodega,
                        })        
                    });
                };
                this.progress=false;
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
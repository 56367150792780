<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Flujo Caja</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Flujo Caja</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">
          <span>Caja</span>
          <v-icon>{{PagoIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Flujo</span>
          <v-icon>{{FlujoIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp2">
          <span>Pagos</span>
          <v-icon>{{CierreIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
      
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
import Pagos from '@/components/Pagos.vue';
import Flujo from '@/components/Flujo.vue';
import Prepago from '@/components/Prepago.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Dte',
  components: { Pagos, Flujo, Prepago ,dialogo},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      PagoIcon:'monetization_on',
      FlujoIcon:'monetization_on',
      CierreIcon:'monetization_on',
      IconDefault:'monetization_on',
      comp0:true,
      comp1:true,
      comp2:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
   methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_7_1!=0) {
          this.comp0=false;
        };
        if(datos[0].Permiso_7_2!=0) {
          this.comp1=false;
        };
        if(datos[0].Permiso_7_3!=0) {
          this.comp2=false;
        };
      } 
      this.moduloActivo();      
    },
    moduloActivo(){
        if (this.comp0!=true){
            this.componente='Pagos';
            this.PagoIcon=this.IconSelect;
            this.FlujoIcon=this.IconDefault;
            this.CierreIcon=this.IconDefault;
            this.value=0
        } else {
          if (this.comp1!=true){
              this.componente='Flujo';
              this.PagoIcon=this.IconDefault;
              this.FlujoIcon=this.IconSelect;
              this.CierreIcon=this.IconDefault;
              this.value=1
          } else {
            if (this.comp2!=true){
                this.componente='Prepago';
                this.PagoIcon=this.IconDefault;
                this.FlujoIcon=this.IconDefault;
                this.CierreIcon=this.IconSelect;
                this.value=2
            } else {
                this.PagoIcon=this.IconDefault;
                this.FlujoIcon=this.IconDefault;
                this.CierreIcon=this.IconDefault;              
            }
          }
        }
        this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Pagos';
          this.PagoIcon=this.IconDefault;
          this.FlujoIcon=this.IconSelect;
          this.CierreIcon=this.IconDefault;
        break;
        case 1:
          vComponente='Flujo';
          this.PagoIcon=this.IconDefault;
          this.FlujoIcon=this.IconSelect;
          this.CierreIcon=this.IconDefault;
        break;
        case 2:
          vComponente='Prepago';
          this.PagoIcon=this.IconDefault;
          this.FlujoIcon=this.IconDefault;
          this.CierreIcon=this.IconSelect;
        break;
      }
      this.componente=vComponente;
    }
  }
}
</script>
<template>
    <div>
        <v-app-bar color="blue-grey" dark fixed flat>
            <div class="hidden-sm-and-up">
                <v-app-bar-nav-icon app @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </div>
            <div class="hidden-sm-and-down">
                <v-icon large>developer_board</v-icon> 
                <span class="ml-2">Control de Inventario</span>        
            </div>
            <v-spacer></v-spacer>
            <v-chip @click="$router.go(-1)" class="ma-2">
                <span>Salir</span>
                <v-icon class="ml-2" small>logout</v-icon>
            </v-chip>
        </v-app-bar>
        <!-- full screen -->
        <div class="hidden-sm-and-down">
            <div id="sidebar" class="sidebar">
                <Menu :usu="usu"/>
            </div>
            <div id="contenido">
                <v-btn small class="mx-2" fab dark color="teal" float fixed v-if="OpenMenu==true" @click="mostrar()">
                    <v-icon dark >keyboard_arrow_right</v-icon>
                </v-btn>
                <v-btn small class="mx-2" fab dark color="teal" float fixed v-else @click="ocultar()">
                    <v-icon dark >keyboard_arrow_left</v-icon>
                </v-btn>
 
                    <component v-bind:is="componenteFull" :usu="usu" :contrato="contrato"></component>

            </div>
        </div>
        <!-- mobile screen -->
        <div class="hidden-sm-and-up">
            <v-navigation-drawer app v-model="drawer" temporary>
                <Menu :usu="usu"/>
            </v-navigation-drawer>
            <div id="contenido">
                <component v-bind:is="componenteMobile" :usu="usu" :contrato="contrato"></component>
            </div>
        </div>
    </div>
</template>
<style scoped>
.sidebar { 
    position: fixed;
    height: 100%;
    width: 0;
    top: 1;
    left: 0;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.4s;
    padding: 1rem 0;
    box-sizing:border-box;
}
#contenido {
    transition: margin-left .4s;
    padding: 1rem;
    margin-top: 50px; 
}
</style>
<script>
import Menu from '@/components/Menu.vue'
import Bienvenido from '@/components/Bienvenido.vue'
import DashBoard from '@/components/NewDashBoard.vue'
import Productos from '@/components/Productos.vue'
import Pedidos from '@/components/Pedidos.vue'
//import Pedidos from '@/components/Compra.vue'
import Dte from '@/components/Dte.vue'
import Inventario from '@/components/Inventario.vue'
import Cartera from '@/components/Cartera.vue'
import Gerencia from '@/components/Gerencia.vue'
import Setup from '@/components/Setup.vue'
import Reportes from '@/components/Reportes.vue'
import Ecomerce from '@/components/Ecomerce.vue'
import Caja from '@/components/Caja.vue'
import Importaciones from '@/components/Importaciones.vue'
import Credito from '@/components/Credito.vue'
import EventBus from '../bus'
import List from '../list'
export default {
    name: 'Cabecera',
    components: { Bienvenido, Menu, DashBoard, Productos, Pedidos, Dte, Inventario, Cartera, Setup, Reportes, Ecomerce, Caja, Importaciones, Gerencia, Credito },
    props: ['usu','contrato'],
data(){
    return{
        OpenMenu:true,
        //Opcion:'dashboard',
        drawer: null,
        componenteFull:'Bienvenido',
        componenteMobile:'Bienvenido',
        body:''
    }
},
created() {
    EventBus.$on('Opcion-Menu', (item) => {
        //console.log(item);
        //this.Opcion=item.text;
        this.componenteFull=item.componente;
        this.componenteMobile=item.componente;
        this.drawer=false
    })
},
mounted(){
    if(!List){ 
        this.$router.push({name:'Login'});
    }
},
methods:{
mostrar() {
    document.getElementById("sidebar").style.width = "300px";
    document.getElementById("contenido").style.marginLeft = "300px";
    this.OpenMenu=false
},
ocultar() {
    document.getElementById("sidebar").style.width = "0";
    document.getElementById("contenido").style.marginLeft = "0";
    this.OpenMenu=true
}
}
}
</script>
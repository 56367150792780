<template>
    <div>
        <v-simple-table fixed-header height="200px" dense>
            <thead>
                <tr>
                <th style="background-color:lightgrey;width:10%">Fecha</th>
                <th style="background-color:lightgrey;width:10%">Referencia</th>
                <th style="background-color:lightgrey;width:25%">Razon Social</th>
                <th style="background-color:lightgrey;width:2%">Tipo</th>
                <th style="background-color:lightgrey;width:5%">Numero</th>
                <th style="background-color:lightgrey;width:2%">Moneda</th>
                <th style="background-color:lightgrey;width:5%">Paridad</th>
                <th style="background-color:lightgrey;width:5%">Precio</th>
                <th style="background-color:lightgrey;width:5%">Gastos</th>
                <th style="background-color:lightgrey;width:5%">Descuento</th>
                <th style="background-color:lightgrey;width:5%">Cantidad</th>
                <th style="background-color:lightgrey;width:7%">CLP Unitario</th>
                <th style="background-color:lightgrey;width:7%">CLP Compra</th>
                <th style="background-color:lightgrey;width:7%">Estado</th>
                </tr>
            </thead>
            <tbody v-if="existe===true" style="background-color:#B2EBF2">
                <tr v-for="item in desserts" :key="item.id">
                    <td style="font-size:9pt">{{ item.fecha }}</td>
                    <td style="font-size:9pt">{{ item.cod_proveedor }}</td>
                    <td style="font-size:9pt">{{ item.proveedor }}</td>
                    <td style="font-size:9pt">{{ item.tipo }}</td>
                    <td style="font-size:9pt">{{ item.numero }}</td>
                    <td style="font-size:9pt">{{ item.moneda }}</td>
                    <td style="font-size:9pt">{{ item.paridad }}</td>
                    <td style="font-size:9pt">{{ item.precio }}</td>
                    <td style="font-size:9pt">{{ item.gasto }}</td>
                    <td style="font-size:9pt">{{ item.descuento }}</td>
                    <td style="font-size:9pt">{{ item.cantidad }}</td>
                    <td style="font-size:9pt">{{ item.unitario }}</td>
                    <td style="font-size:9pt">{{ item.total }}</td>
                    <td style="font-size:9pt">{{ item.estado }}</td>
                </tr>                
            </tbody>
            <tbody v-else-if="existe===false" style="background-color:#B2EBF2">
                <tr>
                    <td colspan="13" style="height:165px;text-align: center">
                        <span class="text-caption grey--text">No existe informacion asociada.</span>
                    </td>
                </tr>
            </tbody>
            <tbody v-else style="background-color:#B2EBF2">
                <tr>
                    <td colspan="13" style="height:165px;text-align: center">
                        <v-progress-circular :width="2" :size="20" indeterminate color="indigo"></v-progress-circular>
                        <span class="ml-2 text-caption grey--text">Buscando números de referencia ......</span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-divider></v-divider>
        <v-row class="mt-1">
            <v-col cols="12" md="4">
                <span>(+) Cantidad Entrada: <strong>{{comprasIN}}</strong></span><br/>
            </v-col>
            <v-col cols="12" md="4">
                <span>(-) Cantidad Salida: <strong>{{comprasOUT}}</strong></span><br/>
            </v-col>
            <v-col cols="12" md="4">
                <span>(=) Total Compras: <strong>{{comprasTotal}}</strong></span><br/>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name:'GerenciaCompras',
    props: ['usu','contrato','sku'], 
    data(){
        return{
            desserts: [],
            existe:null,
            comprasIN:0,
            comprasOUT:0,
            comprasTotal:0,
        }
    },
    mounted(){
        if(this.sku.trim()!=''){
            this.searchCompras()
        } else {
            this.existe=false
        }
    },
    methods:{
        async searchCompras(){
            try {
                const dia_incial='2022-01-01';
                const dia_final= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/compras/sku/detalle/'+this.contrato+'/'+this.sku+'/'+dia_incial+'/'+dia_final);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    var varIN=0;
                    var varOUT=0;
                    var ids=0;
                    //console.log(datos);
                    datos.forEach(element => {
                        if(element.Estado!='Pendiente'){
                            //validar fac NC ND del proveedor
                            varIN++;
                            ids++;
                            let varDesc=0
                            let varUnidad=0;
                            let varTotal=0;
                            let varGasto=0;
                            let varPrecio=0;
                            if(element.Descuento!=0){ varDesc=parseFloat(element.Descuento)/100 };
                            // generar calculos
                            varGasto=parseFloat((parseFloat(element.Precio)*parseFloat(element.Gasto))/100).toFixed(2);
                            varUnidad=parseFloat((parseFloat(element.Precio)+parseFloat(varGasto))-varDesc).toFixed(2);
                            varUnidad=Math.round(parseFloat(varUnidad)*parseFloat(element.Paridad));
                            varTotal=Math.round(varUnidad*parseFloat(element.Cantidad));
                            //varUnidad=(parseFloat(element.Paridad)*(parseFloat(element.Precio)+parseFloat(varGasto)))-(((parseFloat(element.Paridad)*(parseFloat(element.Precio)+parseFloat(varGasto)))*varDesc)/100);
                            this.desserts.push({
                                'id':ids,
                                'fecha':element.Fecha,
                                'tipo':element.Tipo,
                                'numero':element.Orden,
                                'proveedor':element.Proveedor,
                                'cod_proveedor':element.Cod_Proveedor,
                                'moneda':element.Moneda,
                                'paridad':element.Paridad,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'gasto':new Intl.NumberFormat("de-DE").format(parseFloat(varGasto)),
                                'descuento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Descuento)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'unitario':new Intl.NumberFormat("de-DE").format(parseFloat(varUnidad)),
                                'total':new Intl.NumberFormat("de-DE").format(varTotal),
                                'estado':element.Estado,
                            })        
                        }
                    });
                    if (ids!=0){
                        this.existe=true;
                        this.comprasIN=varIN;
                        this.comprasTotal=varIN;                    
                    } else {
                        this.existe=false;
                    }
                } else {
                    this.existe=false;
                }               
            } catch (error) {
                this.existe=false;
                console.log(error)
            }
        }
    }
}
</script>
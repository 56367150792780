<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">COTIZACIÓN</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="2" md="1">
                                <div class="text-center">
                                    <v-btn @click="editar()" color="cyan" fab x-small dark><v-icon>edit</v-icon></v-btn>                                    
                                </div>
                            </v-col>
                            <v-col cols="10" md="3">
                                <v-text-field class='text-caption' v-model="orden" :counter="15" label="Numero Orden" :disabled="soloOrden" :filled="soloOrden" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="10" md="3">
                                <v-select :hint="rutCliente" persistent-hint class='text-caption' v-model="Cliente" :items="itemCliente" label="Cliente" dense outlined disabled filled></v-select>
                                <!--<v-combobox v-if="pcotiza===true" @change="numeroCliente_V1(Cliente)" :hint="rutCliente" persistent-hint class='text-caption' v-model="Cliente" :items="itemCliente" label="Cliente" dense outlined :disabled="disabledOrden" :filled="filledOrden"></v-combobox>
                                 <v-text-field class='text-caption' v-model="Cliente" :hint="rutCliente" label="Cliente" disabled filled outlined dense></v-text-field> -->
                            </v-col>
                            <v-col cols="10" md="1">
                                <v-btn v-if="pcotiza===true" @click="proDialog=true" class="mt-1" small color="indigo" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                                <v-btn v-else class="mt-1" small color="grey" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                            </v-col>
                            <v-col cols="2" md="1">
                                <v-btn v-if="pcotiza===true" @click="solicitaOrden(Cliente)" class="mt-1" small color="teal" dark rounded><v-icon small>add</v-icon></v-btn>
                                <v-btn v-else @click="buscaOrden(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Orden Existe !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nueva Orden !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    Selecciona Datos !!!
                                </v-alert>
                            </v-col >
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-2">
                            <v-col cols="12" md="8">
                                <v-row>
                                    <v-col cols="2" md="1" style="margin-top:-10px">
                                        <v-checkbox class="float-end" @click="CheckBarra()" v-model="checkbox" :disabled="disabledDatos"></v-checkbox>
                                    </v-col>
                                    <v-col cols="10" md="3">
                                        <!-- <v-combobox id="barr" v-on:change="search_BarraItem(listabarra)" class='text-caption' :items="itemProductos" v-model="listabarra" item-text="bar"  label="Codigo Barra" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-combobox> -->
                                        <v-text-field id="barr" v-on:change="search_BarraItem(listabarra)" class='text-caption' @keypress="onlyNumber" v-model="listabarra" label="Cod. Barra" outlined dense :disabled="disabledCheck" :filled="filledCheck"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="7">
                                        <v-combobox :hint="SkuItem" persistent-hint v-on:change="search_SkuItem(listaproducto)" class='text-caption' v-model="listaproducto" :items="itemProductos" item-text="name"  label="Selecciona Producto" dense outlined :disabled="disabledBarra" :filled="filledBarra"></v-combobox>
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-btn class="mt-1" @click="inventa=true" color="cyan" fab dark x-small><v-icon>search</v-icon></v-btn>                                    
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class='text-caption' v-model="precio" label="Precio" outlined dense disabled filled></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select class='text-caption' v-on:change="stockSku(bodega)" v-model="bodega" :items="itemBodegas" label="Bodega" dense outlined :hint="tStock" persistent-hint :disabled="disabledDatos" :filled="filledDatos"></v-select>                                
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <!-- <v-btn @click="saveVenta()" class="mr-5" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                                        <v-btn @click="saveVenta()" class="mt-1" color="teal" fab x-small :disabled="disabledDatos" dark><v-icon small>done_all</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-alert class='text-caption' dense text border="left" color="grey">Neto: ${{subtotal.toLocaleString()}}</v-alert> 
                                        <div style="margin-top:-4%"><span class="text-caption">&nbsp;Desc.{{descuentobase}}%</span></div>
                                        <v-alert class='text-caption' dense text border="left" color="grey">Desc.: ${{descuento.toLocaleString()}}</v-alert> 
                                        <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva.toLocaleString()}}</v-alert>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-row>
                                            <v-col cols="6" md="6">
                                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="pordescuento" label="%" dense outlined :disabled="disabledDesc" :filled="filledDesc"></v-text-field>
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <div><v-icon @click="mas()" class="ml-1 red--text" :disabled="disabledDesc">arrow_drop_up</v-icon></div>
                                                <div class="mt-n1"><v-icon @click="menos()" class="ml-1 red--text" :disabled="disabledDesc">arrow_drop_down</v-icon></div>
                                            </v-col>
                                            <v-col cols="6" md="3" v-if="disabledDesc==false">
                                                <v-btn @click="addDescuento(pordescuento)" class="mt-1" color="teal" dark fab x-small :disabled="disabledDesc"><v-icon small>check</v-icon></v-btn>
                                            </v-col>
                                        </v-row>                                       
                                        <v-alert class='text-caption' dense text border="left" color="grey">Afecto: ${{afecto.toLocaleString()}}</v-alert>
                                        <v-alert class='text-caption' dense text border="left" color="red">Total: ${{total.toLocaleString()}}</v-alert>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-15px">
                <v-btn @click="dialogVentas()" color="indigo" fab dark>{{nVentas}}</v-btn>
                <h3 class="ml-2 text-caption">Total Items</h3>



                <v-btn class="ml-5 mr-5" color="teal" icon
                        v-clipboard:copy="copiar" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        <v-icon>content_copy</v-icon>
                </v-btn>



                <v-spacer></v-spacer>
                <v-btn @click="newVenta()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
            </v-card-actions>
        </v-card>

        <!-- dialog modal -->

        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer></v-spacer>
                <a target="_blank" :href="html" style="text-decoration: none"><v-icon>print</v-icon></a>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <v-icon small @click="deleteVenta(item.id)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" class='mt-3'>
                        <a class="mt-2 ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img src="@/assets/csv.png" width="16px" height="20px"/></a>
                        <a class="ml-2" v-else :href="rutaCSV" style="text-decoration: none"><v-icon color='teal'>cloud_download</v-icon><span class="ml-2 text-caption">Descarga</span></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- dialog inventario -->
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="90%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :loading="progress1" loading-text="Cargando....." @click:row="handleClick" dense dark :search="likesearch" :headers="head" :items="desserts" :page.sync="page" :items-per-page="itemsPerPage"  @page-count="pageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount"  :total-visible="5"></v-pagination>
                <!-- <v-simple-table fixed-header height="300px" dark dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                            <th>Sku</th>
                            <th>Producto</th>
                            <th>Referencia</th>
                            <th>Modelo</th>
                            <th>Neto</th>
                            <th>Venta</th>
                            <th>Stock1</th>
                            <th>Stock2</th>
                            </tr>
                        </thead>
                        <tbody style="font-size:8pt;">
                            <tr @click="selectOption(item)" v-for="item in desserts" :key="item.name">
                            <td style="font-size:9pt;width:10%">{{ item.tSku }}</td>
                            <td style="font-size:9pt;width:50%">{{ item.tNombre }}</td>
                            <td style="font-size:9pt;width:10%">>{{ item.tReferencia }}</td>
                            <td style="font-size:9pt;width:10%">{{ item.tModelo }}</td>
                            <td style="font-size:9pt;width:5%">{{ item.tDetalle }}</td>
                            <td style="font-size:9pt;width:5%">{{ item.tVenta }}</td>
                            <td style="font-size:9pt;width:5%">{{ item.tStock1 }}</td>
                            <td style="font-size:9pt;width:5%">{{ item.tStock2 }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table> -->
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' @keyup="onlySku" v-model="onSku" label="SKU" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' @keyup="onlyProducto" v-model="onProducto" label="Producto" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' @keyup="onlyModelo" v-model="onModelo" label="Referencia" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- dialog proveedores --> 
        <v-dialog v-model="proDialog" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="proDialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="proSearch" label="Buscar" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table :loading="progress" loading-text="Cargando....." @click:row="proClick" dense dark :search="proSearch" :headers="proTitulo" :items="proLista" :page.sync="proPage" :items-per-page="proItemsPerPage"  @page-count="proPageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="proPage" :length="proPageCount"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>

        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            progress:false,
            progress1:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,        
            pcotiza:true,
            hoy:'',
            //contrato:'123',
            observa:'',
            tStock:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',
            disabledOrden:false,
            filledOrden:false,
            disabledDatos:true,
            filledDatos:true,
            disabledCheck:true,
            filledCheck:true,
            disabledDesc:true,
            filledDesc:true,
            disabledBarra:true,
            filledBarra:true,
            soloOrden:true,
            btnGrabar:true,
            orden:'',
            itemCliente:[],
            arrayCliente:[],
            Cliente:'',
            listaproducto:'',
            listabarra:'',
            listanombre:'',
            listacodigo:'',
            itemProductos:[],
            bodega:'',
            itemBodegas:[],
            arrayBodegas:[],
            precio:'',
            cantidad:'',
            radios:'Neto',
            total:0,
            nCliente:0,
            nProducto:0,
            nBodega:0,
            cv:'V',
            nVentas:0,
            // dialog Ventas
            dialog:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            items: [],
            itemsPrecio:['Precio Detalle','Precio x Mayor','Precio Oferta'], 
            miprecio:'',
            html:'',
            descargaCSV:false,
            rutaCSV:'',
            SkuItem:'',
            nStock:0,
            copiar:'',
            checkbox:false,
            descuento:0,
            pordescuento:0,
            descuentobase:0,
            impuesto:0,
            iva:0,
            subtotal:0,
            afecto:0,
            rutCliente:'',
            head: [
                { text: 'Sku', align: 'start', sortable: true, value: 'tSku' },
                { text: 'Referencia', align: 'start', sortable: true, value: 'tCodigos' },
                { text: 'Producto', align: 'start', sortable: true, value: 'tNombre' },
                { text: 'Modelo', align: 'start', sortable: true, value: 'tModelo' },
                //{ text: '($) Neto', align: 'center', sortable: true, value: 'tDetalle' },
                { text: '($) Venta', align: 'center', sortable: true, value: 'tVenta' },
                { text: 'Stock1', align: 'center', sortable: true, value: 'tStock1' },
                { text: 'Stock2', align: 'center', sortable: true, value: 'tStock2' },
            ],
            desserts: [],
            inventa:false,
            onSku:'',
            onProducto:'',
            onModelo:'',
            ondesserts:[],
            newDescuento:0,
            //dialogo proveedores
            proDialog:false,
            proSearch:'',
            proPage: 1,
            proPageCount: 0,
            proItemsPerPage: 8,
            proTitulo:[
                { text: 'Rut', align: 'start', sortable: true, value: 'rutcli' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'nombrecli' }
            ],
            proLista:[],
            txtSucursal:'',
        }
    },
    async mounted(){
        await this.buscarSucursal()
        await this.generaTabla();
        this.progress=true;
        await this.buscaCliente();
        this.progress=false;
        this.progress1=true;
        await this.buscarProducto();
        this.progress1=false;
        //await this.buscarBodegas()
        //console.log(this.usu);

    },
    methods:{
        async buscarSucursal(){
            this.txtSucursal='';
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if (datos[0].Sucursal!=0){
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        datos1.forEach(element => {
                            if(element.ID==datos[0].Sucursal){
                                this.txtSucursal=element.Nombre                            
                            }
                        });
                    }
                }
            }
        },
        handleClick(txtOpcion) {
            //console.log(txtOpcion);
            this.listaproducto=txtOpcion.tNombre;
            this.listanombre=txtOpcion.tNombre;
            this.listacodigo=txtOpcion.tSku;
            this.SkuItem="Sku: "+this.listacodigo;
            this.precio=txtOpcion.precioDetalle;
            this.nProducto=txtOpcion.tSku;
            this.skuBodegas();
            this.inventa=false;
        },
        proClick(fila){
            //console.log(fila);
            this.Cliente=fila.rutcli;
            this.rutCliente=fila.nombrecli;
            this.numeroCliente_V1(this.Cliente);
            this.proDialog=false;
        },
        async generaTabla(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/datatable/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.desserts=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.desserts.push({
                    tSku: element.Sku,
                    tCodigos: element.Codigos,
                    tNombre: element.Nombre,
                    tModelo: element.Descripcion,
                    //tDetalle: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioLocal)),
                    tVenta:new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioVenta)),
                    tStock1: new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock1)),
                    tStock2: new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock2)),
                    precioDetalle:element.PrecioLocal,
                    precioVenta:element.PrecioVenta
                    })
                });
                this.ondesserts = this.desserts; // clonar array
            }
        },
        async addDescuento(txtDescuento){
            console.log(txtDescuento);
            this.disabledDatos=true;
            this.filledDatos=true;
            this.disabledDesc=true;
            this.filledDesc=true;
            this.disabledBarra=true;
            this.filledBarra=true;
            this.disabledCheck=true;
            this.filledCheck=true;
            if (txtDescuento==''){ txtDescuento='0' } 
            const params= {
                'contrato': this.contrato,
                'orden': this.orden,
                'pordescuento':txtDescuento
            }
            const response = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/new/descuento/'+this.contrato,params);
            const datos=response.data;
            if (datos=='si'){
                this.descuentobase=txtDescuento;
                this.reCalcular(this.subtotal);
                // let varDescuento=Math.round((parseInt(this.subtotal)*parseInt(txtDescuento))/100);            
                // this.descuento=varDescuento;
                // let varTotal=parseInt(this.total)-varDescuento;
                // this.total=varTotal;
                this.pordescuento=0;
                this.Msg='Descuento Aplicado';
                this.msgbox=true;
                this.color='green';
                this.showSnack();
            } else {
                this.pordescuento=0;
                this.Msg='Descuento NO Aplicado';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async fileCSV(){
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/fileCsv/cotiza/'+this.contrato+'/'+this.orden+'/'+this.nCliente);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){
                this.descargaCSV=true;
                this.rutaCSV='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+this.orden+'.csv';
            }
            this.loading=false;
        },
        editar(){
            this.newVenta();
            this.pcotiza=false;
            this.soloOrden=false;
        },

            onCopy: function (e) {
                this.Msg='Orden '+ e.text +' Copiada';
                this.msgbox=true;
                this.color='orange';
                this.showSnack();
            },
            onError: function (e) {
                this.Msg='Orden NO Copiada';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            },

        async buscaOrden(nOrden){
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/'+this.contrato+'/'+nOrden);
            const datos=response.data;
            if (datos[0].existe=='si'){
                this.existe='si' 
                this.soloOrden=true;
                this.disabledDatos=false;
                this.filledDatos=false;
                this.disabledDesc=false;
                this.filledDesc=false;
                this.disabledBarra=false;
                this.filledBarra=false;
                //this.Cliente=datos[0].Nombre;
                this.Cliente=datos[0].Rut;
                this.rutCliente=datos[0].Nombre;
                this.numeroCliente_V1(this.Cliente);
                let newTotal=0;
                this.nVentas=0;
                datos.forEach(element => {
                    newTotal=newTotal+parseInt(element.Total);
                    this.nVentas=parseInt(this.nVentas)+parseInt(element.Reg);
                });
                this.impuesto=datos[0].Iva;
                this.descuentobase=datos[0].Descuento;
                this.pordescuento=datos[0].Descuento;
                this.reCalcular(newTotal);
                this.copiar=''+nOrden;
            } else {
                this.existe='';
                this.Msg='Orden no Existe o con DTE';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        onlySku(){
            this.page=1;
            this.onProducto='';
            this.onModelo='';
            const letras=this.onSku.length;
            this.onSku=this.onSku.toUpperCase();
            this.desserts=[];
            this.desserts=this.ondesserts;
            this.desserts.forEach(element => {
                const parteSku=element.tSku.substring(0,letras);
                if (parteSku!=this.onSku){
                    this.desserts = this.desserts.filter(function(dat) {
                        return dat.tSku !== element.tSku; 
                    });
                }             
            });
        },
        onlyProducto(){
            this.page=1;
            this.onSku='';
            this.onModelo='';
            const letras=this.onProducto.length;
            this.onProducto=this.onProducto.toUpperCase();
            this.desserts=[];
            this.desserts=this.ondesserts;
            this.desserts.forEach(element => {
                const parteNombre=element.tNombre.substring(0,letras);
                if (parteNombre!=this.onProducto){
                    this.desserts = this.desserts.filter(function(dat) {
                        return dat.tNombre !== element.tNombre; 
                    });
                }             
            });
        },
        onlyModelo(){
            this.page=1;
            this.onSku='';
            this.onProducto='';
            const letras=this.onModelo.length;
            this.onModelo=this.onModelo.toUpperCase();
            this.desserts=[];
            this.desserts=this.ondesserts;
            this.desserts.forEach(element => {
                const parteModelo=element.tCodigos.substring(0,letras);
                if (parteModelo!=this.onModelo){
                    this.desserts = this.desserts.filter(function(dat) {
                        return dat.tCodigos !== element.tCodigos; 
                    });
                }             
            });
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        dialogVentas(){
            if (this.nCompras!=0){
                this.dialog=true;
                this.descargaCSV=false;
                this.rutaCSV='';
                this.datosDialog()
            }
        },
        async datosDialog(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/'+this.cv+'/'+this.contrato+'/'+this.orden+'/'+this.nCliente);
            const datos=response.data;
            let newTotal;
            newTotal=0;
            this.items=[];
            if (datos[0].existe=='si'){   
                this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/cotiza/'+this.contrato+'/'+this.orden+'/'+this.nCliente;            
                datos.forEach(element => {
                    this.items.push({
                        'id':element.ID,
                        'sku':element.Sku,
                        'producto':element.Producto,
                        'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                        'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                        'bodega':element.Bodega,
                    })
                    newTotal=newTotal+(parseInt(element.Precio)*parseInt(element.Cantidad));                     
                });
            }
            this.reCalcular(newTotal);
        },
        async deleteVenta(n){
            this.loading=true;
            const response = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/delete/'+this.contrato+'/'+n);
            const datos=response.data;
            if (response.data=='ok'){
                await this.datosDialog()
                this.nVentas=this.nVentas-1
            }           
            this.loading=false;
        },
        clsInput(){
            this.tStock='';
            this.SkuItem='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.disabledDatos=true;
            this.filledDatos=true;
            this.disabledCheck=true;
            this.filledCheck=true;
            this.disabledDesc=true;
            this.filledDesc=true;
            this.disabledBarra=true;
            this.filledBarra=true;
            this.soloOrden=true; 
            this.listaproducto='';    
            this.listabarra='';       
            this.listanombre='';
            this.listacodigo='';
            this.precio='';
            this.cantidad='';
            this.observa='';
            this.nCliente=0;
            this.nProducto=0;
            this.bodega='';
            this.nBodega=0;
            this.existe='';
            this.nVentas=0;
            this.dialog=false;
            this.items=[];
            this.pcotiza=true;
            this.hoy='';
            this.html='';
            this.miprecio='';
            this.precio='';
            this.descargaCSV=false;
            this.rutaCSV='';
            this.nStock=0;
            this.copiar='';
            this.checkbox=false;
            this.descuento=0;
            this.pordescuento=0;
            this.descuentobase=0;
            this.impuesto=0;
            this.iva=0;
            this.subtotal=0;
            this.afecto=0;
            //this.rutCliente='';
            this.onSku='';
            this.onProducto='';
            this.onModelo='';
        },
        newVenta(){
            this.orden='';
            this.Cliente='';
            this.rutCliente='';
            this.total=0;
            this.clsInput()
        },
        async buscaCliente(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/lista/clientes/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemCliente=[];
            this.arrayCliente=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemCliente.push(element.Rut);
                    this.arrayCliente.push({id:element.ID,nombrecli:element.Nombre,rutcli:element.Rut,descli:element.Descuento})        
                });
                this.proLista=this.arrayCliente;
            }
        },
        async buscarProducto(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/productos/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemProductos=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemProductos.push({"name":element.Nombre,"code":element.Sku,"bar":element.Barra,"price":element.PrecioLocal});
                });
            }
        },
        async buscarBodegas(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemBodegas=[];
            this.arrayBodegas=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemBodegas.push(element.Nombre);
                    this.arrayBodegas.push({numBodega:element.ID,nameBodega:element.Nombre})        
                });
            }
        },
        CheckBarra(){
            this.listaproducto='';
            this.listanombre='';
            this.listacodigo='';
            this.SkuItem='';
            this.precio='';
            this.nProducto=0;
            if (this.checkbox===false){
                this.disabledCheck=true;
                this.filledCheck=true;
                this.disabledBarra=false;
                this.filledBarra=false;
                this.listabarra='';
            } else {
                this.disabledCheck=false;
                this.filledCheck=false;
                this.disabledBarra=true;
                this.filledBarra=true;
            }
        },
        search_SkuItem(itemPro){ // variable Global
            //console.log(this.listaproducto);
            this.listanombre=itemPro.name;
            this.listacodigo=itemPro.code;
            this.precio=itemPro.price;
            this.SkuItem="Sku: "+this.listacodigo;
            this.nProducto=this.listacodigo;
            this.skuBodegas();
        },
        search_BarraItem(codBarra){ // variable Global
            //console.log(codBarra);
            this.listaproducto='';
            this.listanombre='';
            this.listacodigo='';
            this.SkuItem='';
            this.precio='';
            this.nProducto=0;
            var elemento = document.getElementById("barr");
            elemento.blur();
            const resArray = this.itemProductos.find( datoArray => datoArray.bar === codBarra );
            if (resArray){
                this.listaproducto=resArray.name;
                this.listanombre=resArray.name;
                this.listacodigo=resArray.code;
                this.SkuItem="Sku: "+this.listacodigo;
                this.precio=resArray.price;
                this.nProducto=this.listacodigo;
                this.skuBodegas();
            }
        },
        numeroCliente(txtCliente){
            this.arrayCliente.forEach(element => {
                if (txtCliente==element.nombrecli){
                    this.nCliente=element.id;
                    this.rutCliente="Rut: "+element.rutcli;
                    this.pordescuento=element.descli;
                    this.descuentobase=element.descli;
                }
            });
        },
        numeroCliente_V1(rCliente){
            this.arrayCliente.forEach(element => {
                if (rCliente==element.rutcli){
                    this.nCliente=element.id;
                    this.rutCliente=element.nombrecli;
                    this.pordescuento=element.descli;
                    this.descuentobase=element.descli;
                }
            });
        },
        numeroBodega(txtBodega){
            this.arrayBodegas.forEach(element => {
                if (txtBodega==element.nameBodega){
                    this.nBodega=element.numBodega
                }
            });
        },
        async stockSku(a){
            console.log(this.arrayBodegas);
            this.cantidad='';
            await this.numeroBodega(a);
            if (this.nBodega!=0){
                await this.totalStock();
            } else {
                this.tStock='Error de Bodega';
            }
        },
        async totalStock(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ this.nBodega + '/' + this.nProducto);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.tStock='Total Stock: '+datos[0].Total;
                this.nStock=datos[0].Total;
            }
            if (datos[0].existe=='no'){ 
                this.tStock='Sin Stock';
                this.nStock=0;
            } 
            if (datos[0].existe=='error'){ 
                this.tStock='Error de Stock';
                this.nStock=0;
            } 
        },
        async solicitaOrden(tCliente){
            this.clsInput();
            this.loading=true;
            const timestamp = Date.now();
            this.copiar=''+timestamp;
            //console.log(timestamp);
            this.orden=timestamp;
            const nOrden=timestamp;
            this.total=0;
            if (Number.isInteger(nOrden/1)){
                if (tCliente.trim()!=''){
                    const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/impuesto/'+ this.contrato);
                    const datos=response.data;
                     if (datos[0].existe=='si'){ 
                        this.impuesto=datos[0].Iva;
                        this.numeroCliente_V1(tCliente);
                        this.disabledOrden=true;
                        this.filledOrden=true;
                        this.disabledDatos=false;
                        this.filledDatos=false;
                        this.disabledBarra=false;
                        this.filledBarra=false;
                        this.existe='no'                        
                     } else {
                        this.Msg='Iva NO considerado'
                        this.msgbox=true;
                        this.color='info';
                        this.showSnack();
                        this.newVenta()
                        this.existe='' 
                     }
                } else {
                    this.Msg='Seleccione Cliente'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
                    this.newVenta()
                    this.existe=''                        
                }
            } else {
                this.Msg='Orden no Numerica'
                this.msgbox=true;
                this.color='info';
                this.showSnack();
                this.newVenta()
                this.existe=''                        
            }
            this.loading=false;
        },
        async skuBodegas(){
            this.bodega='';
            this.itemBodegas=[];
            this.arrayBodegas=[];
            this.nBodega=0;
            this.tStock='';
            this.cantidad='';
            this.observa='';
            this.miprecio='';
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/bodegas/producto/'+ this.contrato +'/'+ this.nProducto);
            const datos=response.data;
            console.log(datos);
            datos.forEach(element => {
                if(element.Nombre==this.txtSucursal){
                    this.itemBodegas.push(element.Nombre);
                    this.arrayBodegas.push({numBodega:element.ID,nameBodega:element.Nombre})        
                }
            });
        },
        saveVenta(){
            this.loading=true;
            if (this.contrato!='' && this.orden!='' && this.Cliente!='' && 
                this.bodega!='' && this.cantidad!='' && this.precio!=''){
                if (this.nProducto!=0 && this.nCliente!=0 && this.nBodega!=0 && this.listanombre!=''){
                    //this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/cotiza/orden/'+this.contrato+'/'+this.orden;
                    if(this.nStock>=1 && this.nStock>=parseInt(this.cantidad)){
                        this.save();
                    } else {
                        this.Msg='Producto Sin Stock'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Intentelo Nuevamente'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Datos sin Completar'
                this.msgbox=true;
                this.color='info';
                this.showSnack();
            }
            this.loading=false;
        },
        menos(){
            this.pordescuento=parseFloat(this.pordescuento);
            if (isNaN(this.pordescuento)) { this.pordescuento=0 };
            var descuentoDown = parseFloat(this.pordescuento)-0.01;
            this.pordescuento=Number(descuentoDown.toFixed(2));
            this.descuentobase=this.pordescuento;
            this.reCalcular(this.subtotal);
        },
        mas(){
            this.pordescuento=parseFloat(this.pordescuento);
            if (isNaN(this.pordescuento)) { this.pordescuento=0 };
            var descuentoUp = parseFloat(this.pordescuento)+0.01;
            this.pordescuento=Number(descuentoUp.toFixed(2));
            this.descuentobase=this.pordescuento;
            this.reCalcular(this.subtotal);
        },
        reCalcular(varMonto){
            let varDescuento=Math.round((varMonto*parseFloat(this.descuentobase))/100);            
            let varAfecto=varMonto-varDescuento;
            let nIva=Math.round((varAfecto*parseInt(this.impuesto))/100);
            this.subtotal=varMonto
            this.descuento=varDescuento;
            this.afecto=varAfecto;
            this.iva=nIva;
            this.total=varAfecto+nIva;
        },
        async save(){
            var parametros={
                'contrato': this.contrato,
                'orden': this.orden,
                'entidad': this.nCliente,
                'tipo': this.radios,
                'cv': this.cv,
                'sku':this.nProducto,
                'producto':this.listanombre,
                'precio':this.precio,
                'cantidad':this.cantidad,
                'descuento':this.descuentobase,
                'bodega': this.nBodega,
                'observa':this.observa,
                'usuario':this.usu,
                'referencia':'0'};
            const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/compraventa/nueva',parametros);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){ 
                this.existe='si';    
                this.Msg='Producto Grabado !!!'
                this.msgbox=true;
                this.color='success';
                this.showSnack();
                this.disabledDatos=false;
                this.filledDatos=false;
                this.disabledDesc=false;
                this.filledDesc=false;
                this.btnGrabar=false;
                this.nVentas++;

                // recalculamos el total
                let newTotal=parseInt(this.subtotal)+(parseInt(this.precio)*parseInt(this.cantidad));
                this.reCalcular(newTotal);

                this.listanombre='';
                this.listaproducto='';
                this.listacodigo='';
                this.miprecio='';
                this.precio='';
                this.bodega='';
                this.tStock='';
                this.SkuItem='';
                this.cantidad='';
                this.observa='';
                this.observa='';
                this.listabarra='';
                //enviamos el foco si check es true
                if(this.checkbox===true){
                    var elemento = document.getElementById("barr");
                    elemento.focus();
                }
            } 
            if (datos=='error'){
                this.Msg='Conexion Inestable, intentelo mas tarde'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
                this.newVenta();
                this.existe=''  
            }         
        }
    }
}
</script>
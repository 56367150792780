<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">WEB</v-card-title>
            <v-card-subtitle class="blue-grey white--text">Integracion</v-card-subtitle>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="sku"  :counter="15" label="SKU" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1"><v-btn @click="verHtml(sku)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn></v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    No Existe !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">Sku: 99999</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="7">
                                <v-container class="text-center">
                                    <h2>{{nombre}}</h2>
                                </v-container>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-container class="text-center">
                            <v-col cols="12" md="12">
                                <h3>Link de Integración</h3>
                            </v-col>
                            <v-col cols="12" md="12">                                
                                <div style="width:100%;height:50px;border: 2px solid #BDBDBD;background-color:#F5F5F5">
                                    <v-container>
                                    <a target="_blank" :href="html">{{html}}</a>
                                    </v-container>
                                </div>
                            </v-col>
                            </v-container>
                        </v-row>
                    </v-container>
            </v-card-text>
            <v-card-actions>
                <span class="text-caption grey--text">(*) Copia el Link en tu sitio web.</span>
                <v-spacer></v-spacer>
                    <v-btn @click="newWeb()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name:'WebProducto',
    props: ['usu','contrato'], 
    data(){
        return{
            //contrato:'123',
            html:'',
            sku:'',
            existe:'',
            api:'',
            nombre:'',
        }
    },
    methods:{
        newWeb(){
            this.html='';
            this.sku='';
            this.existe='';
            this.api='';
            this.nombre=''
        },
        async verHtml(nsku){
            if(nsku.trim()!=''){
                this.api='https://www.transervi.cl/Api_TranServi/public/api/inv/producto/integracion/web/'+this.contrato+'/'+nsku;
                const response= await axios.get(this.api);
                if(response.data!='no'){
                    const response1= await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/producto/integracion/web/'+this.contrato+'/'+nsku);
                    //this.html=this.api;
                    this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/producto/integracion/web/'+this.contrato+'/'+nsku;
                    this.existe='si';
                    this.nombre=response1.data[0].Nombre
                } else {
                    this.html='';
                    this.existe='no';
                    this.nombre=''
                }
            }
        }
    }
}
</script>
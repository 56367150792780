<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Cotización / Venta / Devolución</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Cotización / Venta / Devolución</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">
          <span>Cotizacion</span>
          <v-icon>{{CotizaIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Venta</span>
          <v-icon>{{VentaIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp2">
          <span>Devolucion</span>
          <v-icon>{{DevolucionIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
      
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
import Venta from '@/components/Venta.vue';
import Devolucion from '@/components/Devolucion.vue';
import Cotiza from '@/components/Cotiza.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Dte',
  components: { Cotiza, Venta, Devolucion ,dialogo},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      CotizaIcon:'monetization_on',
      VentaIcon:'monetization_on',
      DevolucionIcon:'monetization_on',
      IconDefault:'monetization_on',
      comp0:true,
      comp1:true,
      comp2:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
   methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_3_3!=0) {
          this.comp0=false;
        };
        if(datos[0].Permiso_3_2!=0) {
          this.comp1=false;
        };
        if(datos[0].Permiso_3_4!=0) {
          this.comp2=false;
        };
      } 
      this.moduloActivo();      
    },
    moduloActivo(){
      if (this.comp0!=true){
        this.componente='Cotiza';
        this.CotizaIcon=this.IconSelect;
        this.VentaIcon=this.IconDefault;
        this.DevolucionIcon=this.IconDefault;
        this.value=0
      } else {
        if (this.comp1!=true){
          this.componente='Venta';
          this.CotizaIcon=this.IconDefault;
          this.VentaIcon=this.IconSelect;
          this.DevolucionIcon=this.IconDefault;
          this.value=1
        } else {
          if (this.comp2!=true){
              this.componente='Devolucion';
              this.CotizaIcon=this.IconDefault;
              this.VentaIcon=this.IconDefault;
              this.DevolucionIcon=this.IconSelect;
              this.value=2
          } else {
            this.CotizaIcon=this.IconDefault;
            this.VentaIcon=this.IconDefault;
            this.DevolucionIcon=this.IconDefault;
          }
        }
      }
      this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Cotiza';
          this.CotizaIcon=this.IconSelect;
          this.VentaIcon=this.IconDefault;
          this.DevolucionIcon=this.IconDefault;
        break;
        case 1:
          vComponente='Venta';
          this.CotizaIcon=this.IconDefault;
          this.VentaIcon=this.IconSelect;
          this.DevolucionIcon=this.IconDefault;
        break;
        case 2:
          vComponente='Devolucion';
          this.CotizaIcon=this.IconDefault;
          this.VentaIcon=this.IconDefault;
          this.DevolucionIcon=this.IconSelect;
        break;
      }
      this.componente=vComponente;
    }
  }
}
</script>
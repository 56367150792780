<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">
                SOLICITUD
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row class="mt-5">
                            <v-col cols="8" md="2" class="form-space">
                                <v-text-field class='text-caption' v-model="sku" label="SKU" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <v-btn @click="verSku(sku)" class="mt-1" x-small color="teal" dark fab><v-icon>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="4" class="form-space">
                                <v-text-field class='text-caption' v-model="nombre" label="Nombre Producto" outlined dense disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="6" md="2" class="form-space">
                                <v-select class='text-caption' v-model="universal" :items="itemUniversal" item-text="codigo" label="Cod.Universal" dense outlined></v-select>
                            </v-col>
                            <v-col cols="8" md="2" class="form-space">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <v-btn class="ml-2 mt-1" @click="addItem()" fab x-small dark color="indigo"><v-icon>add</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="12" class="form-space">
                                <v-container>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-data-table dense dark :headers="detalle_head" :items="detalle_items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                                            <template v-slot:item.accion="{ item }">
                                                <v-icon @click="deleteItem(item.id)" small>delete</v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                    <v-col cols="12" md="12" class="form-space">
                                        <p class="text-center">
                                            <v-pagination v-model="page" :length="pageCount"></v-pagination>              
                                        </p>
                                    </v-col>
                                </v-row>                                
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-75px">
                <v-row>
                    <v-col cols="12" md="4" class="mt-1 text-right">
                        <strong class="text-body-2">N° Cotizacion Extranjera:</strong>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="numero" label="Numero" outlined dense disabled filled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <!-- <v-btn v-if="edit!=true" @click="searchSol()" class="mt-1" x-small color="teal" dark fab><v-icon>search</v-icon></v-btn>
                        <v-btn v-else @click="editar()" class="mt-1" x-small color="cyan" dark fab><v-icon>edit</v-icon></v-btn> -->
                        <v-btn @click="dialogo_importa=true" class="mt-1" x-small color="cyan" dark fab><v-icon>edit</v-icon></v-btn>
                    </v-col>
                </v-row>
                <!-- <strong class="ml-2">{{numero}}</strong> -->
                <v-spacer></v-spacer>
                <div class="mr-10">
                <v-btn class="ml-2" @click="newSol()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>        
                <v-btn v-if="modify!=true" class="ml-2" @click="newSave()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                <v-btn v-else class="ml-2" @click="newModify()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Modify</span></v-btn>
                <a v-if="linkReport!=''" target="_blank" :href="linkReport" class="ml-2"><v-icon color="teal">print</v-icon></a>
                <a v-else href="javascript:void(0)" class="ml-3"><v-icon class="grey--text">print</v-icon></a>
                </div>

            </v-card-actions>
        </v-card>

       <!-- dialog importaciones -->
        <v-dialog v-model="dialogo_importa" transition="dialog-bottom-transition" max-width="300" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialogo_importa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="fila_importa" dense dark :search="busca_importa" :headers="head_importa" :items="items_importa" :page.sync="page_importa" :items-per-page="PerPage_importa"  @page-count="pageCount_importa = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="page_importa" :length="pageCount_importa"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>

        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"/>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
.form-space {
    margin-top:-20px;
}
</style>
<script>
import axios from 'axios';
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
export default {
    name:'Importa', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            sku:'',
            nombre:'',
            universal:'',
            itemUniversal:[],
            cantidad:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            detalle_head:[
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Cod. Universal', align: 'center', sortable: true, value: 'universal' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            detalle_items:[],
            idItems:0,
            numero:0,
            edit:true,
            modify:false,
            //dialogo importa
            dialogo_importa:false,
            busca_importa:'',
            page_importa: 1,
            pageCount_importa: 0,
            PerPage_importa: 10,
            head_importa: [
                { text: 'Solicitud', align: 'center', sortable: true, value: 'solicitud' },
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
            ],
            items_importa: [],
            reportHTML:'',
            linkReport:'',
        }
    },
    async mounted(){
        await this.buscarImporta();
    },
    methods:{
        fila_importa(fila){
            //console.log(fila);
            this.numero=fila.solicitud;
            this.searchSol();
            this.dialogo_importa=false;
            this.modify=true;
        },
        async buscarImporta(){
            this.items_importa=[];
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/solicita/all/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.items_importa.push({
                            'solicitud':element.Solicita,
                            'fecha':element.Fecha
                            })        
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        async verSku(xSku){
            if(xSku.trim()!=''){
                this.loading=true;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/producto/'+this.contrato+'/'+xSku);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.nombre=datos[0].Nombre;
                    this.CodigoUniversal(xSku);
                } else {
                    this.Msg='Producto no Existe'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
                this.loading=false;
            } else {
                this.Msg='Ingese Sku'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async CodigoUniversal(xSku){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/codigo/universal/'+ this.contrato+'/'+xSku);
                const datos1=response.data;
                this.itemUniversal=[];
                if (datos1[0].existe=='si'){
                    datos1.forEach(element => {
                        this.itemUniversal.push({
                            'idcodigo': element.ID,
                            'codigo': element.Codigo
                        });
                    });
                } 
            } catch (error) {
                console.log(error);
            }
        },
        addItem(){
            if(this.sku.trim()!='' && this.cantidad!='' && this.universal!=''){
                this.linkReport='';
                this.idItems++
                this.detalle_items.push({
                    'id':this.idItems,
                    'sku': this.sku,
                    'universal': this.universal,
                    'producto':this.nombre,
                    'cantidad':this.cantidad,
                });
                this.clsproducts();
            } else {
                this.Msg='Datos Obligatorios'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        clsInput(){
            this.detalle_items=[];
            this.numero=0;
            this.linkReport='';
            this.clsproducts();
        }, 
        clsproducts(){
            this.sku='';
            this.nombre='';
            this.universal='';
            this.cantidad='';
            this.itemUniversal=[];
        },
        async saveExtranjero(){
            let res=0;
            const params ={
            'contrato':this.contrato,
            'usuario':this.usu,
            'estado':'V'
            }
            const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/extranjero/nuevo',params);
            const datos=response.data;
            if (datos=='si'){
                // leer numero
                const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/extranjero/desc/'+this.contrato);
                const datos1=response1.data;
                if (datos1[0].existe=='si'){
                    res=datos1[0].ID;
                } 
            }
            return res;
        },
        async save(num){
            //grabamos lista
            let filas=0;
            this.reportHTML='';
            //encabezado del html
            this.reportHTML=this.reportHTML+"<table width=100%>"
            this.reportHTML=this.reportHTML+"<tr>"
            this.reportHTML=this.reportHTML+"<td style='width:30%;border: 1px solid #000'><strong>&nbsp;&nbsp;N° Cotización Extranjera</strong></td>"
            this.reportHTML=this.reportHTML+"<td style='width:20%;border: 1px solid #000' align=center><strong>"+num+"</strong></td>"
            this.reportHTML=this.reportHTML+"<td style='width:50%'></td>"
            this.reportHTML=this.reportHTML+"</tr>"
            this.reportHTML=this.reportHTML+"<tr>"
            this.reportHTML=this.reportHTML+"<td style='width:30%;border: 1px solid #000'><strong>&nbsp;&nbsp;Fecha Cotización</strong></td>"
            this.reportHTML=this.reportHTML+"<td style='width:20%;border: 1px solid #000' align=center><strong>"+this.date1+"</strong></td>"
            this.reportHTML=this.reportHTML+"<td style='width:50%'></td>"
            this.reportHTML=this.reportHTML+"</tr>"
            this.reportHTML=this.reportHTML+"<tr><td>&nbsp;</td></tr>"
            this.reportHTML=this.reportHTML+"</table>"
            this.reportHTML=this.reportHTML+"<table width=100%>"
            this.reportHTML=this.reportHTML+"<td style='width:10%;border: 1px solid #000' align=center>SKU</td>"
            this.reportHTML=this.reportHTML+"<td style='width:20%;border: 1px solid #000' align=center>CODIGO</td>"
            this.reportHTML=this.reportHTML+"<td style='width:60%;border: 1px solid #000' align=center>PRODUCTO</td>"
            this.reportHTML=this.reportHTML+"<td style='width:10%;border: 1px solid #000' align=center>CANTIDAD</td>"
            for (let index = 0; index < this.detalle_items.length; index++) {
                const element = this.detalle_items[index];
                const params ={
                'contrato':this.contrato,
                'solicita':num,
                'sku':element.sku,
                'universal': element.universal,
                'producto': element.producto,
                'cantidad': element.cantidad,
                'usuario':this.usu,
                'fecha':this.date1
                }
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/solicita/nueva',params);
                const datos=response.data;
                if (datos=='si'){
                    filas++;
                    // detalle de la solicitud
                    this.reportHTML=this.reportHTML+"<tr>"
                    this.reportHTML=this.reportHTML+"<td align=center>"+element.sku+"</td>"
                    this.reportHTML=this.reportHTML+"<td align=center>"+element.universal+"</td>"
                    this.reportHTML=this.reportHTML+"<td>"+element.producto+"</td>"
                    this.reportHTML=this.reportHTML+"<td align=center>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.cantidad))+"</td>"
                    this.reportHTML=this.reportHTML+"</tr>"
                }
            } // fin for lista
            if (filas>0){ 
                this.numero=num;
                if (filas==this.detalle_items.length){
                    this.Msg='Proceso Exitoso';
                    this.color='success';
                    this.msgbox=true;
                    this.showSnack();
                    await this.saveReport();
                } else {
                    this.Msg='Cotización incompleta';
                    this.color='warning';
                    this.msgbox=true;
                    this.showSnack();
                }
            } else {
                this.Msg='inconsistencia Co. intente nuevamente';
                this.color='warning';
                this.msgbox=true;
                this.showSnack();
            }
        },
        deleteItem(n){
            //console.log(n);
            this.linkReport='';
            this.detalle_items = this.detalle_items.filter(function(dat) {
                return dat.id !== n; 
            });
        },
        newSol(){
            this.edit=true;
            this.modify=false;
            this.clsInput();
        },
        async editar(){
            this.edit=false;
            this.modify=true;
            this.clsInput();
        },
        async searchSol(){
            if (this.numero.trim()!=''){
                this.loading=true;
                this.detalle_items=[];
                this.reportHTML='';
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/solicitud/'+this.contrato+'/'+this.numero);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    //encabezado del html
                    this.reportHTML=this.reportHTML+"<table width=100%>"
                    this.reportHTML=this.reportHTML+"<tr>"
                    this.reportHTML=this.reportHTML+"<td style='width:30%;border: 1px solid #000'><strong>&nbsp;&nbsp;N° Cotización Extranjera</strong></td>"
                    this.reportHTML=this.reportHTML+"<td style='width:20%;border: 1px solid #000' align=center><strong>"+this.numero+"</strong></td>"
                    this.reportHTML=this.reportHTML+"<td style='width:50%'></td>"
                    this.reportHTML=this.reportHTML+"</tr>"
                    this.reportHTML=this.reportHTML+"<tr>"
                    this.reportHTML=this.reportHTML+"<td style='width:30%;border: 1px solid #000'><strong>&nbsp;&nbsp;Fecha Cotización</strong></td>"
                    this.reportHTML=this.reportHTML+"<td style='width:20%;border: 1px solid #000' align=center><strong>"+this.date1+"</strong></td>"
                    this.reportHTML=this.reportHTML+"<td style='width:50%'></td>"
                    this.reportHTML=this.reportHTML+"</tr>"
                    this.reportHTML=this.reportHTML+"<tr><td>&nbsp;</td></tr>"
                    this.reportHTML=this.reportHTML+"</table>"
                    this.reportHTML=this.reportHTML+"<table width=100%>"
                    this.reportHTML=this.reportHTML+"<td style='width:10%;border: 1px solid #000' align=center>SKU</td>"
                    this.reportHTML=this.reportHTML+"<td style='width:20%;border: 1px solid #000' align=center>CODIGO</td>"
                    this.reportHTML=this.reportHTML+"<td style='width:60%;border: 1px solid #000' align=center>PRODUCTO</td>"
                    this.reportHTML=this.reportHTML+"<td style='width:10%;border: 1px solid #000' align=center>CANTIDAD</td>"
                    datos.forEach(element => {
                        this.idItems++
                        this.detalle_items.push({
                            'id':this.idItems,
                            'sku': element.Sku,
                            'universal': element.Universal,
                            'producto':element.Producto,
                            'cantidad':element.Cantidad
                        });
                        // detalle de la solicitud
                        this.reportHTML=this.reportHTML+"<tr>"
                        this.reportHTML=this.reportHTML+"<td align=center>"+element.Sku+"</td>"
                        this.reportHTML=this.reportHTML+"<td align=center>"+element.Universal+"</td>"
                        this.reportHTML=this.reportHTML+"<td>"+element.Producto+"</td>"
                        this.reportHTML=this.reportHTML+"<td align=center>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad))+"</td>"
                        this.reportHTML=this.reportHTML+"</tr>"
                    });
                     await this.saveReport();
                    //this.edit=true;
                } else {
                    this.Msg='Solicitud no Existe'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                };
                this.loading=false;
            } else {
                this.Msg='Ingrese Solicitud'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async saveReport(){
            try {
                //borramos el reporte actual
                await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/caja/diaria/delete/'+this.contrato+'/'+this.date1+'/9999');
                //ingresamos nuevo reporte
                const params={
                    'fecha': this.date1,
                    'contrato':this.contrato,
                    'sucursal': 9999,
                    'html':this.reportHTML
                }
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/rpt/caja/diaria',params);
                const datos=response.data;
                if (datos=='si'){ 
                    console.log('ok');
                    this.linkReport='https://www.transervi.cl/Api_TranServi/public/api/inv/rpt/caja/diaria/vista/'+this.contrato+'/'+this.date1+'/9999';
                }
            } catch (error) {
                console.log(error)
            }
        },
        async newSave(){
            this.loading=true;
            if(this.detalle_items.length!=0){
                //buscar el id-Usuario
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
                const datos=response.data;
                let userID;
                let extID;
                let numID;
                if (datos[0].existe=='si'){
                    userID=datos[0].ID;
                    // grabar numero solicitud
                    extID= await this.saveExtranjero();
                    if (extID>0){
                        numID=userID+''+extID;
                        // grabar solicitud
                        this.save(numID);
                    } else {
                        this.Msg='inconsistencia Ex. intente nuevamente'
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();                    
                    }

                } else {
                    this.Msg='inconsistencia Us. intente nuevamente'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();                    
                }                
            } else {
                this.Msg='Lista sin Productos'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            };
            this.loading=false;
        },
        async newModify(){
            this.loading=true;
            if(this.detalle_items.length!=0){
                //eliminar solicitud
                const response = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/solicitud/delete/'+this.contrato+'/'+this.numero);
                const datos=response.data;
                if (datos=='ok'){
                    // grabar Solicitud
                    this.save(this.numero);
                } else {
                    this.Msg='inconsistencia Mo. intente nuevamente'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();                    
                }
            } else {
                this.Msg='Lista sin Productos'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            };
            this.loading=false;
        }
    }

}
</script>
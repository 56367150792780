<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">EGRESO</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="sku" label="SKU" :disabled="disabledSku" :filled="filledSku" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1"><v-btn @click="verSku(sku)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn></v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    no Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">Sku: 99999</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="nombre" label="Nombre Producto" disabled filled outlined dense ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="categoria" label="Categoria" disabled filled outlined dense ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="red"></v-divider>
                        <v-row class="mt-1">
                            <v-col cols="12" md="6">
                                <v-textarea rows="6" class='text-caption' no-resize outlined v-model="descripcion" label="Descripcion" disabled filled></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field class='text-caption' v-model="caracteristica" label="Caracteristica" outlined dense disabled filled></v-text-field>
                                        <v-select class='text-caption' v-on:change="stockSku(bodega)" v-model="bodega" :items="listabodega" label="Bodega" dense outlined :hint="tStock" persistent-hint :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field class='text-caption' v-model="preciolocal" label="Precio" outlined dense disabled filled></v-text-field>
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="10" md="10">
                                        <v-text-field style="margin-top:-20px" class='text-caption' v-model="observa" label="Observacion" outlined dense :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="2" md="2">
                                        <v-btn @click="addItems()" class="mt-n5" color="indigo" fab x-small dark><v-icon small>done_all</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-45px">
                <v-row>
                    <v-col cols="6" md="5">
                        <v-btn @click="dialogSalida()" color="indigo" fab dark>{{nSalidas}}</v-btn>
                        <span class="ml-2 text-caption">Total Items</span>
                    </v-col>
                    <v-col cols="6" md="3">
                         <v-switch  @change="alertaFolios(guia)"  v-model="guia" label="Guia Electronica"></v-switch>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="float-right mt-5 mb-2 mr-2">
                        <v-btn @click="limpiaAll()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                        <v-btn v-if="btnPrt===true" class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        <v-btn v-else @click="saveGUIA()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        <a  v-if="btnPrt===true" target="_blank" :href="html" class="ml-1"><v-icon color="teal">print</v-icon></a>
                        <a  v-else href="javascript:void(0)" class="ml-2"><v-icon class="grey--text">print</v-icon></a>
                        </div>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <!-- dialog modal -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dense dark :search="likesearch" :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <v-icon small @click="deleteItems(item.id)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- dialog alerta folios --> 
        <v-dialog v-model="dialogFolios" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialogFolios=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <div class="d-flex justify-center">
                <h3>Quedan <strong class="text-h5 red--text">( {{countfolios}} )</strong> Folios Disponibles</h3>
                </div>
            </v-card-text>
        </v-card>
        </v-dialog>

        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Egreso',  
    components:{dialogo, snack}, 
    props: ['usu','contrato'], 
    data(){
        return{
            //contrato:'123',
            tStock:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledBodega:false,
            filledBodega:false,
            disabledSku:false,
            filledSku:false,
            disabledDatos:true,
            filledDatos:true,
            existe:'',
            alertabodega:'',
            newbodega:'',
            dialog:false,
            listabodega:[],
            sku:'',
            nombre:'',
            categoria:'',
            ncategoria:0,
            descripcion:'',
            caracteristica:'',
            cantidad:'',
            bodega:'',
            nbodega:0,
            preciolocal:'',
            observa:'',

            nSalidas:0,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Precio', align: 'start', sortable: true, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Observacion', align: 'center', sortable: true, value: 'observa' },
                { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            items: [],
            idItems:0,
            saveOK:'SI',
            guiaOK:'SI',
            btnPrt:false,
            guia:false,
            html:'',
            idGuia:0,
            dateHoy: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            impuesto:0,
            numeroStock:0,
            //control de Folios
            dialogFolios:false,
            countfolios:0,
        }
    },
    methods:{
        async alertaFolios(tpdoc){
            console.log(tpdoc);
            var codSII=0
            if (tpdoc==true){
                codSII=52;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/alerta/folios/'+this.contrato+'/'+codSII);
                const datos=response.data;
                console.log(datos);
                if (datos[0].existe=='si'){
                    if (datos[0].Cuenta<=10){
                        //activa alerta
                        this.countfolios=datos[0].Cuenta;
                        this.dialogFolios=true;
                    }
                };
            }
        },
        async buscarImpuesto(){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/impuesto/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.impuesto=datos[0].Iva;
                }
            } catch (error) {
                console.log(error)
            }
        },
        addItems(){
            if(this.cantidad!=''){
                console.log(this.cantidad,this.numeroStock);
                if(parseInt(this.cantidad)>this.numeroStock){
                    this.Msg='Cantidad Superior a Stock'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                } else {
                    this.nSalidas ++;
                    this.items.push({
                        id:this.idItems,
                        sku:this.sku,
                        producto:this.nombre,
                        precio:this.preciolocal,
                        cantidad:this.cantidad,
                        observa:this.observa,
                        bodega:this.bodega,
                    });
                    this.idItems ++;
                    this.newIngreso();
                }
            }
        },
        dialogSalida(){
            if (this.nSalidas!=0){
                this.dialog=true;
            }
        },
        deleteItems(id){
            this.items = this.items.filter(function(dat) {
                return dat.id !== id; 
            });
            this.nSalidas --;
        },
        limpiaAll(){
            this.nSalidas=0;
            this.items=[];
            this.saveOK='SI';
            this.guiaOK='SI';
            this.btnPrt=false;
            this.html='';
            this.idGuia=0;
            this.newIngreso();
        },
        async saveGUIA(){
            if (this.items.length!=0){
                if (this.guia===true){
                    //console.log('emitir guia elecronica');
                    // obtenemos el token del contrato
                    let varApiKey='';
                    let varU='';
                    let varP='';
                    let varIdEmpresa='';
                    let numDocumento=0; // 0 folio automatico
                    // tipo de Venta
                    let TpDocumento='52'; // Guia de Despacho electronica
                    // obtener folio
                    const resFolio = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/folio/nuevo/'+this.contrato+'/'+TpDocumento);
                    const datoFolio = resFolio.data;
                    //console.log(datoFolio);
                    if (datoFolio[0].existe=='si'){
                        numDocumento=datoFolio[0].Folio;
                        // reservo folio
                        const parFolio={ 'numero':numDocumento,'estado':'P' };
                        const updateFolio = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio);
                        const dataFolio = updateFolio.data;
                        if (dataFolio=='si'){
                            //token
                            const resToken = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/apikey/access/'+this.contrato);
                            const respuesta = resToken.data;
                            //console.log(respuesta);
                            if (respuesta[0].existe=='si'){
                                const varApiKey=respuesta[0].Token;
                                const varU=respuesta[0].U;
                                const varP=respuesta[0].P;
                                const varIdEmpresa=respuesta[0].IdEmpresa;
                                const varRut=respuesta[0].Rut;
                                const varRazon=respuesta[0].Razon;
                                const varDireccion=respuesta[0].Direccion;
                                const varComuna=respuesta[0].Comuna;
                                const varCiudad=respuesta[0].Ciudad;
                                const varGiro=respuesta[0].Giro;
                                // completar formData
                                let InstFormData = new FormData();
                                InstFormData.append('tipodespacho' , 3);
                                InstFormData.append('indtraslado' , 1);
                                InstFormData.append('fchemis' , this.dateHoy);
                                InstFormData.append('rutrecep' , varRut);
                                InstFormData.append('rznsocrecep' , varRazon);
                                InstFormData.append('dirrecep' , varDireccion);
                                InstFormData.append('cmnarecep' , varComuna); 
                                InstFormData.append('ciudadrecep' , varCiudad);
                                InstFormData.append('idempresa' , varIdEmpresa);
                                InstFormData.append('girorecep' , varGiro);
                                // recorrer items
                                let reg=1;
                                let montoNeto=0;
                                let montoIva=0;
                                let montoTotal=0;
                                let valorIva=this.impuesto;
                                this.items.forEach(element => {
                                    InstFormData.append('indexe'+reg , 0);
                                    InstFormData.append('vlrcodigo'+reg , element.sku);
                                    InstFormData.append('tpocodigo'+reg , 'Sku');
                                    InstFormData.append('nmbitem'+reg , element.producto);
                                    //InstFormData.append('dscitem1' , 'Descripción');
                                    InstFormData.append('unmditem'+reg , 'UN');
                                    InstFormData.append('qtyitem'+reg , element.cantidad);
                                    InstFormData.append('prcitem'+reg , element.precio);                                
                                    //InstFormData.append('descuentopct1' , '0.0');
                                    InstFormData.append('montoitem'+reg , parseInt(element.cantidad)*parseInt(element.precio)); 
                                    montoNeto=montoNeto+(parseInt(element.cantidad)*parseInt(element.precio));
                                    reg ++;
                                });
                                InstFormData.append('preview' , '0');
                                InstFormData.append('rutemisor' , varP);
                                InstFormData.append('decodeutf8' , 1);
                                InstFormData.append('apikey' , varApiKey);
                                InstFormData.append('u' , varU);
                                InstFormData.append('p' , varP);
                                // x 2 copias
                                InstFormData.append('adt_nombre1' , 'Plantilla');
                                InstFormData.append('adt_valor1' , 'x2_dte_tpl');
                                InstFormData.append('mntneto' , montoNeto);
                                montoIva=Math.round((montoNeto*parseInt(valorIva))/100);                           
                                InstFormData.append('iva' , montoIva);                            
                                InstFormData.append('tasaiva' , valorIva);
                                montoTotal=montoNeto+montoIva;
                                InstFormData.append('mnttotal' , montoTotal);                            
                                InstFormData.append('folio' , numDocumento);  
                                InstFormData.append('tipodte' , TpDocumento);
                                let resDTE =[]; 
                                try {
                                    const response= await axios.post('http://dte.transervi.cl/ws_aces/generarDTE/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                                    resDTE=response.data;
                                    //console.log(resDTE);
                                    if (resDTE.mensaje!='OK'){
                                        this.Msg='DTE no enviado'
                                        this.msgbox=true;
                                        this.color='red';
                                        this.showSnack();
                                        //devolvemos el folio estado V
                                        const parFolio1={ 'numero':numDocumento,'estado':'V' };
                                        const updateFolio1 = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio1);
                                        const dataFolio1 = updateFolio1.data;
                                        if (dataFolio1=='si'){ console.log('folio devuelto') }
                                    } else {
                                        numDocumento=resDTE.folio;
                                        this.html=resDTE.url;
                                        this.btnPrt=true;
                                        await this.saveDespacho(numDocumento,this.html);
                                        //cambiamos el estado del folio exitoso
                                        const parFolio2={ 'numero':numDocumento,'estado':'N' };
                                        const updateFolio2 = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio2);
                                        const dataFolio2 = updateFolio2.data;
                                        if (dataFolio2=='si'){ console.log('folio exitoso') }
                                    }
                                } catch (error) {
                                    console.log(error)
                                };                        
                            } else {
                                this.Msg='Token Invalido'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } else {
                            this.Msg='Folio no reservado'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                        this.Msg='Sin folio para DTE'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                }
                if (this.guia===false){
                    //console.log('emitir guia interna');
                    const timestamp = Date.now();
                    this.idGuia=timestamp;
                    this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/guia/'+this.contrato+'/'+this.idGuia;
                    this.saveDespacho(this.idGuia,this.html);
                }
            } else {
                this.Msg='Sin Datos en la Lista'
                this.msgbox=true;
                this.color='error';
                this.showSnack();
            }
        },
        async saveDespacho(nGuia,rutaDocumento){
            for (let index = 0; index < this.items.length; index++) {
                const element = this.items[index];
                await this.numeroBodega(element.bodega);
                var parametros={
                    'contrato': this.contrato,
                    'sku': element.sku,
                    'cantidad': element.cantidad,
                    'signo': '0-',
                    'bodega': this.nbodega,
                    'usuario':this.usu,
                    'observa':element.observa,
                    'origen':'Egreso'
                };
                //grabamos stock
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros);
                const datos=response.data;
                if (datos!='si'){ this.saveOK='NO' };
                if (datos=='si'){
                    //grabamos guia
                    var parGuia={
                        'contrato': this.contrato,
                        'sku': element.sku,
                        'producto':element.producto,
                        'precio':element.precio,
                        'cantidad': element.cantidad,
                        'bodega': this.nbodega,
                        'usuario':this.usu,
                        'observa':element.observa,
                        'guia':nGuia,
                        'ruta':rutaDocumento
                    };
                    const res = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/guia',parGuia);
                    const dat=res.data;
                    //console.log(dat);
                    if (dat!='si'){ this.guiaOK='NO' };
                }
            };
            if (this.saveOK=='SI'){
                    if (this.guiaOK=='SI'){
                    this.Msg='Guia Guardada con Exito'
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                    this.newIngreso();
                    this.html=rutaDocumento;
                    this.btnPrt=true;
                    } else {
                    this.Msg='Stock Rebajado, Guia NO Guardada'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
                    }
            } else {
                this.Msg='Inconsistencia en Stock'
                this.msgbox=true;
                this.color='error';
                this.showSnack();
            }
        },


        // async saveAll(){
        //     if (this.items.length!=0){
        //         const timestamp = Date.now();
        //         this.idGuia=timestamp;
        //         for (let index = 0; index < this.items.length; index++) {
        //             const element = this.items[index];
        //             await this.numeroBodega(element.bodega);
        //             var parametros={
        //                 'contrato': this.contrato,
        //                 'sku': element.sku,
        //                 'cantidad': element.cantidad,
        //                 'signo': '0-',
        //                 'bodega': this.nbodega,
        //                 'usuario':this.usu,
        //                 'observa':element.observa,
        //                 'origen':'Egreso'
        //             };
        //             //grabamos stock
        //             const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros);
        //             const datos=response.data;
        //             if (datos!='si'){ this.saveOK='NO' };
        //             if (datos=='si'){
        //                 //grabamos guia
        //                 var parGuia={
        //                     'contrato': this.contrato,
        //                     'sku': element.sku,
        //                     'producto':element.producto,
        //                     'precio':element.precio,
        //                     'cantidad': element.cantidad,
        //                     'bodega': this.nbodega,
        //                     'usuario':this.usu,
        //                     'observa':element.observa,
        //                     'guia':this.idGuia
        //                 };
        //                 const res = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/guia',parGuia);
        //                 const dat=res.data;
        //                 console.log(dat);
        //                 if (dat!='si'){ this.guiaOK='NO' };
        //             }
        //         };
        //         if (this.saveOK!='SI' || this.guiaOK!='SI'){
        //             this.Msg='Inconsistencia en algunos productos, favor revisar'
        //             this.msgbox=true;
        //             this.color='info';
        //             this.showSnack();
        //         } else {
        //             this.Msg='Productos con stock actualizados.'
        //             this.msgbox=true;
        //             this.color='success';
        //             this.showSnack();
        //         };
        //         this.newIngreso();
        //         this.html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/guia/'+this.contrato+'/'+this.idGuia;
        //         this.btnPrt=true;
        //     } else {
        //         this.Msg='Sin Datos en la Lista'
        //         this.msgbox=true;
        //         this.color='info';
        //         this.showSnack();
        //     }
        // },

        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.nombre='';
            this.categoria='';
            this.ncategoria=0;
            this.descripcion='';
            this.caracteristica='';
            this.cantidad='';
            this.bodega='';
            this.preciolocal='';
            this.listabodega=[];
            this.tStock='';
            this.numeroStock=0;
            this.nbodega=0;
            this.observa=''
        },
        async verSku(xSku){
            this.loading=true;
            this.msgbox=false;
            this.clsInput();
            await this.buscarImpuesto();
            if(xSku.trim()!=''){
                this.disabledSku=true;
                this.filledSku=true;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/producto/'+this.contrato+'/'+xSku);
                const datos=response.data;
                //console.log(datos);
                if (datos[0].existe=='no'){
                    this.existe='no'
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newIngreso()
                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.sku=datos[0].Sku;
                    this.nombre=datos[0].Nombre;
                    this.ncategoria=datos[0].Categoria;
                    this.descripcion=datos[0].Descripcion;
                    this.caracteristica=datos[0].Caracteristica;
                    this.preciolocal=datos[0].PrecioLocal;
                    await this.nombreCategoria(this.ncategoria)
                    this.SelectBodegas();
                    this.restoDatos(false);
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        async nombreCategoria(numeroCategoria){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/categorias/'+ this.contrato);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                if(element.ID==numeroCategoria){
                    this.categoria=element.Nombre
                }
            });
        },


        // async saveIngreso(){
        //     this.loading=true;
        //     this.msgbox=false;
        //     if (this.contrato!='' && this.cantidad!='' && this.bodega!='' && this.nbodega!=0 &&
        //         this.nombre!='' && this.categoria!='' && this.descripcion!='' &&
        //         this.caracteristica!='' && this.preciolocal!='' && this.sku!=''){
        //         var parametros={
        //             'contrato': this.contrato,
        //             'sku': this.sku,
        //             'cantidad': this.cantidad,
        //             'signo': '0-',
        //             'bodega': this.nbodega,
        //             'usuario':this.usu,
        //             'observa':this.observa,
        //             'origen':'Egreso'};
        //         const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros);
        //         const datos=response.data;
        //         console.log(datos);
        //         if (datos=='si'){ 
        //             this.existe='si';               
        //             this.Msg='Datos del Producto Existoso !!!'
        //             this.msgbox=true;
        //             this.color='success';
        //             this.showSnack();
        //             this.totalStock();
        //         } 
        //         if (datos=='error'){
        //             this.Msg='Conexion Inestable, intentelo mas tarde'
        //             this.msgbox=true;
        //             this.color='red';
        //             this.showSnack();
        //             this.newIngreso()
        //         }         
        //     } else {
        //             this.Msg='Todos los datos obligatorios'
        //             this.msgbox=true;
        //             this.color='info';
        //             this.showSnack();
        //     }
        //     this.loading=false;
        // },


        newIngreso(){
            this.loading=true;
            //this.msgbox=false;
            this.sku='';
            this.clsInput();
            this.disabledSku=false;
            this.filledSku=false;
            this.existe='';
            this.loading=false;
            this.restoDatos(true);
        },
        async SelectBodegas(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/bodegas/producto/'+ this.contrato +'/'+ this.sku);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                this.listabodega.push(element.Nombre);
            });
        },
        async stockSku(a){
            this.cantidad='';
            await this.numeroBodega(a);
            if (this.nbodega!=0){
                await this.totalStock();
            } else {
                this.tStock='Error de Bodega';
            }
        },
        async numeroBodega(txtbodega){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/bodegas/'+ this.contrato);
            const datos=response.data;
            datos.forEach(element => {
                if(element.Nombre==txtbodega){
                    this.nbodega=element.ID
                }
            });
        },
        async totalStock(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ this.nbodega + '/' + this.sku);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.tStock='Total Stock:'+datos[0].Total;
                this.numeroStock=parseInt(datos[0].Total);
            }
            if (datos[0].existe=='no'){ 
                this.tStock='Sin Stock';
            } 
            if (datos[0].existe=='error'){ 
                this.tStock='Error de Stock';
            } 
        }
    }
}
</script>
<template>
    <div>
        <h3>Utilidad Mensual ({{Mes}}-{{Anio}})
          <v-progress-linear v-if="rueda==true" rounded height="2" indeterminate color="primary"></v-progress-linear>
        </h3>
        <v-divider></v-divider>
        <div class="mt-2">
        <v-data-table :headers="headerVentas" :items="itemVentas" class="elevation-1" hide-default-footer dark>
          <template v-slot:item.tPromedio="{ item }">
            <v-chip color="indigo" dark> {{ item.tPromedio }}</v-chip>
          </template>
        </v-data-table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'UtilidadMes',
  props:['usu','contrato'],
  data(){
    return {
      //contrato:'123',
      varVentas:0,
      varCompras:0,
      varUtilidad:0,
      varPromedio:0,
      //varResto:0,
      Mes:'',
      Anio:'',
      headerVentas:[
        {text: 'Compras ($)',align: 'center',sortable: false, value: 'tCompras'},
        {text: 'Ventas ($)',align: 'center',sortable: false, value: 'tVentas'},
        {text: 'Utilidad ($)',align: 'center',sortable: false, value: 'tUtilidad'},
        {text: 'Promedio Mes ($)',align: 'center',sortable: false, value: 'tPromedio'}
      ],
      itemVentas: [],
      rueda:true,
    }
  },
  async mounted(){
    this.Utilidades();
    // //const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cartera/utilidad/'+this.contrato);
    // const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cartera/utilidad/ventas/'+this.contrato);
    // const datos=response.data;
    // //console.log(datos);
    // if (datos[0].existe=='si'){
    //   this.Anio=datos[0].Anio
    //   switch (datos[0].Mes) {
    //     case '1':
    //       this.Mes='Ene';
    //       break;
    //     case '2':
    //       this.Mes='Feb';
    //       break;
    //     case '3':
    //       this.Mes='Mar';
    //       break;
    //     case '4':
    //       this.Mes='Abr';
    //       break;
    //     case '5':
    //       this.Mes='May';
    //       break;
    //     case '6':
    //       this.Mes='Jun';
    //       break;
    //     case '7':
    //       this.Mes='Jul';
    //       break;
    //     case '8':
    //       this.Mes='Ago';
    //       break;
    //     case '9':
    //       this.Mes='Sep';
    //       break;
    //     case '10':
    //       this.Mes='Oct';
    //       break;
    //     case '11':
    //       this.Mes='Nov';
    //       break;
    //     case '12':
    //       this.Mes='Dic';
    //       break;
    //   }
    //   this.varVentas=0;
    //   this.varCompras=0;
    //   for (let index = 0; index < datos.length; index++) {
    //     const element = datos[index];
    //     this.varVentas=this.varVentas+parseFloat(element.Total);
    //     await this.buscaCompra(element.Sku,element.Cantidad);
    //   }
    //   await this.PrintItem()
    // }  
    this.rueda=false;   
  },
  methods:{
    async Utilidades(){
      const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cartera/utilidad/ventas/mes/'+this.contrato);
      const datos=response.data;
      //console.log(datos);
      if (datos[0].existe=='si'){        
        this.Anio=datos[0].Fecha.substring(0,4)
        switch (datos[0].Fecha.substring(6,7)) {
          case '1':
            this.Mes='Ene';
            break;
          case '2':
            this.Mes='Feb';
            break;
          case '3':
            this.Mes='Mar';
            break;
          case '4':
            this.Mes='Abr';
            break;
          case '5':
            this.Mes='May';
            break;
          case '6':
            this.Mes='Jun';
            break;
          case '7':
            this.Mes='Jul';
            break;
          case '8':
            this.Mes='Ago';
            break;
          case '9':
            this.Mes='Sep';
            break;
          case '10':
            this.Mes='Oct';
            break;
          case '11':
            this.Mes='Nov';
            break;
          case '12':
            this.Mes='Dic';
            break;
        }
        this.varVentas=0;
        this.varCompras=0;
        this.varUtilidad=0;
        datos.forEach(element => {
          this.varVentas=this.varVentas+(parseFloat(element.Venta)*parseFloat(element.Cantidad));
          this.varCompras=this.varCompras+(parseFloat(element.Compra)*parseFloat(element.Cantidad));
          this.varUtilidad=this.varUtilidad+parseFloat(element.Utilidad);
        });
        this.varPromedio= Math.round(this.varUtilidad/12);
        this.itemVentas=[];
        this.itemVentas.push({
          tCompras:new Intl.NumberFormat("de-DE").format(this.varCompras),
          tVentas:new Intl.NumberFormat("de-DE").format(this.varVentas),
          tUtilidad:new Intl.NumberFormat("de-DE").format(this.varUtilidad),
          tPromedio:new Intl.NumberFormat("de-DE").format(this.varPromedio)
        })        
      }  
      this.rueda=false;   
    }
    // async PrintItem(){
    //   this.varUtilidad=this.varVentas-this.varCompras;
    //   this.varPromedio= Math.round(this.varUtilidad/12);
    //   this.itemVentas=[];
    //   this.itemVentas.push({
    //     tCompras:new Intl.NumberFormat("de-DE").format(this.varCompras),
    //     tVentas:new Intl.NumberFormat("de-DE").format(this.varVentas),
    //     tUtilidad:new Intl.NumberFormat("de-DE").format(this.varUtilidad),
    //     tPromedio:new Intl.NumberFormat("de-DE").format(this.varPromedio)})        
    // },
    // async buscaCompra(sku,cantidad){
    //   const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cartera/utilidad/compras/'+this.contrato+'/'+sku);
    //   const datos1=response1.data;
    //   //console.log(datos1);
    //   if (datos1[0].existe=='si'){
    //     this.varResto=parseFloat(cantidad);
    //     datos1.forEach(element => {
    //       if (this.varResto!=0){
    //         if (this.varResto<=parseFloat(element.Cantidad)){
    //           this.varCompras=this.varCompras+(this.varResto*parseFloat(element.Precio));
    //           this.varResto=0;
    //         } else {
    //           this.varCompras=this.varCompras+(this.varResto*parseFloat(element.Precio));
    //           this.varResto=this.varResto-parseFloat(element.Cantidad)
    //         }
    //       }
    //     });
    //   }
    // }
  }

}
</script>
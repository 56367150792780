<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="12" md="12">INFORME CREDITO</v-col>
                    <!-- <v-col cols="6" md="6">
                        <v-row class="mt-2 mr-2" justify="end">
                            <span class="text-caption">Suc.:<strong class="text-body-2 ml-2">{{txtSucursal}}</strong></span>
                        </v-row>
                    </v-col> -->
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-select @change="clsInput()" class='text-caption' v-model="ntipo" :items="tipo" label="Tipo" item-text="type" item-value="id" outlined rounded dense></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="mt-1">
                            <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip @click="OpenCalendar()" v-bind="attrs" v-on="on" outlined color="teal">
                                        <v-icon class="ml-2">events</v-icon>
                                            {{date1}}
                                    </v-chip>
                                </template>
                                <v-date-picker v-model="date1" @input="menu1 = false" no-title scrollable></v-date-picker>
                            </v-menu>                                        
                            <v-btn class="ml-2" @click="search_datos(ntipo)" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="mt-2">
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Efectivo :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(efectivo))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>T.Debito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(debito))}}</div></strong></v-alert> 
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>T.Credito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(credito))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Transferencia :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(transferencia))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="10" md="3">
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Cheques :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(cheque))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Credito Cta.Cte :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(ctacte))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense text border="left" color="grey"><strong>Notas de Credito :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(nota))}}</div></strong></v-alert>
                            <v-alert class='text-caption' dense dark border="left" color="green"><strong>Total :<div class="float-right">${{new Intl.NumberFormat("de-DE").format(parseFloat(total))}}</div></strong></v-alert>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event"></v-data-table>
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
                <v-card-actions style="margin-top:-25px">
                    <v-spacer></v-spacer>
                    <div v-if="linkReport!=''">
                        <a target="_blank" :href="linkReport" style="text-decoration: none">
                            <v-btn small color="teal" dark rounded><v-icon small>report</v-icon><span class='text-btn'>Reporte Diario</span></v-btn>
                        </a>
                    </div>
                    <div v-else>
                        <v-btn small color="grey" dark rounded><v-icon small>report</v-icon><span class='text-btn'>Reporte Diario</span></v-btn>
                    </div>
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'PagoInforme', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                { text: 'Nombre', align: 'start', sortable: false, value: 'nombre' },
                { text: 'Comprobante', align: 'center', sortable: false, value: 'numero' },
                { text: 'Total Pago', align: 'center', sortable: false, value: 'total' }
            ],
            items: [],
            efectivo:0,
            debito:0,
            credito:0,
            transferencia:0,
            cheque:0,
            ctacte:0,
            nota:0,
            total:0,
            tipo:[
                {id:1,type:'Cliente'},
                {id:2,type:'Proveedor'}
            ],
            ntipo:1,
            html:'',
            nombresHTML:'',
            pagosHTML:'',
            linkReport:''
        }
    },
    methods:{
        OpenCalendar(){
            this.menu1 = true;
            this.clsInput();
        },
        clsInput(){
            this.items=[];
            this.efectivo=0;
            this.debito=0;
            this.credito=0;
            this.transferencia=0;
            this.cheque=0;
            this.ctacte=0;
            this.nota=0;
            this.total=0;

            this.html='';
            this.linkReport='';
            this.nombresHTML='';
            this.pagosHTML='';
            this.html='';
        },
        async search_datos(type){
            this.loading=true;
            try {
                var txtTipo='';
                var txtCuenta='';
                if (type==1){ txtTipo='Cliente'; txtCuenta='Cobrar' }
                if (type==2){ txtTipo='Proveedor'; txtCuenta='Pagar' }
                //buscar todos los comprobantes
                var arrayComprobantes=[];
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/all/comprobantes/'+this.contrato+'/'+this.date1+'/'+this.date1+'/'+txtTipo);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        var nameHTML='';
                        arrayComprobantes.push({
                            rut:element.Rut,
                            nombre:element.Nombre,
                            numero:element.Numero,
                            total:new Intl.NumberFormat("de-DE").format(parseFloat(element.MontoPago))
                        });
                        //cargamos el reporte diario
                        nameHTML=`
                            <tr>
                                <td></td>
                                <td align='center'>`+element.Rut+`</td>
                                <td align='left'>`+element.Nombre+`</td>
                                <td align='center'>`+element.Numero+`</td>
                                <td align='right'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(element.MontoPago))+`</td>
                                <td></td>
                            </tr>
                        `;
                        this.nombresHTML=this.nombresHTML+nameHTML
                    });
                    //buscar pagos de los comprobantes
                    for (let index = 0; index < datos.length; index++) {
                        var payHTML='';
                        const element = datos[index];
                        const res = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/credito/clientes/search/pagos/'+this.contrato+'/'+element.Numero+'/'+txtCuenta);
                        const dat=res.data;
                        if (dat[0].existe=='si'){
                            for (let index = 0; index < dat.length; index++) {
                                const el = dat[index];
                                switch (el.FormaPago) {
                                    case 'Efectivo':
                                        this.efectivo=(this.efectivo+parseInt(el.Monto));
                                        this.total=(this.total+parseInt(el.Monto));
                                        break;
                                    case 'T.Debito':
                                        this.debito=this.debito+parseInt(el.Monto);
                                        this.total=this.total+parseInt(el.Monto);
                                        break;
                                    case 'T.Credito':
                                        this.credito=this.credito+parseInt(el.Monto);
                                        this.total=this.total+parseInt(el.Monto);
                                        break;
                                    case 'Transferencia':
                                        this.transferencia=this.transferencia+parseInt(el.Monto);
                                        this.total=this.total+parseInt(el.Monto);
                                        break;
                                    case 'Cheque':
                                        this.cheque=this.cheque+parseInt(el.Monto);
                                        this.total=this.total+parseInt(el.Monto);
                                        break;
                                    // case 'Credito Cta.Cte.':
                                    //     this.ctacte=this.ctacte+parseInt(element.Monto);
                                    //     this.total=this.total+parseInt(element.Monto);
                                    //     this.reportHTML=this.reportHTML+"<tr><td style='width:20%'>Crédito Cta.Cte.</td><td>"+new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto))+"</td></tr>"
                                    //     break;
                                    case 'Nota de Credito':
                                        this.nota=this.nota+parseInt(el.Monto);
                                        this.total=this.total-parseInt(el.Monto);
                                        break;
                                };                                                                               
                            };
                            //cargamos el reporte diario
                            payHTML=`
                                <tr>
                                    <td></td>
                                    <td align='center'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(this.efectivo))+`</td>
                                    <td align='center'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(this.debito))+`</td>
                                    <td align='center'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(this.credito))+`</td>
                                    <td align='center'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(this.transferencia))+`</td>
                                    <td align='center'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(this.cheque))+`</td>
                                    <td align='center'>$`+new Intl.NumberFormat("de-DE").format(parseFloat(this.nota))+`</td>
                                    <td></td>
                                </tr>
                            `;
                            this.pagosHTML=payHTML
                        }
                    }
                    this.items=arrayComprobantes;
                    await this.ReportDay();
                }
            } catch (error) {
             console.log(error)   
            }
            this.loading=false;
        },
        async ReportDay(){
            if(this.items.length!=0){
                const f = this.date1.toString();
                const fechaInfo = f.substring(8,10)+'-'+f.substring(5,7)+'-'+f.substring(0,4);
                var salida='';
                // nombres de clientes - proveedores 
                const titulo=`
                    <table width='100%' style='margin-top:50px'>
                    <tr>
                    <td align='center' style='width:100%;'><h3>PAGO CREDITO CLIENTES ( Fecha: `+fechaInfo+` )</h3></td>
                    </tr>
                    </table>
                    <table width='100%' style='margin-top:2px'>  
                        <tr>
                        <td style='width:20%'></td>
                        <td style='width:12%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Rut&nbsp;&nbsp;</b></td>
                        <td style='width:24%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Cliente&nbsp;&nbsp;</b></td>
                        <td style='width:12%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;N° Comprobante&nbsp;&nbsp;</b></td>
                        <td style='width:12%;background-color:#d3d3d3;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Total&nbsp;&nbsp;</b></td>
                        <td style='width:20%'></td>
                        </tr>
                `;
                salida=salida+titulo+this.nombresHTML+'</table>';
                //resumen de formas de pagos
                const titulo2=`
                    <table width='100%' style='margin-top:50px'>
                        <tr>
                            <td align='center' style='width:100%;'><h3>PAGOS</h3</td>
                        </tr>
                    </table>
                    <table width='100%' style='margin-top:2px'>  
                        <tr>
                        <td style='width:20%'></td>
                        <td style='width:10%;height:50px;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Efectivo&nbsp;&nbsp;</b></td>
                        <td style='width:10%;height:50px;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Tarjeta Debito&nbsp;&nbsp;</b></td>
                        <td style='width:10%;height:50px;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Tarjeta Credito&nbsp;&nbsp;</b></td>
                        <td style='width:10%;height:50px;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Transferencia&nbsp;&nbsp;</b></td>
                        <td style='width:10%;height:50px;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Cheque&nbsp;&nbsp;</b></td>
                        <td style='width:10%;height:50px;border:1px solid #000' align='center'><b>&nbsp;&nbsp;Nota de Credito&nbsp;&nbsp;</b></td>
                        <td style='width:20%'></td>
                        </tr>
                `;
                salida=salida+titulo2+this.pagosHTML+'</table>';
                //total
                var titulo3=`
                    <table width='100%' style='margin-top:50px'>
                    <tr>
                    <td style='width:20%'></td>
                    <td style='width:60%'><h3>Total Pagos:&nbsp;&nbsp;$`+new Intl.NumberFormat("de-DE").format(parseFloat(this.total))+`</h3></td>
                    <td style='width:20%'></td>
                    </tr>                    
                    </table>
                `
                salida=salida+titulo3;
                this.html=salida;
                await this.saveReport(salida);
            } else {
                this.Msg='Lista sin Datos'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async saveReport(HTML){
            var clave='';
            clave=await this.generateUUID();
            try {
                if (clave!=''){
                    //borramos todos los reportes del usuario
                    await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/rpt/html/'+this.contrato+'/'+this.usu);
                    //grabamos el reporte del usuario
                    const params={
                        'contrato':this.contrato,
                        'fecha': this.date1,
                        'clave':clave,
                        'html':HTML,
                        'usuario': this.usu
                    }
                    const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/rpt/html/',params);
                    const datos=response.data;
                    if (datos=='si'){ 
                        console.log('ok');
                        //rescatamos el reporte del usuario
                        const res1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/rpt/html/'+this.contrato+'/'+clave+'/'+this.usu);
                        const dat=res1.data;
                        if (dat!='no' && dat!='error'){
                            this.linkReport='https://www.transervi.cl/Api_TranServi/public/api/inv/rpt/html/'+this.contrato+'/'+clave+'/'+this.usu;
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async generateUUID() {
            var d = new Date().getTime();
            var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        }
    }
}
</script>
<template>
    <div>
        <v-simple-table fixed-header height="200px" dense>
            <thead>
                <tr>
                <th class="text-left" style="background-color: lightgrey;width:20%">
                    Codigo
                </th>
                <th class="text-left" style="background-color: lightgrey;width:30%">
                    Proveedor o Fabrica
                </th>
                <th style="width:50%"></th>
                </tr>
            </thead>
            <tbody v-if="existe===true" style="background-color:#B2EBF2">
                <tr v-for="item in desserts" :key="item.id">
                    <td style="font-size:9pt">{{ item.code }}</td>
                    <td style="font-size:9pt">{{ item.job }}</td>
                </tr>                
            </tbody>
            <tbody v-else-if="existe===false" style="background-color:#B2EBF2">
                <tr>
                    <td colspan="2" style="height:165px;text-align: center">
                        <span class="text-caption grey--text">No existe informacion asociada.</span>
                    </td>
                </tr>
            </tbody>
            <tbody v-else style="background-color:#B2EBF2">
                <tr>
                    <td colspan="2" style="height:165px;text-align: center">
                        <v-progress-circular :width="2" :size="20" indeterminate color="indigo"></v-progress-circular>
                        <span class="ml-2 text-caption grey--text">Buscando números de referencia ......</span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name:'GerenciaReferencia',
    props: ['usu','contrato','sku'], 
    data(){
        return{
            desserts: [],
            existe:null,
        }
    },
    mounted(){
        if(this.sku.trim()!=''){
            this.searchReferencia()
        } else {
            this.existe=false
        }
    },
    methods:{
        async searchReferencia(){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/codes/ref/'+ this.contrato +'/'+ this.sku);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    var ids=0;
                    datos.forEach(element => {
                        if(element.Sku.trim()!='' && element.Proveedor.trim()!=''){
                            ids++;
                            this.desserts.push({
                                'id':ids,
                                'code':element.Sku,
                                'job':element.Proveedor
                            })
                        }
                    });
                    this.existe=true;
                } else {
                    this.existe=false;
                }               
            } catch (error) {
                this.existe=false;
                console.log(error)
            }
        }
    }


}
</script>
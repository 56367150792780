<template>
    <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Reportes</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Reportes</h3> <!-- Mobile Screen -->
        <v-card class="mt-5" dark>
            <v-card-subtitle class="blue-grey">
                <v-row class="mt-1">
                    <v-col cols="12" md="3">
                        <v-select @change="limpiaTable()" class='text-caption' v-model="tipo" :items="tipoReporte" label="Tipo Reporte" dense outlined></v-select>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="date1" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledfecha"></v-text-field>
                            </template>
                            <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="date2" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined :disabled="disabledfecha"></v-text-field>
                            </template>
                            <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-btn @click="verReporte(tipo)" class="mt-1" small color="white" rounded><v-icon small class="blue-grey--text">search</v-icon></v-btn>
                        <a v-if="descargaCSV===false" href="javascript:void(0)" @click="listaCSV(tipo)" style="text-decoration: none">
                            <v-btn class="mt-1 ml-2" small color="white" rounded><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></v-btn>
                        </a>
                        <a v-else :href="descarga" style="text-decoration: none">
                            <v-btn class="mt-1 ml-2" small color="white" rounded><v-icon class="blue-grey--text">cloud_download</v-icon></v-btn>
                        </a>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <v-data-table :search="likesearch" :headers="headers" :items="itemsResult" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event" dense>
                    <template v-slot:item.ruta="{ item }">
                        <a target="_blank" :href="item.ruta"><v-icon class="white--text" small >print</v-icon></a>                        
                    </template>
                    <template v-slot:item.detalle="{ item }">
                        <v-btn @click="verDetalle(tipo,item)" dark text><v-icon class="white--text" small >description</v-icon></v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount" :total-visible="5"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Dato" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <!-- dialogo detalle -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="90%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer></v-spacer>
                <div v-if="tipo=='Solicitud Extranjera' || tipo=='Importaciones'">
                    <a v-if="descargaDetalleCSV===false" href="javascript:void(0)" @click="listaDetalleCSV(tipo)" style="text-decoration: none">
                        <v-btn class="mt-1 ml-2" small color="white" rounded><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></v-btn>
                    </a>
                    <a v-else :href="descargaDetalle" style="text-decoration: none">
                        <v-btn class="mt-1 ml-2" small color="white" rounded><v-icon class="blue-grey--text">cloud_download</v-icon></v-btn>
                    </a>
                </div>
                <div v-if="tipo=='Compras x Sku'">
                    <strong>{{titulo_detalle}}</strong>
                </div>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearchDialog" :headers="headersDetalle" :items="itemsDetalle" :page.sync="pageDialog" :items-per-page="itemsPerPageDialog" hide-default-footer class="elevation-1 text-size" @page-count="pageCountDialog = $event">
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="pageDialog" :length="pageCountDialog"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearchDialog" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"/>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 8.5pt !important;
    height: 30px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name: 'Reportes',
    components:{dialogo, snack},
    props: ['usu','contrato'],
    data(){
        return{
            fullReport:false,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            tipo:'',
            tipoReporte:[],
            headers: [],
            items: [],
            headersDetalle: [],
            itemsDetalle: [],
            dialog:false,
            itemsResult: [],
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            likesearchDialog:'',
            pageDialog: 1,
            pageCountDialog: 0,
            itemsPerPageDialog: 10,
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu2:false,
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            descarga:'javascript:void(0)',
            descargaCSV:false,
            disabledfecha:false,
            ReportException:[],
            descargaDetalle:'javascript:void(0)',
            descargaDetalleCSV:false,
            titulo_detalle:'',

        }
    },
    async mounted(){
        await this.entrada();
    },
    methods: {
        async listaDetalleCSV(tipo){
            if (this.itemsDetalle.length!=0){
                await this.csvDetalleReporte(tipo);
                this.descargaDetalleCSV=true;
            }
        },
        async listaCSV(tipo){
            console.log(tipo);
            if (this.items.length!=0){
                await this.csvReporte(tipo);
                this.descargaCSV=true;
            }
        },
        limpiaTable(){
            this.titulo_detalle='';
            this.headers= [];
            this.items= [];
            this.headersDetalle= [];
            this.itemsDetalle= [];
            this.itemsResult= [];
            this.descargaCSV=false;
            this.descarga='javascript:void(0)';
            this.descargaDetalleCSV=false;
            this.descargaDetalle='javascript:void(0)';
            //console.log(this.tipo);
             if(this.tipo=='Stock Critico' || 
                this.tipo=='Proveedores' ||
                this.tipo=='Clientes' ||
                this.tipo=='Usuarios' ||
                this.tipo=='Credito Cobranza' ||
                //this.tipo=='NC Proveedores' ||
                this.tipo=='Folios' ||
                this.tipo=='Importaciones' ||
                this.tipo=='Pago Proveedores' ||
                this.tipo=='Inventario' || 
                this.tipo=='Compras x Sku') {
                    this.disabledfecha=true;
             } else { this.disabledfecha=false }
        },
        async entrada(){
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
            const datos=response.data;
            this.tipoReporte=[];
            if (datos[0].existe=='si'){
                if (datos[0].Reporte!=0){
                    this.fullReport=true
                    //this.tipoReporte.push('Stock Critico');
                    //this.tipoReporte.push('Utilidad');
                    this.tipoReporte.push('Ventas x Tipo Pago');
                    this.tipoReporte.push('Credito Cobranza');
                    this.tipoReporte.push('Notas de Credito');
                    this.tipoReporte.push('NC Proveedores');
                    this.tipoReporte.push('Guias de Despacho');
                    this.tipoReporte.push('Pago Clientes');
                    this.tipoReporte.push('Pago Proveedores');
                    this.tipoReporte.push('Resumen Tipo Dte');
                    this.tipoReporte.push('Folios');
                    this.tipoReporte.push('Pago Credito Clientes');
                    this.tipoReporte.push('Pago Credito Proveedores');                    
                    this.tipoReporte.push('Comprobante Clientes');
                    this.tipoReporte.push('Comprobante Proveedores');
                    this.tipoReporte.push('Lista de Cheques');
                    this.ReportException.push('Lista de Cheques');
                } else {
                    this.fullReport=false
                };
                if (datos[0].Importa!=0){
                    // this.tipoReporte.push('Importaciones x Sku');
                    this.tipoReporte.push('Stock Critico');
                    this.tipoReporte.push('Solicitud Extranjera');
                    this.tipoReporte.push('Importaciones');
                    this.ReportException.push('Stock Critico');
                    this.ReportException.push('Solicitud Extranjera');
                    this.ReportException.push('Importaciones');
                    this.tipoReporte.push('Movimiento x Ventas');
                    this.ReportException.push('Movimiento x Ventas');
                };
                if(datos[0].Permiso_1_1!=0) {
                    this.tipoReporte.push('Inventario');
                    this.tipoReporte.push('Stock Critico');
                    this.tipoReporte.push('Movimiento x Ventas');
                    this.ReportException.push('Movimiento x Ventas');
                    this.tipoReporte.push('Compras x Sku');
                    this.ReportException.push('Compras x Sku');
                }; 
                if(datos[0].Permiso_4_1!=0 || datos[0].Permiso_4_2!=0 || datos[0].Permiso_4_3!=0) {
                    this.tipoReporte.push('Productos')
                    this.tipoReporte.push('Guias de Despacho');
                    this.ReportException.push('Guias de Despacho');
                }; 
                // if(datos[0].Permiso_4_3!=0 || datos[0].Permiso_4_3!=0) {
                //     this.tipoReporte.push('Guias de Despacho')
                // }; 
                if(datos[0].Permiso_3_1!=0) {
                    this.tipoReporte.push('Libro de Compras');
                    this.tipoReporte.push('Compras x Sku');
                    this.ReportException.push('Compras x Sku');
                    this.ReportException.push('Libro de Compras');
                };
                if(datos[0].Permiso_3_3!=0) {
                    this.tipoReporte.push('Cotizaciones')
                }; 
                if(datos[0].Permiso_3_2!=0) {
                    this.tipoReporte.push('Libro de Ventas')
                }; 
                if(datos[0].Permiso_3_4!=0) {
                    this.tipoReporte.push('Notas de Credito')
                }; 
                if(datos[0].Permiso_6_1!=0) {
                    this.tipoReporte.push('Proveedores')
                }; 
                if(datos[0].Permiso_6_2!=0) {
                    this.tipoReporte.push('Clientes')
                }; 
                if(datos[0].Permiso_6_3!=0) {
                    this.tipoReporte.push('Usuarios')
                }; 
                if(datos[0].Permiso_7_1!=0) {
                    this.tipoReporte.push('Credito Cobranza');
                    this.tipoReporte.push('Notas de Credito');
                    this.ReportException.push('Credito Cobranza');
                    this.ReportException.push('Notas de Credito');
                }; 
            }
            this.loading=false; 
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        async verDetalle(tipo,array){
            this.descargaDetalleCSV=false;
            this.headersDetalle=[];
            this.itemsDetalle=[];
            let response;
            let datos;
            let dia_incial;
            let dia_final;
            switch (tipo) {
                case 'NC Proveedores':
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/dev/proveedores/one/'+this.contrato+'/'+array.entidad+'/'+array.numero);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'center', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':element.Precio,
                                'cantidad':element.Cantidad
                            })        
                        });
                    }
                break
                case 'Importaciones':
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/importaciones/detalle/'+this.contrato+'/'+array.carpeta);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'center', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Universal', align: 'center', sortable: true, value: 'universal' },
                            { text: 'Auxiliar', align: 'center', sortable: true, value: 'auxiliar' },
                            { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'universal':element.Universal,
                                'auxiliar':element.Auxiliar,
                                'cantidad':element.Cantidad,
                                'precio':element.Precio
                            })        
                        });
                    }
                break
                case 'Solicitud Extranjera':
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/solicitud/extranjera/detalle/'+this.contrato+'/'+array.solicitud);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'center', sortable: true, value: 'sku' },
                            { text: 'Universal', align: 'center', sortable: true, value: 'universal' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' }
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'universal':element.Universal,
                                'producto':element.Producto,
                                'cantidad':element.Cantidad
                            })        
                        });
                    }
                break
                case 'Compras x Sku':
                    //fijamos fechas
                    this.dia_incial='2022-01-01';
                    this.dia_final= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/compras/sku/detalle/'+this.contrato+'/'+array.sku+'/'+this.dia_incial+'/'+this.dia_final);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.titulo_detalle='Sku: '+array.sku;
                        this.headersDetalle=[
                            { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                            // { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Tipo', align: 'start', sortable: true, value: 'tipo' },
                            { text: 'Numero', align: 'center', sortable: true, value: 'orden' },
                            { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor' },
                            { text: 'Cod. Proveedor', align: 'start', sortable: true, value: 'cod_proveedor' },
                            { text: 'Moneda', align: 'center', sortable: true, value: 'moneda' },
                            { text: 'Paridad', align: 'center', sortable: true, value: 'paridad' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'Descuento', align: 'center', sortable: true, value: 'descuento' },
                            { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'CLP Unitario', align: 'center', sortable: true, value: 'unitario' },
                            { text: 'CLP Compra', align: 'center', sortable: true, value: 'total' },
                            { text: 'Estado', align: 'start', sortable: true, value: 'estado' }
                        ];
                        datos.forEach(element => {
                            let varDesc=0
                            let varUnidad=0;
                            let varTotal=0;
                            if(element.Descuento!=0){ varDesc=element.Descuento };
                            // generar calculos
                            varUnidad=(parseFloat(element.Paridad)*parseFloat(element.Precio))-(((parseFloat(element.Paridad)*parseFloat(element.Precio))*varDesc)/100);
                            varTotal=varUnidad*parseFloat(element.Cantidad);
                            this.itemsDetalle.push({
                                'fecha':element.Fecha,
                                'tipo':element.Tipo,
                                'orden':element.Orden,
                                'proveedor':element.Proveedor,
                                'cod_proveedor':element.Cod_Proveedor,
                                'moneda':element.Moneda,
                                'paridad':element.Paridad,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'descuento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Descuento)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'unitario':new Intl.NumberFormat("de-DE").format(parseFloat(varUnidad)),
                                'total':new Intl.NumberFormat("de-DE").format(varTotal),
                                'estado':element.Estado,
                                // 'unitario':new Intl.NumberFormat("de-DE").format(parseFloat(element.Paridad)*parseFloat(element.Precio)),
                                // 'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Paridad)*parseFloat(element.Precio)*parseFloat(element.Cantidad))
                            })        
                        });
                    }
                break
                case 'Libro de Compras':
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/C/'+this.contrato+'/'+array.numero+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                            { text: 'Observacion', align: 'start', sortable: true, value: 'observa' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                                'observa':element.Observa,
                            })        
                        });
                    }
                break
                case 'Cotizaciones':
                    //console.log(array);
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/V/'+this.contrato+'/'+array.orden+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                            { text: 'Observacion', align: 'start', sortable: true, value: 'observa' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                                'observa':element.Observa,
                            })        
                        });
                    }
                break
                case 'Libro de Ventas':
                    //console.log(array);
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/V/'+this.contrato+'/'+array.orden+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                            })        
                        });
                    }
                break
                case 'Devoluciones':
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/D/'+this.contrato+'/'+array.numero+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                            })        
                        });
                    }
                break
                case 'Notas de Credito':
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/orden/entidad/D/'+this.contrato+'/'+array.numero+'/'+array.entidad);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.headersDetalle=[
                            { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                            { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                            { text: 'cantidad', align: 'center', sortable: true, value: 'cantidad' },
                            { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                        ];
                        datos.forEach(element => {
                            this.itemsDetalle.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                'bodega':element.Bodega,
                            })        
                        });
                    }
                break
            }
            this.dialog=true;
        },
        async csvDetalleReporte(tipo){
            console.log(tipo);
            this.loading=true;
            this.descargaDetalle='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            const nameFile = tipo+'_Detalle_'+formatDate(date);
            let csv;
            csv=[];
            let params;
            let response;
            switch (tipo) {
                case 'Importaciones':
                    //titulo
                    csv.push({
                        'sku':'Sku',
                        'producto':'Producto',
                        'universal':'Universal',
                        'auxiliar':'Auxiliar',
                        'cantidad':'Cantidad',
                        'precio':'Precio'
                    });
                    //datos
                    this.itemsDetalle.forEach(element => {
                        csv.push({
                            'sku':element.sku,
                            'producto':element.producto,
                            'universal':element.universal,
                            'auxiliar':element.auxiliar,
                            'cantidad':element.cantidad,
                            'precio':element.precio
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descargaDetalle='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break;
                case 'Solicitud Extranjera':
                    //titulo
                    csv.push({
                        'sku':'Sku',
                        'universal':'Universal',
                        'producto':'Producto',
                        'cantidad':'Cantidad'
                    });
                    //datos
                    this.itemsDetalle.forEach(element => {
                        csv.push({
                            'sku':element.sku,
                            'universal':element.universal,
                            'producto':element.producto,
                            'cantidad':element.cantidad
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descargaDetalle='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break;
            };
            this.loading=false;
        },
        async csvReporte(tipo){
            this.loading=true;
            this.descarga='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            const nameFile = tipo+'_'+formatDate(date);
            let csv;
            csv=[];
            let params;
            let response;
            //console.log(tipo);
            switch (tipo) {
                    case 'Comprobante Proveedores':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'rut':'Rut',
                            'cliente':'Proveedor',
                            'documento':'Tipo Documento',
                            'numero':'N° Documento',
                            'total':'Total Pago',
                            'saldo':'Saldo'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'rut':element.rut,
                                'cliente':element.cliente,
                                'documento':element.documento,
                                'numero':element.numero,
                                'total':element.total,
                                'saldo':element.saldo
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break;
                    case 'Comprobante Clientes':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'rut':'Rut',
                            'cliente':'Cliente',
                            'documento':'Tipo Documento',
                            'numero':'N° Documento',
                            'total':'Total Pago',
                            'saldo':'Saldo'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'rut':element.rut,
                                'cliente':element.cliente,
                                'documento':element.documento,
                                'numero':element.numero,
                                'total':element.total,
                                'saldo':element.saldo
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break;
                    case 'Pago Credito Proveedores':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'rut':'Rut',
                            'cliente':'Proveedor',
                            'documento':'Tipo Documento',
                            'numero':'N° Documento',
                            'total':'Total',
                            'glosa':'Tipo Pago',
                            'folio':'Comprobante'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'rut':element.rut,
                                'cliente':element.cliente,
                                'documento':element.documento,
                                'numero':element.numero,
                                'total':element.total,
                                'glosa':element.glosa,
                                'folio':element.folio
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break;
                    case 'Pago Credito Clientes':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'rut':'Rut',
                            'cliente':'Cliente',
                            'documento':'Tipo Documento',
                            'numero':'N° Documento',
                            'total':'Total',
                            'glosa':'Tipo Pago',
                            'folio':'Comprobante'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'rut':element.rut,
                                'cliente':element.cliente,
                                'documento':element.documento,
                                'numero':element.numero,
                                'total':element.total,
                                'glosa':element.glosa,
                                'folio':element.folio
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break;
                    case 'NC Proveedores':
                        //titulo
                        csv.push({
                            'contable':'Fecha Contable',
                            'fecha':'Fecha Emision',
                            'rut':'Rut',
                            'proveedor':'Proveedor',
                            'factura':'Factura',
                            'numero':'NC',
                            'neto':'Neto',
                            'iva':'Iva',
                            'total':'Total',
                            'pago':'Forma Pago',
                            'ref_pago':'Referencia',
                            'usuario':'Usuario',
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'contable':element.contable,
                                'fecha':element.fecha,
                                'rut':element.rut,
                                'proveedor':element.proveedor,
                                'factura':element.factura,
                                'numero':element.numero,
                                'neto':element.neto,
                                'iva':element.iva,
                                'total':element.total,
                                'pago':element.pago,
                                'ref_pago':element.ref_pago,
                                'usuario':element.usuario,
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break
                    case 'Compras x Sku':
                        //titulo
                        csv.push({
                            'sku':'Sku',
                            'universal':'Cod. Universal',
                            'producto':'Producto',
                            'venta':'Precio Venta'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'sku':element.sku,
                                'universal':element.universal,
                                'producto':element.producto,
                                'venta':element.venta,
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break
                    case 'Movimiento x Ventas':
                        //titulo
                        csv.push({
                            'sku':'Sku',
                            'producto':'Producto',
                            'venta':'Ventas',
                            'stock1':'Stock SB',
                            'stock2':'Stock RT'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'sku':element.sku,
                                'producto':element.producto,
                                'venta':element.venta,
                                'stock1':element.stock1,
                                'stock2':element.stock2
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break
                    case 'Credito Cobranza':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'sucursal':'Sucursal',
                            'rut':'Rut',
                            'cliente':'Cliente',
                            //'orden':'Orden',
                            'documento':'Documento',
                            'numero':'Numero',
                            //'tipopago':'Tipo Pago',
                            //'descuento':'Dcto.(%)',
                            //'neto':'Neto',
                            //'iva':'Iva',
                            'total':'Total',
                            'saldo':'Saldo',
                            //'usuario':'Usuario'
                            'estado':'Estado'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'sucursal':element.sucursal,
                                'rut':element.rut,
                                'cliente':element.cliente,
                                //'orden':element.orden,
                                'documento':element.documento,
                                'numero':element.numero,
                                //'tipopago':element.tipopago,
                                //'descuento':element.descuento,
                                //'neto':element.neto,
                                //'iva':element.iva,
                                'total':element.total,
                                'saldo':element.saldo,
                                //'usuario':element.usuario
                                'estado':element.estado
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break
                    case 'Ventas x Tipo Pago':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'sucursal':'Sucursal',
                            'cliente':'Cliente',
                            'numero':'Numero',
                            //'tipopago':'Tipo Pago',
                            'documento':'Documento',
                            'descuento':'Dcto. (%)',
                            'neto':'Neto',
                            'iva':'Iva',
                            'total':'Total',
                            'formapago':'Forma Pago',
                            'monto':'Monto',
                            // 'referencia':'Referencia',
                            'usuario':'Usuario'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'sucursal':element.sucursal,
                                'cliente':element.cliente,
                                'numero':element.numero,
                                //'tipopago':element.tipopago,
                                'documento':element.documento,
                                'descuento':element.descuento,
                                'neto':element.neto,
                                'iva':element.iva,
                                'total':element.total,
                                'formapago':element.formapago,
                                'monto':element.monto,
                                // 'referencia':element.referencia,
                                'usuario':element.usuario
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break
                    case 'Notas de Credito':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'cliente':'Cliente',
                            'tipo':'Documento',
                            'numero':'Numero',
                            'orden':'N.Orden',
                            'venta':'N.Venta',
                            'total':'Total',
                            'pago':'Pago',
                            'usuario':'Usuario',
                            'sucursal':'Sucursal'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'cliente':element.cliente,
                                'tipo':element.tipo,
                                'numero':element.numero,
                                'orden':element.orden,
                                'venta':element.venta,
                                'total':element.total,
                                'pago':element.pago,
                                'usuario':element.usuario,
                                'sucursal':element.sucursal
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break
                    case 'Guias de Despacho':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'numero':'Numero',
                            'productos':'Productos',
                            'total':'Total',
                            'usuario':'Usuario',
                            'observa':'Observa'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'numero':element.numero,
                                'productos':element.productos,
                                'total':element.total,
                                'usuario':element.usuario, 
                                'observa':element.observa
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break
                    case 'Utilidad':
                        //titulo
                        csv.push({
                            'fecha':'Fecha',
                            'sku':'Sku',
                            'producto':'Producto',
                            'cantidad':'Cantidad',
                            'compra':'Compra',
                            'venta':'Venta',
                            'devolucion':'Devolucion',
                            'utilidad':'Utilidad',
                            'sucursal':'Sucursal'
                        });
                        //datos
                        this.itemsResult.forEach(element => {
                            csv.push({
                                'fecha':element.fecha,
                                'sku':element.sku,
                                'producto':element.producto,
                                'cantidad':element.cantidad,
                                'compra':element.compra,
                                'venta':element.venta,
                                'devolucion':element.devolucion,
                                'utilidad':element.utilidad,
                                'sucursal':element.sucursal
                            })
                        });
                        params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                        response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                        if (response.data=='si'){
                            this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                        }
                    break
                case 'Stock Critico':
                    //titulo
                    csv.push({
                        'sku':'Sku',
                        'producto':'Producto',
                        'stock':'Stock',
                        'u12m':'U12M',
                        'pu12m':'PU12M',
                        'u30d':'U30D',
                        'u90d':'U180D',
                        'p3m':'P6M',
                        'critico':'Critico'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'sku':element.sku,
                            'producto':element.producto,
                            'stock':element.stock,
                            'u12m':element.u12m,
                            'pu12m':element.pu12m,
                            'u30d':element.u30d,
                            'u90d':element.u90d,
                            'p3m':element.p3m,
                            'critico':element.critico
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Productos':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'sku':'Sku',
                        'producto':'Producto',
                        'movimiento':'Movimiento',
                        'entidad':'Entidad',
                        'cantidad':'Cantidad',
                        //'precio':'Precio',
                        'bodega':'Bodega',
                        'usuario':'Usuario',
                        'observa':'Observa'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'sku':element.sku,
                            'producto':element.producto,
                            'movimiento':element.movimiento,
                            'entidad':element.entidad,
                            'cantidad':element.cantidad,
                            //'precio':element.precio,
                            'bodega':element.bodega,
                            'usuario':element.usuario,
                            'observa':element.observa
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Libro de Compras':
                    //titulo
                    csv.push({
                        'fecha':'Fecha Emision',
                        'rut':'Rut',
                        'proveedor':'Proveedor',
                        'numero':'Numero',
                        // 'productos':'Productos',
                        'neto':'Neto',
                        'exento':'Exento',
                        'impuesto':'Impuesto',
                        'iva':'Iva',
                        'total':'Total',
                        'usuario':'Usuario'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'rut':element.rut,
                            'proveedor':element.proveedor,
                            'numero':element.numero,
                            //'productos':element.productos,
                            'neto':element.neto,
                            'exento':element.exento,
                            'impuesto':element.impuesto,
                            'iva':element.iva,
                            'total':element.total,
                            'usuario':element.usuario
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Cotizaciones':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'orden':'Orden',
                        'productos':'Productos',
                        'total':'Total',
                        'usuario':'Usuario'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'orden':element.orden,
                            'productos':element.productos,
                            'total':element.total,
                            'usuario':element.usuario
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Libro de Ventas':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'rut':'Rut',
                        'cliente':'Cliente',
                        'tipo':'Documento',
                        'numero':'Numero',
                        'orden':'Orden',
                        'total':'Total',
                        'pago':'Pago',
                        'usuario':'Usuario',
                        'comision':'Comision',
                        'sucursal':'Sucursal'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'rut':element.rut,
                            'cliente':element.cliente,
                            'tipo':element.tipo,
                            'numero':element.numero,
                            'orden':element.orden,
                            'total':element.total,
                            'pago':element.pago,
                            'usuario':element.usuario,
                            'comision':element.comision,
                            'sucursal':element.sucursal
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Devoluciones':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'cliente':'Cliente',
                        'tipo':'Documento',
                        'numero':'Numero',
                        'orden':'N.Orden',
                        'venta':'N.Venta',
                        'total':'Total',
                        'pago':'Pago',
                        'usuario':'Usuario',
                        'sucursal':'Sucursal'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'cliente':element.cliente,
                            'tipo':element.tipo,
                            'numero':element.numero,
                            'orden':element.orden,
                            'venta':element.venta,
                            'total':element.total,
                            'pago':element.pago,
                            'usuario':element.usuario,
                            'sucursal':element.sucursal
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Proveedores':
                    //titulo
                    csv.push({
                        'rut':'Rut',
                        'nombre':'Nombre',
                        'email':'Email',
                        'ciudad':'Ciudad',
                        'estado':'Estado',
                        'observa':'Observacion'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'rut':element.rut,
                            'nombre':element.nombre,
                            'email':element.email,
                            'ciudad':element.ciudad,
                            'estado':element.estado,
                            'observa':element.observa
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Clientes':
                    //titulo
                    csv.push({
                        'rut':'Rut',
                        'nombre':'Nombre',
                        'email':'Email',
                        'ciudad':'Ciudad',
                        'estado':'Vigente',
                        'observa':'Observacion'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'rut':element.rut,
                            'nombre':element.nombre,
                            'email':element.email,
                            'ciudad':element.ciudad,
                            'estado':element.estado,
                            'observa':element.observa
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Usuarios':
                    //titulo
                    csv.push({
                        'login':'Login',
                        'nombre':'Nombre',
                        'email':'Email',
                        'comision':'Comision',
                        'estado':'Estado'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'login':element.login,
                            'nombre':element.nombre,
                            'email':element.email,
                            'comision':element.comision,
                            'estado':element.estado
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Pago Clientes':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'numero':'N° Documento',
                        'tipo':'Tipo Documento',
                        'entidad':'Cliente',
                        'pago':'Forma de Pago',
                        'referencia':'Referencia',
                        'monto':'Monto',
                        'rutch':'Rut CH',
                        'bcoch':'Banco CH',
                        'fecch':'Fecha CH',
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'numero':element.numero,
                            'tipo':element.tipo,
                            'entidad':element.entidad,
                            'pago':element.pago,
                            'referencia':element.referencia,
                            'monto':element.monto,
                            'rutch':element.rutch,
                            'bcoch':element.bcoch,
                            'fecch':element.fecch,
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Importaciones x Sku':
                    //titulo
                    csv.push({
                        'sku':'Sku',
                        'universal':'Universal',
                        'producto':'Producto',
                        'solicita':'Solicitadas',
                        'importa':'Importadas',
                        'integra':'Integradas',
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'sku':element.sku,
                            'universal':element.universal,
                            'producto':element.producto,
                            'solicita':element.solicita,
                            'importa':element.importa,
                            'integra':element.integra,
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Solicitud Extranjera':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'solicitud':'Solicitud',
                        'productos':'Productos',
                        'usuario':'Usuario'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'solicitud':element.solicitud,
                            'productos':element.productos,
                            'usuario':element.usuario
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Importaciones':
                    //titulo
                    csv.push({
                        'carpeta':'Carpeta',
                        'rut':'Rut',
                        'proveedor':'Proveedor',
                        'productos':'Productos',
                        'estado':'Estado'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'carpeta':element.carpeta,
                            'rut':element.rut,
                            'proveedor':element.proveedor,
                            'productos':element.productos,
                            'estado':element.estado
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Pago Proveedores':
                    //titulo
                    csv.push({
                        //'ingreso':'Fecha Ingreso',
                        'emision':'Fecha Emision',
                        //'contable':'Fecha Contable',
                        //'pago':'Fecha Pago',
                        'rut':'Rut',
                        'proveedor':'Proveedor',
                        'numero':'Numero Documento',
                        //'productos':'Cantidad de Productos',
                        'neto':'Neto',
                        'exento':'Exento',
                        'impuesto':'Impuesto',
                        'iva':'Iva',
                        'total':'Total',
                        'usuario':'Usuario'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            //'ingreso':element.ingreso,
                            'emision':element.emision,
                            //'contable':element.contable,
                            //'pago':element.pago,
                            'rut':element.rut,
                            'proveedor':element.proveedor,
                            'numero':element.numero,
                            //'productos':element.productos,
                            'neto':element.neto,
                            'exento':element.exento,
                            'impuesto':element.impuesto,
                            'iva':element.iva,
                            'total':element.total,
                            'usuario':element.usuario
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Resumen Tipo Dte':
                    //titulo
                    csv.push({
                        'tipodte':'Tipo DTE',
                        'formapago':'Forma Pago',
                        'unidades':'Total Documentos',
                        'netos':'Total Neto',
                        'ivas':'Total Iva',
                        'totales':'Total Pesos',
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'tipodte':element.tipodte,
                            'formapago':element.formapago,
                            'unidades':element.unidades,
                            'netos':element.netos,
                            'ivas':element.ivas,
                            'totales':element.totales,
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break;
                case 'Folios':
                    //titulo
                    csv.push({
                        'tipodte':'Codigo SII',
                        'dte':'Tipo DTE',
                        'actual':'Folio Actual',
                        'final':'Folio Final',
                        'disponibles':'Folios Disponibles'
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'tipodte':element.tipodte,
                            'dte':element.dte,
                            'actual':element.actual,
                            'final':element.final,
                            'disponibles':element.disponibles,
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Inventario':
                    //titulo
                    csv.push({
                        'tSku':'Sku',
                        'tCodigos':'Referencia',
                        'tNombre':'Producto',
                        'tModelo':'Modelo',
                        'tStock1':'Stock SB',
                        'tStock2':'Stock RT',
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'tSku':element.tSku,
                            'tCodigos':element.tCodigos,
                            'tNombre':element.tNombre,
                            'tModelo':element.tModelo,
                            'tStock1':element.tStock1,
                            'tStock2':element.tStock2
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
                case 'Lista de Cheques':
                    //titulo
                    csv.push({
                        'fecha':'Fecha',
                        'rut':'Rut',
                        'banco':'Banco',
                        'cuenta':'Cuenta',
                        'numero':'Numero',
                        'monto':'Monto',
                        'movimiento':'Movimiento',
                    });
                    //datos
                    this.itemsResult.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'rut':element.rut,
                            'banco':element.banco,
                            'cuenta':element.cuenta,
                            'numero':element.numero,
                            'monto':element.monto,
                            'movimiento':element.movimiento
                        })
                    });
                    params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                    response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                    if (response.data=='si'){
                        this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    }
                break
            }
            this.loading=false;
        },
        async verReporte(tipo){
            this.descarga='javascript:void(0)';
            this.descargaCSV=false;
            this.likesearch='';
            this.page=1;
            this.likesearchDialog='';
            this.pageDialog= 1;
            this.loading=true;
            let response;
            let datos;
            let dia_incial;
            let dia_final;
            if (tipo!=''){
                switch (tipo) {
                  case 'Lista de Cheques':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Banco', align: 'start', sortable: true, value: 'banco' },
                        { text: 'Cuenta', align: 'start', sortable: true, value: 'cuenta' },
                        { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                        { text: 'Monto', align: 'center', sortable: true, value: 'monto' },
                        { text: 'Movimiento', align: 'center', sortable: true, value: 'movimiento' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cheques/search/all/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if (parseInt(element.Productos)!=0){
                                this.items.push({
                                    'fecha':element.Fecha,
                                    'rut':element.Rut,
                                    'banco':element.NombreBanco,
                                    'cuenta':element.Cuenta,
                                    'numero':element.Numero,
                                    'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                                    'movimiento':element.Movimiento
                                })
                            }
                        });
                    }
                    break
                  case 'NC Proveedores':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Contable', align: 'start', sortable: true, value: 'contable' },
                        { text: 'Emision', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor' },
                        { text: 'Factura', align: 'start', sortable: true, value: 'factura' },
                        { text: 'NC', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Neto', align: 'center', sortable: true, value: 'neto' },
                        { text: 'Iva', align: 'center', sortable: true, value: 'iva' },
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Forma Pago', align: 'center', sortable: true, value: 'pago' },
                        { text: 'Referencia', align: 'center', sortable: true, value: 'ref_pago' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v2/dev/proveedores/all/report/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if (parseInt(element.Productos)!=0){
                                this.items.push({
                                    'entidad':element.Entidad,
                                    'contable':element.Fec_Contable,
                                    'fecha':element.Fec_Emision,
                                    'rut':element.Rut_Proveedor,
                                    'proveedor':element.Nombre_Proveedor,
                                    'factura':element.Compra,
                                    'numero':element.Numero,
                                    'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Afecto)),
                                    'iva':new Intl.NumberFormat("de-DE").format(parseFloat(element.Iva)),
                                    'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                    'usuario':element.Usuario,
                                    'pago':element.Nombre_Forma_Pago,
                                    'ref_pago':element.Referencia,
                                })
                            }
                        });
                    }
                    break
                  case 'Inventario':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Sku', align: 'start', sortable: true, value: 'tSku' },
                        { text: 'Referencia', align: 'start', sortable: true, value: 'tCodigos' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'tNombre' },
                        { text: 'Modelo', align: 'start', sortable: true, value: 'tModelo' },
                        { text: 'Stock1', align: 'center', sortable: true, value: 'tStock1' },
                        { text: 'Stock2', align: 'center', sortable: true, value: 'tStock2' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/datatable/'+this.contrato);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'tSku': element.Sku,
                                'tCodigos': element.Codigos,
                                'tNombre': element.Nombre,
                                'tModelo': element.Descripcion,
                                'tStock1': new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock1)),
                                'tStock2': new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock2)),
                            })
                        });
                    }
                    break
                  case 'Folios':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Codigo SII', align: 'center', sortable: true, value: 'tipodte' },
                        { text: 'Tipo DTE', align: 'start', sortable: true, value: 'dte' },
                        { text: 'Folio Actual', align: 'start', sortable: true, value: 'actual' },
                        { text: 'Folio Final', align: 'center', sortable: true, value: 'final' },
                        { text: 'Folios Disponibles', align: 'center', sortable: true, value: 'disponibles' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/folios/disponibles/'+this.contrato);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'tipodte':element.TipoDte,
                                'dte':element.Dte,
                                'actual':element.Actual,
                                'final':element.Final,
                                'disponibles':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad))
                            })
                        });
                    }
                    break
                  case 'Resumen Tipo Dte':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Tipo DTE', align: 'start', sortable: true, value: 'tipodte' },
                        { text: 'Forma Pago', align: 'start', sortable: true, value: 'formapago' },
                        { text: 'Total Documentos', align: 'center', sortable: true, value: 'unidades' },
                        { text: 'Total Neto', align: 'center', sortable: true, value: 'netos' },
                        { text: 'Total Iva', align: 'center', sortable: true, value: 'ivas' },
                        { text: 'Total Pesos', align: 'center', sortable: false, value: 'totales' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/dte/resumen/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'tipodte':element.TipoDte,
                                'formapago':element.TipoPago,
                                'unidades':element.Unidades,
                                'netos':new Intl.NumberFormat("de-DE").format(parseFloat(element.Netos)),
                                'ivas':new Intl.NumberFormat("de-DE").format(parseFloat(element.Ivas)),
                                'totales':new Intl.NumberFormat("de-DE").format(parseFloat(element.Totales))
                            })
                        });
                    }
                    break
                  case 'Pago Proveedores':
                    //fijamos fechas
                    this.dia_incial='2022-01-01';
                    this.dia_final= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        //{ text: 'F. Ingreso', align: 'start', sortable: true, value: 'ingreso' },
                        { text: 'F. Emision', align: 'start', sortable: true, value: 'emision' },
                        //{ text: 'F. Contable', align: 'start', sortable: true, value: 'contable' },
                        //{ text: 'F. Pago', align: 'start', sortable: true, value: 'pago' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        //{ text: 'Productos', align: 'start', sortable: true, value: 'productos' },
                        { text: 'Neto', align: 'center', sortable: true, value: 'neto' },
                        { text: 'Exento', align: 'center', sortable: true, value: 'exento' },
                        { text: 'Impuesto', align: 'center', sortable: true, value: 'impuesto' },
                        { text: 'Iva', align: 'center', sortable: true, value: 'iva' },
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/compra/credito/'+this.contrato+'/'+this.dia_incial+'/'+this.dia_final);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if (parseInt(element.Productos)!=0){
                                this.items.push({
                                    'entidad':element.Entidad,
                                    //'ingreso':element.Fecha,
                                    'emision':element.Emision,
                                    //'contable':element.Contable,
                                    //'pago':element.Fecha_Pago,
                                    'rut':element.Rut,
                                    'proveedor':element.Proveedor,
                                    'numero':element.Orden,
                                    'productos':element.Productos,
                                    'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Neto)),
                                    'exento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Exento)),
                                    'impuesto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Impuesto)),
                                    'iva':new Intl.NumberFormat("de-DE").format(parseFloat(element.Iva)),
                                    'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                    'usuario':element.Usuario
                                })
                            }
                        });
                    }
                    break
                  case 'Importaciones':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Carpeta', align: 'center', sortable: true, value: 'carpeta' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor' },
                        { text: 'Productos', align: 'center', sortable: true, value: 'productos' },
                        { text: 'Estado', align: 'center', sortable: true, value: 'estado' },
                        { text: 'Detalle', align: 'center', sortable: false, value: 'detalle' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/importaciones/'+this.contrato);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'carpeta':element.Carpeta,
                                'rut':element.Rut,
                                'proveedor':element.Proveedor,
                                'productos':element.Productos,
                                'estado':element.Estado
                            })
                        });
                    }
                    break
                  case 'Solicitud Extranjera':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                        { text: 'N° Solicitud', align: 'center', sortable: true, value: 'solicitud' },
                        { text: 'Productos', align: 'center', sortable: true, value: 'productos' },
                        { text: 'Usuario', align: 'start', sortable: true, value: 'usuario' },
                        { text: 'Detalle', align: 'center', sortable: false, value: 'detalle' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/solicitud/extranjera/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'fecha':element.Fecha,
                                'solicitud':element.Solicita,
                                'productos':element.Productos,
                                'usuario':element.Usuario,
                            })
                        });
                    }
                    break
                  case 'Importaciones x Sku':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                        { text: 'Universal', align: 'start', sortable: true, value: 'universal' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                        { text: 'Solicitadas', align: 'center', sortable: true, value: 'solicita' },
                        { text: 'Importadas', align: 'center', sortable: true, value: 'importa' },
                        { text: 'Integradas', align: 'center', sortable: true, value: 'integra' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/importacion/sku/'+this.contrato);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'sku':element.Sku,
                                'universal':element.Universal,
                                'producto':element.Producto,
                                'solicita':element.Solicitadas,
                                'importa':element.Importadas,
                                'integra':element.Integradas
                            })
                        });
                    }
                    break
                  case 'Pago Clientes':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'N° Documento', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Tipo Documento', align: 'start', sortable: true, value: 'tipo' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'entidad' },
                        { text: 'Forma de Pago', align: 'start', sortable: false, value: 'pago' },
                        { text: 'Referencia', align: 'start', sortable: false, value: 'referencia' },
                        { text: 'Monto', align: 'center', sortable: false, value: 'monto' },
                        { text: 'Rut CH', align: 'center', sortable: false, value: 'rutch' },
                        { text: 'Banco CH', align: 'center', sortable: false, value: 'bcoch' },
                        { text: 'Fecha CH', align: 'center', sortable: false, value: 'fecch' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/pago/clientes/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'fecha':element.Fecha,
                                'numero':element.NumeroDte,
                                'tipo':element.TipoDte,
                                'entidad':element.Entidad,
                                'pago':element.FormaPago,
                                'referencia':element.Referencia,
                                'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                                'rutch':element.Rut_CH,
                                'bcoch':element.Banco_CH,
                                'fecch':element.Fecha_CH,
                            })
                        });
                    }
                    break
                  case 'Pago Credito Clientes':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Tipo Documento', align: 'start', sortable: true, value: 'documento' },
                        { text: 'N° Documento', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Total', align: 'center', sortable: false, value: 'total' },
                        { text: 'Tipo Pago', align: 'center', sortable: false, value: 'glosa' },
                        { text: 'Comprobante', align: 'center', sortable: false, value: 'folio' },
                        { text: '', align: 'center', sortable: false, value: 'accion' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/pago/credito/clientes/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            var txtDocumento='';
                            var txtFolio='';
                            if(parseFloat(element.TipoDte)!=0){
                                txtDocumento=element.Documento;
                            } else {
                                txtDocumento='Comprobante'
                            };
                            if(parseFloat(element.Folio)!=0){
                                txtFolio=element.Folio;
                            } else {
                                txtFolio='--'
                            };
                            this.items.push({
                                'fecha':element.Fecha,
                                'rut':element.Rut,
                                'cliente':element.Cliente,
                                'entidad':element.Entidad,
                                'tipo':element.TipoDte,
                                'documento':txtDocumento,
                                'numero':element.Numero,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'valor':element.Total,
                                'glosa':element.Glosa,
                                'folio':txtFolio
                            })
                        });
                    }
                    break
                  case 'Pago Credito Proveedores':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Proveedor', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Tipo Documento', align: 'start', sortable: true, value: 'documento' },
                        { text: 'N° Documento', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Total', align: 'center', sortable: false, value: 'total' },
                        { text: 'Tipo Pago', align: 'center', sortable: false, value: 'glosa' },
                        { text: 'Comprobante', align: 'center', sortable: false, value: 'folio' },
                        { text: '', align: 'center', sortable: false, value: 'accion' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/pago/credito/proveedores/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            var txtDocumento='';
                            var txtFolio='';
                            if(parseFloat(element.TipoDte)!=0){
                                txtDocumento=element.Documento;
                            } else {
                                txtDocumento='Comprobante'
                            };
                            if(parseFloat(element.Folio)!=0){
                                txtFolio=element.Folio;
                            } else {
                                txtFolio='--'
                            };
                            this.items.push({
                                'fecha':element.Fecha,
                                'rut':element.Rut,
                                'cliente':element.Cliente,
                                'entidad':element.Entidad,
                                'tipo':element.TipoDte,
                                'documento':txtDocumento,
                                'numero':element.Numero,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'valor':element.Total,
                                'glosa':element.Glosa,
                                'folio':txtFolio
                            })
                        });
                    }
                    break
                  case 'Comprobante Clientes':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Tipo Documento', align: 'start', sortable: true, value: 'documento' },
                        { text: 'N° Documento', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Total Pago', align: 'center', sortable: false, value: 'total' },
                        { text: 'Saldo', align: 'center', sortable: false, value: 'saldo' },
                        { text: 'Ver', align: 'center', sortable: false, value: 'ruta' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/all/comprobantes/'+this.contrato+'/'+this.date1+'/'+this.date2+'/Cliente');
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            let html='https://www.transervi.cl/Api_TranServi/public/api/inv/html/comprobante/'+this.contrato+'/'+element.Numero+'/Cliente';
                            this.items.push({
                                'fecha':element.Fecha,
                                'rut':element.Rut,
                                'cliente':element.Nombre,
                                'documento':'Comprobante',
                                'numero':element.Numero,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.MontoPago)),
                                'saldo':new Intl.NumberFormat("de-DE").format(parseFloat(element.MontoSaldo)),
                                'ruta':html
                            })
                        });
                    }
                    break
                  case 'Comprobante Proveedores':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Proveedor', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Tipo Documento', align: 'start', sortable: true, value: 'documento' },
                        { text: 'N° Documento', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Total Pago', align: 'center', sortable: false, value: 'total' },
                        { text: 'Saldo', align: 'center', sortable: false, value: 'saldo' },
                        { text: 'Ver', align: 'center', sortable: false, value: 'ruta' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/all/comprobantes/'+this.contrato+'/'+this.date1+'/'+this.date2+'/Proveedor');
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            let html='https://www.transervi.cl/Api_TranServi/public/api/inv/html/comprobante/'+this.contrato+'/'+element.Numero+'/Proveedor';
                            this.items.push({
                                'fecha':element.Fecha,
                                'rut':element.Rut,
                                'cliente':element.Nombre,
                                'documento':'Comprobante',
                                'numero':element.Numero,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.MontoPago)),
                                'saldo':new Intl.NumberFormat("de-DE").format(parseFloat(element.MontoSaldo)),
                                'ruta':html
                            })
                        });
                    }
                    break
                //   case 'Pago Clientes':
                //     this.headers=[];
                //     this.items= [];
                //     this.headers=[
                //         { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                //         { text: 'N° Documento', align: 'center', sortable: true, value: 'numero' },
                //         { text: 'Tipo Documento', align: 'start', sortable: true, value: 'tipo' },
                //         { text: 'Cliente', align: 'start', sortable: true, value: 'entidad' },
                //         { text: 'Forma de Pago', align: 'start', sortable: false, value: 'pago' },
                //         { text: 'Referencia', align: 'start', sortable: false, value: 'referencia' },
                //         { text: 'Monto', align: 'center', sortable: false, value: 'monto' },
                //         { text: 'Rut CH', align: 'center', sortable: false, value: 'rutch' },
                //         { text: 'Banco CH', align: 'center', sortable: false, value: 'bcoch' },
                //         { text: 'Fecha CH', align: 'center', sortable: false, value: 'fecch' }
                //     ]
                //     response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/pago/clientes/'+this.contrato+'/'+this.date1+'/'+this.date2);
                //     datos=response.data;
                //     if (datos[0].existe=='si'){
                //         this.items= [];
                //         datos.forEach(element => {
                //             this.items.push({
                //                 'fecha':element.Fecha,
                //                 'numero':element.NumeroDte,
                //                 'tipo':element.TipoDte,
                //                 'entidad':element.Entidad,
                //                 'pago':element.FormaPago,
                //                 'referencia':element.Referencia,
                //                 'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                //                 'rutch':element.Rut_CH,
                //                 'bcoch':element.Banco_CH,
                //                 'fecch':element.Fecha_CH,
                //             })
                //         });
                //     }
                //     break
                  case 'Compras x Sku':
                    //fijamos fechas
                    this.dia_incial='2022-01-01';
                    this.dia_final= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                        { text: 'Cod. Universal', align: 'start', sortable: true, value: 'universal' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                        { text: 'Precio Venta', align: 'center', sortable: true, value: 'venta' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/compras/sku/'+this.contrato+'/'+this.dia_incial+'/'+this.dia_final);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'sku':element.Sku,
                                'universal':element.Universal,
                                'producto':element.Producto,
                                'venta':element.Venta,
                                'desde':this.date1,
                                'hasta':this.date2
                            })
                        });
                    }
                    break
                  case 'Movimiento x Ventas':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                        { text: 'Ventas', align: 'center', sortable: true, value: 'venta' },
                        { text: 'Stock SB', align: 'start', sortable: true, value: 'stock1' },
                        { text: 'Stock RT', align: 'start', sortable: true, value: 'stock2' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/movimiento/producto/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'venta':element.Ventas,
                                'stock1':element.Stock_SB,
                                'stock2':element.Stock_RT,
                            })
                        });
                    }
                    break
                  case 'Credito Cobranza':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        //{ text: 'Orden', align: 'start', sortable: true, value: 'orden' },
                        { text: 'Documento', align: 'start', sortable: false, value: 'documento' },
                        { text: 'Numero', align: 'start', sortable: false, value: 'numero' },
                        //{ text: 'Tipo Pago', align: 'start', sortable: false, value: 'tipopago' },
                        //{ text: 'Dcto. (%)', align: 'start', sortable: false, value: 'descuento' },
                        //{ text: 'Neto', align: 'start', sortable: false, value: 'neto' },
                        //{ text: 'Iva', align: 'start', sortable: true, value: 'iva' },
                        { text: 'Total', align: 'start', sortable: true, value: 'total' },
                        { text: 'Saldo', align: 'start', sortable: true, value: 'saldo' },
                        //{ text: 'Usuario', align: 'start', sortable: true, value: 'usuario' }
                        { text: 'Estado', align: 'start', sortable: true, value: 'estado' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v3/report/cobranza/'+this.contrato);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            var saldoNC=0;
                            if(element.Documento!='Factura'){
                                saldoNC=parseFloat(element.Total)-parseFloat(element.Usado)
                            };
                            this.items.push({
                                'fecha':element.Fecha,
                                'sucursal':element.Sucursal,
                                'rut':element.Rut,
                                'cliente':element.Cliente,
                                //'orden':element.Orden,
                                'documento':element.Documento,
                                'numero':element.Numero,
                                //'tipopago':element.TipoPago,
                                //'descuento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Descuento)),
                                //'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Neto)),
                                //'iva':new Intl.NumberFormat("de-DE").format(parseFloat(element.Iva)),
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'saldo':new Intl.NumberFormat("de-DE").format(parseFloat(saldoNC)),
                                //'usuario':element.Usuario
                                'estado':element.Estado
                            })
                        });
                    }
                    break
                  case 'Ventas x Tipo Pago':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Numero', align: 'start', sortable: false, value: 'numero' },
                        //{ text: 'Tipo Pago', align: 'start', sortable: false, value: 'tipopago' },
                        { text: 'Documento', align: 'start', sortable: false, value: 'documento' },
                        { text: 'Dcto. (%)', align: 'start', sortable: false, value: 'descuento' },
                        { text: 'Neto', align: 'start', sortable: false, value: 'neto' },
                        { text: 'Iva', align: 'start', sortable: true, value: 'iva' },
                        { text: 'Total', align: 'start', sortable: true, value: 'total' },
                        { text: 'Forma Pago', align: 'start', sortable: true, value: 'formapago' },
                        { text: 'Monto', align: 'start', sortable: true, value: 'monto' },
                        // { text: 'Referencia', align: 'start', sortable: true, value: 'referencia' },
                        { text: 'Usuario', align: 'start', sortable: true, value: 'usuario' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/ventasII/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'fecha':element.Fecha,
                                'sucursal':element.Sucursal,
                                'cliente':element.Cliente,
                                'numero':element.Numero,
                                //'tipopago':element.TipoPago,
                                'documento':element.Documento,
                                'descuento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Descuento)),
                                'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Neto)),
                                'iva':new Intl.NumberFormat("de-DE").format(parseFloat(element.Iva)),
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'formapago':element.FormaPago,
                                'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                                // 'referencia':element.Referencia,
                                'usuario':element.Usuario
                            })
                        });
                    }
                    break
                  case 'Notas de Credito':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Documento', align: 'start', sortable: true, value: 'tipo' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        { text: 'N° Orden', align: 'center', sortable: true, value: 'orden' },
                        { text: 'N° Venta', align: 'center', sortable: true, value: 'venta' },                        
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Pago', align: 'center', sortable: true, value: 'pago' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/dte/out/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'entidad':element.Entidad,
                                'fecha':element.Fecha,
                                'cliente':element.Cliente,
                                'tipo':element.Documento,
                                'numero':element.Numero,
                                'orden':element.Orden,
                                'venta':element.nVenta,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'pago':element.Forma,
                                'usuario':element.Usuario,
                                'sucursal':element.Suc,
                                'ruta':element.RutaPdf
                            })
                        });
                    }                      
                    break
                  case 'Guias de Despacho':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        { text: 'Productos', align: 'center', sortable: true, value: 'productos' },
                        { text: 'Total ($)', align: 'center', sortable: true, value: 'total' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Observacion', align: 'start', sortable: false, value: 'observa' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/guias/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if (parseInt(element.Productos)!=0){
                                let html=element.Ruta;
                                this.items.push({
                                    'fecha':element.Fecha,
                                    'numero':element.Guia,
                                    'productos':element.Productos,
                                    'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                    'usuario':element.Usuario,
                                    'observa':element.Observa,
                                    'ruta':html
                                })
                            }
                        });
                    }
                    break
                  case 'Utilidad':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                        { text: 'Cantidad', align: 'start', sortable: false, value: 'cantidad' },
                        { text: 'Compra', align: 'start', sortable: false, value: 'compra' },
                        { text: 'Venta', align: 'start', sortable: false, value: 'venta' },
                        { text: 'Devolucion', align: 'center', sortable: false, value: 'devolucion' },
                        { text: 'Utilidad', align: 'start', sortable: true, value: 'utilidad' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/utilidad/detalle/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'fecha':element.Fecha,
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'cantidad':element.Cantidad,
                                'compra':new Intl.NumberFormat("de-DE").format(parseFloat(element.Compra)),
                                'venta':new Intl.NumberFormat("de-DE").format(parseFloat(element.Venta)),
                                'devolucion':new Intl.NumberFormat("de-DE").format(parseFloat(element.Devolucion)),
                                'utilidad':element.Utilidad,
                                'sucursal':element.Sucursal
                            })
                        });
                    }
                    break
                  case 'Stock Critico':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                        { text: 'Stock', align: 'start', sortable: false, value: 'stock' },
                        { text: 'U12M', align: 'start', sortable: false, value: 'u12m' },
                        { text: 'PU12M', align: 'start', sortable: false, value: 'pu12m' },
                        { text: 'U30D', align: 'center', sortable: false, value: 'u30d' },
                        { text: 'U180D', align: 'start', sortable: false, value: 'u90d' },
                        { text: 'P6M', align: 'start', sortable: false, value: 'p3m' },
                        { text: 'Critico', align: 'start', sortable: true, value: 'critico' }
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/calculostock/'+this.contrato);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            let critical='NO';
                            if(element.Critico!=0){
                                critical='SI';
                            }
                            this.items.push({
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'stock':new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock)),
                                'u12m':new Intl.NumberFormat("de-DE").format(parseFloat(element.U12M)),
                                'pu12m':new Intl.NumberFormat("de-DE").format(parseFloat(element.PU12M)),
                                'u30d':new Intl.NumberFormat("de-DE").format(parseFloat(element.U30D)),
                                'u90d':new Intl.NumberFormat("de-DE").format(parseFloat(element.U90D)),
                                'p3m':new Intl.NumberFormat("de-DE").format(parseFloat(element.P3M)),
                                'critico':critical
                            })
                        });
                    }
                    break
                  case 'Productos':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                        { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                        { text: 'Movimiento', align: 'start', sortable: true, value: 'movimiento' },
                        { text: 'Entidad', align: 'start', sortable: false, value: 'entidad' },
                        { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                        //{ text: 'Precio', align: 'start', sortable: false, value: 'precio' },
                        { text: 'Bodega', align: 'start', sortable: false, value: 'bodega' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        //{ text: 'Observa', align: 'start', sortable: false, value: 'observa',width:'13%' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/productos/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        this.items= [];
                        datos.forEach(element => {
                            this.items.push({
                                'fecha':element.Fecha,
                                'sku':element.Sku,
                                'producto':element.Producto,
                                'movimiento':element.Movimiento,
                                'entidad':element.Entidad,
                                'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                                //'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'bodega':element.Bodega,
                                'usuario':element.Usuario,
                                'observa':element.Observa
                            })
                        });
                    }
                    break
                  case 'Libro de Compras':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha Emision', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        //{ text: 'Productos', align: 'start', sortable: true, value: 'productos' },
                        { text: 'Neto', align: 'center', sortable: true, value: 'neto' },
                        { text: 'Exento', align: 'center', sortable: true, value: 'exento' },
                        { text: 'Impuesto', align: 'center', sortable: true, value: 'impuesto' },
                        { text: 'Iva', align: 'center', sortable: true, value: 'iva' },
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    //response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/cc/C/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/compra/nacional/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if (parseInt(element.Productos)!=0){
                                let html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/compra/'+this.contrato+'/'+element.Orden+'/'+element.Entidad;
                                this.items.push({
                                    'entidad':element.Entidad,
                                    'fecha':element.Emision,
                                    'rut':element.Rut,
                                    'proveedor':element.Proveedor,
                                    'numero':element.Orden,
                                    'productos':element.Productos,
                                    'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Neto)),
                                    'exento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Exento)),
                                    'impuesto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Impuesto)),
                                    'iva':new Intl.NumberFormat("de-DE").format(parseFloat(element.Iva)),
                                    'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                    'usuario':element.Usuario,
                                    'ruta':html
                                })
                            }
                        });
                    }
                    break
                  case 'Cotizaciones':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Orden', align: 'start', sortable: true, value: 'orden' },
                        { text: 'Productos', align: 'start', sortable: true, value: 'productos' },
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/cc/V/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if (parseInt(element.Productos)!=0){
                                let html='https://www.transervi.cl/Api_TranServi/public/api/inv/prt/cotiza/'+this.contrato+'/'+element.Orden+'/'+element.Entidad; 
                                this.items.push({
                                    'entidad':element.Entidad,
                                    'fecha':element.Fecha,
                                    'orden':element.Orden,
                                    'productos':element.Productos,
                                    'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                    'usuario':element.Usuario,
                                    'ruta':html
                                })
                            }
                        });
                    }
                    break
                  case 'Libro de Ventas':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Documento', align: 'start', sortable: true, value: 'tipo' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        //{ text: 'Orden', align: 'center', sortable: true, value: 'orden' },
                        { text: 'Descuento', align: 'center', sortable: true, value: 'descuento' },
                        { text: 'Neto', align: 'center', sortable: true, value: 'neto' },
                        { text: 'Iva', align: 'center', sortable: true, value: 'iva' },
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Pago', align: 'center', sortable: true, value: 'pago' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        //{ text: 'Comision', align: 'start', sortable: false, value: 'comision' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/dte/in/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'entidad':element.Entidad,
                                'fecha':element.Fecha,
                                'rut':element.Rut,
                                'cliente':element.Cliente,
                                'tipo':element.Documento,
                                'numero':element.Numero,
                                'orden':element.Orden,
                                'descuento':new Intl.NumberFormat("de-DE").format(parseFloat(element.Descuento)),
                                'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Neto)),
                                'iva':new Intl.NumberFormat("de-DE").format(parseFloat(element.Iva)),
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'pago':element.Forma,
                                'usuario':element.Usuario,
                                //'comision':element.Comision,
                                'sucursal':element.Suc,
                                'ruta':element.RutaPdf
                            })
                        });
                    }                      
                    break
                  case 'Devoluciones':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                        { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                        { text: 'Documento', align: 'start', sortable: true, value: 'tipo' },
                        { text: 'Numero', align: 'center', sortable: true, value: 'numero' },
                        { text: 'N° Orden', align: 'center', sortable: true, value: 'orden' },
                        { text: 'N° Venta', align: 'center', sortable: true, value: 'venta' },                        
                        { text: 'Total', align: 'center', sortable: true, value: 'total' },
                        { text: 'Pago', align: 'center', sortable: true, value: 'pago' },
                        { text: 'Usuario', align: 'start', sortable: false, value: 'usuario' },
                        { text: 'Sucursal', align: 'start', sortable: true, value: 'sucursal' },
                        { text: 'Detalle', align: 'start', sortable: false, value: 'detalle' },
                        { text: 'Ver', align: 'start', sortable: false, value: 'ruta' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/dte/out/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'entidad':element.Entidad,
                                'fecha':element.Fecha,
                                'cliente':element.Cliente,
                                'tipo':element.Documento,
                                'numero':element.Numero,
                                'orden':element.Orden,
                                'venta':element.nVenta,
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'pago':element.Forma,
                                'usuario':element.Usuario,
                                'sucursal':element.Suc,
                                'ruta':element.RutaPdf
                            })
                        });
                    }                      
                    break
                  case 'Proveedores':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Nombre', align: 'start', sortable: true, value: 'nombre' },
                        { text: 'Email', align: 'start', sortable: true, value: 'email' },
                        { text: 'Ciudad', align: 'start', sortable: true, value: 'ciudad' },
                        { text: 'Vigente', align: 'center', sortable: false, value: 'estado' },
                        { text: 'Observacion', align: 'start', sortable: false, value: 'observa' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/entidad/Proveedor/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'rut':element.Rut,
                                'nombre':element.Razon,
                                'email':element.Email,
                                'ciudad':element.Ciudad,
                                'estado':element.Estado,
                                'observa':element.Observa
                            })
                        });
                    }                      
                    break
                  case 'Clientes':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                        { text: 'Nombre', align: 'start', sortable: true, value: 'nombre' },
                        { text: 'Email', align: 'start', sortable: true, value: 'email' },
                        { text: 'Ciudad', align: 'start', sortable: true, value: 'ciudad' },
                        { text: 'Vigente', align: 'center', sortable: false, value: 'estado' },
                        { text: 'Observacion', align: 'start', sortable: false, value: 'observa' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/entidad/Cliente/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'rut':element.Rut,
                                'nombre':element.Nombre,
                                'email':element.Email,
                                'ciudad':element.Ciudad,
                                'estado':element.Estado,
                                'observa':element.Observa
                            })
                        });
                    }                      
                    break
                  case 'Usuarios':
                    this.headers=[];
                    this.items= [];
                    this.headers=[
                        { text: 'Login', align: 'start', sortable: true, value: 'login' },
                        { text: 'Nombre', align: 'start', sortable: true, value: 'nombre' },
                        { text: 'Email', align: 'start', sortable: true, value: 'email' },
                        { text: 'Comision', align: 'start', sortable: true, value: 'comision' },
                        { text: 'Vigente', align: 'center', sortable: false, value: 'estado' },
                    ]
                    response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/entidad/Usuario/'+this.contrato+'/'+this.date1+'/'+this.date2);
                    datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.items.push({
                                'login':element.Usuario,
                                'nombre':element.Nombre,
                                'email':element.Email,
                                'comision':new Intl.NumberFormat("de-DE").format(parseFloat(element.Comision)),
                                'estado':element.Estado
                            })
                        });
                    }                      
                    break
                };
                let verException='NO';
                if(this.ReportException.length!=0){
                    this.ReportException.forEach(element => {
                        if(tipo==element){
                            verException='SI'
                        }
                    });
                };
                if(verException!='NO'){
                    this.itemsResult = this.items
                } else {
                    let varUsuario=this.usu;
                    if (this.fullReport!=true){
                        this.itemsResult = this.items.filter(function(element){
                            return element.usuario==varUsuario;
                        });
                    } else { this.itemsResult = this.items }
                }
            } else {
                this.Msg='Seleccione tipo Reporte'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
            this.loading=false;
        },
    }
}
</script>
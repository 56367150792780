<template>
    <div>
        <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Gerencia</h2> <!-- Full Screen -->
        <h3 class="mt-1 hidden-sm-and-up grey--text">Gerencia</h3> <!-- Mobile Screen -->
        <div class='mt-5'>
            <v-card v-if="vista===true">
                <!-- <v-card-subtitle>Pedidos</v-card-subtitle> -->
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="7">
                            <v-row>
                                <v-col cols="8" md="4">
                                    <v-text-field class='text-caption' @keyup="sku=sku.toUpperCase();" v-model="sku" label="Sku" :disabled="disabledSku" :filled="disabledSku" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="4" md="1">
                                    <v-btn @click="searchSku()" class='mt-1' color="teal" rounded dark small><v-icon small>search</v-icon></v-btn>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-btn @click="inventa=true" class="mt-1" color="indigo" rounded dark small><v-icon small>find_replace</v-icon></v-btn>                                    
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field class='text-caption' v-model="nombre" label="Nombre" disabled filled outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="6" md="4" class="mt-n10">
                                    <v-text-field class='text-caption' v-model="referencia" label="Referencia" disabled filled outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="6" md="8" class="mt-n10">
                                    <v-text-field class='text-caption' v-model="modelo" label="Modelo" disabled filled outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="6" md="3" class="mt-n10">
                                    <v-text-field class='text-caption' v-model="neto" label="Neto" disabled filled outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="6" md="3" class="mt-n10">
                                    <v-text-field class='text-caption' v-model="iva" label="Iva" disabled filled outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="6" md="3" class="mt-n10">
                                    <v-text-field class='text-caption' v-model="total" label="Total" disabled filled outlined dense></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-textarea class='text-caption' no-resize rows="3" v-model="observa" label="Observacion" disabled filled outlined dense></v-textarea>
                                </v-col>
                                <v-col cols="6" md="4" class="mt-n10">
                                    <v-text-field class='text-caption' v-model="stockSB" label="Stock SB" disabled filled outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="6" md="4" class="mt-n10">
                                    <v-text-field class='text-caption' v-model="stockRT" label="Stock RT" disabled filled outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="6" md="4" class="mt-n10">
                                    <v-text-field class='text-caption' v-model="stockTotal" label="Total Stock" disabled filled outlined dense></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n8">
                        <v-col cols="12" md="3">
                            <v-btn @click="opc(1)" small color="teal" dark block><span class="text-caption">Números de Referencias</span><v-icon v-if="hoja==1" class="ml-2">check_circle</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-btn @click="opc(2)" small color="teal" dark block><span class="text-caption">Ventas</span><v-icon v-if="hoja==2" class="ml-2">check_circle</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-btn @click="opc(3)" small color="teal" dark block><span class="text-caption">Compras</span><v-icon v-if="hoja==3" class="ml-2">check_circle</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-btn @click="opc(4)" small color="teal" dark block><span class="text-caption">Imagen</span><v-icon v-if="hoja==4" class="ml-2">check_circle</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn @click="opc(5)" small color="teal" dark block><span class="text-caption">Pedidos de Compra</span><v-icon v-if="hoja==5" class="ml-2">check_circle</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <div v-if="hoja!=0" class="mt-2">
                        <component v-bind:is="componente" :usu="usu" :contrato="contrato" :sku="sku"></component>
                    </div>
                    <div v-else class="mt-2" style="height:200px;text-align:center">
                        <p>Selecione opción para ver la informacion asociada</p>
                    </div>
                    <div v-if="hoja==0 || hoja==1 || hoja==5">
                        <v-divider></v-divider>
                        <v-row class="mt-1">
                            <v-col cols="12" md="12">&nbsp;</v-col>
                        </v-row>
                    </div>                    
                </v-card-text>
            </v-card>
            <v-card v-else-if="vista===false">
                <v-card-subtitle><v-icon class="red--text">cancel</v-icon><span class="ml-2">Solicitud Denegada</span></v-card-subtitle>
            </v-card>
            <v-card v-else>
                <v-card-subtitle>Cargando...</v-card-subtitle>
            </v-card>
        </div>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />

        <!-- dialog inventario -->
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="90%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClick" dense dark :search="likesearch" :headers="head" :items="desserts" :page.sync="page" :items-per-page="itemsPerPage"  @page-count="pageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount"  :total-visible="5"></v-pagination>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                </div>
            </v-card-actions>
        </v-card>
        </v-dialog>       
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import GerenciaReferencia from '@/components/GerenciaReferencia.vue';
import GerenciaVentas from '@/components/GerenciaVentas.vue';
import GerenciaCompras from '@/components/GerenciaCompras.vue';
import GerenciaImagen from '@/components/GerenciaImagen.vue';
import GerenciaPedidos from '@/components/GerenciaPedidos.vue';
import axios from 'axios';
export default {
    name:'Gerencia', 
    components:{dialogo, snack, GerenciaReferencia, GerenciaVentas, GerenciaCompras, GerenciaImagen, GerenciaPedidos},  
    props: ['usu','contrato'], 
    data(){
        return{
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            vista:null,
            hoja:0,
            componente:'',
            disabledSku:false,
            sku:'',
            nombre:'',
            referencia:'',
            modelo:'',
            impuesto:19,
            neto:0,
            iva:0,
            total:0,
            observa:'',
            stockSB:0,
            stockRT:0,
            stockTotal:0,
            //inventario
            head: [
                { text: 'Sku', align: 'start', sortable: true, value: 'tSku' },
                { text: 'Referencia', align: 'start', sortable: true, value: 'tCodigos' },
                { text: 'Producto', align: 'start', sortable: true, value: 'tNombre' },
                { text: 'Modelo', align: 'start', sortable: true, value: 'tModelo' },
                //{ text: '($) Neto', align: 'center', sortable: true, value: 'tDetalle' },
                { text: '($) Venta', align: 'center', sortable: true, value: 'tVenta' },
                { text: 'Stock1', align: 'center', sortable: true, value: 'tStock1' },
                { text: 'Stock2', align: 'center', sortable: true, value: 'tStock2' },
            ],
            desserts: [],
            inventa:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,        
        }
    },
    async mounted(){
        await this.entrada();
        await this.generaTabla();
    },
    methods:{
        async entrada(){
            this.loading=true;
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if(datos[0].Permiso_5_1!=0) {
                    this.vista=true;
                } else { this.vista=false; }
            } else { this.vista=false; }
            this.loading=false;
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.nombre='';
            this.referencia='';
            this.modelo='';
            this.neto='';
            this.iva='';
            this.total='';
            this.observa='';
            this.stockSB=0;
            this.stockRT=0;
            this.stockTotal=0;
            this.componente='';
            this.hoja=0;
        },
        async searchSku(){
            this.loading=true;
            if(this.sku.trim()!=''){
                this.msgbox=false;
                this.clsInput();
                // buscamos el impuesto
                const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/impuesto/'+ this.contrato);
                const datos1=response1.data;
                if (datos1[0].existe=='si'){
                    this.impuesto=datos1[0].Iva
                };                
                // buscamos sku
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/producto/'+this.contrato+'/'+this.sku);
                const datos=response.data;
                if (datos[0].existe=='si'){               
                    this.nombre=datos[0].Nombre;
                    this.modelo=datos[0].Descripcion; // campo hace referencia a modelo
                    //buscar ultima referencia universal
                    const response2 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/codigo/universal/'+this.contrato+'/'+this.sku);
                    const datos2=response2.data;
                    if (datos2[0].existe=='si'){
                        this.referencia='';
                        datos2.forEach(res => {
                            if(res.Fabrica=='MERCEDES BENZ'){
                                this.referencia=res.Codigo;
                            }
                        });
                    };
                    this.neto=datos[0].PrecioLocal;
                    this.iva=Math.round((parseInt(this.neto)*parseInt(this.impuesto))/100);
                    this.total=parseInt(this.neto)+parseInt(this.iva);
                    this.observa=datos[0].Observa;
                    // buscamos stock del producto en sierra bella
                    const response3 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/bodegas/producto/total/'+this.contrato+'/1/'+this.sku);
                    const datos3=response3.data;
                    if (datos3[0].existe=='si'){
                        this.stockSB=datos3[0].Total;
                    };
                    // buscamos stock del producto en ruiz tagle
                    const response4 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/bodegas/producto/total/'+this.contrato+'/2/'+this.sku);
                    const datos4=response4.data;
                    if (datos4[0].existe=='si'){
                        this.stockRT=datos4[0].Total;
                    };
                    this.stockTotal=parseInt(this.stockSB)+parseInt(this.stockRT);
                } else {
                    this.Msg='Sku no existe'
                    this.msgbox=true;
                    this.color='error'
                    this.showSnack();
                } 
            };
            this.loading=false;
        },
        opc(n){
            if(this.sku.trim()!=''){
                this.componente='';
                this.hoja=n;
                switch (n) {
                    case 1:
                        this.componente='GerenciaReferencia';
                    break;
                    case 2:
                        this.componente='GerenciaVentas';
                    break;
                    case 3:
                        this.componente='GerenciaCompras';
                    break;
                    case 4:
                        this.componente='GerenciaImagen';
                    break;
                    case 5:
                        this.componente='GerenciaPedidos';
                    break;
                }
            } else {
                this.Msg='Ingrese Sku'
                this.msgbox=true;
                this.color='info'
                this.showSnack();
            }
        },
        async generaTabla(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/stock/datatable/'+this.contrato);
            const datos=response.data;
            this.desserts=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.desserts.push({
                    tSku: element.Sku,
                    tCodigos: element.Codigos,
                    tNombre: element.Nombre,
                    tModelo: element.Descripcion,
                    //tDetalle: new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioLocal)),
                    tVenta:new Intl.NumberFormat("de-DE").format(parseFloat(element.PrecioVenta)),
                    tStock1: new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock1)),
                    tStock2: new Intl.NumberFormat("de-DE").format(parseFloat(element.Stock2)),
                    precioDetalle:element.PrecioLocal,
                    precioVenta:element.PrecioVenta
                    })
                });
            }
        },
        handleClick(e) {
            if(e.tSku.trim()!=''){
                this.sku=e.tSku;
                this.inventa=false;
                this.searchSku();
            } else {
                this.inventa=false;
            }
        },
    }
}
</script>

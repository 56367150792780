<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Compra / NC Proveedores</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Compra / NC Proveedores</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">      
          <span>Compra</span>
          <v-icon>{{CompraIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>NC Proveedores</span>
          <v-icon>{{NotaIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp1">
          <span>NC S/Compra</span>
          <v-icon>{{NCSIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
      
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>
<script>
import axios from 'axios';
import Compra from '@/components/Compra.vue';
import Notas from '@/components/Notas.vue';
import NCSCompra from '@/components/NCSCompra.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Pedidos',
  components: { Compra, dialogo, Notas, NCSCompra},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      CompraIcon:'monetization_on',
      CompraDefault:'monetization_on',
      NotaIcon:'monetization_on',
      NotaDefault:'monetization_on',
      NCSIcon:'monetization_on',
      NCSDefault:'monetization_on',
      comp0:true,
      comp1:true,
      comp2:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
  methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_3_1!=0) {
          this.comp0=false;
          this.comp1=false;
          this.comp2=false;
        };
      } 
      this.moduloActivo();      
    },
    moduloActivo(){
      if (this.comp0!=true){
          this.componente='Compra';
          this.CompraIcon=this.IconSelect;
          this.NotaIcon=this.NotaDefault;
          this.NCSIcon=this.NCSDefault;
          this.value=0
      } else {
        if (this.comp1!=true){
          this.componente='Notas';
          this.CompraIcon=this.CompraDefault;
          this.NotaIcon=this.IconSelect;
          this.NCSIcon=this.NCSDefault;
          this.value=1
        } else {
          if (this.comp2!=true){
            this.componente='NCSCompra';
            this.CompraIcon=this.CompraDefault;
            this.NotaIcon=this.NotaDefault;
            this.NCSIcon=this.IconSelect;
            this.value=2
          } else {
            this.CompraIcon=this.CompraDefault;
            this.NotaIcon=this.EntradaDefault;
            this.NCSIcon=this.NCSDefault;
          }
        }
      }
      this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Compra';
          this.CompraIcon=this.IconSelect;
          this.NotaIcon=this.NotaDefault;
          this.NCSIcon=this.NCSDefault;
          break;
        case 1:
          vComponente='Notas';
          this.CompraIcon=this.CompraDefault;
          this.NotaIcon=this.IconSelect;
          this.NCSIcon=this.NCSDefault;
          break;
        case 2:
          vComponente='NCSCompra';
          this.CompraIcon=this.CompraDefault;
          this.NotaIcon=this.NotaDefault;
          this.NCSIcon=this.IconSelect;
          break;
      }
      this.componente=vComponente;
    }
  }
}
</script>
const URLactual = window.location.href;
console.log(URLactual);
let result;
if(URLactual=='http://app.transervi.cl/' || 
    URLactual=='https://app.transervi.cl/' ||    
    URLactual=='http://www.app.transervi.cl/' || 
    URLactual=='https://www.app.transervi.cl/'){
    result=true
} else {
    result=false
};
export default result
<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">
                IMPORTACIÓN
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row class="mt-2">
                            <v-col cols="8" md="2" class="form-space">
                                <!-- <v-text-field class='text-caption' v-model="solicita" label="N° Solicitud" outlined dense :disabled="soloSolicita" :filled="soloSolicita"></v-text-field> -->
                                <v-text-field class='text-caption' v-model="solicita" label="N° Solicitud" outlined dense disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <!-- <v-btn @click="verSolicita()" class="mt-1" x-small color="teal" dark fab><v-icon>search</v-icon></v-btn> -->
                                <v-btn @click="dialogo_importa=true" class="mt-1" x-small color="teal" dark fab><v-icon>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="9" md="3" class="form-space">
                                <v-combobox class='text-caption' @change="search_rut" :hint="proveedor_rut" v-model="proveedor" :items="itemProveedor" persistent-hint item-text="razon" item-value='rut' label="Proveedor" dense outlined :disabled="soloProveedor" :filled="soloProveedor"></v-combobox>
                            </v-col>
                            <v-col cols="3" md="1" class="form-space">
                                <v-btn @click="verProveedor()" class="mt-1" x-small color="indigo" dark fab><v-icon>folder_special</v-icon></v-btn>
                            </v-col>
                            <v-col cols="9" md="2" class="form-space">
                                <v-text-field class='text-caption' v-model="carpeta" label="N° Carpeta" outlined dense disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="3" md="1" class="form-space">
                                <v-btn @click="dialogo_IMP=true" class="mt-1" x-small color="teal" dark fab><v-icon>search</v-icon></v-btn>
                                <v-btn v-if="existe=='si'" @click="delete_carpeta()" class="mt-1 ml-2" x-small color="red" dark fab><v-icon>delete</v-icon></v-btn>
                            </v-col>
                            <v-col cols="6" md="2" class="form-space">
                                <v-alert v-if="existe=='si'" class='mt-1 text-caption' dense text border="left" color="success">Carpeta Existe</v-alert> 
                                <v-alert v-if="existe=='no'" class='mt-1 text-caption' dense text border="left" color="red">Carpeta Nueva</v-alert> 
                            </v-col>
                            <v-col cols="12" md="12" class="mt-n8">
                                <span>Agregar Sku</span>
                                <v-icon class="ml-1" @click="add_sku()" x-samll color="indigo">add_circle_outline</v-icon>
                                <v-data-table dense dark :headers="detalle_head" :items="detalle_items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                                <template v-slot:item.cantidad="{ item }">
                                    <v-edit-dialog large persistent>
                                        <div>{{item.cantidad}}</div>
                                        <template v-slot:input>
                                            <div class="mt-4 text-h6">Cantidad</div>
                                            <v-text-field @keypress="onlyNumber" v-model="item.cantidad" label="Edit" single-line></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                                <template v-slot:item.auxiliar="{ item }">
                                    <v-edit-dialog large persistent>
                                        <div>{{item.auxiliar}}</div>
                                        <template v-slot:input>
                                            <div class="mt-4 text-h6">Codigo Fabrica</div>
                                            <v-text-field v-model="item.auxiliar" label="Edit" single-line></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                                <template v-slot:item.precio="{ item }">
                                    <v-edit-dialog large persistent>
                                        <div>{{item.precio}}</div>
                                        <template v-slot:input>
                                            <div class="mt-4 text-h6">Ingrese Precio</div>
                                            <v-text-field @keypress="onlyDecimal" v-model="item.precio" label="Edit" single-line></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                                <template v-slot:item.accion="{ item }">
                                    <v-icon @click="deleteItems(item.id)" small>delete</v-icon>
                                </template>
                                </v-data-table>
                            </v-col>
                            <v-col cols="12" md="12" class="form-space">
                                <p class="text-center">
                                    <v-pagination v-model="page" :length="pageCount"></v-pagination>              
                                </p>
                            </v-col>
                            <v-col cols="12" md="2" class="form-space">
                                <v-badge bordered overlap color="error" :content="nHistoria"><v-btn @click="historia=true" small color="indigo" dark rounded><span class='text-btn'>Seguimiento</span></v-btn></v-badge>
                            </v-col>
                            <v-col cols="6" md="7" class="form-space">
                                <v-text-field v-model="observa" label="Observacion" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="6" md="3" class="form-space">
                                <v-btn class="ml-2" @click="verIntegra()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                                <v-btn class="ml-2" @click="clsInput()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>        
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-30px">
            </v-card-actions>
        </v-card>
        <!-- DIALOGO HISTORIA -->
        <v-dialog v-model="historia" transition="dialog-bottom-transition" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Seguimiento Importación
                <v-spacer></v-spacer>
                <v-btn @click="historia=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                <v-col cols="12" md="12">
                    <v-data-table dense dark :headers="historia_head" :items="historia_items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                        <template v-slot:item.accion="{ item }">
                            <v-icon @click="unSeguimiento(item.id)" small>remove_red_eye</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="12">
                        <p class="text-center">
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>              
                        </p>
                    </v-col>
                </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DIALOGO DETALLE HISTORIA -->
        <v-dialog v-model="seguimiento" transition="dialog-bottom-transition" persistent>
            <v-card>
            <v-toolbar color="success" dark>Detalle Seguimiento
                <v-spacer></v-spacer>
                <v-btn @click="seguimiento=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                <v-col cols="12" md="12">
                    <v-data-table dense dark :headers="seguimiento_head" :items="seguimiento_items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    </v-data-table>
                </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="12">
                        <p class="text-center">
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>              
                        </p>
                    </v-col>
                </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog Solicitudes -->
        <v-dialog v-model="dialogo_importa" transition="dialog-bottom-transition" max-width="35%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialogo_importa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer></v-spacer>
                <v-text-field class='ml-5 mr-2 text-caption' v-model="IMP_search" label="Buscar" dense required></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="fila_importa" dense dark :search="IMP_search" :headers="head_importa" :items="items_importa" :page.sync="page_importa" :items-per-page="PerPage_importa"  @page-count="pageCount_importa = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="page_importa" :length="pageCount_importa"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- dialog add Sku -->
        <v-dialog v-model="dialog_add" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog_add=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row class="mt-5">
                    <v-col cols="10" md="3" class="form-space">
                        <v-text-field class='text-caption' v-model="importa_sku" label="SKU" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="2" md="1" class="form-space">
                        <v-btn @click="verSku(importa_sku)" class="mt-1" x-small color="teal" dark fab><v-icon>search</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6" class="form-space">
                        <v-text-field class='text-caption' v-model="importa_producto" label="Nombre Producto" outlined dense disabled filled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="form-space">
                        <v-select class='text-caption' v-model="importa_universal" :items="itemUniversal" item-text="codigo" label="Cod.Universal" dense outlined></v-select>
                    </v-col>
                    <v-col cols="8" md="2" class="form-space">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="importa_cantidad" label="Cantidad" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="4" md="1" class="form-space">
                        <v-btn @click="importa_item()" class="mt-1" x-small color="teal" dark fab><v-icon>add</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- dialog importaciones -->
        <v-dialog v-model="dialogo_IMP" transition="dialog-bottom-transition" max-width="50%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialogo_IMP=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer></v-spacer>
                <v-text-field class='ml-5 mr-2 text-caption' v-model="busca_IMP" label="Buscar" dense required></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="fila_IMP" dense dark :search="busca_IMP" :headers="head_IMP" :items="items_IMP" :page.sync="page_IMP" :items-per-page="PerPage_IMP"  @page-count="pageCount_IMP = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="page_IMP" :length="pageCount_IMP"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- dialog Confirma -->
        <v-dialog v-model="dialogo_Confirma" transition="dialog-bottom-transition" max-width="50%" persistent>
        <v-card>
            <v-card-title>
                <h5>¿ Esta Seguro de Eliminar esta Carpeta ?</h5>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4" md="1">
                        <v-btn @click="confirma_SI()" class="mt-1" x-small color="teal" dark rounded>SI</v-btn>
                    </v-col>
                    <v-col cols="4" md="1">
                        <v-btn @click="dialogo_Confirma=false" class="mt-1" x-small color="grey" dark rounded>NO</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"/>

    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 8.5pt !important;
    height: 30px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
.form-space {
    margin-top:-20px;
}
</style>
<script>
import axios from 'axios';
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
export default {
    name:'Importa', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            solicita:'0',
            proveedor:'',
            itemProveedor:[],
            carpeta:0,
            detalle_head:[
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Cod. Universal', align: 'center', sortable: true, value: 'universal' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Cod. Proveedor', align: 'center', sortable: true, value: 'auxiliar' },
                { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            detalle_items:[],
            nHistoria:0,
            observa:'',
            cantidad:'',
            fabrica:'',
            precio:'',
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            historia:false,
            historia_head:[
                { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                { text: 'Observacion', align: 'start', sortable: true, value: 'observa' },
                { text: 'Usuario', align: 'start', sortable: true, value: 'usuario' },
                { text: 'Ver', align: 'start', sortable: true, value: 'accion' },
            ],
            historia_items:[],
            newHistoria:0,
            existe:'',
            idItems:0,
            seguimiento:false,
            seguimiento_head:[
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Cod. Universal', align: 'center', sortable: true, value: 'universal' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Cod. Proveedor', align: 'center', sortable: true, value: 'auxiliar' },
                { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
            ],
            seguimiento_items:[],
            soloSolicita:false,
            soloProveedor:false,
            cierre:'',
            newItems:[],
            //dialogo Solicitud
            IMP_search:'',
            dialogo_importa:false,
            busca_importa:'',
            page_importa: 1,
            pageCount_importa: 0,
            PerPage_importa: 10,
            head_importa: [
                { text: 'Solicitud', align: 'center', sortable: true, value: 'solicitud' },
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
            ],
            items_importa: [],
            //dialogo add Sku
            dialog_add:false,
            importa_sku:'',
            importa_producto:'',
            importa_universal:'',
            importa_cantidad:'',
            itemUniversal:[],
            //dialogo Importa
            dialogo_IMP:false,
            busca_IMP:'',
            page_IMP: 1,
            pageCount_IMP: 0,
            PerPage_IMP: 10,
            head_IMP: [
                { text: 'Carpeta', align: 'start', sortable: true, value: 'carpeta', width: '20%' },
                { text: 'Fecha', align: 'start', sortable: true, value: 'fecha', width: '20%' },
                { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor', width: '60%' },
            ],
            items_IMP: [],
            proveedor_rut:'',
            dialogo_Confirma:false,
        }
    },
    async mounted(){
        await this.buscarImporta();
        await this.buscarSolicitud();
        await this.buscaProveedor();
    },
    methods:{
        add_sku(){
            this.limpia_add();
            this.dialog_add=true;
        },
        limpia_add(){
            this.importa_sku='';
            this.importa_producto='';
            this.importa_universal='';
            this.importa_cantidad='';
            this.itemUniversal=[];
        },
        async verSku(xSku){
            if(xSku.trim()!=''){
                this.loading=true;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/producto/'+this.contrato+'/'+xSku);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.importa_producto=datos[0].Nombre;
                    this.CodigoUniversal(xSku);
                } else {
                    this.Msg='Producto no Existe'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
                this.loading=false;
            } else {
                this.Msg='Ingese Sku'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async CodigoUniversal(xSku){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/codigo/universal/'+ this.contrato+'/'+xSku);
                const datos1=response.data;
                this.itemUniversal=[];
                if (datos1[0].existe=='si'){
                    datos1.forEach(element => {
                        this.itemUniversal.push({
                            'idcodigo': element.ID,
                            'codigo': element.Codigo
                        });
                    });
                } 
            } catch (error) {
                console.log(error);
            }
        },
        fila_importa(fila){
            //console.log(fila);
            this.solicita=fila.solicitud;
            this.verSolicita();
            this.dialogo_importa=false;
        },
        async fila_IMP(fila){
            //buscamos la carpeta 
            const res = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/carpeta/desc/'+this.contrato);
            const datos=res.data;
            if (datos[0].existe=='si'){
                this.carpeta=fila.carpeta;
                this.existe='si';
                let arrayProveedor=[];
                this.soloProveedor=true;
                datos.forEach(element => {
                    if (element.ID==fila.carpeta && element.Proveedor==fila.proveedor){
                        this.solicita=element.Solicita;
                        arrayProveedor = this.itemProveedor.filter(function(dat) {
                            return dat.razon === element.Proveedor; 
                        });
                        this.proveedor=arrayProveedor[0].razon;
                        this.proveedor_rut=arrayProveedor[0].rut;
                    }
                });
                await this.verSeguimiento();
                if (this.nHistoria!=0){
                    await this.verLista(); 
                } else {
                    await this.searchCodigos();
                };
            };
            this.dialogo_IMP=false;
        },
        async buscarImporta(){
            this.items_IMP=[];
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/carpeta/pendientes/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.items_IMP.push({
                            'carpeta':element.ID,
                            'fecha':element.Fecha,
                            'proveedor':element.Proveedor
                            })        
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },
        async buscarSolicitud(){
            this.items_importa=[];
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/solicita/all/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.items_importa.push({
                            'solicitud':element.Solicita,
                            'fecha':element.Fecha
                            })        
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },
        importa_item(){
            if(parseInt(this.carpeta)!=0){
                if(this.importa_sku.trim()!='' && this.importa_producto.trim()!='' && 
                    this.importa_universal!='' && this.importa_cantidad.trim()!=''){
                    this.idItems++;
                    this.detalle_items.push({
                        'id':this.idItems,
                        'sku': this.importa_sku,
                        'universal': this.importa_universal,
                        'producto':this.importa_producto,
                        'cantidad':this.importa_cantidad,
                        'auxiliar':'',
                        'precio':''
                    });
                    this.dialog_add=false;                      
                } else {
                    this.Msg='Datos Obligatorios';
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
            } else {
                this.Msg='Debe existir Carpeta';
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async buscaProveedor(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/proveedores/'+this.contrato);
            const datos=response.data;
            this.itemProveedor=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemProveedor.push({
                        'rut':element.Rut,
                        'razon':element.Razon
                        })        
                });
            }
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        onlyDecimal ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.solicita='0';
            this.carpeta=0;
            this.proveedor='';
            this.detalle_items=[];
            this.nHistoria=0;
            this.newHistoria=0;
            this.historia_items=[];
            this.observa='';
            this.existe='';
            this.idItems=0;
            this.soloSolicita=false;   
            this.soloProveedor=false;
            this.fabrica='';
            this.cantidad='';
            this.precio='';
            this.cierre='';
            this.newItems=[];
            this.proveedor_rut='';
        }, 
        async verSolicita(){
            if (this.solicita.trim()!='0'){
                this.loading=true;
                this.idItems=0;
                this.carpeta=0;
                this.detalle_items=[];
                this.proveedor='';
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/solicitud/'+this.contrato+'/'+this.solicita);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.soloSolicita=true;
                    datos.forEach(element => {
                        this.idItems++;
                        this.detalle_items.push({
                            'id':this.idItems,
                            'sku': element.Sku,
                            'universal': element.Universal,
                            'producto':element.Producto,
                            'cantidad':element.Cantidad,
                            'auxiliar':element.Auxiliar,
                            'precio':element.Precio
                        });                      
                    });
                } else {
                    this.Msg='Solicitud no Existe'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                };
                this.loading=false;
            } else {
                this.Msg='Ingrese Solicitud'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        search_rut(){
            this.proveedor_rut=this.proveedor.rut;
        },
        async verProveedor(){
            if (this.solicita.trim()!='0'){
                if(this.proveedor_rut){
                    this.loading=true;
                    this.existe='';
                    this.soloProveedor=true;
                    await this.verCarpeta();
                    if (this.carpeta!=0){ 
                        this.existe='si';
                    } else { 
                        this.existe='no';
                    };
                    await this.verSeguimiento();
                    if (this.nHistoria!=0){
                        await this.verLista(); 
                    } else {
                        await this.searchCodigos();
                    };
                    this.loading=false;
                } else {
                    this.Msg='Seleccione Proveedor'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
            } else {
                this.Msg='Ingrese Solicitud'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async searchCodigos(){
            if(this.detalle_items.length!=0){
                this.newItems=[];
                try {
                    for (let index = 0; index < this.detalle_items.length; index++) {
                        const element = this.detalle_items[index];
                        let codigoFabrica='';
                        let precioSku='';
                        const respuesta = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/fabricas/codigo/one/'+this.contrato+'/'+this.proveedor_rut+'/'+element.sku+'/'+element.universal);
                        const reg=respuesta.data;
                        if (reg[0].existe=='si'){
                            codigoFabrica=reg[0].Auxiliar;
                            precioSku=reg[0].Precio
                        } else {
                            codigoFabrica=element.Auxiliar;
                             precioSku=0
                        }
                        this.newItems.push({
                            'id':element.id,
                            'sku': element.sku,
                            'universal': element.universal,
                            'producto':element.producto,
                            'cantidad':element.cantidad,
                            'auxiliar':codigoFabrica,
                            'precio':precioSku
                        });                                         
                    }                    
                } catch (error) {
                    console.log(error)
                }
                this.detalle_items=[];
                this.detalle_items=this.newItems
            }
        },
        async verLista(){
            if (this.nHistoria!=0){
                this.detalle_items=[];
                const re = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/detalle/'+this.contrato+'/'+this.carpeta+'/'+this.newHistoria);
                const da=re.data;
                if (da[0].existe=='si'){
                    da.forEach(element => {
                        this.detalle_items.push({
                            'id':element.ID,
                            'sku': element.Sku,
                            'universal': element.Universal,
                            'producto':element.Producto,
                            'cantidad':element.Cantidad,
                            'auxiliar':element.Auxiliar,
                            'precio':element.Precio
                        });                                              
                    });
                }
            }
        },
        async verCarpeta(){
            const res = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/carpeta/desc/'+this.contrato);
            const datos=res.data;
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    if (element.Solicita==this.solicita && element.Proveedor==this.proveedor.razon){
                        this.carpeta=element.ID
                    }
                });
            }
        },
        async verSeguimiento(){
            if(this.carpeta!=0){
                const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/historia/'+this.contrato+'/'+this.carpeta);
                const datos1=response1.data;
                if (datos1[0].existe=='si'){
                    this.newHistoria=datos1[0].ID;
                    this.historia_items=[];
                    this.nHistoria=0;
                    datos1.forEach(element => {
                        this.historia_items.push(
                            {
                                'id':element.ID,
                                'fecha':element.Fecha,
                                'observa':element.Observa,
                                'usuario':element.Usuario
                            }
                        );                         
                        this.nHistoria++;
                    });
                    //ordenamos el array DESC
                    this.historia_items.sort(function (a, b) {
                        if (a.id > b.id) {
                            return -1;
                        }
                        if (a.id < b.id) {
                            return 1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                }
            }
        },
        async verIntegra(){
            this.loading=true;
            try {
                this.cierre='no';
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/integra/all/'+this.contrato+'/'+this.carpeta);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    if (datos[0].Estado=='C'){
                        this.cierre='si';
                        this.Msg='Carpeta Cerrada'
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();
                    }
                };
                if(this.cierre!='si'){
                    await this.saveSeguimiento();
                }
            } catch (error) {
                console.log(error);
            };
            this.loading=false;
        },
        async saveSeguimiento(){
            if(this.observa.trim()!=''){
                if(this.carpeta!=0){
                    this.loading=true;
                    const params ={
                        'contrato':this.contrato,
                        'carpeta':this.carpeta,
                        'fecha': this.date1,
                        'observa':this.observa,
                        'usuario': this.usu
                    }
                    const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/historia/nueva',params);
                    const datos=response.data;
                    if (datos=='si'){
                        await this.verSeguimiento();
                        await this.saveDetalle();
                        this.Msg='Seguimiento Grabado'
                        this.color='success';
                        this.msgbox=true;
                        this.showSnack();
                    };
                    this.loading=false;
                } else {
                    // crear nueva carpeta
                    if (this.existe=='no'){
                        const params ={
                            'contrato':this.contrato,
                            'solicita':this.solicita,
                            'proveedor':this.proveedor.razon,
                            'fecha': this.date1,
                            'usuario':this.usu,
                            'estado': 'V'
                        };
                        const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/carpeta/nueva',params);
                        const datos=response.data;
                        if (datos=='si'){
                            //buscamos carpeta nueva
                            const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/carpeta/desc/'+this.contrato);
                            const datos1=response1.data;
                            if (datos1[0].existe=='si'){
                                this.carpeta=datos1[0].ID;
                                await this.saveSeguimiento();
                                this.existe='si';
                            }
                        }
                    } else {
                        this.Msg='Verificar Carpeta';
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();
                    }
                }
            } else {
                this.Msg='Observacion Obligada'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async saveDetalle(){
            if (this.detalle_items.length!=0){
                for (let index = 0; index < this.detalle_items.length; index++) {
                    const element = this.detalle_items[index];
                    //controlar los indefinidos
                    if (element.cantidad){ this.cantidad=element.cantidad } else { this.cantidad=0 }
                    if (element.auxiliar){ this.fabrica=element.auxiliar } else { this.fabrica='' }
                    if (element.precio){ this.precio=element.precio } else { this.precio=0 }
                    const par={
                    'contrato':this.contrato,
                    'rut':this.proveedor_rut,
                    'carpeta':this.carpeta,
                    'historia':this.newHistoria,
                    'sku': element.sku,
                    'producto':element.producto,
                    'universal': element.universal,
                    'auxiliar': this.fabrica,
                    'cantidad': this.cantidad,
                    'precio': this.precio
                    }
                    try {
                        const res = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/detalle/nuevo',par);
                        const dat=res.data;
                        if (dat=='si'){
                            console.log('ok');
                        }                           
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        },
        async unSeguimiento(h){
            if (this.nHistoria!=0){
                //console.log(h);
                this.seguimiento_items=[];
                const re = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/detalle/'+this.contrato+'/'+this.carpeta+'/'+h);
                const da=re.data;
                if (da[0].existe=='si'){
                    da.forEach(element => {
                        this.seguimiento_items.push({
                            'id':element.ID,
                            'sku': element.Sku,
                            'universal': element.Universal,
                            'producto':element.Producto,
                            'cantidad':element.Cantidad,
                            'auxiliar':element.Auxiliar,
                            'precio':element.Precio
                        });                      
                    });
                }
                this.seguimiento=true;
            }
        },
        deleteItems(n){
            //console.log(n);
            this.detalle_items = this.detalle_items.filter(function(dat) {
                return dat.id !== n; 
            });
            this.nDetalle=this.nDetalle-1;
        },
        async delete_carpeta(){
            if (this.carpeta!=0){
                this.dialogo_Confirma=true;
            }
        },
        async confirma_SI(){
            try {
                const res = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/delete/one/'+this.contrato+'/'+this.carpeta);
                const dato=res.data;
                if (dato=='ok'){
                    await this.buscarImporta();
                    this.clsInput();
                    this.Msg='Carpeta Eliminada';
                    this.color='success';
                    this.msgbox=true;
                    this.showSnack();
                    this.dialogo_Confirma=false;
                }
            } catch (error) {
                console.log(error)
            }
        },
        async confirma_NO(){
            this.dialogo_Confirma=false;
        }

    }
}
</script>
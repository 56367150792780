<template>
    <div>
        <v-card color="green darken-3" dark>
            <v-card-title class="text-caption">
              <span class="text-body-2">Ganancias ({{anio}})</span>
              <v-spacer></v-spacer>
              <v-chip color='green darken-1'>${{new Intl.NumberFormat("de-DE").format(utilidad)}}</v-chip>
            </v-card-title>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'UtilidadVentas',
  props:['usu','contrato'],
    data(){
    return {
      //contrato:'123',
      anio:'',
      utilidad:0,
      // varVentas:0,
      // varCompras:0,
      varUtilidad:0,
      // varResto:0,
    }
  },
  async mounted(){
    this.Utilidades();
    // const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cartera/ganancias/'+this.contrato);
    // const datos=response.data;
    // //console.log(datos);
    // if (datos[0].existe=='si'){
    //     this.anio=datos[0].Anio
    //     this.varVentas=0;
    //     this.varCompras=0;
    //     for (let index = 0; index < datos.length; index++) {
    //       const element = datos[index];
    //       this.varVentas=this.varVentas+parseFloat(element.Total);
    //       await this.buscaCompra(element.Sku,element.Cantidad);
    //     }
    //     await this.PrintItem()
    //   // this.anio=datos[0].Anio
    //   // this.utilidad=datos[0].Utilidad
    // }     
  },
  methods:{
    async Utilidades(){
      const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cartera/utilidad/ventas/anio/'+this.contrato);
      const datos=response.data;
      //console.log(datos);
      if (datos[0].existe=='si'){        
        this.anio=datos[0].Fecha.substring(0,4)
        this.varUtilidad=0;
        this.utilidad=0;
        datos.forEach(element => {
          this.varUtilidad=this.varUtilidad+parseFloat(element.Utilidad);
        });
        this.utilidad=this.varUtilidad;
      }  
    }

    // async PrintItem(){
    //   this.varUtilidad=this.varVentas-this.varCompras;
    //   this.utilidad=this.varUtilidad
    // },
    // async buscaCompra(sku,cantidad){
    //   const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/cartera/utilidad/compras/'+this.contrato+'/'+sku);
    //   const datos1=response1.data;
    //   //console.log(datos1);
    //   if (datos1[0].existe=='si'){
    //     this.varResto=parseFloat(cantidad);
    //     datos1.forEach(element => {
    //       if (this.varResto!=0){
    //         if (this.varResto<=parseFloat(element.Cantidad)){
    //           this.varCompras=this.varCompras+(this.varResto*parseFloat(element.Precio));
    //           this.varResto=0;
    //         } else {
    //           this.varCompras=this.varCompras+(this.varResto*parseFloat(element.Precio));
    //           this.varResto=this.varResto-parseFloat(element.Cantidad)
    //         }
    //       }
    //     });
    //   }
    // }
  }
}
</script>
<template>
    <div>
        <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">DashBoard</h2> <!-- Full Screen -->
        <h3 class="mt-1 hidden-sm-and-up grey--text">DashBoard</h3> <!-- Mobile Screen -->
        <div v-if="vista===true" class="mt-5">
            <div class='mt-5'>
            <v-row>
                <v-col cols="12" md="6">
                    <strong>{{titulo_01}}</strong>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-data-table :loading="cargaVentas" loading-text="Cargando....." :headers="header_table1" :items="item_table1" class="elevation-1 text-size" hide-default-footer dark dense></v-data-table>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-card color="success" dark>
                                <v-card-title>
                                    <v-row>
                                        <v-col cols="12" md="3" class="mt-2">
                                            <v-icon class="ml-1">monetization_on</v-icon>
                                            <span class="ml-1 text-caption">Venta Diaria</span>
                                        </v-col>
                                        <v-col cols="7" md="3" class="mt-2">

                                            <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">                                        
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-chip class="ml-2 text-caption" small @click="menu1 = true" v-bind="attrs" v-on="on" outlined color="white">
                                                        <v-icon class="ml-2">events</v-icon>
                                                        {{date1}}
                                                    </v-chip>                                        
                                                </template>
                                            <v-date-picker v-model="date1" @input="venta_fecha(date1)" no-title scrollable></v-date-picker>
                                            </v-menu>


                                            <!-- <v-chip class="ml-2 text-caption" small outlined color="white">
                                                <v-icon class="ml-2">events</v-icon>
                                                {{dateNow}}
                                            </v-chip>                                         -->
                                        </v-col>
                                        <v-divider inset vertical color="white"></v-divider>
                                        <v-col cols="5" md="3" class="mt-n1 text-right">
                                            <span class="text-caption">Iva</span><br/>
                                            <div v-if="progreso_dia==false">
                                                <h6 class="mt-n1 text-body-2">${{new Intl.NumberFormat("de-DE").format(parseFloat(ivaNow))}}</h6>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular color="white" size="20" width="2" indeterminate></v-progress-circular>
                                            </div>
                                        </v-col>
                                        <v-col cols="5" md="3" class="mt-n1 text-right">
                                            <span class="text-caption">Total</span><br/>
                                            <div v-if="progreso_dia==false">
                                                <h6 class="mt-n1 text-body-2">${{new Intl.NumberFormat("de-DE").format(parseFloat(ventaNow))}}</h6>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular color="white" size="20" width="2" indeterminate></v-progress-circular>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-card color="indigo" dark>
                                <v-card-title>
                                    <v-row>
                                        <v-col cols="12" md="3" class="mt-2">
                                            <v-icon class="ml-1">monetization_on</v-icon>
                                            <span class="ml-1 text-caption">Venta Mensual</span>
                                        </v-col>
                                        <v-col cols="7" md="3" class="mt-2">

                                            <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">                                        
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-chip class="ml-2 text-caption" small @click="menu2 = true" v-bind="attrs" v-on="on" outlined color="white">
                                                        <v-icon class="ml-2">events</v-icon>
                                                        {{date2}}
                                                    </v-chip>                                        
                                                </template>
                                            <v-date-picker v-model="date2" @input="venta_mes(date2)" no-title scrollable></v-date-picker>
                                            </v-menu>


                                            <!-- <v-chip class="ml-2 text-caption" small outlined color="white">
                                                <v-icon class="ml-2">events</v-icon>
                                                {{dateMensual}}
                                            </v-chip>                                         -->
                                        </v-col>
                                        <v-divider inset vertical color="white"></v-divider>
                                        <v-col cols="5" md="3" class="mt-n1 text-right">
                                            <span class="text-caption">Iva</span><br/>
                                            <div v-if="progreso_mes==false">
                                                <h6 class="mt-n1 text-body-2">${{new Intl.NumberFormat("de-DE").format(parseFloat(ivaMensual))}}</h6>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular color="white" size="20" width="2" indeterminate></v-progress-circular>
                                            </div>
                                        </v-col>
                                        <v-col cols="5" md="3" class="mt-n1 text-right">
                                            <span class="text-caption">Total</span><br/>
                                            <div v-if="progreso_mes==false">
                                                <h6 class="mt-n1 text-body-2">${{new Intl.NumberFormat("de-DE").format(parseFloat(ventaMensual))}}</h6>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular color="white" size="20" width="2" indeterminate></v-progress-circular>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-card color="warning" dark>
                                <v-card-title>
                                    <v-row>
                                        <v-col cols="12" md="3" class="mt-2">
                                            <v-icon class="ml-1">monetization_on</v-icon>
                                            <span class="ml-1 text-caption">Compra Mensual</span>
                                        </v-col>
                                        <v-col cols="7" md="3" class="mt-2">

                                            <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">                                        
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-chip class="ml-2 text-caption" small @click="menu3 = true" v-bind="attrs" v-on="on" outlined color="white">
                                                        <v-icon class="ml-2">events</v-icon>
                                                        {{date3}}
                                                    </v-chip>                                        
                                                </template>
                                            <v-date-picker v-model="date3" @input="compra_mes(date3)" no-title scrollable></v-date-picker>
                                            </v-menu>
                                            <!-- <v-chip class="ml-2 text-caption" small outlined color="white">
                                                <v-icon class="ml-2">events</v-icon>
                                                {{dateMensual}}
                                            </v-chip> -->

                                        </v-col>
                                        <v-divider inset vertical color="white"></v-divider>
                                        <v-col cols="5" md="3" class="mt-n1 text-right">
                                            <span class="text-caption">Iva</span><br/>
                                            <div v-if="progreso_compra==false">
                                                <h6 class="mt-n1 text-body-2">${{new Intl.NumberFormat("de-DE").format(parseFloat(ivaCompra))}}</h6>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular color="white" size="20" width="2" indeterminate></v-progress-circular>
                                            </div>
                                        </v-col>
                                        <v-col cols="5" md="3" class="mt-n1 text-right">
                                            <span class="text-caption">Total</span><br/>
                                            <div v-if="progreso_compra==false">
                                                <h6 class="mt-n1 text-body-2">${{new Intl.NumberFormat("de-DE").format(parseFloat(compraMensual))}}</h6>
                                            </div>
                                            <div v-else>
                                                <v-progress-circular color="white" size="20" width="2" indeterminate></v-progress-circular>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12" md="12">
                            <strong>{{titulo_02}}</strong>
                            <v-data-table :loading="cargaComision" loading-text="Cargando....." :headers="header_table2" :items="item_table2" class="elevation-1 text-size col-color" hide-default-footer dense>
                                <template v-slot:item.accion="{ item }">
                                    <v-btn @click="RptComision(item.tVendedor)" color="success" x-small>ver</v-btn>
                                    <!-- <v-btn v-if='PagoComisiones.length!=0' @click="RptComision(item.tVendedor)" color="success" x-small>ver</v-btn>
                                    <v-progress-circular v-else :width="2" :size="15" indeterminate color="grey"></v-progress-circular> -->
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-col cols="12" md="12">
                            <strong>Historico de Ventas</strong>
                            <v-divider></v-divider>
                            <div v-if="cargaChart==true">
                                <GChart type="ColumnChart" :data="dataChart"/>
                            </div>
                            <div v-else>
                                <div class="mt-2">
                                    <v-progress-circular :width="2" :size="20" indeterminate color="indigo"></v-progress-circular>
                                    <span class="ml-2 text-caption grey--text">Cargando ultimos 6 meses ...</span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            </div>
        </div>
        <div v-else-if="vista===false" class="mt-5">
        <v-card>
            <v-card-subtitle><v-icon class="red--text">cancel</v-icon><span class="ml-2">Solicitud Denegada</span></v-card-subtitle>
        </v-card>
        </div>        
        <dialogo :loading="loading"  />
        <v-dialog v-model="dialogo_descarga" transition="dialog-bottom-transition" max-width="50%" persistent>
            <v-card>
                <v-btn class="mt-2 ml-2" @click="dialogo_descarga=false" color="red" small dark><v-icon small>close</v-icon>Cerrar</v-btn>
                <v-card-title>Descargar Archivo</v-card-title>
                <v-card-text>
                    <v-container>
                        <div v-if="descarga==''">
                            <span>Calculando Comisiones de {{vende}}...</span>
                            <v-progress-circular class="ml-2" :width="2" :size="15" indeterminate color="grey"></v-progress-circular>
                        </div>
                        <div v-else>
                            <span>Calculo de Comisiones de {{vende}} Finalizado</span>
                            <a :href="descarga" style="text-decoration: none">
                                <v-btn class="ml-2" small color="success" rounded><v-icon class="mr-2 white--text">cloud_download</v-icon>Descargar</v-btn>
                            </a>
                        </div>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
import { GChart } from 'vue-google-charts';
export default {
    name:'NewdashBoard',
    props: ['usu','contrato'],
    components: { GChart,dialogo },
    data() {
        return {
            loading:false,
            titulo_01:'Ventas',
            cargaVentas:true,
            header_table1:[
                {text: 'Sucursal',align: 'left',sortable: true, value: 'tSucursal'},
                {text: 'Neto ($)',align: 'center',sortable: false, value: 'tNeto'},
                {text: 'Iva ($)',align: 'center',sortable: false, value: 'tIva'},
                {text: 'Total ($)',align: 'center',sortable: false, value: 'tTotal'},
                //{text: 'Store',align: 'center',sortable: false, value: 'tStore'}
            ],
            item_table1: [],
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu2:false,
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            menu3:false,
            date3: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),

            dateNow: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateMensual: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            ventaMensual:0,
            ivaMensual:0,
            ventaNow:0,
            ivaNow:0,
            chartData: [],
            dataChart:[],
            cargaChart:false,
            titulo_02:'Comisiones',
            cargaComision:true,
            header_table2:[
                {text: 'Vendedor',align: 'left',sortable: true, value: 'tVendedor'},
                {text: 'Neto ($)',align: 'center',sortable: false, value: 'tNeto'},
                {text: 'Iva ($)',align: 'center',sortable: false, value: 'tIva'},
                {text: 'Total ($)',align: 'center',sortable: false, value: 'tTotal'},
                {text: 'Comision',align: 'center',sortable: false, value: 'accion'},
            ],
            item_table2: [],
            vista:null,
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'6',forma:'T. Debito'},
                {id:'2',forma:'T. Credito'},
                {id:'30',forma:'Credito (30)'},
                {id:'60',forma:'Credito (60)'},
                {id:'90',forma:'Credito (90)'},
                {id:'8',forma:'Transferencia'},
                {id:'10',forma:'Cheque'}
            ],
            PagoComisiones:[],
            descargaComision:[],
            dialog:false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            head_comision:[
                {text: 'Vendedor',align: 'left',sortable: true, value: 'Usuario'},
                {text: 'Venta ($)',align: 'center',sortable: false, value: 'Venta'},
                {text: 'Comision General (%)',align: 'center',sortable: false, value: 'pGeneral'},
                {text: 'Comision General ($)',align: 'center',sortable: false, value: 'ComisionGeneral'},
                {text: 'Tipo de Pago',align: 'center',sortable: false, value: 'Tipo'},
                {text: 'Comision Tipo Pago (%)',align: 'center',sortable: false, value: 'pForma'},
                {text: 'Comision Tipo Pago ($)',align: 'center',sortable: false, value: 'ComisionForma'}
            ],
            descargaCSV:false,

            dialogo_descarga:false,
            descarga:'',
            vende:'',

            ivaCompra:0,
            compraMensual:0,

            progreso_dia:false,
            progreso_mes:false,
            progreso_compra:false,

        }
    },
    async mounted(){
        this.progreso_dia=true;
        this.progreso_mes=true;
        this.progreso_compra=true;
        await this.entrada();
        await this.compras();
        this.progreso_dia=false;
        this.progreso_mes=false;
        this.progreso_compra=false;
    },
    methods:{
        async entrada(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if(datos[0].Permiso_1_1!=0) {
                    this.vista=true;
                    this.cargaVentas=true;
                    this.cargaComision=true;
                    await this.ventas();
                } else { this.vista=false }
            } else { this.vista=false }
        },
        format_fecha(date){
            var d = new Date(date);
            date = [
                ('0' + d.getDate()).slice(-2),
                ('0' + (d.getMonth() + 1)).slice(-2),
                d.getFullYear()
            ].join('-');
            return date;
        },
        async compras(){
            try {
                //obtener primer y ultimo dia del mes
                var d_chart = new Date();
                var primerDia = new Date(d_chart.getFullYear(), d_chart.getMonth(), 1);
                var ultimoDia = new Date(d_chart.getFullYear(), d_chart.getMonth() + 1, 0);
                var fecha1 = [
                    d_chart.getFullYear(),
                    ('0' + (d_chart.getMonth() + 1)).slice(-2),
                    ('0' + primerDia.getDate()).slice(-2)
                ].join('-');
                var fecha2 = [
                    d_chart.getFullYear(),
                    ('0' + (d_chart.getMonth() + 1)).slice(-2),
                    ('0' + ultimoDia.getDate()).slice(-2)
                ].join('-');
                //consultamos las compras del mes
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/compra/nacional/'+this.contrato+'/'+fecha1+'/'+fecha2);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    var sumaTotalCompras=0;
                    var sumaIvaCompras=0;
                    datos.forEach(element => {
                        sumaTotalCompras=sumaTotalCompras+parseFloat(element.Total)-parseFloat(element.Exento);
                        sumaIvaCompras=sumaIvaCompras+parseFloat(element.Iva);
                    });
                    this.compraMensual=Math.round(sumaTotalCompras);
                    this.ivaCompra=Math.round(sumaIvaCompras);
                }
            } catch (error) {
                console.log(error)
            }
        },
        async ventas(){

            // ventas dataTable ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

            var fecha1='';
            var fecha2='';
            var response=null;
            var datos=null;
            var d = new Date();
            //console.log(d.getDate()+'-'+(d.getMonth()+1)+'-'+d.getFullYear());
            //d.setMonth(d.getMonth() - 1);
            var h = new Date();

            var desde='';
            var hasta='';

            if(d.getMonth()==0){
                desde = [
                    '28',
                    '12',
                    d.getFullYear()-1
                ].join('-');
                fecha1 = [
                    d.getFullYear()-1,
                    '12',
                    '28'
                ].join('-');
            } else {
                desde = [
                    '28',
                    ('0' + d.getMonth()).slice(-2),
                    d.getFullYear()
                ].join('-');
                fecha1 = [
                    d.getFullYear(),
                    ('0' + d.getMonth()).slice(-2),
                    '28'
                ].join('-');                
            };
            hasta = [
                '27',
                ('0' + (h.getMonth() + 1)).slice(-2),
                h.getFullYear()
            ].join('-');
            fecha2 = [
                h.getFullYear(),
                ('0' + (h.getMonth() + 1)).slice(-2),
                '27'
            ].join('-');
            this.titulo_01='Ventas desde '+desde+' hasta '+hasta;
            response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/ventas/'+ this.contrato +'/'+fecha1+'/'+fecha2);
            datos=response.data;
            if (datos[0].existe=='si'){
                //filtramos x sucursal
                const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
                const datos1=response1.data;
                if (datos1[0].existe=='si'){
                    datos1.forEach(element => {
                        //filtramos array datos
                        var arraydatos=[];
                        arraydatos = datos.filter(filtro => filtro.Sucursal==element.ID);
                        if (arraydatos.length!=0){
                            var txtSucursal=element.Nombre;
                            //sumo los totales x sucursal
                            var totalNeto=0;
                            var totalIva=0;
                            var totalTotal=0;
                            arraydatos.forEach(elementos => {
                                totalNeto=totalNeto+parseFloat(elementos.Neto);
                                totalIva=totalIva+parseFloat(elementos.Iva);
                                totalTotal=totalTotal+parseFloat(elementos.Total);
                            });
                            //agregamos el array a la tabla
                            this.item_table1.push({
                                'tSucursal': txtSucursal,
                                'tNeto':new Intl.NumberFormat("de-DE").format(parseFloat(totalNeto)),
                                'tIva':new Intl.NumberFormat("de-DE").format(parseFloat(totalIva)),
                                'tTotal':new Intl.NumberFormat("de-DE").format(parseFloat(totalTotal)),
                            })
                        }
                    });
                }            
            };
            this.cargaVentas=false;

            // venta Mensual +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

            var ventaMes=0;
            var ivaMes=0;
            fecha1 = [
                h.getFullYear(),
                ('0' + (h.getMonth() + 1)).slice(-2),
                '01'
            ].join('-');
            fecha2 = [
                h.getFullYear(),
                ('0' + (h.getMonth() + 1)).slice(-2),
                ('0' + h.getDate()).slice(-2)
            ].join('-');
            response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/ventas/'+ this.contrato +'/'+fecha1+'/'+fecha2);
            datos=response.data;
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    ventaMes=ventaMes+parseFloat(element.Total)
                    ivaMes=ivaMes+parseFloat(element.Iva)
                });
                this.ventaMensual=ventaMes;
                this.ivaMensual=ivaMes
            };

            // venta Diaria +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

            var ventaDay=0;
            var ivaDay=0;
            fecha1 = [
                h.getFullYear(),
                ('0' + (h.getMonth() + 1)).slice(-2),
                ('0' + h.getDate()).slice(-2)
            ].join('-');
            fecha2 = [
                h.getFullYear(),
                ('0' + (h.getMonth() + 1)).slice(-2),
                ('0' + h.getDate()).slice(-2)
            ].join('-');
            response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/ventas/'+ this.contrato +'/'+fecha1+'/'+fecha2);
            datos=response.data;
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    ventaDay=ventaDay+parseFloat(element.Total)
                    ivaDay=ivaDay+parseFloat(element.Iva)
                });
                this.ventaNow=ventaDay;
                this.ivaNow=ivaDay
            };            

            // Comisiones ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

            var d_comision = new Date();
            //d_comision.setMonth(d_comision.getMonth() - 1);
            var h_comision = new Date();

            var desde_comision='';
            var hasta_comision='';

            if(d_comision.getMonth()==0){
                desde_comision = [
                    '28',
                    '12',
                    d_comision.getFullYear()-1
                ].join('-');
                fecha1 = [
                    d_comision.getFullYear()-1,
                    '12',
                    '28'
                ].join('-');

            } else {
                desde_comision = [
                    '28',
                    ('0' + d_comision.getMonth()).slice(-2),
                    d_comision.getFullYear()
                ].join('-');
                fecha1 = [
                    d_comision.getFullYear(),
                    ('0' + d_comision.getMonth()).slice(-2),
                    '28'
                ].join('-');
            };
            hasta_comision = [
                '27',
                ('0' + (h_comision.getMonth() + 1)).slice(-2),
                h_comision.getFullYear()
            ].join('-');
            fecha2 = [
                h_comision.getFullYear(),
                ('0' + (h_comision.getMonth() + 1)).slice(-2),
                '27'
            ].join('-');

            // Resumen Comisiones

            this.titulo_02='Comisiones desde '+desde+' hasta '+hasta;
            response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/ventas/'+ this.contrato +'/'+fecha1+'/'+fecha2);
            datos=response.data;
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.item_table2.push({
                        'tVendedor': element.Usuario,
                        'tNeto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Neto)),
                        'tIva':new Intl.NumberFormat("de-DE").format(parseFloat(element.Iva)),
                        'tTotal':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                    })
                });
            };
            this.cargaComision=false;

            // Detalle Comisiones

            // response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/calculo/comision/venta/'+ this.contrato +'/'+fecha1+'/'+fecha2);
            // datos=response.data;
            // if (datos[0].existe=='si'){
            //     this.PagoComisiones=datos
            // };

            // chartData +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

            this.cargaChart=false;
            this.chartData=[];
            this.chartData.push(['Mes', 'Ventas']);
            var d_chart = new Date();
            for (let index = 1; index <= 6; index++) {
                d_chart.setMonth(d_chart.getMonth() - 1);
                //obtener primer y ultimo dia del mes

                fecha1 = [
                    d_chart.getFullYear(),
                    ('0' + d_chart.getMonth()).slice(-2),
                    '28'
                ].join('-');

                fecha2 = [
                    d_chart.getFullYear(),
                    ('0' + (d_chart.getMonth() + 1)).slice(-2),
                    '27'
                ].join('-');

                // var primerDia = new Date(d_chart.getFullYear(), d_chart.getMonth(), 1);
                // var ultimoDia = new Date(d_chart.getFullYear(), d_chart.getMonth() + 1, 0);

                // fecha1 = [
                //     d_chart.getFullYear(),
                //     ('0' + (d_chart.getMonth() + 1)).slice(-2),
                //     ('0' + primerDia.getDate()).slice(-2)
                // ].join('-');
                // fecha2 = [
                //     d_chart.getFullYear(),
                //     ('0' + (d_chart.getMonth() + 1)).slice(-2),
                //     ('0' + ultimoDia.getDate()).slice(-2)
                // ].join('-');



                //consultamos las ventas
                response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/ventas/'+ this.contrato +'/'+fecha1+'/'+fecha2);
                datos=response.data;
                if (datos[0].existe=='si'){
                    var ventasChart=0;
                    datos.forEach(element => {
                        ventasChart=ventasChart+parseFloat(element.Total)
                    });
                    //agregamos el array al chart
                    var mesChart=(d_chart.getMonth() + 1);
                    ventasChart=parseFloat(ventasChart);
                    switch (mesChart) {
                    case 1:
                        this.chartData.push(['Ene', ventasChart]);
                        break;
                    case 2:
                        this.chartData.push(['Feb', ventasChart]);
                        break;
                    case 3:
                        this.chartData.push(['Mar', ventasChart]);
                        break;
                    case 4:
                        this.chartData.push(['Abr', ventasChart]);
                        break;
                    case 5:
                        this.chartData.push(['May', ventasChart]);
                        break;
                    case 6:
                        this.chartData.push(['Jun', ventasChart]);
                        break;
                    case 7:
                        this.chartData.push(['Jul', ventasChart]);
                        break;
                    case 8:
                        this.chartData.push(['Ago', ventasChart]);
                        break;
                    case 9:
                        this.chartData.push(['Sep', ventasChart]);
                        break;
                    case 10:
                        this.chartData.push(['Oct', ventasChart]);
                        break;
                    case 11:
                        this.chartData.push(['Nov', ventasChart]);
                        break;
                    case 12:
                        this.chartData.push(['Dic', ventasChart]);
                        break;
                    }
                };
            };
            this.dataChart=this.chartData;
            this.cargaChart=true;
        },
        async CargaComisiones(){
            this.PagoComisiones=[];
            var d_comision = new Date();
            //d_comision.setMonth(d_comision.getMonth() - 1);
            var h_comision = new Date();

            var fecha1='';
            var fecha2='';
            var desde_comision='';
            var hasta_comision='';

            if(d_comision.getMonth()==0){
                desde_comision = [
                    '28',
                    '12',
                    d_comision.getFullYear()-1
                ].join('-');
                fecha1 = [
                    d_comision.getFullYear()-1,
                    '12',
                    '28'
                ].join('-');

            } else {
                desde_comision = [
                    '28',
                    ('0' + d_comision.getMonth()).slice(-2),
                    d_comision.getFullYear()
                ].join('-');
                fecha1 = [
                    d_comision.getFullYear(),
                    ('0' + d_comision.getMonth()).slice(-2),
                    '28'
                ].join('-');
            };
            hasta_comision = [
                '27',
                ('0' + (h_comision.getMonth() + 1)).slice(-2),
                h_comision.getFullYear()
            ].join('-');
            fecha2 = [
                h_comision.getFullYear(),
                ('0' + (h_comision.getMonth() + 1)).slice(-2),
                '27'
            ].join('-');

            // Detalle Comisiones

            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/calculo/comision/venta/'+ this.contrato +'/'+fecha1+'/'+fecha2);
            const datos=response.data;
            if (datos[0].existe=='si'){
                this.PagoComisiones=datos
            };
        },
        async RptComision(vendedor){
            this.vende=vendedor;
            this.descarga='';
            this.dialogo_descarga=true;
            try {
                //Buscamos si el usuario tiene comisiones
                let comiGeneral=0 // Tarjeta de Credito o Debito
                let comisionTarjetas=0 // Contado al dia con descuento
                let ComisionContadoConDescuento=0 // contado al dia sin descuento
                let ComisionContadoSinDescuento=0 // credito/Cheque a fecha con descuento
                let ComisionCheque=0 // credito/Cheque a fecha con descuento
                let ComisionCreditoConDescuento=0 // credito/cheque a fecha sin descuento
                let ComisionCreditoSinDescuento=0 // credito/cheque a fecha sin descuento
                let sumaComisiones=0;
                let ComisionGerente='no'
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/dato/comision/usuario/'+this.contrato+'/'+vendedor);
                const datos=response.data;
                if (datos[0].existe=='si'){
                        ComisionGerente=datos[0].Gerente;
                        comiGeneral=parseFloat(datos[0].Comision);
                        comisionTarjetas=parseFloat(datos[0].Tarjetas);
                        ComisionCheque=parseFloat(datos[0].Cheque);
                        ComisionContadoConDescuento=parseFloat(datos[0].ContadoConDes);
                        ComisionCreditoConDescuento=parseFloat(datos[0].CreditoConDes);
                        ComisionContadoSinDescuento=parseFloat(datos[0].ContadoSinDes);
                        ComisionCreditoSinDescuento=parseFloat(datos[0].CreditoSinDes);
                        sumaComisiones=comiGeneral+comisionTarjetas+ComisionCheque+
                            ComisionContadoConDescuento+ComisionCreditoConDescuento+
                            ComisionContadoSinDescuento+ComisionCreditoSinDescuento;
                };
                if(sumaComisiones!=0){
                    // calculo de comisiones
                    await this.CargaComisiones();
                    let arrayCalculo=[];
                    let arrayComision=[];
                    if(ComisionGerente!='si'){
                        arrayComision = this.PagoComisiones.filter(res => res.Usuario==vendedor);
                    } else {
                        arrayComision = this.PagoComisiones 
                    };
                    arrayComision.forEach(element => {
                        let fieldGeneral=0;
                        let fieldForma=0;
                        let pForma=0;
                        let txtForma='';
                        //Comision General
                        fieldGeneral=parseFloat(element.Total)*comiGeneral;
                        //buscar forma
                        const found = this.itemPAGO.find(f => parseInt(f.id)==parseInt(element.TipoPago));
                        if(found){ txtForma=found.forma };
                        switch (parseInt(element.TipoPago)) {
                        // {id:'1',forma:'Efectivo'},
                        // {id:'8',forma:'Transferencia'},
                        // {id:'2',forma:'T. Credito'},
                        // {id:'6',forma:'T. Debito'},
                        // {id:'30',forma:'Credito (30)'},
                        // {id:'60',forma:'Credito (60)'},
                        // {id:'90',forma:'Credito (90)'},
                        // {id:'10',forma:'Cheque'}
                            case 1:
                            case 8:
                                if(parseFloat(element.Descuento)!=0){
                                    // contado con descuento
                                    fieldForma=parseFloat(element.Total)*ComisionContadoConDescuento;
                                    pForma=ComisionContadoConDescuento
                                } else {
                                    // contado sin descuento
                                    fieldForma=parseFloat(element.Total)*ComisionContadoSinDescuento;
                                    pForma=ComisionContadoSinDescuento
                                };
                                break;
                            case 2:
                            case 6:
                                // tarjeta credito / debito
                                fieldForma=parseFloat(element.Total)*comisionTarjetas;
                                pForma=comisionTarjetas;
                                break;
                            case 30:
                            case 60:
                            case 90:
                                if(parseFloat(element.Descuento)!=0){
                                    // credito con descuento
                                    fieldForma=parseFloat(element.Total)*ComisionCreditoConDescuento;
                                    pForma=ComisionCreditoConDescuento
                                } else {
                                    // credito sin descuento
                                    fieldForma=parseFloat(element.Total)*ComisionCreditoSinDescuento;
                                    pForma=ComisionCreditoSinDescuento
                                };
                                break;
                            case 10:
                                // cheque
                                fieldForma=parseFloat(element.Total)*ComisionCheque;
                                pForma=ComisionCheque;
                                break;
                        };
                        fieldGeneral=parseFloat(fieldGeneral)/100;
                        fieldForma=parseFloat(fieldForma)/100;
                        arrayCalculo.push({
                            'Usuario':element.Usuario,
                            'Venta':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                            'pGeneral':new Intl.NumberFormat("de-DE").format(parseFloat(comiGeneral)),
                            'ComisionGeneral':new Intl.NumberFormat("de-DE").format(parseFloat(fieldGeneral)),
                            'nTipo':element.TipoPago,
                            'Tipo':txtForma,
                            'pForma':new Intl.NumberFormat("de-DE").format(parseFloat(pForma)),
                            'ComisionForma':new Intl.NumberFormat("de-DE").format(parseFloat(fieldForma)),
                            'fechaDte':element.Fecha,
                            'numeroDte':element.Numero,
                            'tipoDte':element.TipoDte,
                        })
                    });
                    this.descargaComision=arrayCalculo;
                    await this.descargaComisiones(vendedor);
                } else {
                    console.log('sin Comisiones')
                }
            } catch (error) {
              console.log(error)  
            }
        },
        async descargaComisiones(vendedor){
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            const nameFile = 'Comision_'+vendedor+'_'+formatDate(date);
            let csv;
            csv=[];
            let params;
            let response;
            //titulo
            csv.push({
                'fechaDte':'Fecha',
                'Usuario':'Vendedor',
                'numeroDte':'Numero Dte',
                'tipoDte':'Tipo Dte',
                'Venta':'Total Venta',
                'pGeneral':'Comision General(%)',
                'ComisionGeneral':'Comision General ($)',
                'Tipo':'Tipo de Pago',
                'pForma':'Comision Tipo Pago (%)',
                'ComisionForma':'Comision Tipo Pago ($)',
            });
            //datos
            this.descargaComision.forEach(element => {
                csv.push({
                    'fechaDte':element.fechaDte,
                    'Usuario':element.Usuario,
                    'numeroDte':element.numeroDte,
                    'tipoDte':element.tipoDte,
                    'Venta':element.Venta,
                    'pGeneral':element.pGeneral,
                    'ComisionGeneral':element.ComisionGeneral,
                    'Tipo':element.Tipo,
                    'pForma':element.pForma,
                    'ComisionForma':element.ComisionForma
                })
            });
            params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
            response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
            if (response.data=='si'){
                this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
            }
        },
        async venta_fecha(fec){
            this.progreso_dia=true;
            try {
                var ventaDay=0;
                var ivaDay=0;
                const fecha1=fec;
                const fecha2=fec;
                this.ventaNow=0;
                this.ivaNow=0;
                this.menu1 = false;
                // const fecha1 = [
                //     h.getFullYear(),
                //     ('0' + (h.getMonth() + 1)).slice(-2),
                //     ('0' + h.getDate()).slice(-2)
                // ].join('-');
                // const fecha2 = [
                //     h.getFullYear(),
                //     ('0' + (h.getMonth() + 1)).slice(-2),
                //     ('0' + h.getDate()).slice(-2)
                // ].join('-');
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/ventas/'+ this.contrato +'/'+fecha1+'/'+fecha2);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        ventaDay=ventaDay+parseFloat(element.Total)
                        ivaDay=ivaDay+parseFloat(element.Iva)
                    });
                    this.ventaNow=ventaDay;
                    this.ivaNow=ivaDay
                };                       
            } catch (error) {
                console.log(error)
            }
            this.progreso_dia=false;
        },
        async compra_mes(fec){
            this.progreso_compra=true;
            try {
                this.compraMensual=0;
                this.ivaCompra=0;
                this.menu3 = false;
                this.date3 = fec.substr(0, 7);
                let d_chart=new Date(fec+'T00:00:00');
                d_chart.setMonth(d_chart.getMonth());
                //obtener primer y ultimo dia del mes
                const primerDia = new Date(d_chart.getFullYear(), d_chart.getMonth(), 1);
                const ultimoDia = new Date(d_chart.getFullYear(), d_chart.getMonth() + 1, 0);
                const fecha1 = [
                    d_chart.getFullYear(),
                    ('0' + (d_chart.getMonth() + 1)).slice(-2),
                    ('0' + primerDia.getDate()).slice(-2)
                ].join('-');
                const fecha2 = [
                    d_chart.getFullYear(),
                    ('0' + (d_chart.getMonth() + 1)).slice(-2),
                    ('0' + ultimoDia.getDate()).slice(-2)
                ].join('-');
                //consultamos las compras del mes
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/compra/nacional/'+this.contrato+'/'+fecha1+'/'+fecha2);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    var sumaTotalCompras=0;
                    var sumaIvaCompras=0;
                    datos.forEach(element => {
                        sumaTotalCompras=sumaTotalCompras+parseFloat(element.Total)-parseFloat(element.Exento);
                        sumaIvaCompras=sumaIvaCompras+parseFloat(element.Iva);
                    });
                    this.compraMensual=Math.round(sumaTotalCompras);
                    this.ivaCompra=Math.round(sumaIvaCompras);
                }
            } catch (error) {
                console.log(error)
            }
            this.progreso_compra=false;
        },
        async venta_mes(fec){
            this.progreso_mes=true;
            try {
                var ventaMes=0;
                var ivaMes=0;
                this.ventaMensual=0;
                this.ivaMensual=0;
                this.menu2 = false;
                this.date2 = fec.substr(0, 7);
                let d_chart=new Date(fec+'T00:00:00');
                d_chart.setMonth(d_chart.getMonth());
                //obtener primer y ultimo dia del mes
                const primerDia = new Date(d_chart.getFullYear(), d_chart.getMonth(), 1);
                const ultimoDia = new Date(d_chart.getFullYear(), d_chart.getMonth() + 1, 0);
                const fecha1 = [
                    d_chart.getFullYear(),
                    ('0' + (d_chart.getMonth() + 1)).slice(-2),
                    ('0' + primerDia.getDate()).slice(-2)
                ].join('-');
                const fecha2 = [
                    d_chart.getFullYear(),
                    ('0' + (d_chart.getMonth() + 1)).slice(-2),
                    ('0' + ultimoDia.getDate()).slice(-2)
                ].join('-');
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/v1/new/dashboard/ventas/'+ this.contrato +'/'+fecha1+'/'+fecha2);
                const datos=response.data;               
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        ventaMes=ventaMes+parseFloat(element.Total)
                        ivaMes=ivaMes+parseFloat(element.Iva)
                    });
                    this.ventaMensual=ventaMes;
                    this.ivaMensual=ivaMes
                };
            } catch (error) {
               console.log(error) 
            }
            this.progreso_mes=false;
        },
    }
}
</script>
<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">CLIENTE</v-card-title>
            <v-card-text>
                <v-form class="mt-10">
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2" class="form-space">
                                <v-text-field class='text-caption' v-model="rut" label="Rut" outlined dense required :disabled="disabledRut" :filled="filledRut"></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <v-btn @click="enviarRut(rut)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="3" class="form-space">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nuevo !
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">>>> Rut: 99999999-X</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="4" class="form-space">
                                <v-text-field class='text-caption' v-model="nombre" label="Nombre" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="2" class="form-space">
                                <v-select class='text-caption' v-model="estado" :items="estados" label="Estado" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                            </v-col> 
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12" md="7" class="form-space">
                                        <v-text-field class='text-caption' v-model="direccion" label="Direccion" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="5" class="form-space">
                                        <v-text-field class='text-caption' v-model="comuna" label="Comuna" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="5" class="form-space">
                                        <v-text-field class='text-caption' v-model="ciudad" label="Ciudad" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>                            
                                    <v-col cols="12" md="7" class="form-space">
                                        <v-text-field class='text-caption' v-model="email" label="E-mail"  outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="5" class="form-space">
                                        <v-text-field class='text-caption' v-model="telefono" label="Telefono" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="7" class="form-space">
                                        <v-text-field class='text-caption' v-model="giro" label="Giro" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="5" class="form-space">
                                        <v-text-field class='text-caption' v-model="contacto" label="Contacto" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="7" class="form-space">
                                        <v-text-field class='text-caption' v-model="observacion" label="Observacion" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6" class="form-space">
                                <v-card>
                                <v-card-text>
                                <v-row> 
                                    <v-col cols="12" md="12">                                   
                                        <strong >Datos Adicionales</strong>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-6">
                                    <v-col cols="12" md="4" class="form-space">
                                        <v-checkbox style="margin-top:5px" v-model="ck_credito" label="Linea de Credito" :disabled="disabledDatos" :filled="filledDatos"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="4" class="form-space">
                                        <v-text-field class='text-caption' v-model="cuenta" label="Cuenta" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" class="form-space">
                                        <v-text-field class='text-caption' v-model="banco" label="Banco" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="10" md="11" class="form-space">
                                        <p class="text-end text-caption">Restringe Pago con Cheque</p>
                                    </v-col>
                                    <v-col cols="2" md="1" class="form-space">
                                        <v-checkbox v-model="ck_cheque" style="margin-top:-5px" :disabled="disabledDatos" :filled="filledDatos"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="6" class="form-space">
                                        <v-select :hint="name_vendedor" persistent-hint v-on:change="search_vendedor(vendedor)" class='text-caption' v-model="vendedor" :items="vendedores" label="Vendedor" item-text="vendedor" item-value='nombre' dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col>
                                    <v-col cols="8" md="3" class="form-space">
                                        <p class="text-end text-caption mt-2">Descuento (%)</p>
                                    </v-col>
                                    <v-col cols="4" md="3" class="form-space">
                                        <v-text-field class='text-caption' v-model="descuento" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                </v-card>
                                <v-row class="mt-1">
                                    <v-col cols="12" md="12">
                                    <div class="float-end">
                                        <v-btn  @click="newCliente()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                        <v-btn  @click="saveCliente()" class="mr-5" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                                    </div>
                                    </v-col>
                                </v-row>                                        
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-20px">
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />
    </div>    
</template>
<style>
.form-space {
    margin-top:-35px;
}
.text-space {
    margin-top:-25px;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Cliente',
    components:{dialogo, snack},
    props:['usu','contrato'],
    data(){
        return{
            //contrato:'123',
            disabledRut:false,
            filledRut:false,
            disabledDatos:true,
            filledDatos:true,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',
            rut: '',
            nombre: '',
            direccion:'',
            comuna:'',
            ciudad:'',
            email: '',
            telefono:'',
            observacion:'',
            estados:['V','N'],
            estado:'V',
            giro:'',
            //new datos
            contacto:'',
            ck_credito:false,
            cuenta:'',
            banco:'',
            ck_cheque:false,
            vendedor:'',
            name_vendedor:'',
            vendedores:[],
            descuento:'',            
        }
    },
    async mounted(){
        await this.buscaVendedores();
    },
    methods:{
        enviarRut(rutCompleto){
            let ruts=this.validaRut(rutCompleto);
            if (ruts){
                this.verCliente(rutCompleto)
            } else {
                this.Msg='Rut no Valido'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        validaRut(rutCompleto){
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			    return false;
		    var tmp 	= rutCompleto.split('-');
		    var digv	= tmp[1]; 
		    var ruti 	= tmp[0];
		    if ( digv == 'K' ) digv = 'k' ;
		    return (this.dv(ruti) == digv );
        },
        dv(T){
            var M=0,S=1;
    		for(;T;T=Math.floor(T/10))
	    		S=(S+T%10*(9-M++%6))%11;
		    return S?S-1:'k';
        },
        search_vendedor(vende){
            this.name_vendedor=vende;
        },
        async buscaVendedores(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/report/entidad/Usuario/'+this.contrato+'/0/0');
            const datos=response.data;
            if (datos[0].existe=='si'){
                this.vendedores=[];
                this.vendedores.push({
                        'id':0,
                        'vendedor':'ninguno',
                        'nombre':''
                    })        
                datos.forEach(element => {
                    this.vendedores.push({
                        'id':element.ID,
                        'vendedor':element.Usuario,
                        'nombre':element.Nombre
                    })        
                });
            }
        },
        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.nombre='';
            this.direccion='';
            this.ciudad='';
            this.comuna='';
            this.telefono='';
            this.email='';
            this.observacion='';
            this.estado='V';
            this.giro='';
            this.contacto='';
            this.ck_credito=false;
            this.cuenta='';
            this.banco='';
            this.ck_cheque=false;
            this.vendedor='';
            this.name_vendedor='';
            this.descuento='';
        },
        async verCliente(xRut){
             this.loading=true;
             this.msgbox=false;
             this.clsInput();
            if(xRut.trim()!=''){
                this.disabledRut=true;
                this.filledRut=true;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/clientes/'+this.contrato+'/'+ xRut);
                const datos=response.data;
                console.log(datos);
                if (datos[0].existe=='no'){
                    this.existe='no'
                    this.restoDatos(false);
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newCliente()

                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.rut=datos[0].Rut;
                    this.nombre=datos[0].Nombre;
                    this.direccion=datos[0].Direccion;
                    this.ciudad=datos[0].Ciudad;
                    this.comuna=datos[0].Comuna;
                    this.telefono=datos[0].Telefono;
                    this.email=datos[0].Email;
                    this.observacion=datos[0].Observacion;
                    this.estado=datos[0].Estado;
                    this.giro=datos[0].Giro;
                    this.contacto=datos[0].Contacto;
                    if(datos[0].Credito!=0){this.ck_credito=true} else {this.ck_credito=false};
                    this.cuenta=datos[0].Cuenta;
                    this.banco=datos[0].Banco;
                    if(datos[0].Cheque!=0){this.ck_cheque=true} else {this.ck_cheque=false};
                    this.vendedor=datos[0].Vendedor;
                    this.search_vendedor(this.vendedor);
                    this.descuento=datos[0].Descuento;
                    this.restoDatos(false);
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        async saveCliente(){
             this.loading=true;
             this.msgbox=false;
            if (this.contrato!='' &&
                this.rut!='' && this.nombre!=''){
                //ck
                let pck_credito=0;
                let pck_cheque=0
                if (this.ck_credito===true) {pck_credito=1};
                if (this.ck_cheque===true) {pck_cheque=1};
                var parametros={
                    'contrato': this.contrato,
                    'rut': this.rut,
                    'nombre': this.nombre,
                    'direccion': this.direccion,
                    'ciudad': this.ciudad,
                    'comuna': this.comuna,
                    'telefono': this.telefono,
                    'email': this.email,
                    'giro':this.giro,
                    'observacion': this.observacion,
                    'contacto': this.contacto,
                    'ck_credito':pck_credito,
                    'cuenta':this.cuenta,
                    'banco':this.banco,
                    'ck_cheque':pck_cheque,
                    'vendedor':this.vendedor,
                    'descuento':this.descuento,            
                    'estado': this.estado};
                if (this.existe=='si'){
                    // actualizar
                    const response = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/clientes/actualiza/'+this.rut,parametros);
                    const datos=response.data;
                    console.log(datos);
                    if (datos=='si'){                
                        this.Msg='Modificacion de Cliente Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newCliente()
                    }
                }
                if (this.existe=='no'){
                    // ingresar                    
                    const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/clientes/nuevo',parametros);
                    const datos=response.data;
                    console.log(datos);
                    if (datos=='si'){ 
                        this.existe='si';               
                        this.Msg='Nuevo Cliente Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newCliente()
                    }
                }
                // if (this.existe==''){
                //     this.Msg='Tiene que validar Rut'
                //     this.msgbox=true;
                //     this.color='red'
                // }            
            } else {
                    this.Msg='Rut y Nombre obligatorios'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
            }
            this.loading=false;
        },
        newCliente(){
            this.loading=true;
            this.msgbox=false;
            this.rut='';
            this.clsInput();
            this.disabledRut=false;
            this.filledRut=false;
            this.existe='';
            this.loading=false;
            this.restoDatos(true);
        },
    }
}
</script>
<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Importaciones</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Importaciones</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">      
          <span>Solicitud</span>
          <v-icon>{{SolicitaIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">      
          <span>Importación</span>
          <v-icon>{{ImportaIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp2">
          <span>Costo</span>
          <v-icon>{{CostoIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(3)" :disabled="comp3">
          <span>Integración</span>
          <v-icon>{{IntegraIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
      
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>
<script>
import axios from 'axios';
import Solicita from '@/components/Solicita.vue';
import Importa from '@/components/Importa.vue';
import Costo from '@/components/Costo.vue';
import Integra from '@/components/Integra.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Pedidos',
  components: { Solicita, Importa, Costo, Integra ,dialogo},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      SolicitaIcon:'monetization_on',
      ImportaIcon:'monetization_on',
      CostoIcon:'monetization_on',
      IntegraIcon:'monetization_on',
      IconDefault:'monetization_on',
      comp0:true,
      comp1:true,
      comp2:true,
      comp3:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
   methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Importa!=0) {
            this.comp0=false;
            this.comp1=false; 
            this.comp2=false;
            this.comp3=false;
            this.moduloActivo();
        } else {
            this.comp0=true;
            this.comp1=true;
            this.comp2=true;
            this.comp3=true;
        }
      } 
      this.loading=false;
//           
    },
    moduloActivo(){
      if (this.comp0!=true){
          this.componente='Solicita';
          this.SolicitaIcon=this.IconSelect;
          this.ImportaIcon=this.IconDefault;
          this.CostoIcon=this.IconDefault;
          this.IntegraIcon=this.IconDefault;
          this.value=0
      } else {
          if (this.comp1!=true){
            this.componente='Importa';
            this.SolicitaIcon=this.IconDefault;
            this.ImportaIcon=this.IconSelect;
            this.CostoIcon=this.IconDefault;
            this.IntegraIcon=this.IconDefault;
            this.value=1
          } else {
            if (this.comp2!=true){
              this.componente='Costo';
              this.SolicitaIcon=this.IconDefault;
              this.ImportaIcon=this.IconDefault;
              this.CostoIcon=this.IconSelect;
              this.IntegraIcon=this.IconDefault;
              this.value=2
            } else {
              if (this.comp3!=true){
                this.componente='Integra';
                this.SolicitaIcon=this.IconDefault;
                this.ImportaIcon=this.IconDefault;
                this.CostoIcon=this.IconDefault;
                this.IntegraIcon=this.IconSelect;
                this.value=3
              } else {
                  this.CostoIcon=this.IconDefault;
                  this.IntegraIcon=this.IconDefault;
                  this.ImportaIcon=this.IconDefault;
                  this.SolicitaIcon=this.IconDefault;
              }
            }
          }
      }
      this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Solicita';
          this.SolicitaIcon=this.IconSelect;
          this.ImportaIcon=this.IconDefault;
          this.CostoIcon=this.IconDefault;
          this.IntegraIcon=this.IconDefault;
          break;
        case 1:
          vComponente='Importa';
          this.SolicitaIcon=this.IconDefault;
          this.ImportaIcon=this.IconSelect;
          this.CostoIcon=this.IconDefault;
          this.IntegraIcon=this.IconDefault;
        break;
        case 2:
          vComponente='Costo';
          this.SolicitaIcon=this.IconDefault;
          this.ImportaIcon=this.IconDefault;
          this.CostoIcon=this.IconSelect;
          this.IntegraIcon=this.IconDefault;
        break;
        case 3:
          vComponente='Integra';
          this.SolicitaIcon=this.IconDefault;
          this.ImportaIcon=this.IconDefault;
          this.CostoIcon=this.IconDefault;
          this.IntegraIcon=this.IconSelect;
        break;
      }
      this.componente=vComponente;
    }
  }
}
</script>
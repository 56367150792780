<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">
                INTEGRACIÓN
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row class="mt-10">
                            <v-col cols="8" md="2" class="form-space">
                                <!-- <v-text-field class='text-caption' @keypress="onlyNumber" v-model="carpeta" label="N° Carpeta" :disabled="soloCarpeta" :filled="soloCarpeta" outlined dense></v-text-field> -->
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="carpeta" label="N° Carpeta" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <!-- <v-btn class="mt-1" @click="searchIntegra()" color="teal" fab x-small dark><v-icon>search</v-icon></v-btn>                                     -->
                                <v-btn class="mt-1" @click="dialogo_importa=true" color="teal" fab x-small dark><v-icon>search</v-icon></v-btn>                                    
                            </v-col>
                            <v-col cols="12" md="4" class="form-space">
                                <v-text-field class='text-caption' v-model="proveedor" label="Proveedor" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <v-text-field class='text-caption' v-model="moneda" label="Moneda" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <v-text-field class='text-caption' v-model="paridad" label="Paridad" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <v-text-field class='text-caption' v-model="gasto" label="% Gastos" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <!-- <v-col cols="4" md="1" class="form-space">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="proPrecio" label="% Precio" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1" class="form-space">
                                <v-btn @click="precioVenta()" class="ml-2 mt-2" small icon color="indigo"><v-icon>check_circle</v-icon></v-btn>
                            </v-col> -->
                            <v-col cols="12" md="12" class="form-space">
                                <v-data-table dense dark :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size mt-1" @page-count="pageCount = $event">
                                <template v-slot:item.precio="{ item }">
                                    <v-edit-dialog large persistent>
                                        <div>{{item.precio}}</div>
                                        <template v-slot:input>
                                            <div class="mt-4 text-h6">Ingrese Precio</div>
                                            <v-text-field @keypress="onlyNumber" v-model="item.precio" label="Edit" single-line clearable></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="7" md="4" class="text-center">
                                        <v-select class='text-caption' v-model="sucursal" :items="itemsucursal" item-text="name" item-value="code" label="Sucursal" dense outlined></v-select>
                                    </v-col>
                                    <v-col cols="5" md="3" class="text-center">
                                        <v-btn class="mt-2" @click="cierraIntegra()" small color="red" dark rounded><v-icon small>group_work</v-icon><span class='text-btn'>Integrar</span></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row class="justify-end">
                                    <v-col cols="12" md="3" class="text-center">
                                        <v-btn v-if="btn_save!=true" class="mt-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>                    
                                        <v-btn v-else class="mt-2" @click="savePrecios()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>                    
                                    </v-col>
                                    <v-col cols="12" md="3" class="text-center">
                                            <v-btn class="mt-2" @click="clsInput()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    </v-col>
                                    <v-col cols="12" md="2" class="text-center">
                                        <a v-if="descargaCSV==false" @click="csvReporte()" href="javascript:void(0)" style="text-decoration: none">
                                            <v-btn class="mt-2 ml-2" small color="white" rounded><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></v-btn>
                                        </a>
                                        <a v-else :href="descarga" style="text-decoration: none">
                                            <v-btn class="mt-2 ml-2" small color="white" rounded><v-icon class="blue-grey--text">cloud_download</v-icon></v-btn>
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-30px">
            </v-card-actions>
        </v-card>
        <!-- dialog importaciones -->
        <v-dialog v-model="dialogo_importa" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialogo_importa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :loading="progress" loading-text="Cargando....." @click:row="fila_importa" dense dark :search="busca_importa" :headers="head_importa" :items="items_importa" :page.sync="page_importa" :items-per-page="PerPage_importa"  @page-count="pageCount_importa = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="page_importa" :length="pageCount_importa"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"/>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 8pt !important;
    height: 30px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
.form-space {
    margin-top:-40px;
}
</style>
<script>
import axios from 'axios';
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
export default {
    name:'Integra', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            progress:false,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            carpeta:'',
            soloCarpeta:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            hoy: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            proveedor:'',
            moneda:'',
            paridad:0,
            gasto:0,
            headers:[
                { text: 'SKU', align: 'start', sortable: true, value: 'sku' },
                { text: 'COD. UNIVERSAL', align: 'start', sortable: true, value: 'universal' },
                { text: 'COD. FABRICA', align: 'start', sortable: true, value: 'fabrica' },
                { text: 'DESCRIPCION', align: 'start', sortable: true, value: 'descripcion' },
                { text: 'UNIDAD', align: 'start', sortable: true, value: 'unidad' },
                { text: 'VALOR', align: 'start', sortable: true, value: 'valor' },
                { text: 'GASTOS', align: 'start', sortable: true, value: 'gasto' },
                { text: 'TOTAL CTO. CIF', align: 'start', sortable: true, value: 'total' },
                { text: 'COSTO NAC. UN.', align: 'start', sortable: true, value: 'costo' },
                { text: 'PRECIO VENTA', align: 'start', sortable: true, value: 'precio' }
            ],
            items:[],
            newItems:[],
            proPrecio:0,
            cierre:'',
            itemsucursal:[],
            sucursal:0,
            //dialogo importa
            dialogo_importa:false,
            busca_importa:'',
            page_importa: 1,
            pageCount_importa: 0,
            PerPage_importa: 10,
            head_importa: [
                { text: 'Carpeta', align: 'center', sortable: true, value: 'carpeta', width: '20%' },
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha', width: '20%' },
                { text: 'Proveedor', align: 'start', sortable: true, value: 'proveedor', width: '60%' },
            ],
            items_importa: [],
            descargaCSV:false,
            descarga:'javascript:void(0)',
            btn_save:false,
        }
    },
    async mounted(){
        await this.buscarImporta();
        await this.buscarSucursal()
    },
    methods:{
        async csvReporte(){
            this.loading=true;
            this.descarga='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            const nameFile = 'integra_'+formatDate(date);
            let csv;
            csv=[];
            let params;
            let response;
            if (this.items.length!=0){
                //titulo
                csv.push({
                    'sku':'SKU',
                    'universal':'COD. UNIVERSAL',
                    'fabrica':'COD. FABRICA',
                    'descripcion':'DESCRIPCION',
                    'unidad':'UNIDAD',
                    'valor':'VALOR',
                    'gasto':'GASTOS',
                    'total':'TOTAL CTO. CIF',
                    'costo':'COSTO NAC. UN',
                    'precio':'PRECIO VENTA',
                });
                //datos
                this.items.forEach(element => {
                    csv.push({
                        'sku':element.sku,
                        'universal':element.universal,
                        'fabrica':element.fabrica,
                        'descripcion':element.descripcion,
                        'unidad':element.unidad,
                        'valor':element.valor,
                        'gasto':element.gasto,
                        'total':element.total,
                        'costo':element.costo,
                        'precio':element.precio,
                    })
                });
                params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
                response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/csv/nuevo',params);
                if (response.data=='si'){
                    this.descarga='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                    this.descargaCSV=true;
                };
            };
            this.loading=false;
        },
        fila_importa(fila){
            this.clsInput();
            //console.log(fila);
            this.carpeta=fila.carpeta;
            this.dialogo_importa=false;
            this.searchIntegra();
        },
        async buscarImporta(){
            this.items_importa=[];
            this.progress=true;
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/carpeta/pendientes/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.items_importa.push({
                            'carpeta':element.ID,
                            'fecha':element.Fecha,
                            'proveedor':element.Proveedor
                            })        
                    });
                }
            } catch (error) {
                console.log(error)
            }
            this.progress=false;
        },
        async buscarSucursal(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
            const datos=response.data;
            this.itemsucursal=[];
            //this.itemsucursal.push({"code":"0","name":"Nueva"});
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemsucursal.push({"code":element.ID,"name":element.Nombre})        
                });
            }
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.carpeta='';
            this.soloCarpeta=false;
            this.proveedor='';
            this.moneda='';
            this.paridad=0;
            this.gasto=0;
            this.items=[];
            this.newItems=[];
            this.proPrecio=0;
            this.cierre='';
            this.sucursal=0;
            this.btn_save=false;
        },
        async searchIntegra(){
            this.loading=true;
            try {
                this.cierre='no';
                this.proPrecio=0;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/integra/all/'+this.contrato+'/'+this.carpeta);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    if (datos[0].Estado=='V'){
                        this.proPrecio=datos[0].Porciento;                        
                    } else {
                        this.cierre='si';
                        this.Msg='Carpeta Cerrada'
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();
                    }
                };
                if(this.cierre!='si'){
                    await this.verIntegra();
                }
            } catch (error) {
                console.log(error);
            };
            this.loading=false;
        },
        async verIntegra(){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/detalle/integra/'+this.contrato+'/'+this.carpeta);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.btn_save=true;
                    this.soloCarpeta=true;
                    this.proveedor=datos[0].Proveedor;
                    this.moneda=datos[0].Moneda;
                    this.paridad=datos[0].Paridad;
                    this.gasto=datos[0].Gasto;
                    console.log(datos);
                    this.items=[];
                    datos.forEach(element => {
                        const pGasto=parseFloat(parseFloat(element.Precio)*(parseFloat(element.Gasto)/100)).toFixed(2);
                        const pTotal=parseFloat(parseFloat(element.Precio)+parseFloat(pGasto)).toFixed(2);
                        const pCosto=Math.round(parseFloat(pTotal)*parseFloat(element.Paridad));
                        // const pPrecio=pCosto+(pCosto*(parseFloat(this.proPrecio)/100)); //50%
                        const pPrecio=parseFloat(element.Venta); //50%
                        this.items.push({
                            'sku':element.Sku,
                            'universal':element.Universal,
                            'fabrica':element.Auxiliar,
                            'descripcion':element.Producto,
                            'unidad':element.Cantidad,
                            'valor':element.Precio,
                            'gasto':pGasto,
                            'total':pTotal,
                            'costo':pCosto,
                            'precio':pPrecio
                        })
                    });

                }                
            } catch (error) {
                console.log(error)
            }                    
        },
        precioVenta(){
            if(this.carpeta.trim()!=''){
                if(this.proPrecio.trim()!=''){
                    if(parseFloat(this.proPrecio)!=-1){
                        if (this.items.length!=0){
                            this.loading=true;
                            this.newItems=[];
                            this.items.forEach(element => {
                                const pPrecio=element.costo+(element.costo*(parseFloat(this.proPrecio)/100));
                                this.newItems.push({
                                    'sku':element.sku,
                                    'universal':element.universal,
                                    'fabrica':element.fabrica,
                                    'descripcion':element.descripcion,
                                    'unidad':element.unidad,
                                    'valor':element.valor,
                                    'gasto':element.gasto,
                                    'total':element.total,
                                    'costo':element.costo,
                                    'precio':Math.round(pPrecio)
                                });
                            });
                            this.items=[];
                            this.items=this.newItems;
                            this.loading=false;
                        } else {
                            this.Msg='Sin Lista'
                            this.color='red';
                            this.msgbox=true;
                            this.showSnack();
                        }
                    } else {
                        this.Msg='Ingrese Porcentaje'
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();
                    }
                } else {
                    this.Msg='Sin Porcentaje'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
            } else {
                this.Msg='Ingrese Carpeta'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async savePrecios(){
            if (this.cierre!='si'){
                if(this.carpeta.trim()!=''){
                    this.loading=true;
                    try {
                        //actualizar precio de venta de la importacion
                        let registros=0;
                        for (let index = 0; index < this.items.length; index++) {
                            const element = this.items[index];
                            if(element.precio.trim=='') {element.precio='0'};
                            const params={
                                'contrato':this.contrato,
                                'carpeta':this.carpeta,
                                'sku':element.sku,
                                'venta':element.precio
                            };
                            const response = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/integra/precio/venta/',params);
                            const datos=response.data;
                            if (datos=='si'){ 
                                registros++;
                            } else {
                                element.precio=0;
                            }
                        };
                        if(registros==this.items.length){
                            this.Msg='Proceso Exitoso'
                            this.color='success';
                            this.msgbox=true;
                            this.showSnack();
                            this.btn_save=false;
                        } else {
                            this.Msg='Precios No Actualizados'
                            this.color='warning';
                            this.msgbox=true;
                            this.showSnack();
                        }
                    } catch (error) {
                        console.log(error)
                    }
                    this.loading=false;
                } else {
                    this.Msg='Ingrese Carpeta'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();                    
                }
            } else {
                this.Msg='Carpeta Cerrada'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },

        async saveIntegra_ANT(){
            if (this.cierre!='si'){
                if(this.carpeta.trim()!=''){
                    if(this.proPrecio.trim()!=''){
                        if(parseFloat(this.proPrecio)!=-1){
                            this.loading=true;
                            try {
                                //eliminar integracion
                                const r = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/integra/delete/'+this.contrato+'/'+this.carpeta);
                                const d=r.data;
                                if (d!='error'){
                                    const params={
                                        'contrato':this.contrato,
                                        'carpeta':this.carpeta,
                                        'porciento':this.proPrecio,
                                        'usuario':this.usu,
                                        'fecha':this.hoy,
                                        'estado':'V'
                                    };
                                    const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/integra/nueva',params);
                                    const datos=response.data;
                                    if (datos=='si'){
                                        this.Msg='Datos Ingresados'
                                        this.color='success';
                                        this.msgbox=true;
                                        this.showSnack();
                                    } else {
                                        this.Msg='Carpeta sin Grabar'
                                        this.color='red';
                                        this.msgbox=true;
                                        this.showSnack();
                                    }
                                } else {
                                    this.Msg='Inconsistencia en Carpeta'
                                    this.color='red';
                                    this.msgbox=true;
                                    this.showSnack();
                                }
                            } catch (error) {
                                console.log(error);
                            };
                            this.loading=false;
                        } else {
                            this.Msg='Ingrese Porcentaje'
                            this.color='red';
                            this.msgbox=true;
                            this.showSnack();
                        }
                    } else {
                        this.Msg='Sin Porcentaje'
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();
                    }
                } else {
                    this.Msg='Ingrese Carpeta'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
            } else {
                this.Msg='Carpeta Cerrada'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }
        },
        async cierraIntegra(){
            if (this.cierre!='si'){
                if(this.sucursal!=0){ 
                    if(this.carpeta.trim()!=''){
                        this.loading=true;
                        try {
                            const params={
                                'contrato':this.contrato,
                                'carpeta':this.carpeta,
                                'porciento':this.proPrecio,
                                'usuario':this.usu,
                                'fecha':this.hoy,
                                'estado':'C'
                            };
                            const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/importa/integra/nueva',params);
                            const datos=response.data;
                            if (datos=='si'){
                                this.Msg='Carpeta Cerrada'
                                this.color='success';
                                this.msgbox=true;
                                this.showSnack();
                                this.cierre='si';
                                await this.actualizaStock();
                            } else {
                                this.Msg='Integracion sin Grabar'
                                this.color='red';
                                this.msgbox=true;
                                this.showSnack();
                            }
                        } catch (error) {
                            console.log(error);
                        };
                        this.loading=false;
                    } else {
                        this.Msg='Ingrese Carpeta'
                        this.color='red';
                        this.msgbox=true;
                        this.showSnack();
                    }
                } else {
                    this.Msg='Ingrese Sucursal'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                }
            } else {
                this.Msg='Carpeta Cerrada'
                this.color='red';
                this.msgbox=true;
                this.showSnack();
            }

        },
        async actualizaStock(){
            if (this.items.length!=0){
                for (let index = 0; index < this.items.length; index++) {
                    const element = this.items[index];
                    var parametros1={
                        'contrato': this.contrato,
                        'sku': element.sku,
                        'cantidad': element.unidad,
                        'signo': '0+',
                        'bodega': this.sucursal,
                        'usuario': this.usu,
                        'observa': 'PRODUCTO IMPORTADO',
                        'origen':'Compra'
                    };
                    const response1 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ 
                        //modificar precio producto
                        var parametros2={
                            'contrato': this.contrato,
                            'precio': element.precio
                        };
                        const response2 = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/producto/actualiza/precio/'+element.sku,parametros2);
                        const datos2=response2.data;
                        if (datos2=='si'){ 
                            console.log('datos actualizados')
                        }
                    }                    
                }
            }
        }
    }

}
</script>